<div class="dashboard-container">
  <div class="container-fluid">
    <div class="dashboard-inner">
      <div class="row">
        <div class="col-sm-12" *ngIf="!fromEruQl">
          <!-- <h2 class="f-22 fw-600 mb-0"> Welcome to eru {{fromEruFiles ? 'Files' : 'Auth'}}</h2> -->
          <h2 class="f-22 fw-600 mb-0"> Welcome to eru-{{selectedType}}</h2>
          <!-- <p *ngIf="fromEruRoutes" class="f-14 fw-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p> -->
          <!-- <hr *ngIf="fromEruRoutes"> -->
        </div>
        <div class="col-sm-12" *ngIf="fromEruQl">
          <h2 class="f-22 fw-600 mb-0">Welcome to eru-ql</h2>
        </div>

        <div class="col-md-12 mt-20">
          <div class="row mx-0" *ngIf="!fromEruAuth && fromEruFiles">
            <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToStorage()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/file-hover.svg" alt="">
                  <p class="text-grey">Storage</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.storage}}</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="gotoCreateNewFiles()">Create New</a>
              </div>
            </div>

            <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToKeys()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/key-hover.svg" alt="">
                  <p class="text-grey">Keys</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.keys}}</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="gotoCreateNewKeys()">Create New</a>
              </div>
            </div>
          </div>

          <div class="row mx-0" *ngIf="fromEruQl" >
            <div class="col-md-3 card">
            <div class="card-top d-flex" (click)="goToDatabase()">
              <div class="card-logo">
              <img src="../../../../assets/images/wrapper/ql.svg" alt="">
              <p class="text-grey">Databases</p>
              </div>
              <div class="data-count">
              <h3>{{dashBoardCounts.database}}</h3>
              </div>
            </div>
            <div class="card-actions cursor-pointer" *ngIf="!read" (click)="addDatabase()">
              <a>Create New</a>
            </div>
            </div>
      
            <div class="col-md-3 card" >
            <div class="card-top d-flex" (click)="goToMyQueries()">
              <div class="card-logo">
              <img src="../../../../assets/images/wrapper/my-query.svg" alt="">
              <p class="text-grey">My Queries</p>
              </div>
              <div class="data-count">
              <h3>{{dashBoardCounts.queries}}</h3>
              </div>
            </div>
            <div class="card-actions cursor-pointer" *ngIf="!read" (click)="addQueries()">
              <a>Create New</a>
            </div>
            </div>
    
            <!-- <div class="col-md-3 card" >
            <div class="card-top d-flex" (click)="goToSetting()">
              <div class="card-logo">
              <img src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
              <p class="text-grey">Settings</p>
              </div>
              <div class="data-count">
              <h3>12</h3>
              </div>
            </div>
            <div class="card-actions cursor-pointer" *ngIf="!read">
              <a>Create New</a>
            </div>
            </div> -->
          </div>

          <div class="row mx-0" *ngIf="fromEruAuth && !fromEruFiles">
            <!-- <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToGateway()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/gateway-active.svg" alt="">
                  <p class="text-grey">Gateway</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.gateway}}</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="createNewGateway()">Create New</a>
              </div>
            </div> -->

            <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToAuthorizer()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/authorizer-active.svg" alt="">
                  <p class="text-grey">Authorizers</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.authorizers}}</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="createNewAuthorizer()">Create New</a>
              </div>
            </div>

            <!-- <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToTemplates()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/message-template-active.svg" alt="">
                  <p class="text-grey">Templates</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.templates}}</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="createNewTemplates()">Create New</a>
              </div>
            </div> -->
          </div>

          <div class="row mx-0 eru-routes d-flex flex-wrap" *ngIf="fromEruRoutes">
            <!-- <div class="col-md-3">
              <div class="card justify-content-center">
                <div class="card-top d-flex " (click)="gotoRoutes()">
                  <div class="card-logo col">
                    <img src="../../../../assets/images/icons/erug-routes-red.svg" alt="">
                    <p class="text-grey">Total Routes</p>
                  </div>
                  <div class="data-count col">
                    <h3>{{dashBoardCounts.routes}}</h3>
                  </div>
                </div>
                <div class="card-actions cursor-pointer">
                  <a>Create New</a>
                </div>
              </div>
              
            </div> -->
            <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="gotoRoutes()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/erug-routes-red.svg" alt="">
                  <p class="text-grey">Total Routes</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.routes}}</h3>
                </div>
              </div>    
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="createNewRoutes()">Create New</a>
              </div>         
            </div>
            <div class="col-md-3 card">
              <div class="card-top d-flex" (click)="goToFunctions()">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/erug-functions-square-red.svg" alt="">
                  <p class="text-grey">Function</p>
                </div>
                <div class="data-count">
                  <h3>{{dashBoardCounts.funcGroups}}</h3>
                </div>
              </div>   
              <div class="card-actions cursor-pointer" *ngIf="!read">
                <a (click)="createNewFunctions()">Create New</a>
              </div>          
            </div>
          </div>

          <div class="row mx-0" *ngIf="fromEruGateway">
            <div class="col-md-3 card">
              <div class="card-top d-flex">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
                  <p class="text-grey">Authorizers</p>
                </div>
                <div class="data-count">
                  <h3>03</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer">
                <a>Create New</a>
              </div>
            </div>

            <div class="col-md-3 card">
              <div class="card-top d-flex">
                <div class="card-logo">
                  <img src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
                  <p class="text-grey">Rules</p>
                </div>
                <div class="data-count">
                  <h3>11</h3>
                </div>
              </div>
              <div class="card-actions cursor-pointer">
                <a>Create New</a>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-12" *ngIf="!fromEruRoutes">
          <div class="row detailsArea mt-50 sm-mt-20">
            <div class="col-md-2 col-sm-6 details form-label">
              <p class="f-14 fw-600 text-grey">Last Logged in </p>
              <div class="f-14 fw-400">19 Aug 2022, 3:53 PM</div>
            </div>
            <div class="col-md-2 col-sm-6 details form-label">
              <p class="f-14 fw-600 text-grey">Last Project Accessed</p>
              <div class="f-14 fw-400">NA</div>
            </div>
            <div class="col-md-2 col-sm-6 details form-label">
              <p class="f-14 fw-600 text-grey">Last Service Accessed</p>
              <div class="f-14 fw-400">NA</div>
            </div>
            <div class="col-md-5 col-sm-6 details form-label">
              <p class="f-14 fw-600 text-grey">eru services Enabled</p>
              <div class="f-14 fw-400">eru - Files, eru - Routes, eru - Auth, eru- ql</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="container-wrapper">
      <ul class="dashboard-icons-ul">
        <li class="cursor-pointer">
          <img src="../../../../assets/images/icons/file 1document.svg" alt="" />
          <div class="bLink">View documentation</div>
        </li>
        <li class="cursor-pointer">
          <img src="../../../../assets/images/icons/online-video.svg" alt="" />
          <div class="bLink">Learn through videos</div>
        </li>
        <li class="cursor-pointer">
          <img src="../../../../assets/images/icons/discord.svg" alt="" />
          <div class="bLink">Join our Discord community</div>
        </li>
        <li class="cursor-pointer">
          <img src="../../../../assets/images/icons/github.svg" alt="" />
          <div class="bLink">Visit us on Github</div>
        </li>
      </ul>
    </div>
  </div>
</div>