<div class="invite">
  <div class="wrapper ">
    <div class="f-16 fw-500 title">Invite Users for {{ data.project_name }}</div>
    <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    <mat-divider></mat-divider>
    <div class="mt-10">
      <p class="f-12 f-400 grey">Email Address</p>
    </div>
    <mat-form-field class="mat-chip" appearance="outline" >
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let email of emails"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(email)"
        >
          {{ email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
        class="inviteInputStyle"
          placeholder=""
          #emailInput
          [formControl]="emailCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
      >
        <mat-option
          *ngFor="let name of filteredEmails | async"
          [value]="name.user_email"
        >
  
   
     <ng-container>
   <div class="option_text">
    <div class="f-14 username" >  {{ name.user_first_name }}{{ " " }} {{ name.user_last_name }}</div> 
    <div class="f-12 email">  {{name.user_email}}</div>
   </div>
     </ng-container>
  
   
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-error *ngIf="invalidEmail" class="errText">Invalid Email </mat-error>
    <p class="f-12 f-400 grey">
      Use comma (,) to separate multiple email addressess
    </p>
  
    <!-- <div class="d-flex">
      <section>
        <mat-checkbox #checkbox>Allow complete access</mat-checkbox>
      </section>
    </div> -->
    <ng-container>
      <!-- <ng-container *ngIf="checkbox.checked"> -->
      <div class="grid_container" *ngFor="let ele of rows; let i = index">
        <div class="item2">
          <mat-form-field appearance="outline" >
            <mat-label>Environment</mat-label>
            <mat-select
              [(ngModel)]="ele.environment"
              [disabled]="!selectedProject"
              (selectionChange)="removeEnv($event.value)"
            >
              <ng-container *ngIf="selectedProject">
                <ng-container *ngFor="let env of rows[i].options">
                  <mat-option [value]="env">
                    {{ env.env_name }}
                  </mat-option>
                  <mat-divider></mat-divider>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="item3">
          <mat-form-field appearance="outline">
            <mat-label>Services</mat-label>
            <mat-select
              [(ngModel)]="ele.services"
              multiple
              [disabled]="!ele.environment"
              #select
            >
              <ng-container *ngIf="ele.environment">
                <ng-container *ngFor="let service of ele.environment.services">
                  <mat-option
                    *ngIf="service.service_name"
                    [value]="service"
                    #option
                    (click)="selectionChange(nestedOption, option)"
                  >
                    <div class="d-flex nestedoption_container">
                      {{ service.service_name }}
                      <span (click)="$event.stopPropagation()">
                        <div class="nestedoption">
                          <nested-option
                            #nestedOption
                            [id]="service.service_name"
                            (accessHandler)="accessHandler($event, service)"
                          ></nested-option>
                        </div>
                      </span>
                    </div>
                  </mat-option>
                  <mat-divider></mat-divider>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <button
        mat-button
        class="add_more mt-1"
        (click)="addRow()"
        [disabled]="checkbtn()"
      >
        Add more
      </button>
    </ng-container>
    <div class="actions">
      <button mat-dialog-close class="btn f-14 fw-500 ml-10 btn-cancel">
        Cancel
      </button>
      <button class="btn f-14 fw-500 ml-10 btn-ok" (click)="submit()">
        Invite
      </button>
    </div>
  </div>
  
</div>