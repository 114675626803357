import * as ProjectReducer from "./project.reducer";
import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectProjectState = createFeatureSelector<ProjectReducer.ProjectState>('PROJECT')
// const { selectAll } = AuthReducer.authAdapter.getSelectors();

export const getProjects = createSelector(selectProjectState, (state) => {
    return {
        projects : state.projects
    }
})

export const createProjectResponse = createSelector(selectProjectState, (state) => {
    return {
        createProjectResponse : state.createProjectResponse
    }
})


export const createServiceResponse = createSelector(selectProjectState, (state) => {
    return {
        createServiceResponse : state.createServiceResponse
    }
})
