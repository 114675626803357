<div class="container-fluid variable-tab-container">
    <form [formGroup]="form">
        <div class="row">
            <p class="f-12 fw-400 text-grey col-md-3 w280 mb-style-change" style="padding-left: 0">
                Key
            </p>
        </div>
        <div class="btn-style">
            <button mat-flat-button color="primary" class="w90" [style.opacity]="secret().controls.length==0?'0.8':'1'" [disabled]="secret().controls.length==0" (click)="load()">
                Refresh secrets
            </button>
        </div>
        <div formArrayName="secret">
            <div class=" " *ngFor="let Secret of secret().controls; let i = index" [formGroupName]="i">
                <div class="d-flex mb-20 align-item-baseline change-alignment">
                    <div class="align-items-center d-flex flex-direction-change">
                        <div class="p-0 project-style darkgrey-outline w280 mb-w150">
                            <p class="f-12 fw-400 text-grey col-md-3 w280 displayOnMb" style="padding-left: 0">
                                Key
                            </p>
                            <mat-form-field appearance="outline" class="" [ngClass]="{'disabled':read}">
                                <input formControlName="keyName" matInput type="text" placeholder="eg. Key" />
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class=" ">
                        <div class="p-0 project-style darkgrey-outline ml-15 mb-mobile" *ngIf="!read">
                            <button mat-flat-button color="primary" class="w70 h32"
                                *ngIf="i === secret().controls.length - 1" (click)="saveSecrets(i)">
                                Save
                            </button>
                        </div>
                        <div class="p-0 project-style darkgrey-outline ml-20 d-flex mb-mobile"
                            *ngIf="i !== secret().controls.length - 1">
                            <img class="pointer" [src]="read ? '../../../../assets/images/icons/viewEye.svg' : '../../../../assets/images/icons/edit1.svg'" />
                            <img class="ml-30 pointer" (click)="removeSecret(i)"
                                src="../../../../assets/images/icons/delete.png"  *ngIf="!read"/>
                        </div>
                    </div> -->
                    <div class="d-flex">
                        <div class="p-0 project-style darkgrey-outline ml-20 d-flex mb-mobile">
                            <img *ngIf="!Secret.get('keyName')?.disabled" class="pointer"[src]="read ? '../../../../assets/images/icons/viewEye.svg' : '../../../../../assets/images/logo/save.png'" (click)="saveSecrets(i)"  />
                            <img *ngIf="Secret.get('keyName')?.disabled" class="pointer" (click)="removeSecret(i)"
                                src="../../../../assets/images/icons/delete.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-20 pb-20 mt-20">
            <button class="add-btn" (click)="addSecret()" *ngIf="!read">Add more</button>
        </div>
    </form>
</div>
