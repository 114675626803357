import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  sectionNumber:any = [''];
  project: any[]| any;
  environment: any[]| any;
  product: any[]| any;
  constructor() { }

  ngOnInit(): void {
    this.project = [
      {
        name: "Lamron",
      },
      {
        name: "SmartValues",
      },
    ];
    this.environment = [
      {
        name: "All",
      },
      {
        name: "Development",
      },
    ];
    this.product = [
      {
        name: "eruG-Files, eruG-Auth, eru-functions",
      },
      {
        name: "eruG-ql",
      },
    ];
  }


  addMore(){
    this.sectionNumber.push('');
  }

  delete(index:any){
    this.sectionNumber.splice(index,1);
  }
}
