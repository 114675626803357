import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ProjectFacadeService } from '../../eru-wrapper/state/project/project-facade.service';
import { Observable, catchError, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private httpClient: HttpClient,private projectFacadeService:ProjectFacadeService) {}
  fetchVars(projectName:string,isGateway:boolean=false){

 if(isGateway){
return   this.httpClient.get(
  `${environment.url}/store/gvariables/list`
);
 }else{
  return this.httpClient.get(
    `${environment.url}/store/${projectName}/variables/list`
  );
 }

  }


  getVariablesList(projectName: string, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.get(`${environment.url}/store/${projectName}/sm/list`);
    } else {
      return this.httpClient.get(
        `${environment.url}/store/gsm/load`
      );
    }
  }

  saveVariables(projectName: string, data: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.post(
        `${environment.url}/store/gvariables/savevar`,
        data
      );
    } else {
      return this.httpClient.post(
        `${environment.url}/store/${projectName}/variables/savevar`,
        data
      );
    }
  }

  deleteVariables(projectName: string, key: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.delete(
        `${environment.url}/store/gvariables/removevar/` + key
      );
    } else {
      return this.httpClient.delete(
        `${environment.url}/store/${projectName}/variables/removevar/` + key
      );
    }
  }

  saveEnvVariables(projectName: string, data: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.post(
        `${environment.url}/store/gvariables/saveenvvar`,
        data
      );
    } else {
      return this.httpClient.post(
        `${environment.url}/store/${projectName}/variables/saveenvvar`,
        data
      );
    }
  }

  deleteEnvVariables(projectName: string, key: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.delete(
        `${environment.url}/store/gvariables/removeenvvar/` + key
      );
    } else {
      return this.httpClient.delete(
        `${environment.url}/store/${projectName}/variables/removeenvvar/` + key
      );
    }
  }

  saveSecret(projectName: string, data: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.post(
        `${environment.url}/store/gvariables/savesecret`,
        data
      );
    } else {
      return this.httpClient.post(
        `${environment.url}/store/${projectName}/variables/savesecret`,
        data
      );
    }
  }

  deleteSecret(projectName: string, key: any, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.delete(
        `${environment.url}/store/gvariables/removesecret/` + key
      );
    } else {
      return this.httpClient.delete(
        `${environment.url}/store/${projectName}/variables/removesecret/` + key
      );
    }
  }
  getImport(weburl: any, projectName: any, isEruGateway: boolean,config?:any) {

    return this.checkAuthentication(config).pipe(
      switchMap((res) => {
      if(res.type == 'authentication'){
        const httpOptions = {
          headers: new HttpHeaders({
           [config.auth_header_name]: res.token
          })
        };
        if (isEruGateway) {
          return this.httpClient.get(`${weburl}/store/config${config?'#authorization':''}`, httpOptions);
        } else {
          return this.httpClient.get(`${weburl}/store/${projectName}/config${config?'#authorization':''}`, httpOptions);
        }
      }else{

        if (isEruGateway) {
          return this.httpClient.get(`${weburl}/store/config`);
        } else {
          return this.httpClient.get(`${weburl}/store/${projectName}/config`);
        }
      }

      })
    );
  }

  headerOptionCompare:any={}
  postImport(weburl: any, projectName: any, data: any, isEruGateway: boolean,config?:any,useExisting:boolean=false) {
    return this.checkAuthentication(config).pipe(
      switchMap((res) => {
        if(res.type == 'authentication'){
        const httpOptions = {
          headers: new HttpHeaders({
           [config.auth_header_name]: res.token
          })
        };
        this.headerOptionCompare={
          [config.auth_header_name]: res.token
         }
        if (isEruGateway) {
          return this.httpClient.post(`${weburl}/store/compare${config?'#authorization':''}`, data, httpOptions);
        } else {
          return this.httpClient.post(
            `${weburl}/store/${projectName}/compare${config?'#authorization':''}`,
            data,
            httpOptions
          );
        }}else{
          if (isEruGateway) {
            return this.httpClient.post(`${weburl}/store/compare`, data);
          } else {
            return this.httpClient.post(
              `${weburl}/store/${projectName}/compare`,
              data

            );
          }
        }
      })
    );

  }

  syncMismatch(
    config:any={},
    weburl: any,
    projectName: any,
    serviceType: any,
    data: any,
    evnironment: any,
    serviceName: any

  ) {
if(this.checkAuthentication(config)){
  const httpOptions = {
    headers: new HttpHeaders({
    ...this.headerOptionCompare
    })
  };
  return this.httpClient.post(
    `${weburl}/store/${projectName}/${serviceType}${config?'#authorization':''}`,
    data,httpOptions
  );
}else{
  return this.httpClient.post(
    `${weburl}/store/${projectName}/${serviceType}`,
    data
  );
}







  }

  deleteMismatch(
    config:any={},
    weburl: any,
    projectName: any,
    serviceType: any,
    data: any,
    evnironment: any,
    serviceName: any,

  ) {
    if(this.useAuthentication(config)){
      const httpOptions = {
        headers: new HttpHeaders({
        ...this.headerOptionCompare
        })
      };

      if(serviceName =='eru-gateway'){
        return this.httpClient.delete(
          `${weburl}/store/${serviceType?.replace('variables', 'gvariables')}${config?'#authorization':''}`,
          httpOptions
        );
      }else{
        return this.httpClient.delete(
          `${weburl}/store/${projectName}/${serviceType}${config?'#authorization':''}`,
          httpOptions
        );
      }
    }else{
      if(serviceName =='eru-gateway'){
        return this.httpClient.delete(
          `${weburl}/store/${serviceType?.replace('variables', 'gvariables')}`,
          data
        );
      }else{
        return this.httpClient.delete(
          `${weburl}/store/${projectName}/${serviceType}`,
          data
        );
      }
    }


  }

  loadSecrets(projectName: string, isEruGateway = false) {
    if (isEruGateway) {
      return this.httpClient.get(
        `${environment.url}/store/gsm/load`
      );
    } else {
      return this.httpClient.get(
        `${environment.url}/store/${projectName}/sm/load`
      );
    }
  }

  tokenValue:any=''
  checkAuthentication(env: any): Observable<any> {
    let securityType: string = env.api_key_name
      ? 'apikey'
      : env.auth_user_name
      ? 'authentication'
      : 'none';

    if (securityType === 'none') {
      return of({ type: 'none' });
    } else if (securityType === 'apikey') {
      return of({ type: 'apikey' });
    } else if (securityType === 'authentication') {
      const cred = {
        username: env.auth_user_name,
        password: env.auth_user_password,
      };
      return this.projectFacadeService.authenticateService(env.login_url_path, cred).pipe(
        map((res: any) => ({ type: 'authentication', token: res[env.auth_header_value] })),
        catchError(() => of({ type: 'authentication', token: '' }))
      );
    } else {
      return of({});
    }
  }
  useAuthentication (env:any){
    let securityType: string = env.api_key_name
    ? 'apikey'
    : env.auth_user_name
    ? 'authentication'
    : 'none';

  if (securityType === 'none') {
    return false;
  } else if (securityType === 'apikey') {
    return false
  } else if (securityType === 'authentication') {
    return true
  } else {
    return false
  }
  }
}

