import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((c) => c.AuthModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(
        (c) => c.DashboardModule
      ),
  },
  {
    path: 'project-creation',
    loadChildren: () =>
      import('./project-creation/project-creation.module').then(
        (c) => c.ProjectCreationRoutingModule
      ),
  },
  {
    path: 'database',
    loadChildren: () =>
      import('./database/database.module').then(
        (c) => c.DatabaseModule
      ),
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./setting/setting.module').then(
        (c) => c.SettingModule
      ),
  },
  {
    path: 'eru-wrapper',
    loadChildren: () =>
      import('./modules/eru-wrapper/eru-wrapper.module').then(
        (m) => m.EruWrapperModule
      )
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(
      m => m.AuthModule)
  },
  // {
  //   path:'eru-ql',
  //   redirectTo:'dashboard'
  // },
  {
    path: 'eru-ql',
    loadChildren: () => import('./modules/eru-ql/eru-ql.module').then(m => m.EruQlModule)
  },
  {
    path: 'eru-files',
    loadChildren: () => import('./modules/eru-files/eru-files.module').then(m => m.EruFilesModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: 'eru-auth',
    loadChildren: () => import('./modules/eru-auth/eru-auth.module').then(m => m.EruAuthModule)
  },
  {
    path: 'eru-gateway',
    loadChildren: () => import('./modules/gateway/gateway.module').then(m => m.GatewayModule)
  },
  {
    path:'eru-functions',
    loadChildren: () => import('./modules/eru-routes/eru-routes.module').then(m=> m.EruRoutesModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    paramsInheritanceStrategy: 'always'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
