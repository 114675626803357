import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { PopupComponent } from 'src/app/modules/eru-wrapper/components/popup/popup.component';
import { CommonPopupComponent } from 'src/app/modules/shared/common-popup/common-popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '../../services/crypto.service';
import { NotificationService } from 'src/app/database/services/notification.service';
@Component({
  selector: 'app-environment-variable-tab',
  templateUrl: './environment-variable-tab.component.html',
  styleUrls: ['./environment-variable-tab.component.scss']
})
export class EnvironmentVariableTabComponent implements OnInit {
  form!: FormGroup;
  envVars : any;
  @Input()projectName:any
  @Input()read:any;
  encryptedParams: any;
  decryptedParams: any;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private sharedService : SharedService,
    private notifyService:NotificationService,
    private router: Router,
    private cryptoService: CryptoService,
    private route: ActivatedRoute) {
    this.route.queryParams
    .subscribe((params : any) => {
      // this.projectName = params['project'];
      this.encryptedParams = params['q'];
      this.mapParamsValue();
    });

  this.form = this.fb.group({
    environment:  this.fb.array([this. newEnvironment()]),
  });
   }
   mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectName = param.value;
          }
        }
      }
    }
  }
  ngOnInit(): void {
    const isEruGateway = this.router.url.split('/')[1] == 'eru-gateway'

    this.sharedService.fetchVars(this.projectName,isEruGateway).subscribe((res:any)=>{
      let  {env_vars,secrets,vars}= res
      this.envVars=env_vars
      if(this.envVars){
        this.environment().clear();
        const varKeys = Object.keys(this.envVars)
        varKeys.forEach((e :any , index :any) => {
          const obj = this.envVars[e]
          const getVars = new FormGroup({
            keyName: new FormControl({value:obj.key,disabled:true}),
            valueName: new FormControl({value:obj.value,disabled:true})
          })
          this.environment().push(getVars);
        })
      }


     },()=>{},()=>{
   })
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.envVars){
      this.environment().clear();
      const varKeys = Object.keys(this.envVars)
      varKeys.forEach((e :any , index :any) => {
        const obj = this.envVars[e]
        const getVars = new FormGroup({
          keyName: new FormControl({value:obj.key,disabled:true}),
         // valueName: new FormControl({value:obj.value,disabled:true})
        })
        this.environment().push(getVars);
      })

    }
  }
  environment(): FormArray {
    return this.form.get('environment') as FormArray;
  }
  newEnvironment(): FormGroup {
    return this.fb.group({
      keyName: [''],
     // valueName: ['']
    });
  }
  addEnvironment() {
    this.environment().push(this.newEnvironment());
  }
  // removeEnvironment(index:number) {
  //   this.environment().removeAt(index);
  // }


  removeEnvironment(index:number){
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Delete Environment Variable',
        message : 'Are you sure, you want to delete the environment variable.'}
    });

    dialogRef.afterClosed().subscribe((result : any) => {
      let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

      if(result.event == 'Ok'){
        const variables : any = this.environment().controls;
        this.sharedService.deleteEnvVariables(this.projectName, variables[index].controls.keyName.value,isEruGateway).subscribe((res :any) => {
          this.environment().removeAt(index);
          this.notifyService.showSuccess(res.msg, 'Sucess');
        },(error:any)=>{
          this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

        })
      }
    });
  }

    saveEnvVars(index : any){
      let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

      const variables : any = this.environment().controls;
      let key = variables[index].controls.keyName.value;
      if(key && key.trim() !== "" )
      {
        const data = {
          "key":key
        }
        this.sharedService.saveEnvVariables(this.projectName, data,isEruGateway).subscribe((res : any) => {
          this.notifyService.showSuccess(res.msg, 'Sucess');
          this.environment().controls[index].disable()
        },(error:any)=>{
          this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

        })
      }

    }

}
