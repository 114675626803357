<div class="popup-component-style">
    <h2 mat-dialog-title>
        {{data ? data.title : ''}}
        <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    </h2>
    <mat-dialog-content class="mat-typography" class="justify-center">
        <span class="f-14 fw-400">{{data ? data.message : ''}}</span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button class="btn f-14 fw-500 btn-cancel" (click)="okAction()">
            Yes
        </button>
        <button mat-dialog-close class="btn f-14 fw-500 ml-10 btn-invite" (click)="closeDialog()">
            No
        </button>      
    </mat-dialog-actions>
</div>

