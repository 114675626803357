import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-export-project',
  templateUrl: './export-project.component.html',
  styleUrls: ['./export-project.component.scss']
})
export class ExportProjectComponent implements OnInit {
  isVisible: any;
  public isSelected = true;
  @Input()read:any;
  
  constructor() { }

  ngOnInit(): void {
  }

  uploadFileForm= new FormGroup({
    fileName:new FormControl(null)
  })

  upload(file:any){
    console.log(file)
  }

}
