import {Component, Input} from '@angular/core';
import {SettingsService} from "../service/settings.service";
import {NotificationService} from "../../../eru-ql/components/database/services/notification.service";
import {FormControl, FormGroup} from "@angular/forms";

export interface PeriodicElement {
  alias: string;
  storetype: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {storetype: 'Store type', alias: 'alias', action:''}
];

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {
  @Input()projectName:any
  @Input()from:any

  displayedColumns: string[] = ['storetype', 'alias', 'action'];
  dataSource = [];
  addnew: boolean =false;
  eventsFormG = new FormGroup({
    event_type: new FormControl(''),
    region: new FormControl(''),
    event_name: new FormControl(''),
    authentication: new FormControl(''),
    fifo: new FormControl(false),
    msg_to_poll: new FormControl(null),
    wait_time: new FormControl(null),
    msg_visible_time_out: new FormControl(null),
    key: new FormControl(''),
    secret: new FormControl(''),
  });
  constructor(private service: SettingsService,    private notifyService: NotificationService,) { }
  addEvent() {
    this.addnew = true;
  }
  save() {
    this.service.saveEvents(this.projectName,this.from,this.eventsFormG.value).subscribe(
      {
        next: (res:any)=>{
          this.notifyService.showSuccess(res.msg, "Sucess");
          this.fetch()
          this.addnew = false;
          this.eventsFormG.reset()
        },
        error:(e)=>{
          this.notifyService.showError(e.error, "Error");

        }
      }
    )
  }

  authSelectChange(e: any) {

  }

  ngOnInit(){
    this.fetch()


  }
  fetch(){
    this.service.getFetchEvents(this.projectName,this.from).subscribe((res: any) => {
      let data:any=[]
      for(let obj of Object.keys(res)){
        data.push(res[obj])
      }
      this.dataSource = data
    })
  }
  cancel(){
    this.addnew = false;
    this.eventsFormG.reset()
  }
  remove(id:any){
    this.service.removeEvents(this.projectName,this.from,id).subscribe(  {
      next: (res:any)=>{
        this.notifyService.showSuccess(res.msg, "Sucess");
        this.fetch();

      },
      error:(e)=>{
        this.notifyService.showError(e.error, "Error");

      }
    })
  }

  edit(kmsData:any){
    this.addnew=true

    this.eventsFormG.setValue({
      event_type:kmsData.event_type ,
      region: kmsData.region,
      event_name: kmsData.event_name,
      authentication:kmsData.authentication,
      fifo:kmsData.fifo,
      msg_to_poll:kmsData.msg_to_poll,
      wait_time: kmsData.wait_time,
      msg_visible_time_out: kmsData.msg_visible_time_out,
      key: kmsData.key,
      secret: kmsData.secret,
    })
  }
}

