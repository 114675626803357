<div class="kms-wrapper">
    <div class="kms-inner minH493">
      <div class="d-flex justify-content-between align-item-center">
      <h4>Events</h4>
      <button (click)="addEvent()"
      *ngIf="!addnew"
      style="height: 35px;margin-bottom: 16px;"
      mat-flat-button
      color="primary"
      type="submit"
      class="text-white"
      cdkFocusInitial
    >
      Add New
    </button>
    </div>
      <div class="row table" *ngIf="!addnew">
          <div class="table-container wrapper-table">
              <table mat-table [dataSource]="dataSource" class="table-border">
                  <ng-container matColumnDef="storetype">
                      <th mat-header-cell *matHeaderCellDef> Event Name </th>
                      <td mat-cell *matCellDef="let element"> {{element.event_name}} </td>
                    </ng-container>


                    <ng-container matColumnDef="alias">
                      <th mat-header-cell *matHeaderCellDef> Event Type </th>
                      <td mat-cell *matCellDef="let element"> {{element.event_type}} </td>
                    </ng-container>

                      <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef>Action</th>
                          <td mat-cell *matCellDef="let element"  style="display: flex;">
                          <ng-container>
                    <ng-container >
                      <div class="photo-container icon-style" (click)="edit(element)">
                        <a class="edit-img cursor-pointer">
                            <img src="../../../../assets/images/icons/edit1.svg">
                          <span style="z-index: 10000!important;">Edit</span>
                        </a>
                      </div>
                      <div class="photo-container icon-style" (click)="remove(element.event_name)">
                        <a class="edit-img cursor-pointer">
                          <img src="../../../../../assets/images/icons/delete.png"
                            >
                          <span style="z-index: 10000!important;">Delete</span>
                        </a>
                      </div>
                  </ng-container>

                </ng-container>
                </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <!-- <div class="col-12 mt-20">
              <div class="text-right">

              </div>
            </div> -->
      </div>
      <div class="row mt-20 d-flex align-item-center" style="" *ngIf="addnew">
        <form [formGroup]="eventsFormG">
          <div class="row">
              <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Event Type</span>
                  <mat-form-field appearance="outline" class="w100">
                    <mat-select formControlName="event_type">
                      <mat-option value="AWS_SQS">AWS SQS</mat-option>
                      <mat-option value="AWS_SNS">AWS SNS</mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Region</span>
                  <mat-form-field appearance="outline" class="w100" >
                    <input matInput required type="text" formControlName="region"/>
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Event Name</span>
                  <mat-form-field appearance="outline" class="w100">
                    <input matInput required type="text" formControlName="event_name"/>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">fifo</span> <br/>
                  <mat-checkbox  formControlName="fifo"></mat-checkbox>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Authentication</span>
                  <mat-form-field appearance="outline" class="w100">
                      <mat-select (selectionChange)="authSelectChange($event)"formControlName="authentication">
                          <mat-option value="Secret">Secret</mat-option>
                          <mat-option value="IAM">IAM</mat-option>
                          <!-- <mat-option value="ECS">ECS</mat-option> -->
                      </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Key</span>
                  <mat-form-field appearance="outline" class="w100">
                    <input matInput required type="text"  formControlName="key"/>
                  </mat-form-field>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4">
                  <span class="f-12 fw-500 text-grey3">Secret</span>
                  <mat-form-field appearance="outline" class="w100">
                    <input matInput required type="text" formControlName="secret"/>
                  </mat-form-field>
                </div>

          </div>
        <div class="row">
          <div class="col-md-4">
            <span class="f-12 fw-500 text-grey3">No. of Messages </span>
            <mat-form-field appearance="outline" class="w100">
              <input matInput required type="number" formControlName="msg_to_poll" oninput="this.value=this.value.replace(/[e\+\-]/gi, '')"/>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <span class="f-12 fw-500 text-grey3">Wait Time </span>
            <mat-form-field appearance="outline" class="w100">
              <input matInput required type="number" formControlName="wait_time" oninput="this.value=this.value.replace(/[e\+\-]/gi, '')"/>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <span class="f-12 fw-500 text-grey3">Message Visisbility Time Out</span>
            <mat-form-field appearance="outline" class="w100">
              <input matInput required type="number" formControlName="msg_visible_time_out" oninput="this.value=this.value.replace(/[e\+\-]/gi, '')"/>
            </mat-form-field>
          </div>

        </div>
        </form>
        <div class="col-12">
          <div class="text-right">
          <button mat-stroked-button class="w85" style="background: transparent;margin-right: 15px;color: var(--orange1);min-width: 110px;" (click)='cancel()'>cancel</button>

            <button
              style="height: 35px"
              mat-flat-button
              color="primary"
              type="submit"
              class="text-white"
              cdkFocusInitial (click)="save()"
            >
              Save
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
