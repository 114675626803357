import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import { ProjectFacadeService } from '../../state/project/project-facade.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { UsersService } from '../../services/users.service';
@Component({
  selector: 'app-invite-popup',
  templateUrl: './invite-popup.component.html',
  styleUrls: ['./invite-popup.component.scss'],
})
export class InvitePopupComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput', { static: false })
  emailInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete!: MatAutocomplete;
  projectLists: any[] = [];
  selectedProject: any;
  selectedEnvironment: any;
  copyProjectDetails :any=[]
  selectedServices: any;
  invalidEmail: boolean = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailCtrl = new FormControl();
  filteredEmails: Observable<any[]>;
  emails: string[] = [];
  rows: any = [
    {
      options:[],
      id: 1,
      environment: null,
      services: [],
    },
  ];
  projectSubsribe!: Subscription;
  constructor(
    private projectFacadeService: ProjectFacadeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private dialog: MatDialogRef<InvitePopupComponent>
  ) {
    //!Auto complete api on value string  >= 3
    this.filteredEmails = this.emailCtrl.valueChanges.pipe(
      startWith(null),
      map((email: string | null | any) => {
        if (email?.length >= 3 && !this.isValidEmail(email)) {
          this.invalidEmail = false;
          let result: any = [];
          //!Api call for suggestion
          this.userService
            .fetchUsers({ user_str: email })
            .subscribe((res: any) => {
              console.log(res);

              if (Object.keys(res).length == 0) {
              } else {
                for (let user of res.users) {
                  result.push(user);
                }
              }
            });
          return result;
        } else {
          return [];
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.projectSubsribe.unsubscribe();
  }

  ngOnInit(): void {
    this.projectSubsribe = this.projectFacadeService.getProjects$.subscribe(
      (projects: any) => {
        if (projects.projects) {
          const getProject = cloneDeep(projects.projects);
          this.projectLists = this.extractForDropDown(getProject); //! extracts needed data for dropdown  from store
          this.selectedProject = this.projectLists.find(
            (ele: any) => ele.project_name === this.data.project_name
          );
          this.rows[0].options = this.selectedProject.environments
          
        }
      }
    );
  }
  extractForDropDown(projects: any[]): any[] {
    if (!projects) {
      return [];
    }
    return projects.map((project: any) => {
      if (!project) {
        return {};
      }
      return {
        project_name: project.project_name || '',
        project_id: project.project_id || '',
        environments: (project['public___project_envs'] || []).map(
          (env: any) => {
            if (!env) {
              return {};
            }
            return {
              env_name: env.env_name || '',
              services: (env['public___project_services'] || []).map(
                (service: any) => {
                  if (!service.service_id) {
                    return {};
                  }
                  return {
                    service_id: service['service_id'] || '',
                    service_name: service['service_name'] || '',
                    service_access: 'READ',
                  };
                }
              ),
            };
          }
        ),
      };
    });
  }
  submit() {
    if (this.emails.length > 0 && !this.checkbtn()) {
      let payload: any = {
        project_id: this.selectedProject.project_id,
        user_email: null,
        docs: [],
      };

      for (let r of this.emails) {
        let user_email = r;
        let project_id = this.selectedProject.project_id;
        let services: any = [];
        for (let r of this.rows) {
          for (let s of r.services) {
            services.push({
              project_id: project_id,
              user_email: user_email,
              service_id: s.service_id,
              service_access: s.service_access,
            });
          }
        }
        payload.user_email = user_email;
        payload.docs = services;

        this.userService.sendInvite(payload).subscribe(
          () => {
            this.dialog.close(true);
          },
          (e) => {
            this.dialog.close(false);
          }
        );
      }
    }
  }

  accessHandler(e: any, s: any) {
    s.service_access = e; //!sets read / write access to respective services
  }
  selectionChange(e: any, event: any) {
    if (event._selected) {
      // e.panel.open(); //! Opens panel on click of check box
    }
  }

  add(event: MatChipInputEvent): void {
    //! Add user input if string is valid email.
    this.invalidEmail = false;
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our email
      if ((value || '').trim()) {
        if (this.isValidEmail(event.value)) {
          this.emails.push(value.trim());
        } else {
          this.invalidEmail = true;
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.emailCtrl.setValue(null);
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email); //!Removes email from mat-chip
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.value); //! sets value to the chip on clicking on suggestion box.
    this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
  }

  addRow() {
    //! Adds  new row of options on add more button click

    console.log() //.environments
    this.rows.push({
      options:cloneDeep(this.rows[this.rows.length-1].options).filter((ele:any)=>ele.env_name !== this.rows[this.rows.length-1].environment.env_name),
      id: this.rows.length + 1,
      environment: null,
      services: [],
    });
  }
  //! Validate Email
  isValidEmail(email: string) {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }
  //! Checks all information is filled
  checkbtn() {
    let allcolumns = this.rows.length * 2;
    let selectedColumn = 0;
    for (let e of this.rows) {
      if (e.environment && e.services.length > 0) {
        selectedColumn = selectedColumn + 2;
      }
    }
    return allcolumns !== selectedColumn;
  }


  removeEnv(option:string){
   
  console.log(this.copyProjectDetails.environments)
  }
}
