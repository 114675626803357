import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';

@Component({
  selector: 'app-eru-repo',
  templateUrl: './eru-repo.component.html',
  styleUrls: ['./eru-repo.component.scss']
})
export class EruRepoComponent implements OnInit {

  code:any
  installationId:any;
  constructor(
    private route: ActivatedRoute, private projectsService : ProjectsService) { this.route.queryParams
    .subscribe((params : any) => {
      this.code = params['code'];
      this.installationId = params['installation_id'];
     });}

  ngOnInit(): void {
    let envID= sessionStorage.getItem('envId')
    if(this.code && this.installationId && envID){

    }
  }

}
