<!-- <div class="popup-component">
        <div class="body">
        <div class="content">
            <img src="../../../../../assets/images/icons/clarity_success-standard-line.svg" alt="">
            <h2>{{data ? data.message : ''}}</h2>
        </div>
        <div>
            <button  mat-dialog-close class="btn f-14 fw-500 ml-10 btn-ok">Ok</button>
        </div>
    </div>
</div> -->

<div class="popup-component-style">
    <h2 mat-dialog-title>
        {{data ? data.title : ''}}
        <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    </h2>
    <mat-dialog-content class="mat-typography" class="justify-center">
        <div class="content" style="text-align: center;">
            <div>
                <img src="../../../../../assets/images/icons/clarity_success-standard-line.svg" alt="">
            </div>  
           <div class="mt-10">
                <span>{{data ? data.message : ''}}</span>
           </div>
            
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end"  mat-dialog-close>
        <button class="btn f-14 fw-500 btn-cancel">
            Ok
        </button>          
    </mat-dialog-actions>
</div>

