import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ProjectCreationService } from '../../../../project-creation/services/project-creation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupComponent } from '../popup/popup.component';
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { CommonPopupComponent } from 'src/app/modules/eru-wrapper/components/common-popup/common-popup.component';

@Component({
  selector: 'app-erug-services',
  templateUrl: './erug-services.component.html',
  styleUrls: ['./erug-services.component.scss']
})
export class ErugServicesComponent implements OnInit, OnDestroy {
  // phaseForm!: FormGroup;
  services: any[]| any;
  @Input() project  : any;

  @Output() navigationHandler:EventEmitter<any> = new EventEmitter<any>();
  @Output() updateProjectHandler:EventEmitter<any> = new EventEmitter<any>();
  projectEnvironments : any[] = [];
  currentProject : any;
  serviceFormGroup!: FormGroup;
  modifiedServices:any = [];
  newServices:any = [];
  constructor(
    private projectFacadeService:ProjectFacadeService,
    private dialog: MatDialog, private fb:FormBuilder,
    private router : Router
  ) { }

  ngOnInit(): void {
    console.log(this.project);
    this.projectFacadeService.getProjects$.subscribe((res : any) => {
      if(res.projects && this.project){
        this.currentProject = res.projects.find((p : any) => {return p.project_name == this.project.project_name})
        this.projectEnvironments = this.currentProject ? this.currentProject.public___project_envs : [];
        if(this.projectEnvironments)
        {
          this.addForm();
        }
        if(this.currentProject){
          this.updateProjectHandler.emit(({project:this.currentProject}))
        }
      }
    })

    // this.projectFacadeService.createServiceResponse$.subscribe((response : any) => {
    //   if(response && response.createServiceResponse){

    //   }
    // })
    this.getServices();
    this.addForm()


  }

  addForm(){
    this.serviceFormGroup=this.fb.group({
      environments: new FormArray([]),
    })

    this.projectEnvironments.forEach((element : any, index : any) => {
      const getEnv = new FormGroup({
        env_name: new FormControl(element.env_name),
        env_id: new FormControl(element.env_id),
        service:new FormArray([

        ])
      })
      this.environments().push(getEnv);
      if(element.public___project_services.length > 0 && element.public___project_services[0].service_id !== null){
        element.public___project_services.forEach((s : any, j : any) => {
          const sers = new FormGroup({
            service_id: new FormControl(s.service_id),
            selectservice: new FormControl(s.service_name, [Validators.required]),
            path: new FormControl(s.service_path, [Validators.required ]),
          })
          this.servicegroup(index).push(sers);
        })
      }
      else{
        this.addService(index);
      }
    });
  }

  environments(): FormArray {
    return this.serviceFormGroup.get("environments") as FormArray
  }

  getEnvironmentName(fromGroup : any){
    return fromGroup.get(`env_name`).value
  }

addService(index:number) {
  this.servicegroup(index).push(this.newService());
}

deleteService(env : any, service : any, eIndex: any, sIndex : any){
  console.log('deleteService', env, service)
  const dialogRef = this.dialog.open(CommonPopupComponent, {
    data: {
      title: 'Delete Service',
      message : 'Are you sure, you want to delete the service.'}
  });

  dialogRef.afterClosed().subscribe((result : any) => {
    if(result.event == 'Ok'){
      if(service.controls.service_id && service.controls.service_id.value){
          this.projectFacadeService.deleteService({doc : {
            "service_id": service.controls.service_id.value
          }}).subscribe((res : any) => {
            if(res){
              this.servicegroup(eIndex).removeAt(sIndex);
            }
          })
      }
      else{
        this.servicegroup(eIndex).removeAt(sIndex);
      }
    }
  });
}

servicegroupSize(index:number) : number {
  const size : any = this.environments().at(index).get("service") as FormArray
  return size ? size.length : 0;
}

servicegroup(index:number) : FormArray {
  return this.environments().at(index).get("service") as FormArray
}

  newEnvironments(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      service:new FormArray([
      ])
    })
  }

  newService(): FormGroup {
    return new FormGroup({
      selectservice: new FormControl('', [Validators.required]),
      path: new FormControl('', [Validators.required ]),
    })
  }

  onInputChange(e:any,i:any,text:any){
    // if(text === 'development_service'){
    //   this.developmentNumber[i].service_name=e.value;
    // }
    // else if(text==='development_path'){
    //   this.developmentNumber[i].service_path=e.value;
    // }
    // else if(text === 'production_service'){
    //   this.productionNumber[i].service_name=e.value;
    // }
    // else if(text==='production_path'){
    //   this.productionNumber[i].service_path=e.value;
    // }
  }

  onBack(){
    this.navigationHandler.emit({selectedIndex : 1})
  }

  onSubmit(){
      if(this.serviceFormGroup.valid){
        this.checkServiceDirty();
        if(this.modifiedServices.length > 0){
          const modifiedServStruct = this.generateModifiedServiceStruct(this.modifiedServices)
          for(let ms of modifiedServStruct){
            this.projectFacadeService.editService({doc : ms}).subscribe((res : any) => {
              console.log('editService', res)
            })
          }



          this.dialog.open(PopupComponent, {
            data: {
              title: 'Success',
              message : "Project created successfully"
            },
          });
          this.router.navigate(['eru-wrapper/projects']);
        }
        else if(this.newServices.length > 0){
          this.projectFacadeService.createService({service : {"docs" : this.newServices}}).subscribe((res : any) => {
            console.log('createService', res)


            this.dialog.open(PopupComponent, {
              data: {
                title: 'Success',
                message : "Project created successfully"
              },
            });
          this.router.navigate(['eru-wrapper/projects']);
          })
        }
        else{
          const serviceDoc = this.generateServiceStruct();

          this.projectFacadeService.createService({service : {"docs" : serviceDoc}}).subscribe((res : any) => {

          this.dialog.open(PopupComponent, {
            data: {
              title: 'Success',
              message : "Project created successfully"
            },
          });
            this.router.navigate(['eru-wrapper/projects']);
          })

        }

      }
  }

  generateServiceStruct(){
    let services : any = [];
    let user : any = localStorage.getItem('user');
    user = JSON.parse(user)
    this.environments().controls.forEach((control: any, index : any) => {
      this.servicegroup(index).controls.forEach((service_control: any, jIndex : any) => {

        let ser : any =  {
              "project_id":this.currentProject.project_id,
              "env_id":control.controls.env_id.value,
              "service_name":service_control.controls.selectservice.value,
              "service_path":service_control.controls.path.value,
              "public.project_service_users": {
                  "project_id": this.currentProject.project_id,
                  "env_id":control.controls.env_id.value,
                  "user_id":user.id,
                  "service_access":"WRITE",
                  "invite_status":"ACCEPTED"
              }
            }
          if(service_control.controls.service_id)
          {
            ser.service_id = service_control.controls.service_id.value
          }
          services.push(
            ser
          )
      })

    });
    return services;
  }

  checkServiceDirty(){
    const services = this.generateServiceStruct();
    this.projectEnvironments.forEach((element : any, index : any) => {
      if(element.public___project_services.length > 0 && element.public___project_services[0].service_id !== null){

        for(let s of  services)
        {
          for(let service of  element.public___project_services)
          {
            if(s.env_id == element.env_id && s.service_id == service.service_id){
                if(s.service_name !== service.service_name || s.service_path !== service.service_path)
                {
                  this.modifiedServices.push(s)
                  break;
                }
            }
          }
        }
      }
    })

    for(let service of  services)
    {
      if(!service.service_id)
      {
        this.newServices.push(service)
      }
    }

  }

  generateModifiedServiceStruct(modifiedServices : any){
   const modifiedSerStruct : any = [];
    for(let mSer of modifiedServices)
    {
      modifiedSerStruct.push(
          {
            "doc": {
                        "project_id":mSer.project_id,
                        "env_id":mSer.env_id,
                        "service_name": mSer.service_name,
                        "service_path": mSer.service_path,
                        "service_access":"WRITE"
                    },
                    "service_id":mSer.service_id
            }
          )
        }
        return modifiedSerStruct;
  }

  getServices(){
    this.services = [
      {
        label: "Select",
        image: '',
        value: ''
      },
      {
        label: "eru-ql",
        image: '<img height="25px" src="../../../../assets/images/wrapper/ql.svg"/>',
        value: 'eru-ql'
      },
      {
        label: "eru-files",
        image: '<img height="25px" src="../../../../assets/images/wrapper/files.svg"/>',
        value: 'eru-files'
      },
      {
        label: "eru-functions",
        image: '<img height="25px" src="../../../../assets/images/wrapper/routes.svg"/>',
        value: 'eru-functions'
      },
      {
        label: "eru-auth",
        image: '<img height="25px" src="../../../../assets/images/wrapper/auth.svg"/>',
        value: 'eru-auth'
      },
      {
        label: "eru-gateway",
        image: '<img height="25px" src="../../../../assets/images/wrapper/gateway.svg"/>',
        value: 'eru-gateway'
      },
    ];
  }

  ngOnDestroy(){
    this.projectFacadeService.clearProjectServiceStore();
  }

}

