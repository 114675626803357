import * as ProjectAuthReducer from "./project-auth.reducer";
import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectProjectAuthState = createFeatureSelector<ProjectAuthReducer.ProjectAuthState>('PROJECTAUTH');

export const getProjectConfig = createSelector(selectProjectAuthState, (state) => {
    return {
        projectConfig : state.projectConfig
    }
})

