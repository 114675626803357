import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { getProjects } from '../../state/project/project.selector';
import { DataCommuteService } from 'src/app/services/data-commute.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvitePopupComponent } from '../invite-popup/invite-popup.component';
import { CommonPopupComponent } from '../common-popup/common-popup.component';
import { ImportComponent } from '../import/import.component';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';

export interface Projects {
  project: string;
  // users:string,
  services: string;
  action: string;
}

// const ELEMENT_DATA : Projects[]=[
//   { project:'Project 1 Name',users:'03',services:'eru-files, eru-auth, eruG - Rules, eruG - Processes',action:''},
//   { project:'Project 2 Name',users:'01',services:'eru-files, eru-auth, eruG - Rules, eruG - Gateway',action:''},
//   { project:'Project 3 Name',users:'01',services:'eru-files, eru-auth, eruG - ql',action:''}
// ]

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  // isRepository: boolean = false;
  displayedColumns: string[] = [
    'expandTable',
    'Projects',
    'users',
    'environment',
    'Services',
    'action',
  ];
  secondTableDisplayColumns: string[] = [
    'expandTable',
    'Projects',
    'users',
    'environment',
    'Services',
    'action',
  ];
  dataSource: any = [];
  secondTableDataSource: any = [''];
  search: boolean = false;
  distinctProjects: any[] = [];
  headerList: any = [];
  expandedElement!: Projects | null;
  readOnly: any = false;
  selectedRow: any;
  constructor(
    private router: Router,
    private projectFacadeService: ProjectFacadeService,
    private dataCommuteService: DataCommuteService,
    private dialog: MatDialog,
    private projectServices: ProjectsService,
    private cryptoServices:CryptoService
  ) {}

  ngOnInit(): void {

    this.projectFacadeService.getProjects$.subscribe((projects: any) => {
      if (projects.projects) {
        const getProject = cloneDeep(projects.projects);
        this.distinctProjects = getProject.filter(
          (p: any, i: any, arr: any) =>
            arr.findIndex((t: any) => t.project_name === p.project_name) === i
        );
        let order = this.distinctProjects?.sort((a: any, b: any) =>
          a.project_name.localeCompare(b.project_name)
        );

        this.dataSource = new MatTableDataSource(this.getServiceName(order));
        console.log(this.dataSource);
      }else{
       if(localStorage.getItem('user')){
        this.projectFacadeService.loadProjects();
       }

      }
    });

    this.dataCommuteService.OnProjectChange.subscribe((data: any) => {
      if (data) {
        console.log('OnProjectChange');
        this.onServiceClick(data.project, data.service, data.env);
      }
    });
  }
  getServiceName(order: any) {
    let new_arr: any = [];
    this.headerList = [];
    for (let element of order) {
      let name = '';
      let invite_status = '';
      let is_owner = '';
      let project_id = '';
      name = element.project_name;
      is_owner = element.is_owner;
      invite_status = element.invite_status;
      project_id = element.project_id;
      const envs = element.public___project_envs.map((e: any) => {
        return { name: e.env_name, isDefault: e.is_default == 'Y' };
      });
      this.headerList.push({
        project: { name: element.project_name },
        envs: envs,
      });

      let b = '';
      let a = [];
      for (let i of element.public___project_envs) {
        for (let getService of i.public___project_services) {
          if (
            getService.service_name &&
            a.indexOf(getService.service_name) == -1
          ) {
            a.push(getService.service_name);
          }
        }
        b = a.join(',');
      }
      new_arr.push({
        project_name: name,
        is_owner: is_owner,
        invite_status: invite_status,
        project_id: project_id,
        environment: element.public___project_envs,
      });
    }
    localStorage.setItem('headerList', JSON.stringify(this.headerList));
    return new_arr;
  }

  getEnvCount(element: any) {
    return element.length;
  }

  getServiceCount(element: any) {
    let serviceCount: any = 0;
    for (let i of element) {
      if (i.public___project_services[0].service_name != null) {
        serviceCount += i.public___project_services.length;
      }
    }
    return serviceCount;
  }
  getSecondryTableData(element: any) {
    this.secondTableDataSource = [];
    console.log('element', element);
    let arr: any[] = [];

    for (let e of element.environment) {
      const services = e.public___project_services.filter((s : any) => {return s.service_access == 'READ' || s.service_access == 'WRITE'})
      if(services && services.length > 0) {
        arr.push({
          env_id:e.env_id,
          repo_details:e.repo_details||{},
          environment: e.env_name,
          Services: e.public___project_services,
        });
      }
    }
    this.secondTableDataSource = arr;
  }
  project:any
  toggleRowExpansion(row: any) {
    if (this.selectedRow === row) {
      row.showSecondImage = false;
      this.selectedRow = null;
    } else {
      if (this.selectedRow) {
        this.selectedRow.showSecondImage = false;
      }
      row.showSecondImage = true;
      this.selectedRow = row;
    }
    this.project=row.project_name
    // console.log(row.project_name,'ssssssssss')
  }
  onServiceClick(
    projectName: string,
    serviceName: string,
    envName: string = ''
  ) {
    console.log('projectName', projectName);
    const project = this.distinctProjects
      ? this.distinctProjects.find((p: any) => {
          return p.project_name == projectName;
        })
      : null;
    if (project) {
      //Get project enviroment details and needd to pass to redireccted path.

      //Get Default Env
      const defaultEnv: any = project.public___project_envs.find((e: any) => {
        return e.env_name == envName;
      });
      if (defaultEnv) {
        if (serviceName == 'eru-ql') {
          const serviceObj = defaultEnv.public___project_services.find(
            (s: any) => {
              return s.service_name == 'eru-ql';
            }
          );
          if (serviceObj) {
            this.processEruService(defaultEnv, serviceObj, project);
          } else {
            let eruQlService: any = null;
            let eruQlEnv: any = null;
            for (let e of project.public___project_envs) {
              for (let s of e.public___project_services) {
                if (s.service_name == 'eru-ql') {
                  eruQlService = s;
                  eruQlEnv = e;
                  break;
                }
              }
              if (eruQlService) {
                break;
              }
            }
            if (eruQlEnv && eruQlService) {
              this.processEruService(eruQlEnv, eruQlService, project);
            }
          }
        } else if (serviceName == 'eru-gateway') {
          const serviceObj = defaultEnv.public___project_services.find(
            (s: any) => {
              return s.service_name == 'eru-gateway';
            }
          );
          if (serviceObj) {
            this.processEruService(defaultEnv, serviceObj, project);
          } else {
            let eruQlService: any = null;
            let eruQlEnv: any = null;
            for (let e of project.public___project_envs) {
              for (let s of e.public___project_services) {
                if (s.service_name == 'eru-gateway') {
                  eruQlService = s;
                  eruQlEnv = e;
                  break;
                }
              }
              if (eruQlService) {
                break;
              }
            }
            if (eruQlEnv && eruQlService) {
              this.processEruService(eruQlEnv, eruQlService, project);
            }
          }
        } else if (serviceName == 'eru-files') {
          const serviceObj = defaultEnv.public___project_services.find(
            (s: any) => {
              return s.service_name == 'eru-files';
            }
          );
          if (serviceObj) {
            this.processEruService(defaultEnv, serviceObj, project);
          } else {
            let eruQlService: any = null;
            let eruQlEnv: any = null;
            for (let e of project.public___project_envs) {
              for (let s of e.public___project_services) {
                if (s.service_name == 'eru-files') {
                  eruQlService = s;
                  eruQlEnv = e;
                  break;
                }
              }
              if (eruQlService) {
                break;
              }
            }
            if (eruQlEnv && eruQlService) {
              this.processEruService(eruQlEnv, eruQlService, project);
            }
          }
        } else if (serviceName == 'eru-auth') {
          const serviceObj = defaultEnv.public___project_services.find(
            (s: any) => {
              return s.service_name == 'eru-auth';
            }
          );
          if (serviceObj) {
            this.processEruService(defaultEnv, serviceObj, project);
          } else {
            let eruQlService: any = null;
            let eruQlEnv: any = null;
            for (let e of project.public___project_envs) {
              for (let s of e.public___project_services) {
                if (s.service_name == 'eru-auth') {
                  eruQlService = s;
                  eruQlEnv = e;
                  break;
                }
              }
              if (eruQlService) {
                break;
              }
            }
            if (eruQlEnv && eruQlService) {
              this.processEruService(eruQlEnv, eruQlService, project);
            }
          }
        } else if (serviceName == 'eru-functions') {
          const serviceObj = defaultEnv.public___project_services.find(
            (s: any) => {
              return s.service_name == 'eru-functions';
            }
          );
          if (serviceObj) {
            this.processEruService(defaultEnv, serviceObj, project);
          } else {
            let eruQlService: any = null;
            let eruQlEnv: any = null;
            for (let e of project.public___project_envs) {
              for (let s of e.public___project_services) {
                if (s.service_name == 'eru-functions') {
                  eruQlService = s;
                  eruQlEnv = e;
                  break;
                }
              }
              if (eruQlService) {
                break;
              }
            }
            if (eruQlEnv && eruQlService) {
              this.processEruService(eruQlEnv, eruQlService, project);
            }
          }
        }
      } else {
      }
    }
  }

  processEruService(env: any, service: any, project: any) {
    let securityType: string = '';
    securityType = env.api_key_name
      ? 'apikey'
      : env.auth_user_name
      ? 'authentication'
      : 'none';
    let clonedProject = cloneDeep(project);
    project.securityType = securityType;
    project.service_path = service.service_path;
    if (securityType == 'none') {
      if (service.service_name == 'eru-ql') {
        this.navigateToEruQl(env, service, project);
      } else if (service.service_name == 'eru-gateway') {
        this.navigateToEruGateway(env, service, project);
      } else if (service.service_name == 'eru-files') {
        this.navigateToEruFiles(env, service, project);
      } else if (service.service_name == 'eru-auth') {
        this.navigateToEruAuth(env, service, project);
      } else if (service.service_name == 'eru-functions') {
        this.navigateToEruRoutes(env, service, project);
      }
    } else if (securityType == 'apikey') {
      project.api_key_name = env.api_key_name;
      project.api_key_value = env.api_key_value;
      if (service.service_name == 'eru-ql') {
        this.navigateToEruQl(env, service, project);
      } else if (service.service_name == 'eru-gateway') {
        this.navigateToEruGateway(env, service, project);
      } else if (service.service_name == 'eru-files') {
        this.navigateToEruFiles(env, service, project);
      } else if (service.service_name == 'eru-auth') {
        this.navigateToEruAuth(env, service, project);
      } else if (service.service_name == 'eru-functions') {
        this.navigateToEruRoutes(env, service, project);
      }
    } else if (securityType == 'authentication') {
      const cred = {
        username: env.auth_user_name,
        password: env.auth_user_password,
      };
      this.projectFacadeService
        .authenticateService(env.login_url_path, cred)
        .subscribe((res: any) => {
          if (res) {
            project.eruQlAuth = res;
            project.auth_header_name = env.auth_header_name;
            project.auth_header_value = env.auth_header_value;
            if (service.service_name == 'eru-ql') {
              this.navigateToEruQl(env, service, project);
            } else if (service.service_name == 'eru-gateway') {
              this.navigateToEruGateway(env, service, project);
            } else if (service.service_name == 'eru-files') {
              this.navigateToEruFiles(env, service, project);
            } else if (service.service_name == 'eru-auth') {
              this.navigateToEruAuth(env, service, project);
            } else if (service.service_name == 'eru-functions') {
              this.navigateToEruRoutes(env, service, project);
            }
          } else {
            //Show error message to user.
          }
        });
    }
  }

  navigateToEruQl(env: any, service: any, project: any) {
    localStorage.setItem(
      `${project.project_name}_${env.env_name}_${service.service_name}`,
      JSON.stringify(project)
    );
    // const url = this.router.serializeUrl(
    //   //this.router.createUrlTree([service.service_name])
    //   this.router.createUrlTree([`${service.service_name}/dashboard/overview?project=${project.project_name}`])

    // );
    this.readOnly = service.service_access && service.service_access == 'READ' ? true : false;
    const encryptParams = this.cryptoServices.encrypt(`project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`)
    const url = `${service.service_name}/dashboard/overview?q=${encryptParams}`;
    this.callNavigateApi(service.service_id,env.env_id,'ql')
    const newTab:any= window.open(url, '_blank');
    newTab.onload = function() {
      newTab.document.title = `${service.service_name} (${project.project_name} - ${env.env_name})`;
    };
    // this.router.navigateByUrl(`${url}`)
  }

  navigateToEruGateway(env: any, service: any, project: any) {
    localStorage.setItem(
      `${project.project_name}_${env.env_name}_${service.service_name}`,
      JSON.stringify(project)
    );
    // const url = this.router.serializeUrl(
    //   //this.router.createUrlTree([service.service_name])
    //   this.router.createUrlTree([`${service.service_name}/dashboard/overview?project=${project.project_name}`])

    // );
    this.readOnly = service.service_access && service.service_access == 'READ' ? true : false;
    const encryptParams = this.cryptoServices.encrypt(`project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`)
    const url = `${service.service_name}/dashboard?q=${encryptParams}`;
    console.log(service)
    this.callNavigateApi(service.service_id,env.env_id,'gateway')
    const newTab:any= window.open(url, '_blank');
    newTab.onload = function() {
      newTab.document.title = `${service.service_name} (${project.project_name} - ${env.env_name})`;
    };
    // this.router.navigateByUrl(`${url}`)
  }

  navigateToEruFiles(env: any, service: any, project: any) {
    localStorage.setItem(
      `${project.project_name}_${env.env_name}_${service.service_name}`,
      JSON.stringify(project)
    );
    // const url = this.router.serializeUrl(
    //   //this.router.createUrlTree([service.service_name])
    //   this.router.createUrlTree([`${service.service_name}/dashboard?project=${project.project_name}`])

    // );
    this.readOnly = service.service_access && service.service_access == 'READ' ? true : false;
    // const url = `${service.service_name}/dashboard?project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`;
    const encryptParams = this.cryptoServices.encrypt(`project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`)
    const url = `${service.service_name}/dashboard?q=${encryptParams}`;
    this.callNavigateApi(service.service_id,env.env_id,'files')

    const newTab:any= window.open(url, '_blank');
    newTab.onload = function() {
      newTab.document.title = `${service.service_name} (${project.project_name} - ${env.env_name})`;
    };
    // this.router.navigateByUrl(`${url}`);
  }

  navigateToEruAuth(env: any, service: any, project: any) {
    localStorage.setItem(
      `${project.project_name}_${env.env_name}_${service.service_name}`,
      JSON.stringify(project)
    );
    // const url = this.router.serializeUrl(
    //   //this.router.createUrlTree([service.service_name])
    //   this.router.createUrlTree([`${service.service_name}/dashboard?project=${project.project_name}`])

    // );
    this.readOnly = service.service_access && service.service_access == 'READ' ? true : false;
    // const url = `${service.service_name}/dashboard?project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`;
    const encryptParams = this.cryptoServices.encrypt(`project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`)
    const url = `${service.service_name}/dashboard?q=${encryptParams}`;
    this.callNavigateApi(service.service_id,env.env_id,'auth')

    const newTab:any= window.open(url, '_blank');
    newTab.onload = function() {
      newTab.document.title = `${service.service_name} (${project.project_name} - ${env.env_name})`;
    };
  }

  navigateToEruRoutes(env: any, service: any, project: any) {
    localStorage.setItem(
      `${project.project_name}_${env.env_name}_${service.service_name}`,
      JSON.stringify(project)
    );
    // const url = this.router.serializeUrl(
    //   //this.router.createUrlTree([service.service_name])
    //   this.router.createUrlTree([`${service.service_name}/dashboard?project=${project.project_name}`])

    // );
    this.readOnly = service.service_access && service.service_access == 'READ' ? true : false;
    const encryptParams = this.cryptoServices.encrypt(`project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`)
    const url = `${service.service_name}/dashboard?q=${encryptParams}`;
    // const url = `${service.service_name}/dashboard?project=${project.project_name}&env=${env.env_name}&read=${this.readOnly}`;
    this.callNavigateApi(service.service_id,env.env_id,'routes')

    const newTab:any= window.open(url, '_blank');
    newTab.onload = function() {
      newTab.document.title = `${service.service_name} (${project.project_name} - ${env.env_name})`;
    };
    // this.router.navigateByUrl(`${url}`);
  }

  createProject() {
    this.router.navigate(['eru-wrapper/create-project']);
  }

  onEditClick(project_name: string) {
    //this.router.navigate(['eru-wrapper/edit-project'], { queryParams: { project:project_name} });
    this.router.navigate([`eru-wrapper/edit-project/${project_name}`]);
  }

  callNavigateApi(sId:any,eId:any,base:any){
let body={
  env_id:eId,
  service_id:sId
}
this.projectServices.getAccessToken(body).subscribe()
  }



  searchUser(e: any) {
    this.search = true;
    this.dataSource.filter = e.target.value;
  }
  onInviteClick(e: any) {
    const dialogRef = this.dialog.open(InvitePopupComponent, {
      width: '800px',
      data: e,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let respDialog = this.dialog.open(CommonPopupComponent, {
          data: { message: 'Invitation sent successfully.' },
        });
        let data = {};
        respDialog.afterClosed().subscribe((result: any) => {
          if (result.event == 'Ok') {
          }
        });
      } else {
        // let respDialog = this.dialog.open(CommonPopupComponent, {
        //   data: { message: 'Invitation sent Fail,Try again.' },
        // });
        // let data = {};
        // respDialog.afterClosed().subscribe((result: any) => {
        //   if (result.event == 'Ok') {
        //   }
        // });
      }
    });
  }
  acceptInvite(d: any) {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    let payload = {
      project_id: d.project_id,
      user_id: user.id,
    };
    this.projectServices.acceptInviteForProject(payload).subscribe(
      (res: any) => {
        let respDialog = this.dialog.open(CommonPopupComponent, {
          data: { message: 'Invitation accepted sucessfully.' },
        });
        let data = {};
        respDialog.afterClosed().subscribe((result: any) => {
          this.projectFacadeService.loadProjects();
        });
      },
      (e) => {
        let respDialog = this.dialog.open(CommonPopupComponent, {
          data: { message: 'Something went wrong.' },
        });
        let data = {};
        respDialog.afterClosed().subscribe((result: any) => {
          if (result.event == 'Ok') {
          }
        });
      },
      () => {}
    );
  }
  rejectInvite(d: any) {
    let user: any = localStorage.getItem('user');
    user = JSON.parse(user);
    let payload = {
      project_id: d.project_id,
      user_id: user.id,
    };
    this.projectServices.rejectInviteForProject(payload).subscribe(
      (res: any) => {
        let respDialog = this.dialog.open(CommonPopupComponent, {
          data: { message: 'Invitation accepted sucessfully.' },
        });
        let data = {};
        respDialog.afterClosed().subscribe((result: any) => {
          this.projectFacadeService.loadProjects();
        });
      },
      (e) => {
        let respDialog = this.dialog.open(CommonPopupComponent, {
          data: { message: 'Something went wrong.' },
        });
        let data = {};
        respDialog.afterClosed().subscribe((result: any) => {
          if (result.event == 'Ok') {
          }
        });
      },
      () => {}
    );
  }
  openImportDialog(element: any) {
    const project = this.distinctProjects
    ? this.distinctProjects.find((p: any) => {
        return p.project_name == this.project;
      })
    : null;
    localStorage.setItem(
      `${this.project}_${element.environment}`,
      JSON.stringify(project)
    );

    let data = {
      dataValue: element,
      environmentName: this.secondTableDataSource,
      projectName: this.project,
      public___project_envs:project.public___project_envs

    }
    const dataString = JSON.stringify(data);
    const popup = this.dialog.open(ImportComponent, {
      data:data
      // data: {
      //   dataValue: element,
      //   environmentName: this.secondTableDataSource,
      //   projectName:this.project
      // },
    });
    popup.afterOpened().subscribe(() => {
      const encryptParams = this.cryptoServices.encrypt(`data=${dataString}`)
      this.router.navigateByUrl(`/eru-wrapper/projects?q=${encryptParams}`, {
        skipLocationChange: true
      })
    })
    //TODO : clear the local storage
  }
  repository(data:any) {
    console.log(data)
  //  this.isRepository = true;
  this.router.navigate([`/eru-wrapper/repository`],{state:{
    envId:data.env_id,
    repoSettings:data.repo_details
  }});
  }

}
