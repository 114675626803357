import * as ProjectFilesReducer from "./project-ql.reducer";
import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectProjectQLState = createFeatureSelector<ProjectFilesReducer.ProjectQlState>('PROJECTQL');

export const getProjectConfig = createSelector(selectProjectQLState, (state) => {
    return {
        projectConfig : state.projectConfig
    }
})

