import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/database/services/notification.service';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-repo',
  templateUrl: './repo.component.html',
  styleUrls: ['./repo.component.scss'],
})
export class RepoComponent {
  @Input() projectName: any = '';
  @Input() from: any = '';
  repoData: any = {};
  @Input() envID: any;
  @Input() repo_details: any;
  autoCommit: boolean = false;
  repoName: string = '';
  branchName: string = '';
  secretOpt: any = null;
  token: any;
  key: any;
  selectedValue: any;
  selectedAuthValue: any;
  installation_id: any;
  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private notifyService: NotificationService
  ) {
    this.envID = this.router.getCurrentNavigation()?.extras?.state?.envId;
    this.repo_details =
      this.router.getCurrentNavigation()?.extras?.state?.repoSettings;
    this.settingsService.installationId.subscribe((res: any) => {
      this.installation_id = res;
    });
  }

  type = [
    {
      name: 'GITHUB',
    },
  ];

  authType = [
    {
      name: 'TOKEN',
      value: 1,
    },
    {
      name: 'GITHUBAPP',
      value: 2,
    },
  ];

  ngOnInit(): void {
    console.log(this.repoData, 'repoData');
    this.repoSettings();
  }
  commitRepo() {
    this.settingsService.commitRepo(this.projectName, this.from).subscribe({
      next: (res: any) => {
        this.notifyService.showSuccess(res.message, 'Sucess');
        // }
      },
      error: (res) => {
        this.notifyService.showError(res.error.error, 'Error');
      },
    });
  }
  repoSettings(): void {
    this.settingsService.getFetchRepo(this.projectName, this.from).subscribe({
      next: (res: any) => {
        this.repoData = res;
        this.installation_id = this.repoData.installation_id;
        this.selectedValue = this.repoData.repo_type;
        this.repoName = this.repoData.repo_name;
        this.branchName = this.repoData.branch_name;
        this.selectedAuthValue =
          this.repoData.auth_mode == 'GITHUBAPP'
            ? 2
            : this.repoData.auth_mode == 'TOKEN'
            ? 1
            : '';
        this.autoCommit = this.repoData.auto_commit;
        this.token = this.repoData.auth_key;
      },
      error: (res) => {
        this.notifyService.showError(res.error.error, 'Error');
      },
    });
  }
}
