import { createAction, props } from '@ngrx/store';

const PROJECTROUTES : string = 'PROJECTROUTES';

export const LoadProjectConfig = createAction(`${PROJECTROUTES} Load Project Config`,(payload: any = {}) => payload);
export const LoadProjectConfigSuccess = createAction(`${PROJECTROUTES} Load Project Config Success`,
                                                        props<{response : any}>());
export const LoadProjectConfigFailure = createAction(`${PROJECTROUTES} Load Project Config Failure`,
                                                        props<{error : any}>());


export const ClearProjectRoutes = createAction(`${PROJECTROUTES} Clear Project Files`);
