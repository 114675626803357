<div class="container-fluid define-projectbox" *ngIf="!openDefine">
	<form [formGroup]="form">
		<div class="form-label">
			<p class="f-12 fw-400 text-grey">Name your Project</p>
		</div>
		<div class="w600 project-style projectName darkgrey-outline">
			<mat-form-field appearance="outline" class="">
				<input
					class="myproj"
					matInput
					placeholder="eg: MyProject"
					formControlName="project"
					[(ngModel)]="projectName"
					autocomplete="off"
				/>
				<mat-error>
					<mat-error *ngIf="errorHandling('project', 'required')">
						project Id required
					</mat-error>
				</mat-error>
			</mat-form-field>
		</div>
		
		<div  formArrayName="environments">
			<div *ngIf="fromEruWrapper">
				<h2 class="f-14 fw-500 pt-30 mb-0 text-darkgrey">Add Environments</h2>
			</div>
			<div  *ngFor="let quantity of environments().controls; let i=index" [formGroupName]="i" >
				
				<div class="dotted-line" *ngIf="(environments().controls.length > 1) && (i>0)"></div>
				<div class="d-flex align-items-center">
					<div class="col-12 col-md-8 pb-20  ps-10" *ngIf="fromEruWrapper" style="padding-left: 12px;">
						<div class="row align-items-center envName">
							<div class="col-md-4 p-0 project-style darkgrey-outline">
								<div>
									<p class="f-12 m-0">Environment Name</p>
								</div>
								<mat-form-field appearance="outline" class="">
									<input  formControlName="environment"
										matInput
										type="text"
										placeholder="eg. Development"
									/>
								</mat-form-field>
							</div>
							<div class="col-md-3 checkboxUI" style="margin-left: 20px; margin-top: 15px;">
								<mat-checkbox class="example-margin"  formControlName="default_environment"
								 >Default Environment</mat-checkbox>
							</div>
						</div>
					</div>
					<!-- <div class="col-md-4" align="end" (click)="delete(i)" *ngIf="projectNumber.length>1">
						<img src="../../../../assets/images/icons/delete.png" >
					</div> -->
					<div class="col-md-4 action-btn-style" align="end" >
						<img (click)="removeEnvironment(i)" src="../../../../assets/images/icons/delete.png" >
					</div>
				</div>
			
			<div *ngIf="fromEruWrapper">
				<div>
					<div class="security-section ps-1 pb-10">
						<div>
							<p class="f-12 fw-400 text-grey">Security</p>
						</div>
						<div class="d-flex">
							<div>
								<mat-radio-group aria-label="Select an option" formControlName="type">
									<mat-radio-button
									value="none"
									(change)="onRadioChange($event, i)"
									class="f-14 fw-400 me-2 text-darkgrey"
									>
										None
									</mat-radio-button>
									<mat-radio-button 
										value="apikey"
										(change)="onRadioChange($event, i)"
										class="f-14 fw-400 me-2 text-darkgrey ml-20"
									>
										API Key
									</mat-radio-button>
										<mat-radio-button 
										value="authentication"
										(change)="onRadioChange($event, i)" 
										class="f-14 fw-400 me-2 text-darkgrey ml-20"
									>
										Authentication
									</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
						<div *ngIf="getSecurityType(quantity) == 'apikey'">
							<div class="row addpdl">
								<div class="col-md-3 project-style darkgrey-outline headername">
									<div class="form-label">
										<p class="f-12 fw-400 text-grey">Header Name</p>
									</div>
									<mat-form-field appearance="outline" class="">
										<input formControlName="hname"
											class="myproj"
											matInput
											autocomplete="off"
										/>
									</mat-form-field>
								</div>
								<div class="col-md-3 keyWidth project-style darkgrey-outline" style="margin-left: 10px;">
									<div class="form-label">
										<p class="f-12 fw-400 text-grey">Key</p>
									</div>
									<mat-form-field appearance="outline" class="">
										<input formControlName="key"
											class="myproj"
											matInput
											autocomplete="off"
										/>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div  *ngIf="getSecurityType(quantity) == 'authentication'" class="addpdl">
							<div class="row pt-10">
								<div class="col-md-3 project-style darkgrey-outline form-label w300">
									<p class="text-grey f-12 fw-400">
										User ID
									</p>
									
									<mat-form-field appearance="outline" class="">
										<input formControlName="user_id"
											matInput
											autocomplete="off"
										/>
									</mat-form-field>
								</div>
								<div  class="col-md-3 project-style darkgrey-outline form-label w300">
									<p class="text-grey f-12 fw-400">
										Password
									</p>
									
									<mat-form-field appearance="outline" class="">
										<input formControlName="password"
											matInput
											type="password"
											autocomplete="off"
										/>
									</mat-form-field>
								</div>
							</div>
							<div class="row pt-10">
								<div  class="col-md-6 project-style darkgrey-outline form-label w600">
									<p class="text-grey f-12 fw-400">
										Login Url
									</p>
									
									<mat-form-field appearance="outline" class="">
										<input formControlName="login_url"
											matInput									
											autocomplete="off"
										/>
									</mat-form-field>
								</div>								
							</div>
							<div class="row pt-10">
								<div  class="col-md-3 project-style darkgrey-outline form-label w300">
									<p class="text-grey f-12 fw-400">
										Header Name
									</p>
									
									<mat-form-field appearance="outline" class="">
										<input formControlName="header_name"
											matInput
											autocomplete="off"
										/>
									</mat-form-field>
								</div>
								<div  class="col-md-3 form-label w300 project-style darkgrey-outline">
									<p class="text-grey f-12 fw-400">
										Key
									</p>

									<mat-form-field appearance="outline" class="">
										<input formControlName="key_value"
										matInput
										autocomplete="off"
										/>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			</div>
			
		</div>
		
			
		<div class="pt-20 pb-20" *ngIf="fromEruWrapper">
			<!-- <button class="add-btn" (click)="addMore('project')">Add more</button> -->
			<button class="add-btn" (click)="addEnvironment()">Add more</button>
		</div>
	</form>

	<div class="button-group-new text-right">
		<button
			mat-flat-button
			color="primary"
			(click)="createProj($event)"
			class="w150 text-white"
			*ngIf="fromEruWrapper"
		>
			Save Project
		</button>
	</div>
</div>
<div *ngIf="openDefine">
	<!-- <app-define-database></app-define-database> -->
</div>
