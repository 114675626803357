<!-- <app-nav-bar></app-nav-bar>
<div class="ewrapper d-flex">
    <div class="sidebar" *ngIf="!hideElement">
        <app-side-bar ></app-side-bar>
    </div>
    <div class="content"
        [ngClass]="{'sidebar-hidden': hideSidebar==true}">
        <router-outlet ></router-outlet>
    </div>
</div> -->
<app-nav-bar></app-nav-bar>
<div class="ewrapper d-flex">
    <mat-drawer-container>
        <mat-drawer mode="side" opened *ngIf="showSidemenu" class="sideBar">
            <app-side-bar></app-side-bar>
        </mat-drawer>
        <mat-drawer-content class="main-section" [ngClass]="[showSidemenu === true ? 'height100' : 'calHeight']">            
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
</div>