import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CryptoService {
params!:any;
encryptedParams !: any;
decryptedParams !: any;
key:any = environment.key;
  constructor() { }
encrypt(param:any){
let params=param;
  this.encryptedParams = CryptoJS.AES.encrypt(params.trim(),this.key.trim()).toString();
  console.log(this.encryptedParams)
  // return this.encryptedParams;
  return encodeURIComponent(this.encryptedParams);

}

decrypt(param:any, key:any = this.key.trim()){
let params1 =decodeURIComponent(param);
  this.decryptedParams = CryptoJS.AES.decrypt(params1, key).toString(CryptoJS.enc.Utf8);
  return this.decryptedParams;
}
}