import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class EruAuthService {

  constructor(private httpClient:HttpClient) { }
  
  getProjectConfig(projectName : string){   
    return this.httpClient.get(`${environment.url}/store/${projectName}/config`);
  }

  saveGateway(projectName : string, gatewayType : string, channel : string, data:any){   
    return this.httpClient.post(`${environment.url}/store/${projectName}/save/gateway/${gatewayType}/${channel}`,data)
  }

  removeGateway(projectName : string,gatewayName : string, gatewayType : string, channel : string, data:any){   
    return this.httpClient.delete(`${environment.url}/store/${projectName}/remove/gateway/${gatewayName}/${gatewayType}/${channel}`,data)
  }

  saveAuth(projectName : string, data:any){   
    return this.httpClient.post(`${environment.url}/store/${projectName}/save/auth`,data)
  }

  removeAuth(projectName : string, authName : string, data:any){   
    return this.httpClient.delete(`${environment.url}/store/${projectName}/remove/auth/${authName}`,data)
  }

  saveMessageTemplate(projectName : string, data:any){   
    return this.httpClient.post(`${environment.url}/store/${projectName}/save/messagetemplate`,data)
  }

  removeMessageTemplate(projectName : string, messageName : string){   
    return this.httpClient.delete(`${environment.url}/store/${projectName}/remove/messagetemplate/${messageName}`)
  }
  functionList(projectName : string){
    return this.httpClient.get(`${environment.url}/store/${projectName}/func/list`);
  }

}
