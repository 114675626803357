<div class="export-wrapper">
    <div class="export-inner minH493">
        <!-- <h4>Export Project</h4> -->
        <!-- <div class="d-flex">
            <label class="f-14 text-grey d-flex mr-20">
                <input
                  [value]="1"
                  [(ngModel)]="isVisible"
                  name="isVisible"
                  type="radio"
                  [checked]="isSelected"/>
                  <span class="ml-10">Export to Git repository</span>
            </label>

            <label class="f-14 text-grey d-flex mr-20">
                <input
                  [value]="0"
                  [(ngModel)]="isVisible"
                  name="isVisible"
                  type="radio"
                  [checked]="!isSelected"/>
                  <span class="ml-10">Export to file</span>
            </label>
        </div>
        <div class="row">
            <div *ngIf="isVisible == 1"></div>
            <div *ngIf="isVisible == 0" class="mt-30">
                <p>Path</p>
                <div>
                    <input type="file" class="custom-file-input" id="customFile" name="filename" [disabled]="read">
                    <label class="custom-file-label" for="customFile"></label>
                </div>
            </div>
        </div>
        <div class="row button-style">
            <div class="text-right">
                <button style="height: 35px;"

                        mat-flat-button
                        color="primary"
                        type="submit"
                        cdkFocusInitial>
                        Save
                </button>
            </div>
        </div> -->
        <div (click)="uploadFile.click()">
          Click here to export your configuration : &nbsp;
            <button mat-raised-button color="primary" class="text-white">Export</button>
            <input #uploadFile (change)="upload($event)" type='file' style="display:none"/>
          </div>
    </div>
</div>
