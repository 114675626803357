import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HttpService {

  constructor(private http:HttpClient) { }

  public get(path:string){
    return new Promise((resolve, reject) => {
       this.http.get(path).subscribe(res => resolve(res), error => reject(error));
    });
  }
  public post(path:string,body:any){
    return new Promise((resolve, reject) => {
      this.http.post(path, body).subscribe(res => resolve(res), error => reject(error));
    });
  } 

  public update(path:string,body:any){
    return new Promise((resolve, reject) => {
      this.http.put(path, body).subscribe(res => resolve(res), error => reject(error));
    });
  } 
  
  public delete(path:string){
    return new Promise((resolve, reject) => {
      this.http.delete(path).subscribe(res => resolve(res), error => reject(error));
    });
  } 
  
 }
