<div class="users-component">
  <div class="container">
    <h2 class="f-30 fw-600">Users</h2>
    <div class="mt-20 d-flex justify-content-between align-items-center">
      <div>
        <form>
          <div class="">
            <mat-form-field appearance="outline">
              <!-- <div class="icon">
                        <mat-icon class="search-icon">search</mat-icon>
                      </div> -->
              <input
                matInput
                #searchInput
                placeholder="Search"
                (input)="searchUser($event)"
                [ngClass]="{ 'no-back': search === true }"
              />

              <img
                matPrefix
                style="
                  padding-bottom: 8px;
                  margin-right: 10px;
                  height: 21px;
                  width: 13px;
                "
                src="../../../../../assets/images/icons/search-icon-grey.svg"
                alt=""
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="text-white"
          mat-button
          (click)="inviteUser()"
        >
          Invite Users
        </button>
      </div>
    </div>
    <div>
      <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="searchInput.value=''" animationDuration="0ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <p>My Invities</p>
          </ng-template>
          <app-my-invities-table  #invitesTable></app-my-invities-table>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <p>My Invitations</p>
          </ng-template>
          <div class="table-container mt-4 wrapper-table">
            <table mat-table [dataSource]="dataSource2" class="table-border">
              <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef>Project</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.project_name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="environment">
                <th mat-header-cell *matHeaderCellDef>Environment</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.env_name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="services">
                <th mat-header-cell *matHeaderCellDef>Services</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.service_name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="invitation_status">
                <th mat-header-cell *matHeaderCellDef>Invite Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.invite_status }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element"  style="display: flex;">
                <ng-container>
                  <ng-container >
                    <div [style.opacity]="element.invite_status =='PENDING'?1:0" [style.pointerEvents]="element.invite_status =='PENDING'?'auto':'none'"class="photo-container icon-style">
                      <a class="edit-img cursor-pointer">
                        <img src="../../../../../assets/images/icons/email_accept.png"
                          >
                        <span style="z-index: 10000!important;">Accept</span>
                      </a>
                    </div>
                    <div class="photo-container icon-style" [style.opacity]="element.invite_status =='PENDING'?1:0" [style.pointerEvents]="element.invite_status =='PENDING'?'auto':'none'">
                      <a class="edit-img cursor-pointer">
                        <img src="../../../../../assets/images/icons/mail_reject.svg"
                          >
                        <span style="z-index: 10000!important;">Reject</span>
                      </a>
                    </div>

                </ng-container>

              </ng-container>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>
</div>
