import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit ,AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<OtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr:ChangeDetectorRef,
    private authService:AuthService,
    private router:Router
  ) {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges()
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  email = '';
  mobNum = '' ;
  ngOnInit(): void {
    console.log(this.data)
    if (this.data) {
      this.email = this.maskEmail(this.data.email);
      this.mobNum = this.maskMobile(this.data.mobile)
    } else {
      this.email = '';
      this.mobNum = '';
    }
    this.verifyCode()


  }
  otpMobileControl = new FormControl('');
  otpEmailControl = new FormControl('');

  onSubmit() {
    console.log(this.dialogRef);
    this.dialogRef.close(true);
    this.router.navigate(['eru-wrapper/dashboard']);
  }
  maskEmail(email: string) {
    const parts = email?.split('@');
    const username = parts[0];
    const domain = parts[1];

    const maskedUsername = username?.slice(0, 3) + '***' + username.slice(-3);

    return `${maskedUsername}@${domain}`;
  }
  maskMobile(mob:string) {
    const parts = mob.split('')
    const length = mob.length;
    let maskedNum = ''
    for(let i=0;i<length;i++) {
      maskedNum = maskedNum + `${[0,1,2,length-2].includes(i)?parts[i]:'*'}`;
    }
    return maskedNum
  }

  mobileBtnText:any='Verify'
  emailBtnText:any='Verify'
  mobileBtnClicked =false
  emailBtnClicked =false
  verifyCode() {
    if(!this.data.email_verified){
      let emailData = {
        username: this.data.email,
        credential_type: 'email',
      };
      this.authService.getVerifyCode(emailData).subscribe((res: any) => {});
    }
    if(!this.data.mobile_verified){
    let mobileData = {
      username: this.data.mobile,
      credential_type: 'mobile',
    };
    this.authService.getVerifyCode(mobileData).subscribe((res: any) => {});
  }
  }
  onVerify(value: string) {
    if (value === 'mobile') {
      let data = {
        code: this.otpMobileControl.value,
        id: this.data.mobile,
      };
      this.mobileBtnClicked = true;
      this.authService.getConfirmCode(data).subscribe({
        next: (res: any) => {
          if (res) {
            this.mobileBtnText = 'Verified';
            this.mobileBtnClicked = false;
          }
        },
        error: () => {
          this.mobileBtnText = 'Verify';
          this.mobileBtnClicked = false;
        },
      });
    }
     else {
      let data = {
        code: this.otpEmailControl.value,
        id:  this.data.email,
      };
      this.emailBtnClicked = true;
      this.authService.getConfirmCode(data).subscribe({
        next: (res: any) => {
          if (res) {
            // this.authService.setAuth(res);

            this.emailBtnText = 'Verified';
            this.emailBtnClicked = false;
          }
        },
        error: () => {
          this.emailBtnText = 'Verify';
          this.emailBtnClicked = false;
        },
      });
    }
  }
}
