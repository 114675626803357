import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { DashboardState } from 'src/app/dashboard/store/dashboard.state';
import { selectDashboardState } from 'src/app/dashboard/store/dashboard.state';
import {  } from 'src/app/dashboard/store/reducers/dashboard.reducers';
import { LoggerService } from '../../services/logger.service';
//import { LoadProject, ProjectsLoad } from 'src/app/dashboard/store/actions/dashboards.actions';
import { ApplicationService } from '../../services/application.service';
import { GetDatastoreTables } from 'src/app/dashboard/store/actions/dashboards.actions';
import { MENUS } from '../../constants/menus';
import { DataCommuteService } from 'src/app/services/data-commute.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Logout } from 'src/app/store/actions/logout.action';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {

  isSideBarActive: boolean = false;
  istoggleActive: boolean = false;
  isBarActive: boolean = false;
  istogleActive: boolean = false;
  showMe: boolean = false;
  hideElement: boolean = false;
  fromEruAuth:boolean=false;
  //private getState: Observable<any>;
  public project:any;
  public fromEruWrapper: boolean = false;
  public projects:any;
  public dbOptions:any[]=[];
  public selectedProject:any = '';
  public selectedItem:number = 0;
  public selectedChild:any = null;
  public menus:any[]=[];
  public panelOpenState = false;
  public showBold = false;
  private projectID:any;
  private envName:any;
  activeLink:any;
  link:any;
  fromEruFiles:boolean=false;
  projectList : any = [];
  envList : any = [];
  headerListParsed : any;
  selectedEnv : any = '';
  loginUser: string = '';
  projectName:any
  environmentName:any
  encryptedParams: any;
  decryptedParams: any;
  fromEruRoutes: boolean = false;
  fromEruGateway: boolean = false;
  fromEruQl: boolean = false;
  constructor(private router: Router, private store: Store<DashboardState>,private appService:ApplicationService,
    private authService:AuthService,
    private cryptoService: CryptoService,
    private _route: ActivatedRoute, private route: ActivatedRoute, private dataCommuteService : DataCommuteService) {
      this.route.queryParams
      .subscribe(params => {
        this.encryptedParams = params['q'];
        //  this.projectID = params['project'];
        //  this.envName = params['env'];
        //  this.projectName = this.projectID;
        //  this.environmentName =this.envName;
        if(this.encryptedParams){
          this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
          this.mapParamsValue();
        }


       });
      //this.getState = this.store.select(selectDashboardState);
  }

  ename = [
    {name: 'Environment Name', value : '1'},
    {name: 'Environment Name2', value : '2'},
    {name: 'Environment Name3', value : '3'},
  ];
  pname = [
    {name: 'Project Name', value : '1'},
    {name: 'Project Name2', value : '2'},
    {name: 'Project Name3', value : '3'},
  ];
  ngOnInit() {


    this.authService.onUserUpdate.subscribe((data)=>{
      this.loginUser = `${data.attributes.first_name} ${data.attributes.last_name}`;
    });
    if (!this.loginUser) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      if (user) {
        this.loginUser = `${user.attributes.first_name} ${user.attributes.last_name}`;
      }
    }
    if(this.router.url.split('/')[1]=='eru-wrapper'){
      this.fromEruWrapper = true;
    }
    this.menus =  MENUS;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideElement =
          event.url === '/' || event.url === '/signin' || event.url === '/signup'||event.url==='/verify-otp' ? true : false;
      }
      if (event instanceof NavigationEnd) {
        this.showMe =
        event.url === '/dashboard'|| event.url === '/project-creation' ? true : false;
      }
   });
  this.wrapperLoad();

  const headerList : any = localStorage.getItem('headerList');
  if(headerList){
    this.headerListParsed = JSON.parse(headerList);
    this.projectList = this.headerListParsed.map((h : any) => {return {name : h.project.name, value : h.project.name}})
  }


 }
 mapParamsValue() {
  if (this.decryptedParams) {
    const keyValuePairs = this.decryptedParams?.split('&');
    const paramsArray = keyValuePairs?.map((pair: string) => {
      const [key, value] = pair?.split('=');
      return { key, value };
    });
    if (paramsArray) {
      for (const param of paramsArray) {
        if (param.key === 'project') {
          this.projectID = param.value;
          this.projectName = param.value
        } else if (param.key === 'env') {
          this.envName = param.value;
          this.environmentName=param.value;
        }
      }
    }
  }
}
 onProjectChange(event : any){
  console.log('onProjectChange', event)
  const selectedProject = this.headerListParsed.find((h : any) => { return h.project.name == event.value});
  this.envList = [];
  if(selectedProject){
    this.envList = selectedProject.envs.map((e : any) => {return {name : e.name, value : e.name}})
  }
 }

 onEnvChange(event : any){
   this.dataCommuteService.onProjectOrEnvChange.next(
     {project : this.selectedProject, env : event.value, service : 'eru-ql'}
    );

 }

  togglesideBar() {
    this.isSideBarActive = !this.isSideBarActive;
    this.istoggleActive = !this.istoggleActive;
  }
  toglesideBar() {
    this.isBarActive = !this.isBarActive;
    this.istogleActive = !this.istogleActive;
  }


  onHomeClick(){
    this.router.navigate(['eru-ql'])
  }

  gotoDashboard(){
    if(this.fromEruWrapper){

      this.router.navigate(['eru-wrapper/dashboard'])
    }
    else{
      this.router.navigate(['eru-ql'])
    }
  }

  gotoUser(){
    this.link= "users";
    this.router.navigate(['eru-wrapper/users']);
  }
  gotoProjects(){
    this.link = "projects";
    this.router.navigate(['eru-wrapper/projects']);
  }

  openSubMenu = (item: any) => {
    item.expanded=!item.expanded;
  };

  textBold() {
    this.showBold = !this.showBold;
  }

  gotoLink(item:any, isChild :any, index : any, parent : any){
    if(isChild){
      this.isSideBarActive = false;
      let parentIndex = this.menus.indexOf((x : any) => x.label == parent.label)
      this.selectedChild = index;
      this.selectedItem = parentIndex;
    }
    else{
      this.isSideBarActive = false;
    this.selectedItem = index;
    }
    this.router.navigate([item.link], { queryParams: { project:this.projectID, env : this.envName } });
  }

  selectionChanged(item : any) {
    this.selectedItem = item;
  }

  closeSide() {
    this.isSideBarActive = false;
  }
  wrapperLoad() {
    this.activeLink = this.router.url.split('/');
    if(this.activeLink[1]=='eru-wrapper'){
      this.fromEruWrapper = true;
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="users"){
        this.link= 'users';
      }
      if(this.activeLink[2]==="projects" || this.activeLink[2]==="edit-project"){
        this.link = 'projects';
      }
      // if(this.activeLink[2]==='create-project'){
      //   this.hideSidebar = true;
      // }
    }
    else{
      this.fromEruWrapper = false;
    }
    if(this.activeLink[1]=='eru-files'){
      this.fromEruFiles = true;
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="files" || this.activeLink[2]==="create-file"){
        this.link= 'files';
      }
      if(this.activeLink[2]==="key" || this.activeLink[2]==="create-key"){
        this.link = 'key';
      }
      if(this.activeLink[2]==="variables"){
        this.link = 'variables';
      }
    } else{
      this.fromEruFiles = false;
    }
    if(this.activeLink[1]=='eru-functions') {
      this.fromEruRoutes = true;
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="routes-list" || this.activeLink[2]==="create-file"){
        this.link= 'routes-list';
      }
      if(this.activeLink[2]==="functions" || this.activeLink[2]==="create-file"){
        this.link= 'functions';
      }
      if(this.activeLink[2]==="variables"){
        this.link= 'variables';
      }
    } else {
      this.fromEruRoutes = false;
    }
    if(this.activeLink[1]=='eru-ql') {
      this.fromEruQl = true;
      if(this.activeLink[2]==="overview"){
        this.eruqlOverview();
      }
      if(this.activeLink[2]==="database"){
        this.qlDashboard();
      }
      if(this.activeLink[2]==="my-queries"){
        this.myQueries();
      }
      if(this.activeLink[2]==="setting"){
        this.qlSettings();
      }
      if(this.activeLink[2]==="variables"){
        this.qlVariables();
      }
    } else {
      this.fromEruQl = false;
    }
    if(this.activeLink[1]=='eru-gateway') {
      this.fromEruGateway = true;
      if(this.activeLink[2]==='dashboard'){
        this.gotoGatewayDashboard();
      }
      if(this.activeLink[2]==='authorizer-list' || this.activeLink[2]==='add-authorizer'){
        this.gotoAuthorizers();
      }
      if(this.activeLink[2]==='rule-list'){
        this.gotoRules();
      }
    } else {
      this.fromEruGateway = false;
    }
    if(this.activeLink[1]==='eru-auth'){
      this.fromEruAuth = true;
      if(this.activeLink[2]==='gateway'){
        this.gotoGateway();
      }
      else if(this.activeLink[2]==='create-gateway'){
        this.gotoCreateGateway();
      }
      else if(this.activeLink[2]==='message-template'){
        this.gotoMessageTemplates();
      }
      else if(this.activeLink[2]=='create-message-template'){
        this.gotoCreateMessageTemplates();
      }
      else if(this.activeLink[2]=='authorizer'){
        this.gotoAuthorizer();
      }
      else if(this.activeLink[2]=='create-authorizer'){
        this.gotoCreateAuthorizer();
      }
    }
    else{
      this.fromEruAuth=false;
    }
    this.menus =  MENUS;
  }

  gotoDashboardFiles() {
    this.link= "dashboard";
    this.router.navigate([`eru-files/dashboard`], { queryParams: { q:this.encryptedParams } });
  }
  gotoFiles() {
    this.link= "files";
    this.router.navigate([`eru-files/files`], { queryParams: { q:this.encryptedParams } });
  }
  gotoKeys() {
    this.link= "key";
    this.router.navigate([`eru-files/key`], { queryParams: { q:this.encryptedParams } });
  }

  gotoGateway(){
    this.link = "gateway";
    this.router.navigate(['eru-auth/gateway'], { queryParams: { q:this.encryptedParams } });
  }

  gotoAuthDashboard(){
    this.link="dashboard";
    this.router.navigate(['eru-auth/dashboard'], { queryParams: { q:this.encryptedParams } });
  }

  gotoCreateGateway(){
    this.link = "gateway";
    this.router.navigate(['eru-auth/create-gateway'], { queryParams: { q:this.encryptedParams } });
  }
  gotoMessageTemplates(){
    this.link="message-template";
    this.router.navigate(['eru-auth/message-template'], { queryParams: { q:this.encryptedParams } });
  }

  gotoCreateMessageTemplates(){
    this.link="message-template";
    this.router.navigate(['eru-auth/create-message-template'], { queryParams: { q:this.encryptedParams } });
  }

  gotoAuthorizer(){
    this.link="authorizer";
    this.router.navigate(["eru-auth/authorizer"], { queryParams: { q:this.encryptedParams } });
  }

  gotoCreateAuthorizer(){
    this.link="authorizer";
    this.router.navigate(["eru-auth/create-authorizer"], { queryParams: { q:this.encryptedParams } });
  }
  gotoRoutesDashboard() {
    this.link="dashboard";
    this.router.navigate(["eru-functions/dashboard"], { queryParams: { q:this.encryptedParams } });
  }
  gotoRoutes() {
    this.link="routes-list";
    this.router.navigate(["eru-functions/routes-list"], { queryParams: { q:this.encryptedParams } });
  }
  gotoFunctions() {
    this.link="functions";
    this.router.navigate(["eru-functions/functions"], { queryParams: { q:this.encryptedParams } });
  }

  onLogout(): void {
    const refreshToken: any = localStorage.getItem('refreshToken');
      // this.authService.getTokens(refreshToken).subscribe((response) => {

      console.log("Got response");
      this.authService.logoutUser().subscribe((res: any) => {
        this.authService.clearTokens();
        localStorage.clear();
        this.store.dispatch(new Logout());
        console.log("Dispatchde logout")
        this.router.navigateByUrl('/auth');
        });
        this.authService.clearTokens();
        localStorage.clear();
        this.store.dispatch(new Logout());
      //});


    // localStorage.clear();
    // this.router.navigateByUrl('/auth');
  }
  gotoVariables(){
    this.link="variables";
    this.router.navigate(['eru-files/variables'], { queryParams: { q:this.encryptedParams } });
  }
  gotoRouteVariables(){
    this.link="variables";
    this.router.navigate(['eru-functions/variables'], { queryParams: { q:this.encryptedParams } });
  }
  gotoGatewayDashboard(){
    this.link="dashboard";
    this.router.navigate(['eru-gateway/dashboard'], { queryParams: { q:this.encryptedParams } });

  }
  gotoRules() {
    this.link="rule-list";
    this.router.navigate(['eru-gateway/rule-list'], { queryParams: { q:this.encryptedParams } });
  }
  gotoAuthorizers() {
    this.link="authorizer-list";
    this.router.navigate(['eru-gateway/authorizer-list'], { queryParams: { q:this.encryptedParams } });
  }
  eruqlOverview() {
    this.link="overview";
    this.router.navigate(['eru-ql/dashboard/overview'], { queryParams: { q:this.encryptedParams } });
  }
  qlDashboard() {
    this.link="database";
    this.router.navigate(['eru-ql/dashboard/database/list'], { queryParams: { q:this.encryptedParams } });
  }
  myQueries() {
    this.link="my-queries";
    this.router.navigate(['eru-ql/dashboard/database/my-queries'], { queryParams: { q:this.encryptedParams } });
  }
  qlVariables() {
    this.link="variables";
    this.router.navigate(['eru-ql/dashboard/variables'], { queryParams: { q:this.encryptedParams } });
  }
  qlSettings() {
    this.link="setting";
    this.router.navigate(['eru-ql/dashboard/database/setting'], { queryParams: { q:this.encryptedParams } });
  }
}
