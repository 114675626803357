import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '../services/crypto.service';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit {
  tabIndex:any=0
  projectName : any;
  vars : any;
  envVars : any;
  secrets : any;
  read!:boolean;
  readValue!:any
  encryptedParams: any;
  decryptedParams: any;

  constructor(
    private sharedService : SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService
    ) {
    this.route.queryParams
    .subscribe((params : any) => {
      this.encryptedParams = params['q'];
    });
    this.mapParamsValue();
   }
   mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectName = param.value;
          } else if (param.key === 'read') {
            this.readValue = param.value;
            if (this.readValue === 'true') {
              this.read = true;
            } else {
              this.read = false;
            }
          }
        }

      }
    }
  }
  ngOnInit(): void {
  //  let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

    // this.sharedService.getVariablesList(this.projectName,isEruGateway).subscribe(
    //   (res :any) =>{
    //     console.log('getVariablesList', res)
    //     this.vars = res.vars;
    //     this.envVars = res.env_vars;
    //     this.secrets = res.secrets;
    //   }
    // )
    // this.sharedService.fetchVars(this.projectName).subscribe((res:any)=>{
    //  let  {env_vars,secrets,vars}= res
    //  this.vars=vars
    //  this.envVars=env_vars
    //   this.secrets=secrets

    // })
  }

}
