import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  private FETCH_USERS = '/store/eru/myquery/execute/fetch_users';
  private SEND_INVITE = '/store/eru/myquery/execute/create_user_invite';
  private SEND_INVITE_2 = '/eru/func/invite_user';
  private FETCH_INVITE = '/store/eru/myquery/execute/fetch_invites';
  private FETCH_PROJECT_USER = '/store/eru/myquery/execute/fetch_project_users';

  fetchUsers(data: { user_str: string }): Observable<any> {
    return this.httpClient
      .post<any>(environment.base_url + this.FETCH_USERS, data)
      .pipe(
        map((res: any) => {
          return res[0];
        }),
        catchError((error: HttpErrorResponse) => {
          // handle error
          if (error.error instanceof ErrorEvent) {
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
          }

          return throwError(
            () => 'Something bad happened; please try again later.'
          );
        })
      );
  }
  sendInvite(data: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.base_url + this.SEND_INVITE, data)
      .pipe(
        map((res: any) => {
          return res[0];
        }),
        catchError((error: HttpErrorResponse) => {
          // handle error
          if (error.error instanceof ErrorEvent) {
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
          }
          return throwError(
            () => 'Something bad happened; please try again later.'
          );
        })
      );
  }
  sendInvite2(data: any): Observable<any> {
    return this.httpClient
      .post<any>(environment.routes_url + this.SEND_INVITE_2, data)
      .pipe(
        map((res: any) => {
          return res[0];
        }),
        catchError((error: HttpErrorResponse) => {
          // handle error
          if (error.error instanceof ErrorEvent) {
          } else {
            console.error(
              `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
          }

          return throwError(
            () => 'Something bad happened; please try again later.'
          );
        })
      );
  }

  fetchInvites(data:any) {
    return this.httpClient.post(environment.base_url + this.FETCH_INVITE, data).pipe(tap((res:any)=>{
      let data = res[0]?.users
      if (data) {
        const groupedData :any= data.reduce((acc:any, item:any) => {
          const key = `${item.project_name}-${item.env_name}`;
          if (!acc[key]) {
            acc[key] = { ...item, service_name: [item.service_name] };
          } else {
            acc[key].service_name.push(item.service_name);
          }
          return acc;
        }, {});

        const result:any = Object.values(groupedData).map((item:any) => ({
          ...item,
          service_name: item.service_name.join(', ')
        }));

        console.log(result,'check');
        this.invitations.invitations= result
        this.invitations.totalInvites= result.filter((e:any)=>e.invite_status=='PENDING').length||0
      }
    }));
  }

  fetchProjectUsers() {
    return this.httpClient.post(environment.base_url + this.FETCH_PROJECT_USER,{}).pipe(tap((res:any)=>{
        this.invitations.invities=this.groupProjectData(res[0].Results)||[]
    }));
  }
  dashboardLoaded=false
  invitations:any={
    invitations:[],
    invities:[],
    totalInvites:0,
    clicked:false
  }
  acceptInvities(body:any){
    return this.httpClient.post(`${environment.base_url}/store/eru/myquery/execute/accept_user_invite`,body);
  }
  rejectInvities(body:any){
    return this.httpClient.post(`${environment.base_url}/store/eru/myquery/execute/reject_user_invite`,body);

  }

  groupProjectData(responseData: any[]) {
    let groupedData = responseData.reduce((acc, curr) => {
      let key = `${curr.project_name}-${curr.env_name}`;
      if (!acc[key]) {
        acc[key] = {
          project_name: curr.project_name,
          env_name: curr.env_name,
          invite_status: curr.invite_status,
          innerTable: [{
            user: `${curr.user_first_name} ${curr.user_last_name}`,
            env_name: curr.env_name,
            services: [curr.service_name],
            invite_status: curr.invite_status
          }]
        };
      } else {
        let userIndex = acc[key].innerTable.findIndex((user:any) => user.user === `${curr.user_first_name} ${curr.user_last_name}`);
        if (userIndex !== -1) {
          if (!acc[key].innerTable[userIndex].services.includes(curr.service_name)) {
            acc[key].innerTable[userIndex].services.push(curr.service_name);
          }
          acc[key].innerTable[userIndex].invite_status = curr.invite_status;
        } else {
          acc[key].innerTable.push({
            user: `${curr.user_first_name} ${curr.user_last_name}`,
            env_name: curr.env_name,
            services: [curr.service_name],
            invite_status: curr.invite_status
          });
        }
      }
      return acc;
    }, {});

    // Convert to array and join services with comma
    let result = Object.values(groupedData).map((item: any) => {
      item.innerTable = item.innerTable.map((user: any) => {
        user.services = user.services.join(', ');
        return user;
      });
      return item;
    });

    // Sort the result array by status in the order: accepted, rejected, pending
    result.sort((a: any, b: any) => {
      const statusOrder = ['ACCEPTED', 'REJECTED', 'PENDING'];
      return statusOrder.indexOf(a.invite_status) - statusOrder.indexOf(b.invite_status);
    });

    return result;
  }
}
