<div class="settingWrapper">
  <h2 class="functions f-22 fw-600 mb-0 content-heading">Settings</h2>
  <mat-tab-group animationDuration="0ms" [(selectedIndex)]="tabIndex">
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold">Settings</p>
      </ng-template>
      <app-encrypt-decrypt *ngIf="1 == tabIndex+1" [read]="read"></app-encrypt-decrypt>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold">Export Project</p>
      </ng-template>
      <app-export-project *ngIf="2 == tabIndex+1" [read]="read"></app-export-project>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold" *ngIf="fromQl">Secret Manager & KMS</p>
        <p class="bold" *ngIf="!fromQl">Secret Manager</p>
      </ng-template>
      <app-secret-manager *ngIf="3 == tabIndex+1"
        [read]="read"
        [from]="fromQl"
        [secretData]="secretDataFromApi"
        (saveSecret)="saveSecret($event)"
      ></app-secret-manager>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold">Repo</p>
      </ng-template>
      <app-repo *ngIf="4 == tabIndex+1" [projectName]="this.projectName" [from]="from"></app-repo>

    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold">KMS</p>
      </ng-template>
      <app-kms *ngIf="5 == tabIndex+1" [projectName]="this.projectName" [from]="from"></app-kms>
      <!-- <app-repo *ngIf="5 == tabIndex+1" [projectName]="this.projectName" [from]="from"></app-repo> -->
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <p class="bold">Events</p>
      </ng-template>
      <app-events *ngIf="6 == tabIndex+1" [projectName]="this.projectName" [from]="from"></app-events>
      <!-- <app-repo *ngIf="5 == tabIndex+1" [projectName]="this.projectName" [from]="from"></app-repo> -->
    </mat-tab>
  </mat-tab-group>
</div>
