import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonsUiModule } from 'src/app/commons-ui/commons-ui.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { DashboardFooterComponent } from './dashboard-footer/dashboard-footer.component';
import { VariablesComponent } from './variables/variables.component';
import { VariablesTabComponent } from './variables/variables-tab/variables-tab.component';
import { EnvironmentVariableTabComponent } from './variables/environment-variable-tab/environment-variable-tab.component';
import { SecretTabComponent } from './variables/secret-tab/secret-tab.component';
import { CommonPopupComponent } from 'src/app/modules/shared/common-popup/common-popup.component';
import { SettingsComponent } from './settings/settings.component';
import { EncryptDecryptComponent } from './settings/encrypt-decrypt/encrypt-decrypt.component';
import { ExportProjectComponent } from './settings/export-project/export-project.component';
import { GitSettingsComponent } from './settings/git-settings/git-settings.component';
import { SecretsComponent } from './settings/secrets/secrets.component';
import { SecretManagerComponent } from './settings/secret-manager/secret-manager.component';
import { RepoComponent } from './settings/repo/repo.component';
import { KmsComponent } from './settings/kms/kms.component';
import { EventsComponent } from './settings/events/events.component';



@NgModule({
  declarations: [
    SharedComponent,
    DashboardComponent,
    DashboardFooterComponent,
    VariablesComponent,
    VariablesTabComponent,
    EnvironmentVariableTabComponent,
    SecretTabComponent,
    CommonPopupComponent,
    SettingsComponent,
    EncryptDecryptComponent,
    ExportProjectComponent,
    GitSettingsComponent,
    SecretsComponent,
    SecretManagerComponent,
    RepoComponent,
    KmsComponent,
    EventsComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    CommonsUiModule,
    MatTableModule,

  ],
  exports: [
    DashboardComponent,
    DashboardFooterComponent,
    GitSettingsComponent
  ],
})
export class SharedModule { }
