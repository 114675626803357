import { Component, OnInit, Input } from '@angular/core';
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { Router } from '@angular/router';
import { PopupComponent } from 'src/app/modules/eru-wrapper/components/popup/popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonPopupComponent } from 'src/app/modules/eru-wrapper/components/common-popup/common-popup.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @Input() project : any;
  constructor(
    private router : Router,
    private dialog: MatDialog,
    private projectFacadeService:ProjectFacadeService) { }

  ngOnInit(): void {
  }

  deleteProject(){
    if(this.project && this.project.project_id)
    {
        const dialogRef = this.dialog.open(CommonPopupComponent, {
          data: {
            title: 'Delete Project',
            message : 'Are you sure, you want to delete the project.'}
        });
    
        dialogRef.afterClosed().subscribe((result : any) => {
          if(result.event == 'Ok'){
                  
            this.projectFacadeService.deleteProject({doc : {
              "project_id":this.project.project_id
            }}).subscribe((res : any) => {
              if(res){
                this.dialog.open(PopupComponent, {
                  data: {
                    message : "Project deleted successfully"
                  },
                });  
                this.router.navigate(['eru-wrapper/projects']);
              }
            })
          }
        });
      }
  }

}
