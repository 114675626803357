import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';


interface Algorithm {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-secrets',
  templateUrl: './secrets.component.html',
  styleUrls: ['./secrets.component.scss']
})
export class SecretsComponent implements OnInit {
  isVisible: any;
  public isSelected = true;
  isChecked1 = false;
  isChecked2 = false;
  algorithmSelected: string = '';
  jwtUrl: string = '';
  headerKey: string = '';
  secretKey: string = '';
  algorithm: Algorithm[] = [
    { value: 'select', viewValue: 'select ' },
    { value: 'HSA', viewValue: 'HS256 ' },
  ];

  audience: any = [''];
  issuer: any = [''];
  @Input() read:any;
  constructor(public settingService: SettingsService) {}

  ngOnInit(): void {
    this.isVisible = 1;
  }
  inputChange(event: any, type: any) {
    // this.algorithmSelected=val
    if (type === 'option') {
      this.algorithmSelected = event.value;
    }
    if (type === 'text') {
      this.jwtUrl = event.target.value;
    }
  }
  save() {
    let body = {
      headerKey: '',
      secretAlgo: '',
      secretKey: '',
      jwkUrl: '',
      audience: [],
      issuer: [],
    };

    if (this.isVisible === 1) {
      //secret
      body.secretAlgo = this.algorithmSelected;
      body.headerKey = this.headerKey;
      body.secretKey = this.secretKey;
      body.audience = this.audience;
      body.issuer = this.issuer;
    } else {
      //jwt
      body.jwkUrl = this.jwtUrl;
      body.audience = this.audience;
      body.issuer = this.issuer;
    }

    this.settingService
      .saveConfig(this.settingService.setTokenSecret(body))
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  toggle(event: any, type: any) {
    if (type === 'audience') {
      if (event.checked) {
        this.audience = [''];
      }
    } else {
      if (event.checked) {
        this.issuer = [''];
      }
    }
  }

  addMore(type: any) {
    if (type === 'audience') {
      this.audience.push('');
    }
    if (type === 'issuer') {
      this.issuer.push('');
    }
  }

  deleteInput(type: any, index: any) {
    if (type === 'audience') {
      this.audience.splice(index, 1);
    }
    if (type === 'issuer') {
      this.issuer.splice(index, 1);
    }
  }

  verificationInputChange(event: any, index: any, type: any) {
    if (type === 'audience') {
      this.audience[index] = event.value;
    }
    if (type === 'issuer') {
      this.issuer[index] = event.value;
    }
  }

  toggleChange(value: any) {
    this.audience = [''];
    this.issuer = [''];
    this.isChecked1 = false;
    this.isChecked2 = false;
  }
}
