import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styles: [
    `
      .header,
      .dialog-message {
        text-transform: lowercase;
      }
      .header::first-letter,
      .dialog-message::first-letter {
        text-transform: uppercase;
      }
      .btn-cancel {
        background-color: var(--red);
        color: var(--white);
      }
      .popup-section {
        width: 450px;
        .popup-header {
          span {
            margin: 0;
            font-size: 16px;
          }
          .pclose {
            position: absolute;
            right: 2%;
            top: 30%;
          }
        }
        .cancel {
          border: 1px solid #ccc;
        }
        .popup-footer {
          padding: 0 16px 16px;
        }
      }
    `,
  ],
})
export class CommonDialogComponent implements OnInit {
  status!: string;
  title!: string;
  message!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cancelText: string;
      confirmText: string;
      message: string;
      title: string;
      status: string;
    },
    private mdDialogRef: MatDialogRef<CommonDialogComponent>
  ) {
    console.log('data', data);
  }
  ngOnInit(): void {
  }
  public cancel() {
    this.close(false);
  }
  public close(value: boolean) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  public closeMe() {
    this.close(true);
  }
  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }
  
}