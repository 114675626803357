import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonPopupComponent } from 'src/app/modules/shared/common-popup/common-popup.component';
import { PopupComponent } from 'src/app/modules/eru-wrapper/components/popup/popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CryptoService } from '../../services/crypto.service';
import { NotificationService } from 'src/app/database/services/notification.service';
@Component({
  selector: 'app-secret-tab',
  templateUrl: './secret-tab.component.html',
  styleUrls: ['./secret-tab.component.scss']
})
export class SecretTabComponent implements OnInit {
  form!: FormGroup;
   secrets : any;
  @Input()read:any;
  @Input()projectName:any
  encryptedParams: any;
  decryptedParams: any;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private sharedService : SharedService,
    private notificationService:NotificationService,
    private router: Router,
    private notifyService:NotificationService,
    private cryptoService: CryptoService,
    private route: ActivatedRoute) {
    this.route.queryParams
    .subscribe((params : any) => {
      // this.projectName = params['project'];
      this.encryptedParams = params['q'];
    });



     this.form = this.fb.group({
      secret:  this.fb.array([this. newSecret()]),
    });
   }

  ngOnInit(): void {
    this.mapParamsValue();
    let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'
   this.sharedService.fetchVars(this.projectName,isEruGateway).subscribe((res:any)=>{
    let  {env_vars,secrets,vars}= res

     this.secrets=secrets
    if(this.secrets){
      this.secret().clear();
      const secretKeys = Object.keys(this.secrets)
      secretKeys.forEach((e :any , index :any) => {
        const obj = this.secrets[e]
        const getSecrets = new FormGroup({
          keyName: new FormControl({value:obj.key,disabled:true}),
          valueName: new FormControl({value:obj.value,disabled:true})
        })
        this.secret().push(getSecrets);
      })
    }

   },()=>{},()=>{

   })


  }
  mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectName = param.value;
          }
        }
      }
    }
  }
  ngOnChanges(changes: SimpleChanges){
    if(this.secrets){
      this.secret().clear();
      const varKeys = Object.keys(this.secrets)
      varKeys.forEach((e :any , index :any) => {
        const obj = this.secrets[e]
        const getVars = new FormGroup({
          keyName: new FormControl({value:obj.key,disabled:true})
        })
        this.secret().push(getVars);
      })

    }
  }

  secret(): FormArray {
    return this.form.get('secret') as FormArray;
  }
  newSecret(): FormGroup {
    return this.fb.group({
      keyName: ['']
    });
  }
  addSecret() {
    this.secret().push(this.newSecret());
  }

  removeSecret(index:number){
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Delete Secret',
        message : 'Are you sure, you want to delete the secret.'}
    });

    dialogRef.afterClosed().subscribe((result : any) => {
      let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

      if(result.event == 'Ok'){
        const variables : any = this.secret().controls;
        this.sharedService.deleteSecret(this.projectName, variables[index].controls.keyName.value,isEruGateway).subscribe((res :any) => {
          this.secret().removeAt(index);
          this.notifyService.showSuccess(res.msg, 'Sucess');
},(error:any)=>{
  this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

})
      }
    });
  }


  saveSecrets(index : any){
    let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

    const variables : any = this.secret().controls;
    let key = variables[index].controls.keyName.value;
    if(key && key.trim() !== "" )
    {
      const data = {
        "key":key
      }
      this.sharedService.saveSecret(this.projectName, data,isEruGateway).subscribe((res : any) => {

        this.secret().controls[index].get('keyName')?.disable();
        this.notifyService.showSuccess(res.msg, 'Sucess');
},(error:any)=>{
  this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

})
    }

  }
  load() {
    let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

    this.sharedService.loadSecrets(this.projectName,isEruGateway).subscribe(
      (res :any) =>{
        console.log('load', res)
        // this.vars = res.vars;
        // this.envVars = res.env_vars;
        // this.secrets = res.secrets;
        this.notifyService.showSuccess(res.msg, 'Sucess');
      },(error:any)=>{
        this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

      })


  }
}
