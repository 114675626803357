<div class="wrapper">
  <div>
    <div>Enter the six-digit OTP sent to your email id {{ email }} and your mobile number {{mobNum}}</div>
    <div class="row">
    <div class="col-md-6">
      <div class="otp_container">
      <p class="f-12 fw-400 text-left"  style="margin-left: 20px;">Mobile OTP</p>
      <ng-otp-input
        [formCtrl]="otpMobileControl"
        [config]="{ length: 6 }"
        [ngStyle]="{'pointer-events': data.mobile_verified ? 'none' : 'auto', 'opacity': data.mobile_verified ? '0.8' : '1'}"
      ></ng-otp-input>
    </div>
    <div class="d-flex justify-center" [ngStyle]="{'pointer-events': mobileBtnClicked ? 'none' : 'auto', 'opacity': mobileBtnClicked ? '0.8' : '1'}">
      <p class="resend_text text-right"  style="margin-right: 20px;" (click)="onVerify('mobile')">{{mobileBtnText}}</p>
      <p class="resend_text text-right"  style="margin-right: 20px;" *ngIf="mobileBtnText !== 'Verified'">Resend</p>
    </div>
    </div>

    <div class="col-md-6">
      <div class="otp_container">
      <p class="f-12 fw-400 text-left" style="margin-left: 20px;">Email OTP</p>
      <ng-otp-input
        [formCtrl]="otpEmailControl"
        [config]="{ length: 6 }"
        [ngStyle]="{'pointer-events': data.email_verified ? 'none' : 'auto', 'opacity': data.email_verified ? '0.8' : '1'}"

      ></ng-otp-input>
    </div>
    <div class="d-flex justify-center" [ngStyle]="{'pointer-events': emailBtnClicked ? 'none' : 'auto', 'opacity': emailBtnClicked ? '0.8' : '1'}">
      <p class="resend_text text-right"  style="margin-right: 20px;" (click)="onVerify('email')">{{emailBtnText}}</p>
      <p class="resend_text text-right"  style="margin-right: 20px;" *ngIf="emailBtnText !== 'Verified'">Resend</p>
    </div>
    </div>
    </div>

    <button
      style="height: 48px !important"
      class="submit"
      (click)="onSubmit()"
      mat-flat-button
      color="primary"
    >
      Submit
    </button>
  </div>
</div>
