import {Component, Input} from '@angular/core';
import {SettingsService} from "../service/settings.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, FormGroup} from "@angular/forms";
import {NotificationService} from "../../../eru-ql/components/database/services/notification.service";

export interface PeriodicElement {
  alias: string;
  storetype: string;

  action: string;
}



@Component({
  selector: 'app-kms',
  templateUrl: './kms.component.html',
  styleUrls: ['./kms.component.scss']
})
export class KmsComponent {
  @Input()projectName:any;
  @Input()from:any;
  kmsFormG = new FormGroup({
    kms_store_type: new FormControl(''),
    region: new FormControl(''),
    kms_alias: new FormControl(''),
    kms_desc: new FormControl(''),
    authentication: new FormControl(''),
    key: new FormControl(''),
    secret: new FormControl(''),
  });
  displayedColumns: string[] = ['storetype', 'alias', 'action'];
  dataSource:any = [];
  addnew: boolean =false;
  isEdit:boolean =false
constructor(private service: SettingsService,    private notifyService: NotificationService,) { }
  addKms() {
    this.addnew = true;
  }
  save() {
    this.service.saveKms(this.projectName,this.from,this.kmsFormG.value).subscribe(
      {
     next: (res:any)=>{
      this.notifyService.showSuccess(res.msg, "Sucess");
       this.fetch()
       this.addnew = false;
       this.kmsFormG.reset()
    },
    error:(e)=>{
      this.notifyService.showError(e.error, "Error");

    }
      }
    )
  }

  authSelectChange(e: any) {

  }

  ngOnInit(){
    this.fetch()


}
fetch(){
  this.service.getFetchKms(this.projectName,this.from).subscribe((res: any) => {
    let data:any=[]
    for(let obj of Object.keys(res)){
      data.push(res[obj])
    }
    this.dataSource = data
  })
}
  cancel(){
    this.addnew = false;
    this.isEdit=false
    this.kmsFormG.reset()
  }
  remove(id:any){
  this.service.removeKms(this.projectName,this.from,id).subscribe(  {
    next: (res:any)=>{
      this.notifyService.showSuccess(res.msg, "Sucess");
      this.fetch();

    },
    error:(e)=>{
      this.notifyService.showError(e.error, "Error");

    }
  })
  }

  edit(kmsData:any){
    this.addnew=true

    this.kmsFormG.setValue({
      "kms_store_type": kmsData.kms_store_type,
      "region": kmsData.region,
      "kms_alias": kmsData.kms_alias,
      "kms_desc": kmsData.kms_desc,
      "authentication":kmsData.authentication,
      "key": kmsData.key,
      "secret": kmsData.secret
    })
  }
}
