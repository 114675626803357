import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MENUS } from '../../constants/menus';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  fromEruQl:boolean=false;
  fromEruGateway:boolean=false;
  fromEruWrapper:boolean=false;
  fromEruFiles:boolean=false;
  fromEruAuth:boolean=false;
  fromEruRoutes:boolean=false;
  link:any;
  public selectedItem:number = 0;
  public selectedChild:any = null;
  hideSidebar: boolean = false;
  public menus:any[]=[];
  public panelOpenState = false;
  public showBold = false;
  activeLink:any;
  private projectID:any;
  private envName:any;
  private readonly : any;
  encryptedParams: any;
  decryptedParams: any;
  encryptParams:any;
  constructor(private router: Router,private route: ActivatedRoute, private cryptoService: CryptoService){
    this.route.queryParams
    .subscribe(params => {
      //  this.projectID = params['project'];
      //  this.envName = params['env'];
      //  this.readonly = params['read'];
      this.encryptedParams = params['q'];
     });

  }
  ngOnInit(): void {
    this.mapParamsValue();
    this.activeLink = this.router.url.split('/');
    if(this.activeLink[1]=='eru-ql'){
      this.fromEruQl = true;
      if(this.activeLink[3].split('?').includes('overview')){
        this.link = "overview"
      }
      if(this.activeLink[3]==="database"){
        this.link= 'database';
      }
      if(this.activeLink[3].split('?').includes("setting")){
        this.gotoSettings('eru-ql/dashboard/settings');

      }
      if(this.activeLink[4]?.split('?').includes("my-queries")){
        this.link = 'my-queries';
      }
      if(this.activeLink[3]=='variables'){
        this.gotoVariables('eru-ql/dashboard/variables');
      }
    }
    else{
      this.fromEruQl = false;
    }
    if(this.activeLink[1]=='eru-wrapper'){
      this.fromEruWrapper = true;
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="users"){
        this.link= 'users';
      }
      if(this.activeLink[2]==="projects" || this.activeLink[2]==="edit-project"){
        this.link = 'projects';
      }
      // if(this.activeLink[2]==='create-project'){
      //   this.hideSidebar = true;
      // }
    }
    else{
      this.fromEruWrapper = false;
    }
    if(this.activeLink[1]=='eru-files'){
      this.fromEruFiles = true;
      this.gotoDashboardFiles();
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="files" || this.activeLink[2]==="create-file"){
        this.link= 'files';
      }
      if(this.activeLink[2]==="key" || this.activeLink[2]==="create-key"){
        this.link = 'key';
      }
      else if(this.activeLink[2]=='settings'){
        this.gotoVariables('eru-files/settings');
      }
      if(this.activeLink[2]=='variables'){
        this.gotoVariables('eru-files/variables');
      }
    } else{
      this.fromEruFiles = false;
    }
    if(this.activeLink[1]==='eru-auth'){
      this.fromEruAuth = true;
      this.gotoAuthDashboard();
      if(this.activeLink[2]==='gateway'){
        this.gotoGateway();
      }
      else if(this.activeLink[2]==='create-gateway'){
        this.gotoCreateGateway();
      }
      else if(this.activeLink[2]==='message-template'){
        this.gotoMessageTemplates();
      }
      else if(this.activeLink[2]=='create-message-template'){
        this.gotoCreateMessageTemplates();
      }
      else if(this.activeLink[2]=='authorizer'){
        this.gotoAuthorizer();
      }
      else if(this.activeLink[2]=='create-authorizer'){
        this.gotoCreateAuthorizer();
      }
      else if(this.activeLink[2]=='settings'){
        this.gotoVariables('eru-auth/settings');
      }
      else if(this.activeLink[2]=='variables'){
        this.gotoVariables('eru-auth/variables');
      }
    }
    else{
      this.fromEruAuth=false;
    }
    if(this.activeLink[1]=='eru-gateway'){
      this.fromEruGateway = true;
      this.gotoGatewayDashboard();
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==="authorizer-list"){
        this.link= 'authorizer-list';
      }
      if(this.activeLink[2]==="rule-list" ){
        this.link = 'rule-list';
      }
      else if(this.activeLink[2]=='settings'){
        this.gotoVariables('eru-gateway/settings');
      }
      // if(this.activeLink[2]==='create-project'){
      //   this.hideSidebar = true;
      // }
    }
    else{
      this.fromEruGateway = false;
    }
    if(this.activeLink[1]=='eru-functions'){
      this.fromEruRoutes = true;
      this.gotoRoutesDashboard();
      if(this.activeLink[2]==='dashboard'){
        this.link = "dashboard"
      }
      if(this.activeLink[2]==='routes-list'){
        this.link = "routes-list"
      }
      if(this.activeLink[2]==='functions'){
        this.link = "functions"
      }
      if(this.activeLink[2]==='create-route'){
        this.link = "routes-list"
      }
      if(this.activeLink[2]=='settings'){
        this.gotoSettings('eru-functions/settings');
      }
      if(this.activeLink[2]=='variables'){
        this.gotoVariables('eru-functions/variables');
      }
    }
    else{
      this.fromEruRoutes = false;
    }
    this.menus =  MENUS;
  }
  mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectID = param.value;
          } else if (param.key === 'env') {
            this.envName = param.value;
          } else if (param.key === 'read') {
            this.readonly = param.value;
          }
        }
        this.encryptParams = this.cryptoService.encrypt(`project=${this.projectID}&env=${this.envName}&read=${this.readonly}`)
      }
    }
  }
  openSubMenu = (item: any) => {
    item.expanded=!item.expanded;
  };

  textBold() {
    this.showBold = !this.showBold;
  }

  gotoLink(item:any, link:any){
    this.link = item;
    // this.router.navigate([link], { queryParams: { project:this.projectID, env : this.envName, read : this.readonly } });
    this.router.navigateByUrl(`${link}?q=${this.encryptParams}`)
  }
  // gotoLink(item:any, isChild :any, index : any, parent : any){
  //   if(isChild){
  //     let parentIndex = this.menus.indexOf((x : any) => x.label == parent.label)
  //     this.selectedChild = index;
  //     this.selectedItem = parentIndex;
  //   }
  //   else{
  //   this.selectedItem = index;
  //   }
  //   this.router.navigate([item.link], { queryParams: { project:this.projectID } });
  // }

  selectionChanged(item : any) {
    this.selectedItem = item;
  }


  gotoDashboard(){
    this.link= "dashboard";
    this.router.navigate(['eru-wrapper/dashboard']);
  }

  gotoUser(){
    this.link= "users";
    this.router.navigate(['eru-wrapper/users']);
  }

  gotoProjects(){
    this.link = "projects";
    this.router.navigate(['eru-wrapper/projects']);
  }

  gotoDashboardFiles() {
    this.link= "dashboard";
    // this.router.navigate(['eru-files/dashboard'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-files/dashboard?q=${this.encryptParams}`)
  }
  gotoFiles() {
    this.link= "files";
    this.router.navigate(['eru-files/files'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-files/files?q=${this.encryptParams}`)
  }
  gotoKeys() {
    this.link= "key";
    // this.router.navigate(['eru-files/key'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-files/key?q=${this.encryptParams}`)
  }

  gotoGateway(){
    this.link = "gateway";
    // this.router.navigate(['eru-auth/gateway'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-auth/gateway?q=${this.encryptParams}`)
  }

  gotoAuthDashboard(){
    this.link="dashboard";
    // this.router.navigate(['eru-auth/dashboard'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-auth/dashboard?q=${this.encryptParams}`)
  }

  gotoCreateGateway(){
    this.link = "gateway";
    this.router.navigate(['eru-auth/create-gateway'], { queryParams: { project:this.projectID , env : this.envName} });
  }
  gotoMessageTemplates(){
    this.link="message-template";
    // this.router.navigate(['eru-auth/message-template'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-auth/message-template?q=${this.encryptParams}`)
  }

  gotoCreateMessageTemplates(){
    this.link="message-template";
    this.router.navigate(['eru-auth/create-message-template'], { queryParams: { project:this.projectID , env : this.envName} });
  }

  gotoAuthorizer(){
    this.link="authorizer";
    // this.router.navigate(["eru-auth/authorizer"], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-auth/authorizer?q=${this.encryptParams}`)
  }

  gotoCreateAuthorizer(){
    this.link="authorizer";
    this.router.navigate(["eru-auth/create-authorizer"], { queryParams: { project:this.projectID , env : this.envName} });
  }


  gotoGatewayDashboard(){
    this.link="dashboard";
    // this.router.navigate(['eru-gateway/dashboard'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-gateway/dashboard?q=${this.encryptParams}`)
  }

  gotoAuthorizers(){
    this.link= "authorizer-list";
    // this.router.navigate(['eru-gateway/authorizer-list'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-gateway/authorizer-list?q=${this.encryptParams}`)
  }

  gotoRules(){
    this.link= "rule-list";
    // this.router.navigate(['eru-gateway/rule-list'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-gateway/rule-list?q=${this.encryptParams}`)
  }

  gotoRoutesDashboard(){

    this.link="dashboard";
    console.log(this.link,'link');
    // this.router.navigate(['eru-functions/dashboard'],{ queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-functions/dashboard?q=${this.encryptParams}`)
  }

  gotoRoutes(){
    this.link="routes-list";
    // this.router.navigate(['eru-functions/routes-list'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-functions/routes-list?q=${this.encryptParams}`)
  }

  gotoFunctions(){
    this.link="functions";
    // this.router.navigate(['eru-functions/functions'], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`eru-functions/functions?q=${this.encryptParams}`)
  }
  gotoVariables(link:any){
    this.link="variables";
    // this.router.navigate([link], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`${link}?q=${this.encryptParams}`)
  }
  gotoSettings(link:any) {
    console.log(link,'link')
    this.link="settings";
    // this.router.navigate([link], { queryParams: { project:this.projectID , env : this.envName, read : this.readonly} });
    this.router.navigateByUrl(`${link}?q=${this.encryptParams}`)
  }
}
