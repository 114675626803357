import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ProjectFacadeService } from '../../state/project/project-facade.service';
import { cloneDeep } from 'lodash';
import { NotificationService } from 'src/app/database/services/notification.service';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  @ViewChild('auto', { static: false }) matAutocomplete!: MatAutocomplete;
  @ViewChild('emailInput', { static: false })
  selectedData:any={
    projectID:'',
    environment:'',
    services:[],
  }




  emailInput!: ElementRef<HTMLInputElement>;
  hideSelections: boolean = false;
  checkedBox : boolean = true;
  sectionNumbers :any =[''];
  environment: any[]| any;
  services: any[]| any;
  project: any[]| any;
  value!:string;
  form!: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  distinctProjects: any;
  projectEnvironments: any;
  getEnvironmentDetails: any;
  getEnvironment: any;
  getServiceDetails: any;
  isValidEmail(email: string) {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  }
  rows: any = [
    {
      options:[],
      id: 1,
      environment: null,
      services: [],
    },
  ];
  filteredEmails: Observable<any[]>;
  emails: string[] = [];
  emailCtrl = new FormControl();
  invalidEmail: boolean = false;
  selectable = true;
  removable = true;
  addOnBlur = true;
  projectSubsribe!: Subscription;
  projectLists: any[] = [];
  selectedProject: any;
  selectedEnvironment: any;

  constructor( private userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notifyService: NotificationService,
    private projectFacadeService: ProjectFacadeService,
    private matDialogRef:MatDialogRef<InviteUsersComponent>
    ) {
    this.filteredEmails = this.emailCtrl.valueChanges.pipe(
      startWith(null),
      map((email: string | null | any) => {
        if (email?.length >= 3 && !this.isValidEmail(email)) {
          this.invalidEmail = false;
          let result: any = [];
          //!Api call for suggestion
          this.userService
            .fetchUsers({ user_str: email })
            .subscribe((res: any) => {
              console.log(res);

              if (Object.keys(res).length == 0) {
              } else {
                for (let user of res.users) {
                  let data ={
                    userEmail :user.user_email,
                    userName :user.user_first_name +' '+user.user_last_name
                  }
                  result.push(data);
                }
              }
            });
          return result;
        } else {
          return [];
        }
      })
    );

  }

  ngOnInit(): void {

    this.projectSubsribe = this.projectFacadeService.getProjects$.subscribe(
      (projects: any) => {
        // const getProject = cloneDeep(projects.projects);
        // this.distinctProjects = getProject.filter(
        //   (p: any, i: any, arr: any) =>
        //     arr.findIndex((t: any) => t.project_name === p.project_name) === i
        // );
        // this.projectEnvironments = this.distinctProjects ? this.distinctProjects.public___project_envs : [];
        this.projectSubsribe = this.projectFacadeService.getProjects$.subscribe(
          (projects: any) => {

              const getProject = cloneDeep(projects.projects);
              this.projectLists = this.extractForDropDown(getProject); //! extracts needed data for dropdown  from store
              // this.selectedProject = this.projectLists.find(
              //   (ele: any) => ele.project_name === this.data.project_name
              // );
              // this.rows[0].options = this.selectedProject.environments
      console.log(this.projectLists)

          }
        );

        if( this.projectLists.length ==0 ){
          this.projectFacadeService.loadProjects();
        }
        console.log(this.projectEnvironments,this.distinctProjects, 'projects');
      });

    this.form = new FormGroup({
      email: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(this.emailValidation),
        ],
      }),

    });
    if(this.checkedBox===true){
      this.hideSelections = true;
    }
    else{
      this.hideSelections = false;
    }
    this.project = [
      {
        name: "Eru Technologies",
      },
      {
        name: "Eru Technologies 2",
      },
      {
        name: "Eru Technologies 3",
      }
    ]
    this.environment = [
      {
        name: "Select Environment",
      },
      {
        name: "Eru Development",
      },
      {
        name: "Eru Pre Production",
      },
      {
        name: "Eru Production",
      },
      {
        name: "All Environments",
      },
    ];
    this.services = [
      {
        name: "eru - Files",
      },
      {
        name: "eru - Route",
      },
      {
        name: "eru - Auth",
      },
      {
        name: "eru - Gateway",
      }
    ];
  }
  extractForDropDown(projects: any[]): any[] {
    if (!projects) {
      return [];
    }
    return projects.map((project: any) => {
      if (!project) {
        return {};
      }
      return {
        project_name: project.project_name || '',
        project_id: project.project_id || '',
        environments: (project['public___project_envs'] || []).map(
          (env: any) => {
            if (!env) {
              return {};
            }
            return {
              env_name: env.env_name || '',
              env_id:env.env_id||'',
              services: (env['public___project_services'] || []).map(
                (service: any) => {
                  if (!service.service_id) {
                    return {};
                  }
                  return {
                    service_id: service['service_id'] || '',
                    service_name: service['service_name'] || '',
                    service_access: 'READ',
                  };
                }
              ),
            };
          }
        ),
      };
    });
  }

  selectProject(e:any) {
    this.getEnvironmentDetails = e.value;
    console.log(this.getEnvironmentDetails.environments,'value');
    this.getEnvironment = this.getEnvironmentDetails.environments;
  }
  selectEnvironment(e:any) {
    this.getServiceDetails = e.value;
    console.log(this.getServiceDetails,'value s');

  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.emails.push(event.option.value); //! sets value to the chip on clicking on suggestion box.
    // this.emailInput.nativeElement.value = '';
    this.emailCtrl.setValue(null);
    // console.log( this.emailInput.nativeElement.value)
  }

  add(event: MatChipInputEvent): void {
    //! Add user input if string is valid email.
    this.invalidEmail = false;
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our email
      if ((value || '').trim()) {
        if (this.isValidEmail(event.value)) {
          this.emails.push(value.trim());
        } else {
          this.invalidEmail = true;
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.emailCtrl.setValue(null);
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email); //!Removes email from mat-chip
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }


  onAllowCompleteAccess(e:any){
    if(e.checked){
      this.hideSelections = true;
    }
    else{
      this.hideSelections = false;
    }
  }

  sendTheNewValue(event: any) {
    this.value = event.target.value;
  }

  addMore(){
    this.sectionNumbers.push('');
  }

  delete(index:any){
    this.sectionNumbers.splice(index,1)
  }
  onSubmit() {
 let payload:any ={
  docs:[]
 }
for(let e of this.emails){
  for(let s of this.selectedData.services){
    payload.docs.push(
      {
        "project_id": this.selectedData.projectID,
        "user_email": e,
        "service_id":s.service_id,
        "env_id":this.selectedData.environment,
        "service_access":s.service_access.toUpperCase()
      }
    )
  }
}
this.userService.sendInvite2(payload).subscribe({
  next:(res)=>{
    this.notifyService.showSuccess('Invitation sent sucessfully', "Sucess");
  },
  error:(e)=>{
    this.notifyService.showError(e.error, "Error");  },
    complete:()=>{
      this.matDialogRef.close()
    }
})
  }
  accessHandler(e: any, s: any) {
    s.service_access = e; //!sets read / write access to respective services
  }
  selectionChange(e: any, event: any) {
    if (event._selected) {
      // e.panel.open(); //! Opens panel on click of check box
    }
  }
  getEnvs(arr:any,pid:string){
    return arr?.find((e:any)=>e.project_id==pid)?.environments ||[]
  }
  getServices(arr:any,eid:any){
    return arr?.find((e:any)=>e.project_id==this.selectedData.projectID)?.environments?.find((e:any)=>e.env_id == eid)?.services ||[]
  }
}
