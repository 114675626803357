import * as ProjectRoutesReducer from "./project-routes.reducer";
import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectProjectRoutesState = createFeatureSelector<ProjectRoutesReducer.ProjectRoutesState>('PROJECTROUTES');

export const getProjectConfig = createSelector(selectProjectRoutesState, (state) => {
console.log(state,'asddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd')
    return {
        projectConfig : state.projectConfig
    }
})

