import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-secret-manager',
  templateUrl: './secret-manager.component.html',
  styleUrls: ['./secret-manager.component.scss'],
})
export class SecretManagerComponent implements OnInit {
  @Input() read: any;
  @Input() from: boolean = false;
  @Input() secretData: any={};
  @Output() saveSecret: EventEmitter<any> = new EventEmitter<any>();
  form!: FormGroup;
  showFields: boolean = false;

  constructor(
    public fb: FormBuilder,
    private settingsService: SettingsService
  ) {
    this.createForm();
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes:SimpleChanges) {

    if(Object.keys(changes['secretData'].currentValue)?.length>0) {
    if(this.form){
      this.updateForm(changes.secretData.currentValue)
    }
    }
  }

  updateForm(data:any) {
    this.form.controls['managerStore'].setValue(data.sm_store_type)
    this.form.controls['region'].setValue(data.region);
    this.form.controls['authentication'].setValue(data.authentication);
    this.form.controls['key'].setValue(data.key);
    this.form.controls['secret'].setValue(data.secret);
    this.form.controls['managerName'].setValue(data.sm_name);
    this.form.controls['keyStoreName'].setValue(data.kms_name);
    if(data.authentication === 'Secret') {
      this.showFields = true
    }
  }

  createForm() {
      this.form = new FormGroup({
        managerStore: new FormControl('', Validators.required),
        region: new FormControl('', Validators.required),
        authentication: new FormControl('', Validators.required),
        key: new FormControl(''),
        secret: new FormControl(''),
        managerName: new FormControl('', Validators.required),
        keyStoreName: new FormControl(''),
      });
    if (this.from) {
      this.form.controls.keyStoreName.setValidators(Validators.required);
    }
  }

  showSecretForm() {
    this.showFields = true;
    this.form.controls.key.setValidators(Validators.required);
    this.form.controls.secret.setValidators(Validators.required);
  }

  authSelectChange(e: any) {
    if (e.value === 'Secret') {
      this.showSecretForm();
    } else {
      this.showFields = false;
      this.form.controls.key.clearValidators();
      this.form.controls.secret.clearValidators();
    }
  }

  onSave() {
    console.log(this.secretData)
    // console.log(this.form.valid)
    if (this.form.valid) {
      let data: any = {
        sm_store_type: this.form.value.managerStore,
        region: this.form.value.region,
        sm_name: this.form.value.managerName,
        authentication: this.form.value.authentication,
        kms_name: this.form.value.keyStoreName
      };
      if (this.showFields) {
        data['key'] = this.form.value.key;
        data['secret'] = this.form.value.secret;
      }
      // console.log(data)
      this.saveSecret.emit(data);
      // this.settingsService.saveSecretManager(data).subscribe({
      //   next:(res:any)=>{
      //     console.log(res)
      //   }
      // })
    }
  }
}
