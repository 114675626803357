import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProjectFilesService {

  constructor(private httpClient:HttpClient) { }

  getProjectConfig(projectName : string){   
    return this.httpClient.get(`${environment.url}/store/${projectName}/config`);
  }

  saveProjectStorage(projectName : string, storageName : string, storageType : string, data:any){   
    return this.httpClient.post(`${environment.url}/store/${projectName}/storage/save/${storageName}/${storageType}`,data)
  }

  generateAESKey(projectName : string, key : string, data:any){   
    return this.httpClient.post(`${environment.url}/store/${projectName}/aeskey/generate/${key}`,data)
  }

  removeProjectStorage(projectName : string, storageName : string, data:any){   
    return this.httpClient.delete(`${environment.url}/store/${projectName}/storage/remove/${storageName}`,data)
  }
}
