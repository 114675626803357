<div class="projects-component">
  <div class="container-fluid">
    <h2 class="f-22 fw-600 mb-0">Projects</h2>
    <div class="mt-20 d-flex justify-content-between align-items-center">
      <div>
        <form>
          <div class="">
            <mat-form-field appearance="outline">
              <!-- <mat-icon class="search-icon">search</mat-icon> -->
              <input matInput placeholder="Search" (input)="searchUser($event)"
                [ngClass]="{ 'no-back': search === true }" />
                <img matPrefix style="padding-bottom: 8px;margin-right: 10px;"
                src="../../../../../assets/images/icons/search-icon-grey.svg" alt="">
            </mat-form-field>
          </div>
        </form>
      </div>
      <div>
        <button mat-flat-button class="text-white" color="primary" mat-button (click)="createProject()">
          Add
        </button>
      </div>
    </div>

    <div class="table-container mt-4" style="height: 360px; overflow: auto">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-border">

        <ng-container matColumnDef="expandTable">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg"></th>
          <td mat-cell *matCellDef="let element" style="width:73px">
            <div>
              <img class="cursor-pointer" *ngIf="element.invite_status === 'ACCEPTED'"
                (click)="getSecondryTableData(element); element.showSecondImage = !element.showSecondImage;toggleRowExpansion(element)"
                [src]="
                  !element.showSecondImage
                    ? '../../../../../assets/images/icons/right-arrow.png'
                    : '../../../../../assets/images/icons/down-arrow-expand.png'
                " />
            </div>
          </td>
        </ng-container>
        <!-- Gateway Name Column -->
        <ng-container matColumnDef="Projects">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg">Project</th>
          <td mat-cell *matCellDef="let element">{{ element.project_name }}</td>
        </ng-container>

        <!-- Template Type Column -->
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg">Users</th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.users}} -->{{ element.invite_status === 'PENDING' ? '' : 01 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="environment">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg">Environment</th>
          <td mat-cell *matCellDef="let element" >
            {{ element.invite_status === 'PENDING' ? '' : getEnvCount(element.environment)}}
          </td>
        </ng-container>

        <!-- Template ID Type -->
        <ng-container matColumnDef="Services">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg">Services</th>
          <td mat-cell *matCellDef="let element">
            <!-- <span *ngFor="let service_name of element.services"
              (click)="onServiceClick(element.project_name, service_name)">
              {{ service_name }}
            </span> -->
            {{element.invite_status === 'PENDING' ? '' : getServiceCount(element.environment)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg">Action</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.is_owner">
              <div class="img-content">
                <div class="icons">
                  <div class="photo-container icon-style">
                    <a class="edit-img cursor-pointer">
                      <img src="../../../../assets/images/icons/edit1.svg" (click)="onEditClick(element.project_name)">
                      <span>Edit</span>
                    </a>
                  </div>
                  <div class="photo-container icon-style">
                    <a class="edit-img cursor-pointer">
                      <img src="../../../../../assets/images/icons/send_envitation.svg"
                        (click)="onInviteClick(element)">
                      <span>Invitation</span>
                    </a>
                  </div>
                </div>
                <!-- <a class="cursor-pointer" style="margin-right: 30px">
                  <img _ngcontent-wkr-c233="" src="../../../../assets/images/icons/edit1.svg"
                    (click)="onEditClick(element.project_name)" />
                </a> -->
                <!-- <a class="cursor-pointer" style="margin-right: 30px">
                  <span (click)="onInviteClick(element)">
                    <img src="../../../../../assets/images/icons/send_envitation.svg" alt="" />
                  </span>
                </a> -->
                <!-- <a class="cursor-pointer">
                        <img _ngcontent-wkr-c233="" src="../../../../assets/images/icons/delete1.svg" />
                    </a> -->
              </div>

            </ng-container>
            <ng-container *ngIf="element.invite_status === 'PENDING'">
              <a class="cursor-pointer" style="margin-right: 30px" (click)="acceptInvite(element)">
                <img src="../../../../../assets/images/icons/email_accept.png" alt="" srcset="" />
              </a>
              <a class="cursor-pointer" style="margin-right: 30px" (click)="rejectInvite(element)">
                <img src="../../../../../assets/images/icons/mail_reject.svg" alt="" />
              </a>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandTables">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div [hidden]="!element.showSecondImage">
              <table mat-table [dataSource]="secondTableDataSource" class="table-border" style="border: none;">
                <ng-container matColumnDef="expandTable">
                  <td mat-cell *matCellDef="let element1" class="expandTableWidth"></td>
                </ng-container>
                <!-- Gateway Name Column -->
                <ng-container matColumnDef="Projects">
                  <td mat-cell *matCellDef="let element1" class="expandTableProjectsWidth"></td>
                </ng-container>

                <!-- Template Type Column -->
                <ng-container matColumnDef="users">
                  <td mat-cell *matCellDef="let element1" class="expandTableUsersWidth"></td>
                </ng-container>

                <ng-container matColumnDef="environment">
                  <td mat-cell *matCellDef="let element1" class="expandTableEnvironmentWidth">
                    {{element1.environment}}
                  </td>
                </ng-container>

                <!-- Template ID Type -->
                <ng-container matColumnDef="Services">
                  <td class="text-center text-orange cursor-pointer " mat-cell *matCellDef="let element1"
                    class="expandTableServicesWidth">
                    <div class="d-flex">
                      <div style="display: flex" *ngFor="let service of element1.Services">
                        <div class="img-content">
                          <div class="icons">
                            <div class="photo-container icon-style " *ngIf="service.service_access && service.service_name == 'eru-ql'">
                              <a class="edit-img cursor-pointer">
                                <img src="../../../../../assets/images/wrapper/ql.svg" alt=""
                                  (click)="onServiceClick(element.project_name, service.service_name, element1.environment)" />
                                <span>QL</span>
                              </a>
                            </div>
                            <div class="photo-container icon-style " *ngIf="service.service_access && service.service_name == 'eru-files'">
                              <a class="edit-img cursor-pointer">
                                <img src="../../../../../assets/images/wrapper/files.svg" alt=""
                                  (click)="onServiceClick(element.project_name, service.service_name, element1.environment)" />
                                <span>Files</span>
                              </a>
                            </div>
                            <div class="photo-container icon-style " *ngIf="service.service_access && service.service_name == 'eru-functions'">
                              <a class="edit-img cursor-pointer">
                                <img src="../../../../../assets/images/wrapper/routes.svg" alt=""
                                  (click)="onServiceClick(element.project_name, service.service_name, element1.environment)" />
                                <span>Functions</span>
                              </a>
                            </div>
                            <div class="photo-container icon-style " *ngIf="service.service_access && service.service_name == 'eru-auth'">
                              <a class="edit-img cursor-pointer">
                                <img src="../../../../../assets/images/wrapper/auth.svg" alt=""
                                  (click)="onServiceClick(element.project_name, service.service_name, element1.environment)" />
                                <span>Auth</span>
                              </a>
                            </div>
                            <div class="photo-container icon-style " *ngIf="service.service_access && service.service_name == 'eru-gateway'">
                              <a class="edit-img cursor-pointer">
                                <img src="../../../../../assets/images/wrapper/gateway.svg" alt=""
                                  (click)="onServiceClick(element.project_name, service.service_name, element1.environment)" />
                                <span>Gateway</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="servicesImageStyle" *ngIf="service.service_name == 'eru-ql'">
                            <img src="../../../../../assets/images/wrapper/ql.svg" alt=""
                            (click)="onServiceClick(element.project_name, service.service_name)" />
                          </div>
                        <div class="servicesImageStyle" *ngIf="service.service_name == 'eru-files'">
                          <img src="../../../../../assets/images/wrapper/files.svg" alt=""
                          (click)="onServiceClick(element.project_name, service.service_name)" />
                        </div>
                        <div class="servicesImageStyle" *ngIf="service.service_name == 'eru-routes'">
                          <img src="../../../../../assets/images/wrapper/routes.svg" alt=""
                          (click)="onServiceClick(element.project_name, service.service_name)"/>
                        </div>
                        <div class="servicesImageStyle" *ngIf="service.service_name == 'eru-auth'">
                          <img src="../../../../../assets/images/wrapper/auth.svg" alt=""
                          (click)="onServiceClick(element.project_name, service.service_name)"/>
                        </div>
                        <div class="servicesImageStyle" *ngIf="service.service_name == 'eru-gateway'">
                          <img src="../../../../../assets/images/wrapper/gateway.svg" alt=""
                          (click)="onServiceClick(element.project_name, service.service_name)"/>
                        </div> -->
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <td mat-cell *matCellDef="let element" style="display: flex;">
                    <div class="photo-container icon-style">
                      <a class="edit-img cursor-pointer">
                        <img src="../../../../../assets/images/icons/import_icon.png"
                          (click)="openImportDialog(element)">
                        <span style="z-index: 10000!important;">Import</span>
                      </a>
                    </div>
                      <div class="photo-container icon-style">
                      <a class="edit-img cursor-pointer">
                        <img src="../../../../../assets/images/icons/repository.svg" (click)="repository(element)">
                        <span>Repository</span>
                      </a>
                    </div>
                    <!-- <img src="../../../../../assets/images/icons/import_icon.png" alt=""> -->
                  </td>

                </ng-container>
                <tr mat-row *matRowDef="let row; columns: secondTableDisplayColumns"></tr>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"  class="lightpink-bg"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: ['expandTables']" [class.hide]="!element.showSecondImage"
          style="padding: 0 !important"></tr>
      </table>
    </div>

  </div>
</div>
