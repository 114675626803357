import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectAuthSelectors from './project-auth.selector';
import * as ProjectAuthActions from './project-auth.action';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EruAuthService } from 'src/app/modules/eru-auth/services/eru-auth.service';

@Injectable()
export class ProjectAuthFacadeService {
    getProjectConfig$ = this.store.select(ProjectAuthSelectors.getProjectConfig);

    
    constructor(private store : Store, private router : Router, private eruAuthService : EruAuthService) {
        
    }

    loadProjectConfig(projectName : string){
        this.store.dispatch(
            ProjectAuthActions.LoadProjectConfig({projectName : projectName})
        )
    }

    saveGateway(projectName : string, gatewayType : string, channel : string, data:any){   
        return this.eruAuthService.saveGateway(projectName, gatewayType, channel, data);
    }
    
    removeGateway(projectName : string,gatewayName : string, gatewayType : string, channel : string, data:any){   
        return this.eruAuthService.removeGateway(projectName, gatewayName, gatewayType, channel, data);
    }

    saveAuth(projectName : string, data:any){   
        return this.eruAuthService.saveAuth(projectName, data);
    }

    removeAuth(projectName : string, authName : string, data:any){     
        return this.eruAuthService.removeAuth(projectName, authName, data);
    }

    saveMessageTemplate(projectName : string, data:any){   
        return this.eruAuthService.saveMessageTemplate(projectName, data);
    }

    removeMessageTemplate(projectName : string, messageName : string){   
        return this.eruAuthService.removeMessageTemplate(projectName, messageName);
    }
    

    clearProjectServiceStore(){
        this.store.dispatch(
            ProjectAuthActions.ClearProjectAuth()
        )
    }


}