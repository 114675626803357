<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruQl">
	<!-- <ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='overview'}"
					(click)="gotoLink('overview', 'eru-ql/dashboard/overview')"
				>
					<img *ngIf="link==='overview'" src="../../../../assets/images/side-nav/home.svg" alt="" />
					<img *ngIf="link!=='overview'" src="../../../../assets/images/side-nav/home.svg" alt="" />
					<span class="nav-label selected">Project Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='database'}"
					(click)="gotoLink('database', 'eru-ql/dashboard/database/list')"
				>
					<img *ngIf="link!=='database'" src="../../../../assets/images/wrapper/ql.svg" alt="">
					<img *ngIf="link==='database'" src="../../../../assets/images/wrapper/ql.svg" alt="">
					<span class="nav-label selected">Database</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='my-queries'}"
					(click)="gotoLink('my-queries', 'eru-ql/dashboard/database/my-queries')"
				>
					<img *ngIf="link!=='my-queries'" src="../../../../assets/images/wrapper/my-query.svg" alt="">
					<img *ngIf="link==='my-queries'" src="../../../../assets/images/wrapper/my-query.svg" alt="">
					<span class="nav-label selected">My Queries</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='setting'}"
					(click)="gotoLink('setting', 'eru-ql/dashboard/setting')"
				>
					<img *ngIf="link!=='setting'" src="../../../../assets/images/side-nav/settings.svg" alt="">
					<img *ngIf="link==='setting'" src="../../../../assets/images/side-nav/settings.svg" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container> -->

	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer eru-ql-img"
					[ngClass]="{'active-link':link==='overview'}"
					(click)="gotoLink('overview', 'eru-ql/dashboard/overview')"
				>
					<img *ngIf="link==='overview'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='overview'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='database'}"
					(click)="gotoLink('database', 'eru-ql/dashboard/database/list')"
				>
					<img *ngIf="link!=='database'" src="../../../../assets/images/wrapper/ql-visit.svg" alt="">
					<img *ngIf="link==='database'" src="../../../../assets/images/wrapper/ql.svg" alt="">
					<span class="nav-label selected">Database</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='my-queries'}"
					(click)="gotoLink('my-queries', 'eru-ql/dashboard/database/my-queries')"
				>
					<img *ngIf="link!=='my-queries'" src="../../../../assets/images/wrapper/my-query-visit.svg" alt="">
					<img *ngIf="link==='my-queries'" src="../../../../assets/images/wrapper/my-query.svg" alt="">
					<span class="nav-label selected">My Queries</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<!-- <div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='setting'}"
					(click)="gotoLink('setting', 'eru-ql/dashboard/setting')"
				> -->
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='settings'}"

					(click)="gotoSettings('eru-ql/dashboard/settings')"
				>
					<img *ngIf="link!=='settings'" src="../../../../assets/images/side-nav/setting2.png" alt="">
					<img *ngIf="link==='settings'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoVariables('eru-ql/dashboard/variables')"
					(click)="gotoLink('variables', 'eru-ql/dashboard/variables')"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates-active-visit.svg" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>

<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruWrapper">
	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoDashboard()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='projects'}"
					(click)="gotoProjects()"
				>
					<img *ngIf="link!=='projects'" src="../../../../assets/images/icons/wrapper-projects.svg" alt="">
					<img *ngIf="link==='projects'" src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
					<span class="nav-label selected">Projects</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='users'}"
					(click)="gotoUser()"
				>
					<img *ngIf="link!=='users'" src="../../../../assets/images/icons/wrapper-users.svg" alt="">
					<img *ngIf="link==='users'" src="../../../../assets/images/icons/wrapper-users-active.svg" alt="">
					<span class="nav-label selected">Users</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>

<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruFiles">
	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoDashboardFiles()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='files'}"
					(click)="gotoFiles()"
				>
					<img *ngIf="link!=='files'" src="../../../../assets/images/icons/file.svg" alt="">
					<img *ngIf="link==='files'" src="../../../../assets/images/icons/file-hover.svg" alt="">
					<span class="nav-label selected">Storage</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='key'}"
					(click)="gotoKeys()"
				>
					<img *ngIf="link!=='key'" src="../../../../assets/images/icons/key.svg" alt="">
					<img *ngIf="link==='key'" src="../../../../assets/images/icons/key-hover.svg" alt="">
					<span class="nav-label selected">Keys</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='settings'}"

					(click)="gotoSettings('eru-files/settings')"
				>
				<img *ngIf="link!='settings'" src="../../../../assets/images/side-nav/setting2.png" alt="" />
				<img *ngIf="link=='settings'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoVariables('eru-files/variables')"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>

<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruAuth">
	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoAuthDashboard()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<!-- <ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='gateway'}"
					(click)="gotoGateway()"
				>
				<img *ngIf="link!='gateway'" src="../../../../assets/images/icons/gateway.svg" alt="" />
					<img *ngIf="link=='gateway'" src="../../../../assets/images/icons/gateway-active.svg" alt="">
					<span class="nav-label selected">Gateway</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr> -->
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='authorizer'}"
					(click)="gotoAuthorizer()"
				>
				<img *ngIf="link!=='authorizer'" src="../../../../assets/images/icons/authorizer.svg" alt="" />
				<img *ngIf="link=='authorizer'" src="../../../../assets/images/icons/authorizer-active.svg" alt="">
					<span class="nav-label selected">Authorizer</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<!-- <ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='message-template'}"
					(click)="gotoMessageTemplates()"
				>
					<img *ngIf="link!='message-template'" src="../../../../assets/images/icons/message-templates.svg" alt="" />
					<img *ngIf="link=='message-template'" src="../../../../assets/images/icons/message-template-active.svg" alt="">
					<span class="nav-label selected">Message Templates</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr> -->
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='settings'}"

					(click)="gotoSettings('eru-auth/settings')"
				>
				<img *ngIf="link!='settings'" src="../../../../assets/images/side-nav/setting2.png" alt="" />
				<img *ngIf="link=='settings'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoVariables('eru-auth/variables')"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>

<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruGateway">
	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoGatewayDashboard()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='authorizer-list'}"
					(click)="gotoAuthorizers()"
				>
					<img *ngIf="link!=='authorizer-list'" src="../../../../assets/images/icons/wrapper-projects.svg" alt="">
					<img *ngIf="link==='authorizer-list'" src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
					<span class="nav-label selected">Authorizers</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='rule-list'}"
					(click)="gotoRules()"
				>
					<img *ngIf="link!=='rule-list'" src="../../../../assets/images/icons/wrapper-users.svg" alt="">
					<img *ngIf="link==='rule-list'" src="../../../../assets/images/icons/wrapper-users-active.svg" alt="">
					<span class="nav-label selected">Listener Rules</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='settings'}"

					(click)="gotoSettings('eru-gateway/settings')"
				>

					<img *ngIf="link!='settings'" src="../../../../assets/images/side-nav/setting2.png" alt="" />
					<img *ngIf="link=='settings'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoVariables('eru-gateway/variables')"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>

<mat-list role="list" class="text-center wrapper-menus" *ngIf="fromEruRoutes">
	<ng-container>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoRoutesDashboard()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Overview</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem">
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='routes-list'}"
					(click)="gotoRoutes()"
				>
					<img *ngIf="link!=='routes-list'" src="../../../../assets/images/icons/erug-routes-icon-black.svg" alt="">
					<img *ngIf="link==='routes-list'" src="../../../../assets/images/icons/erug-routes-icon-red.svg" alt="">
					<span class="nav-label selected">Routes</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='functions'}"
					(click)="gotoFunctions()"
				>
					<img *ngIf="link!=='functions'" src="../../../../assets/images/icons/erug-functions-square-black.svg" alt="">
					<img *ngIf="link==='functions'" src="../../../../assets/images/icons/erug-functions-square-red.svg" alt="">
					<span class="nav-label selected">Functions</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='settings'}"

					(click)="gotoSettings('eru-functions/settings')"
				>

					<img *ngIf="link!='settings'" src="../../../../assets/images/side-nav/setting2.png" alt="" />
					<img *ngIf="link=='settings'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
					<span class="nav-label selected">Settings</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
		<ng-container>
			<mat-list-item role="listitem" class="listitem" >
				<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoVariables('eru-functions/variables')"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
			</mat-list-item>
		</ng-container>
		<hr>
	</ng-container>
</mat-list>
