import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.action';

export interface ProjectState {
    projects: any | null;
    createProjectResponse: any | null;
    createServiceResponse: any | null
    errorMessage: string | null;
}

const initialValues = {
    projects: null,
    createProjectResponse: null,
    createServiceResponse : null,
    errorMessage: null,
}

export const initialProjectState : ProjectState = {
    ...initialValues
}

export const projectReducer = createReducer(
    initialProjectState,
    on(ProjectActions.LoadProjectsSuccess, (state, action) => {
        return {
            ...state,
            projects: action.response,
        }
    }),    
    on(ProjectActions.LoadProjectsFailure, (state, action) => {
        return {
            ...state,
            errorMessage: action.error,
        }
    }),    
    on(ProjectActions.GetProjectSuccess, (state, action) => {
        return {
            ...state,
            createProjectResponse : null,
            projects: [...state.projects, action.response],
        }
    }),  
    on(ProjectActions.CreateProjectSuccess, (state, action) => {
        return {
            ...state,
            createProjectResponse: action.response
        }
    }),    
    on(ProjectActions.CreateProjectFailure, (state, action) => {
        return {
            ...state,
            errorMessage: action.error,
        }
    }),  
    on(ProjectActions.CreateServiceSuccess, (state, action) => {
        return {
            ...state,
            createServiceResponse: action.response
        }
    }),    
    on(ProjectActions.CreateServiceFailure, (state, action) => {
        return {
            ...state,
            errorMessage: action.error,
        }
    }),
    on(ProjectActions.ClearProjectAndService, state => {
        return {
            ...state,
            createProjectResponse : null,
            createServiceResponse : null,
            errorMessage: null,
        }
    })



);