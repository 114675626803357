import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { InviteUsersComponent } from '../invite-users/invite-users.component';
import { UsersService } from '../../services/users.service';

export interface PeriodicElement2 {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  action: string;
}

const ELEMENT_DATA2: PeriodicElement2[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: 'd' },
];
export interface Users {
  email: string;
  projects: string;
  last_accessed_projects: string;
  action: string;
  invite_status: string;
}

const ELEMENT_DATA: Users[] = [
  {
    email: 'vinay.parab@lepolabs.com',
    projects: '03',
    last_accessed_projects: 'Eru Technologies',
    invite_status: '',
    action: '',
  },
  // { email:'vinay.parab@lepolabs.com',projects:'01',last_accessed_projects:'Eru Technologies',action:''},
  // { email:'vinay.parab@lepolabs.com',projects:'01',last_accessed_projects:'Eru Technologies',action:''}
];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'Email ID',
    'Projects',
    'Last Accessed Projects',
    'Invite Status',
    'action',
  ];
  dataSource: any = [];
  displayedColumns2: string[] = [
    'project',
    'environment',
    'services',
    'invitation_status',
    'action',
  ];
  dataSource2: any = new MatTableDataSource([]);
  tabIndex: any = 0;
  search: boolean = false;
  loginUser: any;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private userService: UsersService
  ) {}

  ngOnInit(): void {
    // this.dataSource = new MatTableDataSource(ELEMENT_DATA);

    console.log(this.userService.invitations, 'INVITATIONS');

    this.dataSource2 = new MatTableDataSource(
      this.userService.invitations.invitations
    );
    //  const storageUser: any = localStorage.getItem('user');
    //  const user = JSON.parse(storageUser);
    //  if (user) {
    //    this.loginUser = `${user.attributes.email}`;
    //  }
    //   console.log(this.loginUser, 'user')
    //   const data:any = {
    //     "user_email": this.loginUser,
    //   };

    // this.userService.fetchInvites(data).subscribe()
    // this.userService.fetchProjectUsers().subscribe()
    if (!this.userService.dashboardLoaded) {
      this.router.navigateByUrl('/eru-wrapper/dashboard');
    }
  }

  editUser() {
    this.dialog.open(EditUserComponent);
  }

  inviteUser() {
    this.dialog.open(InviteUsersComponent);
  }
@ViewChild('invitesTable',{static:true})invitesTable:any
  searchUser(e: any) {

    this.search = true;
   console.log(this.tabIndex)
if(this.tabIndex==1){
  this.dataSource2.filter= e.target.value;
}else{
  let searchValue = e.target.value.toLowerCase();
  let dataSource;
  if(searchValue === ''){
    dataSource = this.userService.invitations.invities;
  } else {
    dataSource = this.userService.invitations.invities.map((element:any) => {
      let topLevelMatch = Object.values(element).some((value:any) => value.toString().toLowerCase().includes(searchValue));
      if(topLevelMatch) return element;
      let innerTableMatch = element.innerTable.filter((innerElement:any) => {
        return Object.values(innerElement).some((value:any) => value.toString().toLowerCase().includes(searchValue));
      });
      if(innerTableMatch.length > 0){
        return {...element, innerTable: innerTableMatch};
      }
      return null;
    }).filter((element:any) => element !== null);
  }
  this.invitesTable.dataSource= new MatTableDataSource(dataSource)
}
  }
  acceptInvite(ele: any) {

    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    let body = {
      project_id: ele.project_id,
      user_id: user.id,
      env_id: ele.env_id,
      user_email: user.attributes.email,
    };

    this.userService.acceptInvities(body).subscribe(()=>{
      this.userService.fetchInvites({user_email:user.attributes.email}).subscribe()
    });
  }
  rejectInvite(ele: any) {
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    console.log(user);
    let body = {
      project_id: ele.project_id,
      user_email: user.attributes.email,
      env_id: ele.env_id,
    };
    this.userService.rejectInvities(body).subscribe(()=>{
      this.userService.fetchInvites({user_email:user.attributes.email}).subscribe()
    });
  }
}
