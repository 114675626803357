import { createAction, props } from '@ngrx/store';

const PROJECTQL : string = 'PROJECTQL';

export const LoadProjectConfig = createAction(`${PROJECTQL} Load Project Config`,(payload: any = {}) => payload);
export const LoadProjectConfigSuccess = createAction(`${PROJECTQL} Load Project Config Success`, 
                                                        props<{response : any}>());
export const LoadProjectConfigFailure = createAction(`${PROJECTQL} Load Project Config Failure`, 
                                                        props<{error : any}>());

                                                        
export const ClearProjectQL = createAction(`${PROJECTQL} Clear Project Files`);