import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { getProjects } from '../../state/project/project.selector';
import { DataCommuteService } from 'src/app/services/data-commute.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvitePopupComponent } from '../invite-popup/invite-popup.component';
import { CommonPopupComponent } from '../common-popup/common-popup.component';
import { ImportComponent } from '../import/import.component';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
import { UsersService } from '../../services/users.service';


export interface Projects {
  project: string;
  // users:string,
  services: string;
  action: string;
}

@Component({
  selector: 'app-my-invities-table',
  templateUrl: './my-invities-table.component.html',
  styleUrls: ['./my-invities-table.component.scss']
})

export class MyInvitiesTableComponent {
  // isRepository: boolean = false;
  displayedColumns: string[] = [
    'expandTable',
    'Projects',
    'environment',
    'users',

    'Services',
    'status',
    'action',
  ];
  secondTableDisplayColumns: string[] = [
    'expandTable',
    'Projects',
    'environment',
    'users',

    'Services',
    'status',
    'action',
  ];
  dataSource: any =new MatTableDataSource([]);
  secondTableDataSource: any = [''];
  search: boolean = false;
  distinctProjects: any[] = [];
  headerList: any = [];
  expandedElement!: Projects | null;
  readOnly: any = false;
  selectedRow: any;
  constructor(
    private router: Router,
    private projectFacadeService: ProjectFacadeService,
    private dataCommuteService: DataCommuteService,
    private dialog: MatDialog,
    private projectServices: ProjectsService,
    private cryptoServices:CryptoService,
    public userServices:UsersService
  ) {}

  ngOnInit(): void {



    this.dataSource=new MatTableDataSource(this.userServices.invitations.invities)
    console.log(this.userServices.invitations.invities)

  }

  getSecondryTableData(element: any) {
    this.secondTableDataSource = [];
    console.log('element', element);
    let arr: any[] = [];
    this.secondTableDataSource = new MatTableDataSource(element.innerTable)
    return new MatTableDataSource(element.innerTable)
    ;
  }
  project:any
  toggleRowExpansion(row: any) {
    if (this.selectedRow === row) {
      row.showSecondImage = false;
      this.selectedRow = null;
    } else {
      if (this.selectedRow) {
        this.selectedRow.showSecondImage = false;
      }
      row.showSecondImage = true;
      this.selectedRow = row;
    }
    this.project=row.project_name
  }



//!invites
getUserCount(e:any){
  let count = 0
let users = e.innerTable.map((e:any)=>e.user)
  count = [...new Set(users)].length
  return count
}
getSecondryTableDatas(){
  return this.secondTableDataSource
}





}
