import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export class LoggerService {
  static log(...args: any[]) {
    if (!environment.production) {
      console.log(...args);
    }
  }
  static error(...args: any[]) {
    if (!environment.production) {
      console.error(...args);
    }
  }
  static warn(...args: any[]) {
    if (!environment.production) {
      console.warn(...args);
    }
  }
}
