import { createAction, props } from '@ngrx/store';

const PROJECTAUTH : string = 'PROJECTAUTH';

export const LoadProjectConfig = createAction(`${PROJECTAUTH} Load Project Config`,(payload: any = {}) => payload);
export const LoadProjectConfigSuccess = createAction(`${PROJECTAUTH} Load Project Config Success`, 
                                                        props<{response : any}>());
export const LoadProjectConfigFailure = createAction(`${PROJECTAUTH} Load Project Config Failure`, 
                                                        props<{error : any}>());

                                                        
export const ClearProjectAuth = createAction(`${PROJECTAUTH} Clear Project Auth`);