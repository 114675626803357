import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { InviteUsersComponent } from '../invite-users/invite-users.component';
import { ProjectFacadeService } from '../../state/project/project-facade.service';
import { cloneDeep } from 'lodash';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-wrapper-dashboard',
  templateUrl: './wrapper-dashboard.component.html',
  styleUrls: ['./wrapper-dashboard.component.scss']
})
export class WrapperDashboardComponent implements OnInit {
  projectLength: any=`00`
  constructor(public dialog: MatDialog, public router:Router,    private projectFacadeService: ProjectFacadeService,
  public  userService:UsersService
    ) { }
    loginUser:any=null
  ngOnInit(): void {
    this.projectFacadeService.loadProjects();
    this.userService.dashboardLoaded=true
    this.projectFacadeService.getProjects$.subscribe((projects: any) => {
      if (projects.projects) {
        const getProject = cloneDeep(projects.projects);
       this.projectLength=getProject?.length||`00`
      }
    });
    const storageUser: any = localStorage.getItem('user');
    const user = JSON.parse(storageUser);
    if (user) {
      this.loginUser = `${user.attributes.email}`;
    }
     console.log(this.loginUser, 'user')
     const data:any = {
       "user_email": this.loginUser,
     };
     this.userService.fetchInvites(data).subscribe((res: any) => {
      //  console.log(res,'res')
      //  let data = res[0].users
     })

     this.userService.fetchProjectUsers().subscribe((res: any) => {
      let data :any= res[0]?.Results



     })
  }

  inviteUsers(){
    this.dialog.open(InviteUsersComponent)
  }
  notification() {
    this.router.navigate(['eru-wrapper/users']).then(()=>{
      this.userService.invitations.clicked=true
    });
  }

  createProject(){
    this.router.navigate(['eru-wrapper/create-project']);
  }

  openProjects() {
    this.router.navigate(['eru-wrapper/projects']);
  }

  openUsers() {
    this.router.navigate(['eru-wrapper/users']);
  }

}
