import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CommonPopupComponent>,) { }

  ngOnInit(): void {
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  okAction(){
    this.dialogRef.close({event:'Ok'});
  }

  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }


}
