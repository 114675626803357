import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from '../services/crypto.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  activeLink: any;
  @Input() dashBoardCounts: any;
  fromEruAuth: boolean = false;
  fromEruFiles: boolean = false;
  fromEruGateway: boolean = false;
  fromEruRoutes: boolean = false;
  fromEruQl: boolean = false;
  selectedType: any;
  projectID: any;
  read!: boolean;
  readValue: any;
  private envName: any;
  encryptedParams: any;
  decryptedParams: any;
  encryptParams:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.encryptedParams = params['q'];
      //  this.projectID = params['project'];
      //  this.envName = params['env'];
      //  this.readValue = params['read'];
      // if(this.readValue ==='true'){
      //   this.read=true;
      // }else{
      //   this.read=false
      // }
      // this.decryptedParams = this.cryptoService?.decrypt(this.encryptedParams);
      // console.log(this.decryptedParams,'888888')

      // this.mapParamsValue();
    });


    this.activeLink = this.router.url.split('/');
    if (this.activeLink[1] === 'eru-auth') {
      this.fromEruAuth = true;
      this.selectedType = 'auth';
    } else if (this.activeLink[1] === 'eru-files') {
      this.fromEruFiles = true;
      this.selectedType = 'files';
    } else if (this.activeLink[1] === 'eru-gateway') {
      this.fromEruGateway = true;
    } else if (this.activeLink[1] === 'eru-ql') {
      this.fromEruQl = true;
    } else if (this.activeLink[1] === 'eru-functions') {
      this.fromEruRoutes = true;
      this.selectedType = 'routes';
    }
  }

  ngOnInit() {

    this.mapParamsValue();
  }
  mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectID = param.value;
          } else if (param.key === 'env') {
            this.envName = param.value;
          } else if (param.key === 'read') {
            this.readValue = param.value;
            if (this.readValue === 'true') {
              this.read = true;
            } else {
              this.read = false;
            }
          }
        }
        this.encryptParams = this.cryptoService.encrypt(`project=${this.projectID}&env=${this.envName}&read=${this.read}`)
      }
    }
  }

  gotoRoutes() {
    this.selectedType = 'routes';
    this.fromEruRoutes = true;
    // this.router.navigate(['eru-routes/routes-list'], {
    //   queryParams: {
    //     project: this.projectID,
    //     env: this.envName,
    //     read: this.read,
    //   },
    // });

    this.router.navigateByUrl(`eru-functions/routes-list?q=${this.encryptParams}`)
  }

  createNewRoutes() {
    this.selectedType = 'routes';
    this.fromEruRoutes = true;
    this.router.navigateByUrl(`eru-functions/create-route?q=${this.encryptParams}`);
  }

  goToFunctions() {
    this.selectedType = 'routes';
    this.fromEruRoutes = true;
    this.router.navigateByUrl(`eru-functions/functions?q=${this.encryptParams}`);
  }

  createNewFunctions() {
    this.selectedType = 'routes';
    this.fromEruRoutes = true;
    this.router.navigateByUrl(`eru-functions/create-function?q=${this.encryptParams}`);
  }

  goToDatabase() {
    // this.router.navigate(['eru-ql/dashboard/database/list'], {
    //   queryParams: {
    //     project: this.projectID,
    //     env: this.envName,
    //     read: this.read,
    //   },
    // });
    this.router.navigateByUrl(`eru-ql/dashboard/database/list?q=${this.encryptParams}`)
  }
  goToMyQueries() {
    // this.router.navigate(['eru-ql/dashboard/database/my-queries'], {
    //   queryParams: {
    //     project: this.projectID,
    //     env: this.envName,
    //     read: this.read,
    //   },
    // });
    this.router.navigateByUrl(`eru-ql/dashboard/database/my-queries?q=${this.encryptParams}`)
  }
  goToSetting() {
    // this.router.navigate(['eru-ql/dashboard/setting'], {
    //   queryParams: {
    //     project: this.projectID,
    //     env: this.envName,
    //     read: this.read,
    //   },
    // });
    this.router.navigateByUrl(`eru-ql/dashboard/setting?q=${this.encryptParams}`)
  }

  gotoCreateNewFiles(){
    this.fromEruFiles = true;
    this.selectedType = 'files';
    this.router.navigateByUrl(`eru-files/create-file?q=${this.encryptParams}`)
  }

  gotoCreateNewKeys(){
    this.fromEruFiles = true;
    this.selectedType = 'files';
    this.router.navigateByUrl(`eru-files/create-key?q=${this.encryptParams}`)
  }
  addDatabase(){
    this.router.navigateByUrl(`eru-ql/dashboard/database/add-database?q=${this.encryptParams}`)

  }
  addQueries(){
    this.router.navigateByUrl(`eru-ql/dashboard/database/my-queries/add?q=${this.encryptParams}`)

  }

  goToGateway() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(`eru-auth/gateway?q=${this.encryptParams}`);
  }

  createNewGateway() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(`eru-auth/create-gateway?q=${this.encryptParams}`);
  }

  goToAuthorizer() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(`eru-auth/authorizer?q=${this.encryptParams}`);
  }

  createNewAuthorizer() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(
      `eru-auth/create-authorizer?q=${this.encryptParams}`
    );
  }

  goToTemplates() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(`eru-auth/message-template?q=${this.encryptParams}`);
  }

  createNewTemplates() {
    this.fromEruAuth = true;
    this.selectedType = 'auth';
    this.router.navigateByUrl(
      `eru-auth/create-message-template?q=${this.encryptParams}`
    );
  }

  goToStorage() {
    this.fromEruFiles = true;
    this.selectedType = 'files';
    this.router.navigateByUrl(`eru-files/files?q=${this.encryptParams}`);
  }

  goToKeys() {
    this.fromEruFiles = true;
    this.selectedType = 'files';
    this.router.navigateByUrl(`eru-files/key?q=${this.encryptParams}`);
  }
}
