import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, shareReplay } from 'rxjs';

@Injectable()
export class ProjectsService {
  private FETCH_PROJECTS = '/store/eru/myquery/execute/fetch_projects';
  private ACCEPT_INVITE = '/store/eru/myquery/execute/accept_user_invite';
  private REJECT_INVITE = '/store/eru/myquery/execute/reject_user_invite';
  private CREATE_PROJECT = '/store/eru/myquery/execute/create_project';
  private DELETE_PROJECT = '/store/eru/myquery/execute/delete_project';
  private CREATE_PROJECT_ENV = '/store/eru/myquery/execute/create_project_env';
  private EDIT_PROJECT_ENV = '/store/eru/myquery/execute/edit_project_env';
  private DELETE_PROJECT_ENV = '/store/eru/myquery/execute/delete_project_env';
  private CREATE_PROJECT_SERVICE =
    '/store/eru/myquery/execute/create_project_service';
  private EDIT_PROJECT_SERVICE =
    '/store/eru/myquery/execute/edit_project_service';
  private DELETE_PROJECT_SERVICE =
    '/store/eru/myquery/execute/delete_project_service';
  private SAVE_INSTALLATION_ID = '/store/eru/myquery/execute/save_installation_id'

  constructor(private httpClient: HttpClient) {}

  getProjects(data: any = {}) {
    return this.httpClient.post(
      environment.base_url + this.FETCH_PROJECTS,
      data
    ).pipe(shareReplay());
  }

  createProject(data: any) {
    return this.httpClient.post(
      environment.base_url + this.CREATE_PROJECT,
      data
    );
  }

  deleteProject(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.DELETE_PROJECT,
      data
    );
  }

  createEnvironment(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.CREATE_PROJECT_ENV,
      data
    );
  }

  editEnvironment(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.EDIT_PROJECT_ENV,
      data
    );
  }

  deleteEnvironment(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.DELETE_PROJECT_ENV,
      data
    );
  }

  createService(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.CREATE_PROJECT_SERVICE,
      data
    );
  }

  editService(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.EDIT_PROJECT_SERVICE,
      data
    );
  }

  deleteService(data: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.DELETE_PROJECT_SERVICE,
      data
    );
  }

  authenticateForService(loginUrl: any, data: any): Observable<any> {
    return this.httpClient.post(loginUrl, data);
  }

  acceptInviteForProject(payload: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.ACCEPT_INVITE,
      payload
    );
  }
  rejectInviteForProject(payload: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + this.REJECT_INVITE,
      payload
    );
  }
  
  saveInstallationId(data: any): Observable<any> {
    return this.httpClient.post(environment.base_url + this.SAVE_INSTALLATION_ID,
       data);
  }
  getAccessToken(body:any){
    return this.httpClient.post(environment.wrapperUrl + '/eru/func/get_access_token',
      body);
  }
}
