import { createAction, props } from "@ngrx/store";

const DASHBOARD : string = 'DASHBOARD';

export const ProjectsLoad = createAction(`${DASHBOARD} Projects Load`,(payload: any = {}) => payload);
export const ProjectsLoadSuccess = createAction(`${DASHBOARD} Projects Load Success`, 
                                                        props<{response : any}>());
export const ProjectsLoadFailure = createAction(`${DASHBOARD} Projects Load Failure`, 
                                                        props<{error : any}>());

export const LoadProject = createAction(`${DASHBOARD} Load Project`,(payload: any = {}) => payload);
export const LoadProjectSuccess = createAction(`${DASHBOARD} Load Project Success`, 
                                                        props<{response : any}>());
export const LoadProjectFailure = createAction(`${DASHBOARD} Load Project Failure`, 
                                                        props<{error : any}>());

export const GetDatastoreTables = createAction(`${DASHBOARD} Get Datastore Tables`,(payload: any = {}) => payload);
export const GetDatastoreTablesSuccess = createAction(`${DASHBOARD} Get Datastore Tables Success`, 
                                                        props<{response : any}>());
export const GetDatastoreTablesFailure = createAction(`${DASHBOARD} Get Datastore Tables Failure`, 
                                                        props<{error : any}>()); 

export const AddTablesToSchema = createAction(`${DASHBOARD} Add Tables to schema`,(payload: any = {}) => payload);
export const AddTablesToSchemaSuccess = createAction(`${DASHBOARD} Add Tables to schema Success`, 
                                                        props<{response : any}>());
export const AddTablesToSchemaFailure = createAction(`${DASHBOARD} Add Tables to schema Failure`, 
                                                        props<{error : any}>());

export const RemoveTablesToSchema = createAction(`${DASHBOARD} Remove Tables to schema`,(payload: any = {}) => payload);
export const RemoveTablesToSchemaSuccess = createAction(`${DASHBOARD} Remove Tables to schema Success`, 
                                                        props<{response : any}>());
export const RemoveTablesToSchemaFailure = createAction(`${DASHBOARD} Remove Tables to schema Failure`, 
                                                        props<{error : any}>()); 

export const ProjectsAddEdit = createAction(`${DASHBOARD} Project Creations AddEdit`,(payload: any = {}) => payload);
export const ProjectsAddEditSuccess = createAction(`${DASHBOARD} Project Creations AddEdit Success`, 
                                                        props<{response : any}>());
export const ProjectsAddEditFailure = createAction(`${DASHBOARD} Project Creations AddEdit Failure`, 
                                                        props<{error : any}>());

export const ProjectsDatastoreAddEdit = createAction(`${DASHBOARD} Project Datastore Creations AddEdit`,(payload: any = {}) => payload);
export const ProjectsDatastoreAddEditSuccess = createAction(`${DASHBOARD} Project Datastore Creations AddEdit Success`, 
                                                        props<{response : any}>());
export const ProjectsDatastoreAddEditFailure = createAction(`${DASHBOARD} Project Datastore Creations AddEdit Failure`, 
                                                        props<{error : any}>());  

export const GetDefaultDriverConfig = createAction(`${DASHBOARD} Project Datastore Creations getDefaultConfig`,(payload: any = {}) => payload);
export const GetDefaultDriverConfigSuccess = createAction(`${DASHBOARD} Project Datastore Creations getDefaultConfig Success`, 
                                                        props<{response : any}>());
export const GetDefaultDriverConfigFailure = createAction(`${DASHBOARD} Project Datastore Creations getDefaultConfig Failure`, 
                                                        props<{error : any}>());                                                       

// export enum DashboardActionTypes {
//   PROJECTS_LOAD = "[Dashboard] Projects Load",
//   PROJECTS_LOAD_SUCCESS = "[Dashboard] Projects Load Success",
//   PROJECTS_LOAD_FAILURE = "[Dashboard] Projects Load Failure",

//  LOAD_PROJECT = "[Dashboard] Project Load",
//  LOAD_PROJECT_SUCCESS = "[Dashboard] Load Project Success",
//  LOAD_PROJECT_FAILURE = "[Dashboard] Load Project Failure",

//   LOAD_DATASTORES =  "[Dashboard] Load data stores",
//   LOAD_DATASTORES_SUCCESS=  "[Dashboard] Load data stores success",
//   LOAD_DATASTORES_FAILURE =  "[Dashboard] Load data stores failure",

//   GET_DATASTORE_TABLES = "[Database] Get Datastore Tables",
//   GET_DATASTORE_TABLES_SUCCESS = "[Dashboard] Get Datastore Tables Success",
//   GET_DATASTORE_TABLES_FAILURE = "[Dashboard] Get Datastore Tables Failure",

//   ADD_TABLES_TO_SCHEMA = "[Database] Add Tables to schema",
//   ADD_TABLES_TO_SCHEMA_SUCCESS = "[Dashboard] Add Tables to schema Success",
//   ADD_TABLES_TO_SCHEMA_FAILURE = "[Dashboard] Add Tables to schema Failure",

//   REMOVE_TABLES_TO_SCHEMA = "[Database] Remove Tables to schema",
//   REMOVE_TABLES_TO_SCHEMA_SUCCESS = "[Dashboard] Remove Tables to schema Success",
//   REMOVE_TABLES_TO_SCHEMA_FAILURE = "[Dashboard] Remove Tables to schema Failure",

//   LOAD_DATASTORE_TABLES =  "[Database] Load data store tables",
//   LOAD_DATASTORE_TABLES_SUCCESS=  "[Database] Load data store tables success",
//   LOAD_DATASTORE_TABLES_FAILURE =  "[Database] Load data store tables failure",

//   LOAD_PROJECT_CONFIG_SUCCESS =  "[Database] Load  projects config success",


//   /*-----------------------------------------------*/
//  // PROJECTS_LOAD = "[ProjectCreations] Load",
//  // PROJECTS_LOAD_SUCCESS = "[ProjectCreations] Load Success",
//  // PROJECTS_LOAD_FAILURE = "[ProjectCreations] Load Failure",

//   PROJECT_ADDEDIT = "[ProjectCreations] AddEdit",
//   PROJECT_ADDEDIT_SUCCESS = "[ProjectCreations] AddEdit Success",
//   PROJECT_ADDEDIT_FAILURE = "[ProjectCreations] AddEdit Failure",

//   PROJECT_DATASTORE_ADDEDIT = "[ProjectDatastoreCreations] AddEdit",
//   PROJECT_DATASTORE_ADDEDIT_SUCCESS = "[ProjectDatastoreCreations] AddEdit Success",
//   PROJECT_DATASTORE_ADDEDIT_FAILURE = "[ProjectDatastoreCreations] AddEdit Failure",

//   GET_DEFAULT_CONFIG = "[ProjectDatastoreCreations] getDefaultConfig",
//   GET_DEFAULT_CONFIG_SUCCESS = "[ProjectDatastoreCreations] getDefaultConfig Success",
//   GET_DEFAULT_CONFIG_FAILURE = "[ProjectDatastoreCreations] getDefaultConfig Failure",

// }

// export class ProjectsLoad implements Action {
//   readonly type = DashboardActionTypes.PROJECTS_LOAD;
//   constructor(public payload: any) {}
// }

// export class ProjectsLoadSuccess implements Action {
//   readonly type = DashboardActionTypes.PROJECTS_LOAD_SUCCESS;
//   constructor(public payload: any) {}
// }

// export class ProjectsLoadFailure implements Action {
//   readonly type = DashboardActionTypes.PROJECTS_LOAD_FAILURE;
//   constructor(public payload: any) {}
// }
 

// export class LoadProject implements Action {
//   readonly type = DashboardActionTypes.LOAD_PROJECT;
//   constructor(public payload: any) {}
// }

// export class LoadProjectSuccess implements Action {
//   readonly type = DashboardActionTypes.LOAD_PROJECT_SUCCESS;
//   constructor(public payload: any) {}
// }

// export class LoadProjectFailure implements Action {
//   readonly type = DashboardActionTypes.LOAD_PROJECT_FAILURE;
//   constructor(public payload: any) {}
// } 

// export class GetDatastoreTables implements Action {
//   readonly type = DashboardActionTypes.GET_DATASTORE_TABLES;
//   constructor(public payload: any) {}
// }

// export class GetDatastoreTablesSuccess implements Action {
//   readonly type = DashboardActionTypes.GET_DATASTORE_TABLES_SUCCESS;
//   constructor(public payload: any) {}
// }
// export class GetDatastoreTablesFailure implements Action {
//     readonly type = DashboardActionTypes.GET_DATASTORE_TABLES_FAILURE;
//     constructor(public payload: any) {}
//   }

  // export class AddTablesToSchema implements Action {
  //   readonly type = DashboardActionTypes.ADD_TABLES_TO_SCHEMA;
  //   constructor(public payload: any) {}
  // }
  
  // export class AddTablesToSchemaSuccess implements Action {
  //   readonly type = DashboardActionTypes.ADD_TABLES_TO_SCHEMA_SUCCESS;
  //   constructor(public payload: any) {}
  // }
  // export class AddTablesToSchemaFailure implements Action {
  //     readonly type = DashboardActionTypes.ADD_TABLES_TO_SCHEMA_FAILURE;
  //     constructor(public payload: any) {}
  // }
 
  // export class RemoveTablesToSchema implements Action {
  //   readonly type = DashboardActionTypes.REMOVE_TABLES_TO_SCHEMA;
  //   constructor(public payload: any) {}
  // }
  
  // export class RemoveTablesToSchemaSuccess implements Action {
  //   readonly type = DashboardActionTypes.REMOVE_TABLES_TO_SCHEMA_SUCCESS;
  //   constructor(public payload: any) {}
  // }
  // export class RemoveTablesToSchemaFailure implements Action {
  //     readonly type = DashboardActionTypes.REMOVE_TABLES_TO_SCHEMA_FAILURE;
  //     constructor(public payload: any) {}
  // }

  // export class ProjectConfigLoadSuccess implements Action {
  //   readonly type = DashboardActionTypes.LOAD_PROJECT_CONFIG_SUCCESS;
  //   constructor(public payload: any) {}
  // } 

  /*-------------------PROJECT CREATION ACTION METHODES---------------------------*/
  // export class ProjectsAddEdit implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_ADDEDIT;
  //   constructor(public payload: any) {}
  // }
  
  // export class ProjectsAddEditSuccess implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_ADDEDIT_SUCCESS;
  //   constructor(public payload: any) {}
  // }
  
  // export class ProjectsAddEditFailure implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_ADDEDIT_FAILURE;
  //   constructor(public payload: any) {}
  // }
  
  // export class ProjectsDatastoreAddEdit implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_DATASTORE_ADDEDIT;
  //   constructor(public payload: any) {}
  // }
  
  // export class ProjectsDatastoreAddEditSuccess implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_DATASTORE_ADDEDIT_SUCCESS;
  //   constructor(public payload: any) {}
  // }
  
  // export class ProjectsDatastoreAddEditFailure implements Action {
  //   readonly type = DashboardActionTypes.PROJECT_DATASTORE_ADDEDIT_FAILURE;
  //   constructor(public payload: any) {}
  // }
  
  // export class GetDefaultDriverConfig implements Action {
  //   readonly type = DashboardActionTypes.GET_DEFAULT_CONFIG;
  //   constructor(public payload: any) {}
  // }
  // export class GetDefaultDriverConfigSuccess implements Action {
  //   readonly type = DashboardActionTypes.GET_DEFAULT_CONFIG_SUCCESS;
  //   constructor(public payload: any) {}
  // }
  // export class GetDefaultDriverConfigFailure implements Action {
  //   readonly type = DashboardActionTypes.GET_DEFAULT_CONFIG_FAILURE;
  //   constructor(public payload: any) {}
  // }
  /*-----------------------------------------------*/



// export type All = 
//  ProjectsLoad |
//  ProjectsLoadSuccess |
//  ProjectsLoadFailure |
//  LoadProject |
//  LoadProjectSuccess |
//  LoadProjectFailure | 
//  GetDatastoreTables |
// GetDatastoreTablesSuccess |
// GetDatastoreTablesFailure |
// AddTablesToSchema |
// AddTablesToSchemaSuccess |
// AddTablesToSchemaFailure |
// RemoveTablesToSchema |
// RemoveTablesToSchemaSuccess |
// RemoveTablesToSchemaFailure |
//  ProjectConfigLoadSuccess |
//  ProjectsAddEdit |
//  ProjectsAddEditSuccess | 
//  ProjectsAddEditFailure |
//  ProjectsDatastoreAddEdit |
//  ProjectsDatastoreAddEditSuccess|
//  ProjectsDatastoreAddEditFailure |
//  GetDefaultDriverConfig|
//  GetDefaultDriverConfigSuccess |
//  GetDefaultDriverConfigFailure ;