import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonsUiModule } from '../commons-ui/commons-ui.module';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


const routes: Routes = [];

@NgModule({
  imports: [CommonsUiModule,RouterModule],
  declarations: [NavBarComponent,SideBarComponent, PageNotFoundComponent],
  exports: [NavBarComponent,SideBarComponent],
})
export class CoreModule {

  ngOnInit(){}
}
