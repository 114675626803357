<div class="container-fluid variable-tab-container">
  <form [formGroup]="form">
      <div class="row">
          <p class="f-12 fw-400 text-grey col-md-3 w280 mb-style-change" style="padding-left: 0">
              Key
          </p>
          <!-- <p class="f-12 fw-400 text-grey col-md-3 ml-15 w280 mb-style-change" style="padding-left: 0">
              Value
          </p> -->
      </div>
      <div formArrayName="environment">
          <div class=" " *ngFor="let environments of environment().controls; let i = index" [formGroupName]="i">
              <div class="d-flex mb-20 align-item-baseline change-alignment">
                  <div class="align-items-center d-flex flex-direction-change">
                      <div class="p-0 project-style darkgrey-outline w280 mb-w150">
                          <p class="f-12 fw-400 text-grey col-md-3 w280 displayOnMb" style="padding-left: 0">
                              Key
                          </p>
                          <mat-form-field appearance="outline" class="" [ngClass]="{'disabled':read}">
                              <input formControlName="keyName" matInput type="text" placeholder="eg. Key"/>
                          </mat-form-field>
                      </div>
                      <!-- <div class="p-0 project-style darkgrey-outline w280 mb-w150 ml-15 change-ml">
                          <p class="f-12 fw-400 text-grey col-md-3 ml-15 change-ml w280 displayOnMb"
                              style="padding-left: 0">
                              Value
                          </p>
                          <mat-form-field appearance="outline" class="" [ngClass]="{'disabled':read}">
                              <input formControlName="valueName" matInput type="text" placeholder="eg. Value" />
                          </mat-form-field>
                      </div> -->
                  </div>
                  <!-- <div class=" ">
                      <div class="p-0 project-style darkgrey-outline ml-15 mb-mobile" *ngIf="!read">
                          <button mat-flat-button color="primary" class="w70 h32"
                              *ngIf="i === environment().controls.length - 1" (click)="saveEnvVars(i)" >
                              Save
                          </button>
                      </div>
                      <div class="p-0 project-style darkgrey-outline ml-20 d-flex mb-mobile"
                          *ngIf="i !== environment().controls.length - 1">
                          <img class="pointer" [src]="read ? '../../../../assets/images/icons/viewEye.svg' : '../../../../assets/images/icons/edit1.svg'" />
                          <img class="ml-30 pointer" (click)="removeEnvironment(i)"
                              src="../../../../assets/images/icons/delete.png" *ngIf="!read"/>
                      </div>
                  </div> -->
                  <div class="d-flex">
                    <!-- <div class="p-0 project-style darkgrey-outline ml-15 mb-mobile" *ngIf="!read" style="width: 18px; height: 18px;"> -->
                        <!-- <button  mat-flat-button color="primary" class="w70 h32"
                            *ngIf="i === variables().controls.length - 1 || isEditClick" (click)="saveVars(i)">
                            Save
                        </button> -->
                        <!-- <img src="../../../../../assets/images/logo/save.png" *ngIf="i === variables().controls.length - 1 || isEditClick" (click)="saveVars(i)" />
                    </div> -->
                    <div class="p-0 project-style darkgrey-outline ml-20 d-flex mb-mobile">
                        <img *ngIf="!environments.get('keyName')?.disabled" class="pointer"[src]="read ? '../../../../assets/images/icons/viewEye.svg' : '../../../../../assets/images/logo/save.png'" (click)="saveEnvVars(i)"  />
                        <img *ngIf="environments.get('keyName')?.disabled" class="pointer" (click)="removeEnvironment(i)"
                            src="../../../../assets/images/icons/delete.png" />
                            <!-- <img class="ml-30 pointer" (click)="cancel(i)"
                            src="../../../../../assets/images/icons/cancel.png" *ngIf="!read && isEditClick"/> -->
                    </div>
                </div>
              </div>
          </div>
      </div>

      <div class="pt-20 pb-20 mt-20">
          <button class="add-btn" (click)="addEnvironment()" *ngIf="!read">Add more</button>
      </div>
  </form>
</div>