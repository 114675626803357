
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectFilesSelectors from './project-ql.selector';
import * as ProjectFilesActions from './project-ql.action';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EruRoutesService } from 'src/app/modules/eru-routes/services/eru-routes.service';

@Injectable()
export class ProjectQlFacadeService {
    getProjectConfig$ = this.store.select(ProjectFilesSelectors.getProjectConfig);

    
    constructor(private store : Store, private router : Router, private eruRoutesService : EruRoutesService) {
        
    }

    loadProjectConfig(projectName : string){
        this.store.dispatch(
            ProjectFilesActions.LoadProjectConfig({projectName : projectName})
        )
    }
    

    saveRoutes(projectName : string, data : any){   
        return this.eruRoutesService.saveRoutes(projectName, data);
      }

    removeRoutes(projectName : string, routeName:string, data : any){   
        return this.eruRoutesService.removeRoutes(projectName, routeName, data);
      }
    
    

    clearProjectServiceStore(){
        this.store.dispatch(
            ProjectFilesActions.ClearProjectQL()
        )
    }


}