import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApplicationService {
    private _project:any;
    constructor(){

    }

    public set project(value:string){
        this._project = value;
    }

 
    public get project() : string {
        return  this._project;
    }
    

}