export const environment = {
  production: true,
  url: '',
  auth_url:'https://eruauth.app.erutech.io',
  base_url:'https://eruql.app.erutech.io',
  routes_url: 'https://erufunc.app.erutech.io',
  gateway_url: '',
  git_url:'https://github.com/apps/eru-githubapp/installations/new',
  key : "f2555ea9b05bd409d",
  wrapperUrl:'https://erufunc.app.erutech.io'
};
