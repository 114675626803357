<div class="git-wrapper">
    <div class="git-inner minH493">
        <h4>Repository Settings</h4>
        <div class="row">
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Repo Name</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]='repoName' name='repoName' matInput autocomplete="off" placeholder="" [disabled]="read" />
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 select-drop">
                <h4 class="mt-0 text-grey3 f-12">Repo Type</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectedValue" >
                          <mat-option *ngFor="let item of type" [value]="item.name">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Branch Name</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <input  [(ngModel)]='branchName' name='branchName'matInput autocomplete="off" placeholder="" [disabled]="read"/>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Auth Type</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectedAuthValue" (ngModelChange)="onChange($event)">
                          <mat-option *ngFor="let item of authType" [value]="item.value">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
             
                </div>
                <h5 *ngIf="this.selectedAuthValue == 2"  class="mt-0 f-12"> Installation ID:{{installation_id }}</h5>
       
     
            </div>
        </div>
        <!-- <div class="row authentication mt-20">
            <p class="f-14 text-grey3">Authentication Mode</p>
            <div class="d-flex">
                <div class="radio-item">
                    <input type="radio" id="ssh" name="secretOpt" (change)='inputChange()' [(ngModel)]="secretOpt"  [value]="1">
                    <label for="ssh">SSH</label>
                </div>
                
                <div class="radio-item">
                    <input type="radio" id="http" name="secretOpt" (change)='inputChange()'[(ngModel)]="secretOpt" [value]="0">
                    <label for="http">HTTP</label>
                </div>
            </div>            
        </div> -->
        <div class="row mt-20">
            <div class="col-md-4">
                <span *ngIf='secretOpt===1'>
                    <p class="f-14 text-grey3">Key</p>
                    <!-- <mat-form-field  appearance="outline" class="w-100 instance-input default-msg-text-area">
                        <textarea [(ngModel)]='key' name ="key"matInput rows="10" cols="9" placeholder="Claim Description-1000 charcters">
                        </textarea>
                    </mat-form-field> -->
                    <mat-form-field appearance="outline" class="w-75 instance-input default-msg-text-area">
                        <textarea  [(ngModel)]='key' name ="key" matInput rows="5" cols="9" placeholder="Write your script here" [disabled]="read">
                      </textarea>
                    </mat-form-field>
                </span>
                <span *ngIf='selectedAuthValue===1'>
                    <p class="f-14 text-grey3">Token</p>
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]='token' name="token"matInput autocomplete="off" placeholder="" [disabled]="read"/>
                    </mat-form-field>
                </span>
            </div>
            <!-- <div class="col-md-1 text-center">
                <img src="../../../../assets/images/icons/or.svg" />
            </div>
            <div class="col-md-3 drop">
                <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)" accept=".csv"/>
                <button mat-stroked-button (click)="csvInput.click()">Drop your file here</button>
            </div> -->
        </div>
        <div class="row mt-10">
            <div class="col-md-4">
             
                    <p class="f-14 text-grey3">Auto Commit</p>
               
                    <mat-checkbox [(ngModel)]='autoCommit' name="autoCommit" [disabled]="read"></mat-checkbox>
              
            
            </div>
         
        </div>
      
        <div class="row button-style">
            <div class="text-right">
                <button style="height: 35px;"
                        mat-flat-button
                        *ngIf="!read"
                        color="primary"
                        type="submit"
                        class="text-white"
                        (click)='onSave()'
                        cdkFocusInitial>
                        Save
                </button>
            </div>
        </div>
    </div>
</div>
