import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EruRoutesService {

  constructor(private httpClient:HttpClient) { }

  sidePopupSubject: BehaviorSubject<any> = new BehaviorSubject<any>({type:null,data:{},output:{type:null,data:null}});
  sidePopupValue$ = this.sidePopupSubject.asObservable();

  updateSidePopupValue(o:any,d:any):void {

    this.sidePopupSubject.next({type:o,data:d})
  }

  getProjectConfig(projectName : string){
    return this.httpClient.get(`${environment.url}/store/${projectName}/config`);
  }

  getMyQueryList(projectName : string){
    return this.httpClient.get(`${environment.url}/store/${projectName}/myquery/list`);
  }

  saveRoutes(projectName : string, data : any){
    return this.httpClient.post(`${environment.url}/store/${projectName}/route/save`, data);
  }

  removeRoutes(projectName : string, routeName:string, data : any){
    return this.httpClient.delete(`${environment.url}/store/${projectName}/route/remove/${routeName}`, data);
  }

  saveFunc(projectName : string, data : any){
    return this.httpClient.post(`${environment.url}/store/${projectName}/func/save`, data);
  }

  removeFunc(projectName : string, funcName:string, data : any){
    return this.httpClient.delete(`${environment.url}/store/${projectName}/func/remove/${funcName}`, data);
  }

}
