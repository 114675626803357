import { createAction, props } from '@ngrx/store';

const PROJECT : string = 'PROJECT';

export const LoadProjects = createAction(`${PROJECT} Load Projects`,(payload: any = {}) => payload);
export const LoadProjectsSuccess = createAction(`${PROJECT} Load Projects Success`, 
                                                        props<{response : any}>());
export const LoadProjectsFailure = createAction(`${PROJECT} Load Projects Failure`, 
                                                        props<{error : any}>());

export const GetProject = createAction(`${PROJECT} Get Project`,(payload: any = {}) => payload);
export const GetProjectSuccess = createAction(`${PROJECT} Get Project Success`, 
                                                        props<{response : any}>());
export const GetProjectFailure = createAction(`${PROJECT} Get Project Failure`, 
                                                        props<{error : any}>());
                                                        
export const CreateProject = createAction(`${PROJECT} Create Project`,(payload: any = {}) => payload);
export const CreateProjectSuccess = createAction(`${PROJECT} Create Project Success`, 
                                                        props<{response : any}>());
export const CreateProjectFailure = createAction(`${PROJECT} Create Project Failure`, 
                                                        props<{error : any}>());

export const CreateEnvironment = createAction(`${PROJECT} Create Environment`,(payload: any = {}) => payload);
export const CreateEnvironmentSuccess = createAction(`${PROJECT} Create Environment Success`, 
                                                        props<{response : any}>());
export const CreateEnvironmentFailure = createAction(`${PROJECT} Create Environment Failure`, 
                                                        props<{error : any}>());
                                                        
export const CreateService = createAction(`${PROJECT} Create Service`,(payload: any = {}) => payload);
export const CreateServiceSuccess = createAction(`${PROJECT} Create Service Success`, 
                                                        props<{response : any}>());
export const CreateServiceFailure = createAction(`${PROJECT} Create Service Failure`, 
                                                        props<{error : any}>());
                                                        
export const ClearProjectAndService = createAction(`${PROJECT} Clear Project Service`);