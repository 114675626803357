import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import * as eruCompareGaterway from 'src/assets/compareJson/eru_gateway_compare.json';
import * as eruCompareRoutes from 'src/assets/compareJson/eru_routes_compare.json';
import * as eruCompareauth from 'src/assets/compareJson/eruauth_compare.json';
import * as eruCompareFiles from 'src/assets/compareJson/erufiles_compare.json';
import { ImportLoaderComponent } from './import-loader/import-loader.component';
import { CommonPopupComponent } from 'src/app/modules/eru-wrapper/components/common-popup/common-popup.component';
import { NotificationService } from 'src/app/database/services/notification.service';
@Component({
  selector: 'app-import',
  templateUrl:  './import.component.html',
  styleUrls: ['./import.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ]
})
export class ImportComponent implements OnInit {
  Object:any = Object;
  viewType: any = 'selectService';
  switchImage: boolean = false;
  text: any = `Path : {*storage.AwsStorage}.Region`;
  evnironment: any;
  environmentName: any;
  selectedEnvironment: any;
  removeEnvironmentOption: any;
  selectedService: any;
  popupHeading: any = '';
  showImportButton: boolean = true;
  compareGatewya: any; // = eruCompareGaterway;
  CompareRoutes: any; // = eruCompareRoutes;
  Compareauth: any; // = eruCompareauth;
  CompareFiles: any; // = eruCompareFiles;
  compareService: any;
  switchImageHeaderOne: any = {};
  switchImageHeaderTwo: any = {};
  projectConfig: any;
  data: any;
  encryptedParams: any;
  decryptedParams: any;
  showHtml: boolean = false;

  commonSubHeadingOne: any;
  totalMissingHeadingOneImport: any = [];
  commonSubHeadingTwo: any;
  totalMissingHeadingTwoImport: any = [];
  totalNewHeadingOneImport: any = [];
  totalNewHeadingTwoImport: any = [];
  totalMismatchHeadingOneImport: any;
  totalMismatchHeadingTwoImport: any;
  totalMissingImport: any;
  totalNewImport: any;
  totalMismatch: any;
  values: any;
  commonSubHeadingThird: any;
  totalMissingHeadingThirdImport: any;
  commonSubHeadingFourth: any;
  totalMissingHeadingForthImport: any;
  commonSubHeadingFifth: any;
  totalMissingHeadingFifthImport: any;
  commonSubHeadingsixth: any;
  commonSubHeadingseven: any;
  commonSubHeadingeight: any;
  commonSubHeadingnine: any;
  commonSubHeadingten: any;
  commonSubHeadingeleven: any;
  totalMissingHeadingsixthImport: any;
  totalMissingHeadingSevenImport: any;
  totalNewHeadingThirdImport: any;
  totalNewHeadingForthImport: any;
  totalNewHeadingFifthImport: any;
  totalNewHeadingSixthImport: any;
  totalNewHeadingSevenImport: any;
  totalMismatchHeadingThirdImport: any;
  totalMismatchHeadingForthImport: any;
  totalMismatchHeadingFifthImport: any;
  totalMismatchHeadingSixthImport: any;
  totalMismatchHeadingSevenImport: any;
  totalMissingHeadingEightImport:any;
  totalNewHeadingEightImport:any
  commonSubHeadingEight:any
  totalMismatchHeadingEightImport:any
  commonSubHeadingNine:any
  totalMissingHeadingNineImport:any
  totalNewHeadingNineImport:any
  totalMismatchHeadingNineImport:any

  totalMissingHeadingtenImport:any
  totalNewHeadingtenImport:any
  totalMismatchHeadingtenImport:any

  totalMissingHeadingelevenImport:any
  totalNewHeadingelevenImport:any
  totalMismatchHeadingelevenImport:any
  constructor(
    // private dialogRef: MatDialogRef<ImportComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private notifyService: NotificationService,
    private importService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
    private dialog: MatDialog
  ) {
    this.route.queryParams.subscribe((params) => {
      this.encryptedParams = params['q'];
      if (this.encryptedParams) {
        this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
        const removedDataEquals = this.decryptedParams.split('data=')[1];
        this.data = JSON.parse(removedDataEquals);
        this.initiate();
      }
    });
    this.router.events.subscribe((val) => {
      const currentUrl = this.router.url;
      // console.log(currentUrl.indexOf('import') > -1);

      if (currentUrl.indexOf('import') > -1) {
        this.showHtml = true;
      } else {
        this.showHtml = false;
      }
    });

    const state = this.router.getCurrentNavigation()?.extras.state;

    if (state && Object.keys(state).length > 0) {
      this.openImports(state);
    } else {
      this.router.navigateByUrl('/eru-wrapper/projects');
    }
  }
  ngOnInit(): void {}
  // close() {
  //   this.dialogRef.close();
  // }

  initiate() {
    console.log(this.data);
    this.evnironment = this.data.environmentName;
    this.removeEnvironmentOption = this.data.dataValue.environment;
  }
  goBack() {
    this.router.navigateByUrl('/eru-wrapper/projects');
  }

  environmentSelectionChange() {
    this.environmentName = this.selectedEnvironment.Services;
    sessionStorage.setItem('currentSelectedEnvId',this.selectedEnvironment.env_id)
  }
  weburl!: string;
  projectName!: string;
  serviceSelectionChange() {
    this.weburl = this.selectedService.service_path;
    this.popupHeading = this.selectedService?.service_name;
    if (this.selectedService) {
      this.showImportButton = false;
    }
    const eruProject: any = localStorage.getItem(
      `${this.data.projectName}_${this.removeEnvironmentOption}`
    );
    localStorage.setItem(
      `${this.data.projectName}_${this.removeEnvironmentOption}_${this.selectedService.service_name}`,
      eruProject
    );
    //TODO : clear the local storage on dialog close
  }

  openImport() {
    this.router.navigateByUrl('/eru-wrapper/projects/import', {
      state: {
        data: this.data,
        selectedService: this.selectedService,
      },
    });

    // this.viewType = 'serviceLoading';
    // console.log('this.data',this.data)
    // this.projectName = this.data.projectName;
    // this.importService
    //   .getImport(this.weburl, this.projectName)
    //   .subscribe((res: any) => {
    //     if (res) {
    //       this.projectConfig = res?.project;
    //       this.compareService = this.data.dataValue.Services.find((s  :any) => {return s.service_name == this.selectedService.service_name})
    //       this.importService
    //         .postImport(this.compareService.service_path, this.projectName, this.projectConfig)
    //         .subscribe((res: any) => {
    //           if (res) {
    //             this.viewType = 'import';
    //             if (this.selectedService?.service_name == 'eru-gateway') {
    //               this.compareGatewya = res;
    //               this.commonSubHeadingOne = 'Listener Rules';
    //               this.totalMissingHeadingOneImport =
    //                 this.compareGatewya?.DeleteListenerRules;
    //               this.totalNewHeadingOneImport =
    //                 this.compareGatewya?.NewListenerRules;
    //               this.totalMismatchHeadingOneImport =
    //                 this.compareGatewya?.MismatchListenerRules;
    //             } else if (this.selectedService?.service_name == 'eru-routes') {
    //               this.CompareRoutes = res;
    //               this.commonSubHeadingOne = 'Routes';
    //               this.commonSubHeadingTwo = 'Function';
    //               this.totalMissingHeadingOneImport =
    //                 this.CompareRoutes?.DeleteRoutes;
    //               this.totalMissingHeadingTwoImport =
    //                 this.CompareRoutes?.DeleteFuncs;
    //               this.totalNewHeadingOneImport = this.CompareRoutes?.NewRoutes;
    //               this.totalNewHeadingTwoImport = this.CompareRoutes?.NewFuncs;
    //               this.totalMismatchHeadingOneImport =
    //                 this.CompareRoutes?.MismatchRoutes;
    //               this.totalMismatchHeadingTwoImport =
    //                 this.CompareRoutes?.MismatchFuncs;
    //             } else if (this.selectedService?.service_name == 'eru-ql') {
    //             } else if (this.selectedService?.service_name == 'eru-auth') {
    //               this.Compareauth = res;
    //               this.commonSubHeadingOne = 'Auth';
    //               this.totalMissingHeadingOneImport =
    //                 this.Compareauth?.DeleteAuth;
    //               this.totalNewHeadingOneImport = this.Compareauth?.NewAuth;
    //               this.totalMismatchHeadingOneImport =
    //                 this.Compareauth?.MismatchAuth;
    //             } else if (this.selectedService?.service_name == 'eru-files') {
    //               this.CompareFiles = res;
    //               this.commonSubHeadingOne = 'Storage';
    //               this.commonSubHeadingTwo = 'keys';
    //               this.totalMissingHeadingOneImport =
    //                 this.CompareFiles?.DeleteStorages;
    //                 this.totalMissingHeadingTwoImport = [];
    //               // this.totalMissingHeadingTwoImport =
    //               //   this.CompareFiles?.DeleteKeys;
    //               this.totalNewHeadingOneImport =
    //                 this.CompareFiles?.NewStorages;
    //               this.totalNewHeadingTwoImport = this.CompareFiles?.NewKeys;
    //               this.totalMismatchHeadingOneImport =
    //                 this.CompareFiles?.MismatchStorages;
    //               this.totalMismatchHeadingTwoImport =
    //                 this.CompareFiles?.MismatchKeys;
    //             }
    //             this.getTotal();
    //             this.switchImages();
    //           }
    //         });
    //     }
    //   });
  }
configAuthSettings={};
compareAuthSettings={};
  openImports(state: any) {
//this.data have project __project_env
    if (!state) {
      this.router.navigateByUrl('/eru-wrapper/projects');
    }
    console.log(state)
    this.selectedService = state.selectedService;
    this.data = state.data;
    this.initiate();
    this.weburl = this.selectedService.service_path;
    this.popupHeading = this.selectedService?.service_name;
    const isEruGateway =
      this.selectedService?.service_name == 'eru-gateway' ? true : false;
    this.projectName = this.data.projectName;
    if (this.weburl) {
      this.dialog.open(ImportLoaderComponent, {
        data: this.popupHeading,
      });

        for(let i of this.data.public___project_envs){
          if(i.env_id == sessionStorage.getItem('currentSelectedEnvId')){
            this.configAuthSettings=i
          }
          if(i.env_id == this.data.dataValue.env_id){

            this.compareAuthSettings = i
          }
        }

      this.importService
        .getImport(this.weburl, this.projectName, isEruGateway,this.configAuthSettings)
        .subscribe((res: any) => {
          console.log(res)
          if (res) {
            let gatewayLitenersRule;
            if (isEruGateway) {
              gatewayLitenersRule = res;
            } else {
              this.projectConfig = res?.project;
            }
            this.compareService = this.data.dataValue.Services.find(
              (s: any) => {
                return s.service_name == this.selectedService.service_name;
              }
            );
            const importData = isEruGateway ? gatewayLitenersRule : this.projectConfig;
            this.postImportData(this.compareService.service_path, this.projectName, importData, isEruGateway,this.compareAuthSettings);
          }
        });
  }



  }


  postImportData(servicePath: string, projectName: string, importData: any, isEruGateway: boolean,compareAuthSettings?:any) {
    this.importService
      .postImport(servicePath, projectName, importData, isEruGateway,compareAuthSettings)
      .subscribe((res: any) => {
        console.log(res)
        if (res) {
          this.dialog.closeAll();
          this.updateImportData(res);
          this.getTotal();
          this.switchImages();
        }
      });
  }

  updateImportData(res: any) {
    if (this.selectedService?.service_name == 'eru-gateway') {
      this.updateGatewayData(res);
    } else if (this.selectedService?.service_name == 'eru-functions') {
      this.updateRoutesData(res);
    } else if (this.selectedService?.service_name == 'eru-ql') {
      this.updateQlData(res);
    } else if (this.selectedService?.service_name == 'eru-auth') {
      this.updateAuthData(res);
    } else if (this.selectedService?.service_name == 'eru-files') {
      this.updateFilesData(res);
    }
  }

  updateGatewayData(res: any) {
    let obj:any = {
      'Variables':res?.delete_variables,
      'Environment Variables':res?.delete_env_variables,
      'Secrets':res?.delete_secrets,
      'Settings':res?.delete_settings,
      'Listener Rules':res?.delete_listener_rules,
      'Authorizer':res?.delete_authorizer
    };
    this.missingInImports=obj


    let newobj: any = {
      Variables: res?.new_variables,
      'Environment Variables': res?.new_env_variables,
      Secrets: res?.new_secrets,
      Settings: res?.new_settings,
      'Listener Rules': res?.new_listener_rules,
      Authorizer: res?.new_authorizer,
    };
    this.newInImports = newobj;

    let mobj: any = {
      Variables: res?.mismatch_variables,
      'Environment Variables': res?.mismatch_env_variables,
      Secrets: res?.mismatch_secrets,
      Settings: res?.mismatch_settings,
      'Listener Rules': res?.mismatch_listener_rules,
      Authorizer: res?.mismatch_authorizer,
    };
    this.mismatchInImports = mobj;






    this.compareGatewya = res;
    this.commonSubHeadingOne = 'Listener Rules';
    this.totalMissingHeadingOneImport = this.compareGatewya?.delete_listener_rules;
    this.totalNewHeadingOneImport = this.compareGatewya?.new_listener_rules;
    this.totalMismatchHeadingOneImport = this.compareGatewya?.mismatch_listener_rules;
    this.commonSubHeadingTwo = 'variables';
    this.totalMissingHeadingTwoImport =  this.compareGatewya?.delete_variables
    this.totalNewHeadingTwoImport = this.compareGatewya?.new_variables;
    this.totalMismatchHeadingTwoImport = this.compareGatewya?.mismatch_variables;
    this.commonSubHeadingThird = 'Environment Variables';
    this.totalMissingHeadingThirdImport = res?.delete_env_variables;
    this.totalNewHeadingThirdImport = res?.new_env_variables;
    this.totalMismatchHeadingThirdImport = res?.mismatch_env_variables;
    this.commonSubHeadingFourth = 'Secrets';
    this.totalMissingHeadingForthImport = res?.delete_secrets;
    this.totalNewHeadingForthImport = res?.new_secrets;
    this.totalMismatchHeadingForthImport = res?.mismatch_secrets;
    this.commonSubHeadingFifth = 'Secret Manager';
    this.totalMissingHeadingFifthImport = res?.delete_secret_manager;
    this.totalNewHeadingFifthImport = res?.new_secret_manager;
    this.totalMismatchHeadingFifthImport =res?.mismatch_secret_manager;
    this.commonSubHeadingsixth = 'Authorizer';
    this.totalMissingHeadingsixthImport = res?.delete_authorizer;
    this.totalNewHeadingSixthImport = res?.new_authorizer;
    this.totalMismatchHeadingSixthImport = res?.mismatch_authorizer;

    this.commonSubHeadingseven = 'Settings';
    this.totalMissingHeadingSevenImport = res?.delete_settings;
    this.totalNewHeadingSevenImport = res?.new_settings;
    this.totalMismatchHeadingSevenImport = res?.mismatch_settings;

  }

  updateRoutesData(res: any) {
    let obj:any = {
      'Routes':res?.delete_routes,
      'Functions':res?.delete_funcs,
      'Variables':res?.delete_variables,
      'Environment Variables':res?.delete_env_variables,
      'Secrets':res?.delete_secrets,
      'Settings':res?.delete_settings
    };
    this.missingInImports=obj

    let newobj: any = {
      Routes: res?.new_routes,
      Functions: res?.new_funcs,
      Variables: res?.new_variables,
      'Environment Variables': res?.new_env_variables,
      Secrets: res?.new_secrets,
      Settings: res?.new_settings,
    };
    this.newInImports = newobj;

    let mobj: any = {
      Routes: res?.mismatch_routes,
      Functions: res?.mismatch_funcs,
      Variables: res?.mismatch_variables,
      'Environment Variables': res?.mismatch_env_variables,
      Secrets: res?.mismatch_secrets,
      Settings: res?.mismatch_settings,
    };
    this.mismatchInImports = mobj;


    this.CompareRoutes = res;
    this.commonSubHeadingOne = 'Routes';
    this.commonSubHeadingTwo = 'Function';
    this.totalMissingHeadingOneImport =this.CompareRoutes?.delete_routes;
    this.totalMissingHeadingTwoImport = this.CompareRoutes?.delete_funcs;
    this.totalNewHeadingOneImport =this.CompareRoutes?.new_routes;
    this.totalNewHeadingTwoImport =this.CompareRoutes?.new_funcs || [];
    this.totalMismatchHeadingOneImport =this.CompareRoutes?.mismatch_routes;
    this.totalMismatchHeadingTwoImport = this.CompareRoutes?.mismatch_funcs;

    this.commonSubHeadingThird = 'Environment Variables';
    this.totalMissingHeadingThirdImport = res?.delete_env_variables;
    this.totalNewHeadingThirdImport = res?.new_env_variables;
    this.totalMismatchHeadingThirdImport = res?.mismatch_env_variables;
    this.commonSubHeadingFourth = 'Secrets';
    this.totalMissingHeadingForthImport = res?.delete_secrets;
    this.totalNewHeadingForthImport = res?.new_secrets;
    this.totalMismatchHeadingForthImport = res?.mismatch_secrets;
    this.commonSubHeadingFifth = 'Secret Manager';
    this.totalMissingHeadingFifthImport = res?.delete_secret_manager;
    this.totalNewHeadingFifthImport = res?.new_secret_manager;
    this.totalMismatchHeadingFifthImport =res?.mismatch_secret_manager;
    this.commonSubHeadingsixth = 'Authorizer';
    this.totalMissingHeadingsixthImport = res?.delete_authorizer;
    this.totalNewHeadingSixthImport = res?.new_authorizer;
    this.totalMismatchHeadingSixthImport = res?.mismatch_authorizer;

    this.commonSubHeadingseven = 'Variables';
    this.totalMissingHeadingSevenImport = res?.delete_variables;
    this.totalNewHeadingSevenImport = res?.new_variables;
    this.totalMismatchHeadingSevenImport = res?.mismatch_variables;

  }

  updateQlData(res: any) {
    let obj:any = {
      'Variables':res?.delete_variables,
      'Environment Variables':res?.delete_env_variables,
      'Secrets':res?.delete_secrets,
      'Secret Manager':res?.delete_secret_manager,
      'Settings':res?.delete_settings,
      'Queries':res?.delete_queries,
      'Data Sources':res?.delete_data_sources,
      'Tables':res?.delete_tables,
      'joins':res?.delete_joins,
      'Table Security':res?.delete_table_security,
      'Table Transformation':res?.delete_table_transformation,
    };
    this.missingInImports=obj

    let newobj: any = {
      Variables: res?.new_variables,
      'Environment Variables': res?.new_env_variables,
      Secrets: res?.new_secrets,
      'Secret Manager': res?.new_secret_manager,
      Settings: res?.new_settings,
      Queries: res?.new_queries,
      'Data Sources': res?.new_data_sources,
      Tables: res?.new_tables,
      joins: res?.new_joins,
      'Table Security': res?.new_table_security,
      'Table Transformation': res?.new_table_transformation,
    };
    this.newInImports = newobj;

    let mobj: any = {
      Variables: res?.mismatch_variables,
      'Environment Variables': res?.mismatch_env_variables,
      Secrets: res?.mismatch_secrets,
      'Secret Manager': res?.mismatch_secret_manager,
      Settings: res?.mismatch_settings,
      Queries: res?.mismatch_queries,
      'Data Sources': res?.mismatch_data_sources,
      Tables: res?.mismatch_tables,
      joins: res?.mismatch_joins,
      'Table Security': res?.mismatch_table_security,
      'Table Transformation': res?.mismatch_table_transformation,
    };
    this.mismatchInImports = mobj;



    this.commonSubHeadingOne = 'Data Sources';
    this.totalMissingHeadingOneImport = res?.delete_data_sources;
    this.totalNewHeadingOneImport = res?.new_data_sources;
    this.totalMismatchHeadingOneImport =
      res?.mismatch_data_sources;
    this.commonSubHeadingTwo = 'Joins';
    this.totalMissingHeadingTwoImport = res?.delete_joins;
    this.totalNewHeadingTwoImport = res?.new_joins;
    this.totalMismatchHeadingTwoImport = res?.mismatch_joins;
    this.commonSubHeadingThird = 'Queries';
    this.totalMissingHeadingThirdImport = res?.delete_queries;
    this.totalNewHeadingThirdImport = res?.new_queries;
    this.totalMismatchHeadingThirdImport = res?.mismatch_queries;
    this.commonSubHeadingFourth = 'Security';
    this.totalMissingHeadingForthImport =
      res?.delete_table_security;
    this.totalNewHeadingForthImport = res?.new_table_security;
    this.totalMismatchHeadingForthImport =
      res?.mismatch_table_security;
    this.commonSubHeadingFifth = 'Table Transformation';
    this.totalMissingHeadingFifthImport =
      res?.delete_table_transformation;
    this.totalNewHeadingFifthImport =
      res?.new_table_transformation;
    this.totalMismatchHeadingFifthImport =
      res?.mismatch_table_transformation;
    this.commonSubHeadingsixth = 'Tables';
    this.totalMissingHeadingsixthImport = res?.delete_tables;
    this.totalNewHeadingSixthImport = res?.new_tables;
    this.totalMismatchHeadingSixthImport = res?.mismatch_tables;
    this.commonSubHeadingseven = 'Variables';
    this.totalMissingHeadingSevenImport = res?.delete_variables;
    this.totalNewHeadingSevenImport = res?.new_variables;
    this.totalMismatchHeadingSevenImport = res?.mismatch_variables;


    this.commonSubHeadingEight = 'Environment Variables';
    this.totalMissingHeadingEightImport = res?.delete_env_variables;
    this.totalNewHeadingEightImport = res?.new_env_variables;
    this.totalMismatchHeadingEightImport = res?.mismatch_env_variables;

    this.commonSubHeadingNine = 'Secrets';
    this.totalMissingHeadingNineImport = res?.delete_secrets;
    this.totalNewHeadingNineImport = res?.new_secrets;
    this.totalMismatchHeadingNineImport = res?.mismatch_secrets;
    this.commonSubHeadingten = 'Secret Manager';
    this.totalMissingHeadingtenImport = res?.delete_secret_manager;
    this.totalNewHeadingtenImport = res?.new_secret_manager;
    this.totalMismatchHeadingtenImport =res?.mismatch_secret_manager;
    this.commonSubHeadingeleven = 'Settings';
    this.totalMissingHeadingelevenImport = res?.delete_settings;
    this.totalNewHeadingelevenImport = res?.new_settings;
    this.totalMismatchHeadingelevenImport = res?.mismatch_settings;




  }

  missingInImports:any={};
  newInImports:any={};
  mismatchInImports:any={};
  onDeletingMissingImport(key:any,heading:string){
    console.log(key,heading)
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Delete',
        message: `Are you sure, you want to delete ${key} .`,
      },
    });

    dialogRef.afterClosed().subscribe(async(result: any) => {
      if (result.event == 'Ok') {
        let body = {};
        let url: any;

        switch (this.selectedService?.service_name) {
          case 'eru-functions':
            url = this.getRoutesUrl(key, heading, 'remove')?.value + key;
            break;
          case 'eru-files':
            url = this.getFilesUrl(key, heading, 'remove')?.value + key;
            break;
          case 'eru-auth':
            url = this.getAuthUrl(key, heading, 'remove')?.value + key;
            break;
          case 'eru-ql':
            url = this.getQlUrl(key, heading, 'remove')?.value + key;
            break;
          case 'eru-gateway':
            url = this.getGatewayUrl(key, heading, 'remove')?.value + key;
            console.log(url,'url');
            break;
        }

        this.importService
          .deleteMismatch(
            this.compareAuthSettings,
            this.compareService.service_path,
            this.projectName,
            url,
            body,
            this.removeEnvironmentOption,
            this.selectedService?.service_name,
          )
          .subscribe((res: any) => {
            this.notifyService.showSuccess(res.msg, "Sucess");

            this.refresh();
          },(e:any)=>{
            this.notifyService.showError(e.error, "Error");
          });
      }
    });
  }
  updateAuthData(res: any) {
    let obj:any = {
      'Auth':res?.delete_auth,
      'Variables':res?.delete_variables,
      'Environment Variables':res?.delete_env_variables,
      'Secrets':res?.delete_secrets,
      'Secret Manager':res?.delete_secret_manager,
      'Settings':res?.delete_settings
    };
    this.missingInImports=obj

    let newobj: any = {
      Auth: res?.new_auth,
      Variables: res?.new_variables,
      'Environment Variables': res?.new_env_variables,
      Secrets: res?.new_secrets,
      'Secret Manager': res?.new_secret_manager,
      Settings: res?.new_settings,
    };
    this.newInImports = newobj;

    let mobj: any = {
      Auth: res?.mismatch_auth,
      Variables: res?.mismatch_variables,
      'Environment Variables': res?.mismatch_env_variables,
      Secrets: res?.mismatch_secrets,
      'Secret Manager': res?.mismatch_secret_manager,
      Settings: res?.mismatch_settings,
    };
    this.mismatchInImports = mobj;



    this.commonSubHeadingOne = 'Auth';
    this.totalMissingHeadingOneImport =
      this.Compareauth?.delete_auth;
    this.totalNewHeadingOneImport = this.Compareauth?.new_auth;
    this.totalMismatchHeadingOneImport =
      this.Compareauth?.mismatch_auth;

      this.commonSubHeadingTwo = 'Variables';
      this.totalMissingHeadingTwoImport = res?.delete_variables;
      this.totalNewHeadingTwoImport = res?.new_variables;
      this.totalMismatchHeadingTwoImport = res?.mismatch_variables;
      this.commonSubHeadingThird = 'Environment Variables';
    this.totalMissingHeadingThirdImport = res?.delete_env_variables;
    this.totalNewHeadingThirdImport = res?.new_env_variables;
    this.totalMismatchHeadingThirdImport = res?.mismatch_env_variables;
    this.commonSubHeadingFourth = 'Secrets';
    this.totalMissingHeadingForthImport = res?.delete_secrets;
    this.totalNewHeadingForthImport = res?.new_secrets;
    this.totalMismatchHeadingForthImport = res?.mismatch_secrets;
    this.commonSubHeadingFifth = 'Secret Manager';
    this.totalMissingHeadingFifthImport = res?.delete_secret_manager;
    this.totalNewHeadingFifthImport = res?.new_secret_manager;
    this.totalMismatchHeadingFifthImport =res?.mismatch_secret_manager;
    this.commonSubHeadingsixth = 'Settings';
    this.totalMissingHeadingsixthImport = res?.delete_settings;
    this.totalNewHeadingSixthImport = res?.new_settings;
    this.totalMismatchHeadingSixthImport = res?.mismatch_settings;
  }
  getMissingImportTotal(){
    let total = 0;
    for (let key in this.missingInImports) {
      if (this.missingInImports[key]) {
        total++;
      }
    }
    return total;
  }
  getNewImportTotal(){
    let total = 0;
    for (let key in this.newInImports) {
      if (this.newInImports[key]) {
        total++;
      }
    }
    return total;
  }

  getMismatchImportTotal(){
    let total = 0;
    for (let keys of this.Object.keys(this.mismatchInImports)) {
    if (
      this.mismatchInImports[keys] &&
      this.Object.keys(this.mismatchInImports[keys])?.length>0
    ) {
        total++;
    }
    }
    return total;
  }

  updateFilesData(res: any) {
    let obj:any = {
      'Variables':res?.delete_variables,
      'Environment Variables':res?.delete_env_variables,
      'Secrets':res?.delete_secrets,
      'Storages':res?.delete_storages,
      // 'Keys':res?.delete_keys
    };
    this.missingInImports=obj

    let newobj: any = {
      Variables: res?.new_variables,
      'Environment Variables': res?.new_env_variables,
      Secrets: res?.new_secrets,
      Storages: res?.new_storages,
      // Keys: res?.new_keys,
    };
    this.newInImports = newobj;

    let mobj: any = {
      Variables: res?.mismatch_variables,
      'Environment Variables': res?.mismatch_env_variables,
      Secrets: res?.mismatch_secrets,
      Storages: res?.mismatch_storages,
      // Keys: res?.mismatch_keys,
      'Secret Manager': res?.mismatch_secret_manager,
      'Settings': res?.mismatch_settings,
    };
    this.mismatchInImports = mobj;

    this.CompareFiles = res;
    this.commonSubHeadingOne = 'Storage';
    this.commonSubHeadingTwo = 'keys';
    this.totalMissingHeadingOneImport =
      this.CompareFiles?.delete_storages;
    this.totalMissingHeadingTwoImport = [];
    // this.totalMissingHeadingTwoImport =
    //   this.CompareFiles?.DeleteKeys;
    this.totalNewHeadingOneImport =
      this.CompareFiles?.new_storages;
    this.totalNewHeadingTwoImport = this.CompareFiles?.new_keys;
    this.totalMismatchHeadingOneImport =
      this.CompareFiles?.mismatch_storages;
    this.totalMismatchHeadingTwoImport =
      this.CompareFiles?.mismatch_keys;
      this.commonSubHeadingThird = 'Environment Variables';
      this.totalMissingHeadingThirdImport = res?.delete_env_variables;
      this.totalNewHeadingThirdImport = res?.new_env_variables;
      this.totalMismatchHeadingThirdImport = res?.mismatch_env_variables;
      this.commonSubHeadingFourth = 'Secrets';
    this.totalMissingHeadingForthImport = res?.delete_secrets;
    this.totalNewHeadingForthImport = res?.new_secrets;
    this.totalMismatchHeadingForthImport = res?.mismatch_secrets;
    this.commonSubHeadingFifth = 'Secret Manager';
    this.totalMissingHeadingFifthImport = res?.delete_secret_manager;
    this.totalNewHeadingFifthImport = res?.new_secret_manager;
    this.totalMismatchHeadingFifthImport =res?.mismatch_secret_manager;
    this.commonSubHeadingseven = 'Settings';
    this.totalMissingHeadingSevenImport = res?.delete_settings;
    this.totalNewHeadingSevenImport = res?.new_settings;
    this.totalMismatchHeadingSevenImport = res?.mismatch_settings;
    this.commonSubHeadingsixth = 'Variables';
    this.totalMissingHeadingsixthImport = res?.delete_variables;
    this.totalNewHeadingSixthImport = res?.new_variables;
    this.totalMismatchHeadingSixthImport = res?.mismatch_variables;
  }
  getTotal() {
    let valueMissingOne = this.totalMissingHeadingOneImport?.length || 0;
    let valueMissingTwo = this.totalMissingHeadingTwoImport?.length || 0;
    let valueMissingThree = this.totalMissingHeadingThirdImport?.length || 0;
    let valueMissingTfour = this.totalMissingHeadingForthImport?.length || 0;
    let valueMissingFive = this.totalMissingHeadingFifthImport?.length || 0;
    let valueMissingSix = this.totalMissingHeadingsixthImport?.length || 0;
    let valueMissingSeven = this.totalMissingHeadingSevenImport?.length || 0;
    let valueMissingEight = this.totalMissingHeadingEightImport?.length || 0;
    let valueMissingNine = this.totalMissingHeadingNineImport?.length || 0;
    let valueMissingten = this.totalMissingHeadingtenImport?.length || 0;
    let valueMissingeleven = this.totalMissingHeadingelevenImport?.length || 0;


    let valueNewOne = this.totalNewHeadingOneImport?.length || 0;
    let valueNewTwo = this.totalNewHeadingTwoImport?.length || 0;
    let valueNewThree = this.totalNewHeadingThirdImport?.length || 0;
    let valueNewfour = this.totalNewHeadingForthImport?.length || 0;
    let valueNewFive = this.totalNewHeadingFifthImport?.length || 0;
    let valueNewSix = this.totalNewHeadingSixthImport?.length || 0;
    let valueNewSeven = this.totalNewHeadingSevenImport?.length || 0;
    let valueNewEight = this.totalNewHeadingEightImport?.length || 0;
    let valueNewNine = this.totalNewHeadingNineImport?.length || 0;
    let valueNewten = this.totalMissingHeadingtenImport?.length || 0;
    let valueNeweleven = this.totalNewHeadingelevenImport?.length || 0;
    let valueMismatchOne = this.totalMismatchHeadingOneImport
      ? Object.keys(this.totalMismatchHeadingOneImport).length
      : 0;
    let valueMismatchTwo = this.totalMismatchHeadingTwoImport
      ? Object.keys(this.totalMismatchHeadingTwoImport).length
      : 0;
    let valueMismatchThree = this.totalMismatchHeadingThirdImport
      ? Object.keys(this.totalMismatchHeadingThirdImport).length
      : 0;
    let valueMismatchfour = this.totalMismatchHeadingForthImport
      ? Object.keys(this.totalMismatchHeadingForthImport).length
      : 0;
    let valueMismatchFive = this.totalMismatchHeadingFifthImport
      ? Object.keys(this.totalMismatchHeadingFifthImport).length
      : 0;
    let valueMismatchSix = this.totalMismatchHeadingSixthImport
      ? Object.keys(this.totalMismatchHeadingSixthImport).length
      : 0;
      let valueMismatchSeven = this.totalMismatchHeadingSevenImport
      ? Object.keys(this.totalMismatchHeadingSevenImport).length
      : 0;
      let valueMismatchEight = this.totalMismatchHeadingEightImport
      ? Object.keys(this.totalMismatchHeadingEightImport).length
      : 0;
      let valueMismatchNine = this.totalMismatchHeadingNineImport
      ? Object.keys(this.totalMismatchHeadingNineImport).length
      : 0;
      let valueMismatchten = this.totalMismatchHeadingtenImport
      ? Object.keys(this.totalMismatchHeadingtenImport).length
      : 0;
      let valueMismatcheleven = this.totalMismatchHeadingelevenImport
      ? Object.keys(this.totalMismatchHeadingelevenImport).length
      : 0;

    this.totalMissingImport =
      valueMissingOne +
      valueMissingTwo +
      valueMissingThree +
      valueMissingTfour +
      valueMissingFive +
      valueMissingSix +valueMissingSeven +valueMissingEight+valueMissingNine+valueMissingten+valueMissingeleven;
    this.totalNewImport =
      valueNewOne +
      valueNewTwo +
      valueNewThree +
      valueNewfour +
      valueNewFive +
      valueNewSix+valueNewSeven+valueNewEight+valueNewNine+valueNewten+valueNeweleven;
    this.totalMismatch =
      valueMismatchOne +
      valueMismatchTwo +
      valueMismatchThree +
      valueMismatchfour +
      valueMismatchFive +
      valueMismatchSix+valueMismatchSeven+valueMismatchEight+valueMismatchNine+valueMismatchten+valueMismatcheleven;
  }
  switchImageHeaderThree: any = {};
  switchImageHeaderForth: any = {};
  switchImageHeaderFifth: any = {};
  switchImageHeadersixth: any = {};
  switchImageHeaderseven: any = {};
  switchImageHeaderEight: any = {};
  switchImageHeaderten: any = {};
  switchImageHeaderNine: any = {};
  switchImageHeadereleven: any = {};
  switchImages() {
    if (this.totalMismatchHeadingOneImport) {
      Object.keys(this.totalMismatchHeadingOneImport).forEach((key) => {
        this.switchImageHeaderOne[key] = {};
        Object.keys(this.totalMismatchHeadingOneImport[key]).forEach((item) => {
          this.switchImageHeaderOne[key][item] = false;
        });
      });
    }
    if (this.totalMismatchHeadingTwoImport) {
      Object.keys(this.totalMismatchHeadingTwoImport).forEach((key) => {
        this.switchImageHeaderTwo[key] = {};
        Object.keys(this.totalMismatchHeadingTwoImport[key]).forEach((item) => {
          this.switchImageHeaderTwo[key][item] = false;
        });
      });
    }
    if (this.totalMismatchHeadingThirdImport) {
      Object.keys(this.totalMismatchHeadingThirdImport).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.totalMismatchHeadingThirdImport[key]).forEach(
          (item) => {
            this.switchImageHeaderThree[key][item] = false;
          }
        );
      });
    }
    if (this.switchImageHeaderForth) {
      Object.keys(this.switchImageHeaderForth).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderForth[key]).forEach((item) => {
          this.switchImageHeaderForth[key][item] = false;
        });
      });
    }
    if (this.switchImageHeaderFifth) {
      Object.keys(this.switchImageHeaderFifth).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderFifth[key]).forEach((item) => {
          this.switchImageHeaderFifth[key][item] = false;
        });
      });
    }
    if (this.switchImageHeadersixth) {
      Object.keys(this.switchImageHeadersixth).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeadersixth[key]).forEach((item) => {
          this.switchImageHeadersixth[key][item] = false;
        });
      });
    }
    if (this.switchImageHeaderseven) {
      Object.keys(this.switchImageHeaderseven).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderseven[key]).forEach((item) => {
          this.switchImageHeaderseven[key][item] = false;
        });
      });
    }
    if (this.switchImageHeaderEight) {
      Object.keys(this.switchImageHeaderEight).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderEight[key]).forEach((item) => {
          this.switchImageHeaderEight[key][item] = false;
        });
      });
    }
    if (this.switchImageHeaderNine) {
      Object.keys(this.switchImageHeaderNine).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderNine[key]).forEach((item) => {
          this.switchImageHeaderNine[key][item] = false;
        });
      });
    }
    if (this.switchImageHeaderten) {
      Object.keys(this.switchImageHeaderten).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeaderten[key]).forEach((item) => {
          this.switchImageHeaderten[key][item] = false;
        });
      });
    }
    if (this.switchImageHeadereleven) {
      Object.keys(this.switchImageHeadereleven).forEach((key) => {
        this.switchImageHeaderThree[key] = {};
        Object.keys(this.switchImageHeadereleven[key]).forEach((item) => {
          this.switchImageHeadereleven[key][item] = false;
        });
      });
    }
  }






  importNewInImportSection(key: any, heading: string) {
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Import',
        message: `Are you sure, you want to import ${key} .`,
      },
    });

    dialogRef.afterClosed().subscribe(async(result: any) => {
      if (result.event == 'Ok') {
        let body: any;
        let url: any;
        let urlObject: any;

        switch (this.selectedService?.service_name) {
          case 'eru-functions':
            body = this.getRoutesGroupData(key, heading);
            urlObject = this.getRoutesUrl(key, heading, 'save');
            break;
          case 'eru-files':
            body = this.getFilesGroupData(key, heading);
            urlObject = this.getFilesUrl(key, heading, 'save');
            break;
          case 'eru-auth':
            body = this.getAuthGroupData(key, heading);
            urlObject = this.getAuthUrl(key, heading, 'save');
            break;
          case 'eru-ql':
            body = this.getQlGroupData(key, heading);
            urlObject = this.getQlUrl(key, heading, 'save');
            break;
          case 'eru-gateway':
            body = this.getGatewayGroupData(key, heading);
            urlObject = this.getGatewayUrl(key, heading, 'save');
            break;
        }
        if (urlObject) {
          url = urlObject.value;
          if (body?.storage_type) {
            url += `${key}/` + body.storage_type;
          }
          else if(body?.db_name) {
            url += key

          }
          else {
            url ;
          }
        }

        this.importService
          .syncMismatch(
            this.compareAuthSettings,
            this.compareService.service_path,
            this.projectName,
            url,
            body,
            this.removeEnvironmentOption,
            this.selectedService?.service_name,

          )
          .subscribe((res: any) => {
            this.notifyService.showSuccess(res.msg, "Sucess");

            this.refresh();
          },(e:any)=>{
            this.notifyService.showError(e.error, "Error");
          });
      }
    });
  }

  mismatchSyncSection(key: any,heading:any) {
    console.log(key,heading)

    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Sync',
        message: `Are you sure, you want to sync ${key} .`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.event == 'Ok') {
        let body: any;
        let url: any;
        let urlObject: any;
        switch (this.selectedService?.service_name) {
          case 'eru-functions':
            body = this.getRoutesGroupData(key, heading);
            urlObject = this.getRoutesUrl(key, heading, 'save');
            break;
          case 'eru-files':
            body = this.getFilesGroupData(key, heading);
            urlObject = this.getFilesUrl(key, heading, 'save');
            break;
          case 'eru-auth':
            body = this.getAuthGroupData(key, heading);
            urlObject = this.getAuthUrl(key, heading, 'save');
            break;
          case 'eru-ql':
            body = this.getQlGroupData(key, heading);
            urlObject = this.getQlUrl(key, heading, 'save');
            break;
          case 'eru-gateway':
            body = this.getGatewayGroupData(key, heading);
            urlObject = this.getGatewayUrl(key, heading, 'save');
            break;
        }

        if (urlObject) {
          url = urlObject.value;
          if (body?.storage_type) {
            url += `${key}/` + body.storage_type;
          }else if(heading.toLowerCase() == 'secret manager'){
            url += body.sm_store_type;
          }

          else {
            url ;
          }
        }
        this.importService
          .syncMismatch(
            this.compareAuthSettings,
            this.compareService.service_path,
            this.projectName,
            url,
            body,
            this.removeEnvironmentOption,
            this.selectedService?.service_name,
          )
          .subscribe((res: any) => {
            this.notifyService.showSuccess(res.msg, "Sucess");

            this.refresh();
          },(e:any)=>{
            this.notifyService.showError(e.error, "Error");
          });
      }
    });
  }








   getQlGroupData(key: any, groupName: any) {
    let value: any;
    if (groupName.toLowerCase() == 'data sources') {
      value = this.projectConfig.data_sources[key];
    } else if (groupName.toLowerCase() == 'queries') {
      value = this.projectConfig.my_queries[key];
    } else if(groupName.toLowerCase() == 'settings') {
      value = this.projectConfig.project_settings
    }
    return value;
  }



  showExpanded!: boolean;
  toggelExpand: any;
  Showclick(index: any) {
    console.log(index);
    this.toggelExpand = index;
  }

  getRoutesGroupData(key: any, groupName: any) {
    let value: any;
    if (groupName.toLowerCase() == 'functions') {
      value = this.projectConfig.func_groups[key];
    } else if (groupName.toLowerCase() == 'routes') {
      value = this.projectConfig.routes[key];
    }else if (groupName.toLowerCase() == 'secrets') {
      value = this.projectConfig.secrets[key];
    } else if (groupName.toLowerCase() == 'environment variables') {
      value = this.projectConfig.env_variables[key];
    } else if (groupName.toLowerCase() == 'secret manager') {
      value = this.projectConfig.secret_manager;
    } else if (groupName.toLowerCase() == 'variables') {
      value = this.projectConfig.variables[key];
    }
    return value;
  }

  getFilesGroupData(key: any, groupName: any) {
    let value: any = null; // Initialize value to null to handle cases where projectConfig might be null
    if (this.projectConfig) { // Check if projectConfig is not null before attempting to access its properties
      if (groupName.toLowerCase() == 'keys') {
        value = this.projectConfig.aes_keys[key];
        if (value) { // Check if value is not null before attempting to access its properties
          value.bits = value.bits.toString();
        }
      } else if (groupName.toLowerCase() == 'secret manager') {
        value = this.projectConfig.secret_manager;
      } else if (groupName.toLowerCase() == 'storages') {
        value = this.projectConfig.storages[key];
      } else if (groupName.toLowerCase() == 'environment variables') {
        value = this.projectConfig.variables['env_vars'][key];
      } else if (groupName.toLowerCase() == 'variables') {
        value = this.projectConfig.variables['vars'][key];
      }
    }
    console.log(value,'value ');
    return value;
  }

  getAuthGroupData(key: any, groupName: any) {
    let value: any;
    console.log( this.projectConfig);
    if (groupName.toLowerCase() == 'auth') {
      value = this.projectConfig.auth[key];
    } else if (groupName.toLowerCase() == 'secrets') {
      value = this.projectConfig.variables.secrets[key];
    } else if (groupName.toLowerCase() == 'environment variables') {
      value = this.projectConfig.variables.env_vars[key];
    } else if (groupName.toLowerCase() == 'secret manager') {
      value = this.projectConfig.secret_manager;
    } else if (groupName.toLowerCase() == 'settings') {
      value = this.projectConfig.project_settings;
    }
    else if (groupName.toLowerCase() == 'variables') {
      value = this.projectConfig.variables['vars'][key];
    }
    return value;
  }
  getGatewayGroupData(key: any, groupName: any) {
    let value: any;
    console.log( this.projectConfig);
   if (groupName.toLowerCase() == 'secrets') {
      value = this.projectConfig.secrets[key];
    } else if (groupName.toLowerCase() == 'environment variables') {
      value = this.projectConfig.env_variables[key];
    } else if (groupName.toLowerCase() == 'secret manager') {
      value = this.projectConfig.secret_manager;
    } else if (groupName.toLowerCase() == 'settings') {
      value = this.projectConfig.settings[key];
    }
    else if (groupName.toLowerCase() == 'gvariables') {
      value = this.projectConfig.variables[key];
    }
    return value;
  }

  getRoutesUrl(key: any, groupName: any, action: any) {
    let value: any;
    if (action == 'save') {
      value = serviceSaveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    } else if (action == 'remove') {
      value = serviceRemoveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    }
    return value;
  }

  getFilesUrl(key: any, groupName: any, action: any) {
    let value: any;
    if (action == 'save') {
      value = serviceSaveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    } else if (action == 'remove') {
      value = serviceRemoveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    }
    return value;
  }

  getAuthUrl(key: any, groupName: any, action: any) {
    let value: any;
    if (action == 'save') {
      value = serviceSaveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );

    } else if (action == 'remove') {
      value = serviceRemoveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );

    }
    console.log(value,'value')
    return value;
  }
   getQlUrl(key: any, groupName: any, action: any) {
    let value: any;
    if (action == 'save') {
      value = serviceSaveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    } else if (action == 'remove') {
      value = serviceRemoveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    }
    return value;
  }

  getGatewayUrl(key: any, groupName: any, action: any) {
    let value: any;
    if (action == 'save') {
      value = serviceSaveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    } else if (action == 'remove') {
      value = serviceRemoveURLMap.find(
        (item: any) => item.key == groupName.toLowerCase()
      );
    }


    return value;
  }

  confirmAction(action: string) {
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Delete',
        message: 'Are you sure, you want to delete.',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == 'Ok') {
      }
    });
  }

refresh(){
  const isEruGateway =
  this.selectedService?.service_name == 'eru-gateway' ? true : false;
  this.importService
  .getImport(this.weburl, this.projectName, isEruGateway,this.configAuthSettings)
  .subscribe((res: any) => {
    if (res) {
      let gatewayLitenersRule;
      if (isEruGateway) {
        gatewayLitenersRule = res;
      } else {
        this.projectConfig = res?.project;
      }
      this.projectConfig = res?.project;
      this.compareService = this.data.dataValue.Services.find(
        (s: any) => {
          return s.service_name == this.selectedService.service_name;
        }
      );
      this.importService
        .postImport(
          this.compareService.service_path,
          this.projectName,
          isEruGateway ? gatewayLitenersRule : this.projectConfig,
          isEruGateway,
          this.compareAuthSettings,
          true
        )
        .subscribe((res: any) => {
          if (res) {
            this.dialog.closeAll();
            this.updateImportData(res);
            this.getTotal();
            this.switchImages();
          }
        });
    }
  });
}

}

export const serviceSaveURLMap = [
  { key: 'routes', value: 'route/save/' },
  { key: 'functions', value: 'func/save' },
  { key: 'keys', value: 'aeskey/generate/' },
  { key: 'storages', value: 'storage/save/' },
  { key: 'auth', value: 'save/auth' },
  { key: 'listenerrule', value: 'listenerrule/save' },
  { key: 'authorizer', value: 'authorizer/save' },
  { key: 'data sources', value: 'datasource/save/' },
  { key: 'queries', value: 'myquery/save' },
  { key: 'secrets', value: 'variables/savesecret'},
  { key: 'variables', value: 'variables/savevar'},
  { key: 'environment variables', value: 'variables/saveenvvar'},
  { key: 'secret manager', value: 'sm/save/'},
  { key: 'settings', value: 'settings/save'}
];

export const serviceRemoveURLMap = [
  { key: 'routes', value: 'route/remove/' },
  { key: 'functions', value: 'func/remove/' },
  { key: 'storages', value: 'storage/remove/' },
  { key: 'auth', value: 'remove/auth/' },
  { key: 'listenerrule', value: 'listenerrule/remove/' },
  { key: 'authorizer', value: 'authorizer/remove/' },
  { key: 'data sources', value: 'datasource/remove/' },
  { key: 'queries', value: 'myquery/remove/' },
  { key: 'secrets', value: 'variables/removesecret/'},
  { key: 'variables', value: 'variables/removevar/'},
  { key: 'environment variables', value: 'variables/removeenvvar/'},
  { key: 'secret manager', value: 'sm/remove/'},
  { key: 'settings', value: 'settings/remove/'}
];

