import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { interval } from 'rxjs/internal/observable/interval';
import { from, shareReplay, tap } from 'rxjs';
import { OtpComponent } from './components/otp/otp.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private AUTH = 'auth';
  private USERMGMT = 'usermgmt';
  private LOGIN = '/eru/eru/login';
  private REGISTER = '/eru/func/register_user';
  private USER_INFO = '/eru/eru/userinfo';
  private FETCH_TOKEN = '/eru/eru/fetchtokens';
  private LOGOUT = '/eru/eru/logout';
  private USER_FETCH = 'USER_FETCH';
  private FETCH_ME = 'FETCH_ME';
  private CHANGE_PASSWORD = 'CHANGE_PASSWORD';
  private SET_NEW_USER_PASSWORD = 'SET_NEW_USER_PASSWORD';
  private Token: string = '';
  private Access_Token: string = '';
  private readonly REFRESH_TOKEN: string = 'refreshToken';
  private User: any;
  onUserUpdate: Subject<any> = new Subject();

  constructor(private http: HttpClient, public router: Router,private dialogBox:MatDialog) {}

  validateUser(email: string, password: string, action: string) {
    const data = {
      action: action,
      username: email,
      password: password,
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  loginUser(email: string, password: string) {
    const data = {
      username: email,
      password: password,
    };
    return this.http.post(environment.auth_url + this.LOGIN, data);
  }
  registerUser(
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    mobile: number
  ) {
    const data = {
      docs: {
        user_email: email,
        password: password,
        user_first_name: first_name,
        user_last_name: last_name,
        user_mobile: mobile
      },
    };
    console.log('here im');
    return this.http.post(environment.routes_url + this.REGISTER, data);
  }

  fetchUser(access_token: string, idToken: any) {
    const headers = { Authorization: idToken };
    const data = {
      // action: this.FETCH_ME,
      // username: username
      access_token: access_token,
    };
    return this.http.post(`${environment.auth_url}${this.USER_INFO}`, data, {
      headers,
    });
  }

  public get user() {
    return this.User;
  }

  public setUser(user: any) {
    this.User = user;
    localStorage.setItem('id', user.id);
    this.onUserUpdate.next(user);
  if(!user.attributes.email_verified || !user.attributes.mobile_verified){
            const dialog = this.dialogBox.open(OtpComponent, {
            data: user.attributes,
            // disableClose:true
          });
  dialog.afterClosed().subscribe((res)=>{
    console.log(res)
  })
  }else{
    this.router.navigate(['eru-wrapper/dashboard']);
  }
  }

  public get token() {
    return this.Token;
  }

  clearTokens() {
    this.Token = '';
    this.Access_Token = '';
  }

  public setTokens(data: any) {
    this.Token = data.id_token;
    this.Access_Token = data.access_token;
    localStorage.setItem('refreshToken', data.refresh_token);
    //
  }

  getTokens(refresh_token: string,id:string): Observable<any> {
    const data = {
      refresh_token: refresh_token,
      id:id
    };
    return this.http
      .post(environment.auth_url + this.FETCH_TOKEN, data)
      .pipe(shareReplay());
  }

  public refreshToken(minutes: number) {
    return new Observable<any>((subscriber) => {
      interval(1000 * 60 * minutes).subscribe((x) => {
        const refreshToken: any = localStorage.getItem(this.REFRESH_TOKEN);
        const id: any = localStorage.getItem('id');
        //
        this.getTokens(refreshToken,id).subscribe((response) => {
          subscriber.next(response);
        });
      });
    });
  }

  public startTokenUpdateTimer(time: number) {
    this.refreshToken(time).subscribe((data) => {
      this.setTokens(data);
    });
  }

  public chnagePassword(oldPassword: any, newPassword: any) {
    const data = {
      action: this.CHANGE_PASSWORD,
      oldpassword: oldPassword,
      newpassword: newPassword,
      access_token: this.Access_Token,
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  public setNewUserPassword(
    username: any,
    newpassword: any,
    newusersession: any
  ) {
    const data = {
      action: this.SET_NEW_USER_PASSWORD,
      username: username,
      password: newpassword,
      session: newusersession,
    };
    return this.http.post(environment.url + this.AUTH, data);
  }

  logoutUser() {
    const refreshToken: any = localStorage.getItem('refreshToken');
    const data = {
      refresh_token: refreshToken,
    };
    const headers = { authorization: this.Token };
    return this.http.delete(`${environment.auth_url}${this.LOGOUT}`, {
      headers: headers,
      body: data,
    });
  }

  getSSO_URL(): Observable<any> {
    return this.http.get<any>(
      `${environment.auth_url}/eru/ms/getssourl?state=${window.location.hostname}/login`,
    );
  }
  loginMicrosoft(code: string, request_id: string) {
    return this.http
      .post<any>(
        `${environment.auth_url}/eru/ms/login`,
        {
          request_id,
          code,
        },
      )
      .pipe(
        tap((response) => {
          this.setTokens(response)
          this
          .fetchUser(response.access_token, response.id_token)
          .subscribe((res: any) => {
            localStorage.setItem('user', JSON.stringify(res));
            this.setUser(res);
          });
        }),
      );
  }
  private childWindow: Window | null = null;
  private intervalId!: ReturnType<typeof setTimeout>;
  initiateSSO(
    response: any,
  ): Observable<string | null | unknown> {
    return from(
      new Promise<string | null>((resolve) => {
        const requestId = response.requestId;
        const windowWidth = 500;
        const windowHeight = 500;
        const left = (window.screen.width - windowWidth) / 2;
        const top = (window.screen.height - windowHeight) / 2;
        const childWindowFeatures = `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;
        this.childWindow = window.open(
          response.url,
          '_blank',
          childWindowFeatures,
        );
        this.intervalId = setInterval(() => {
          try {
            if (this.childWindow && this.childWindow.closed === false) {
              const childWindowURL = this.childWindow.location.href;
              if (childWindowURL.includes('#code=')) {
                const regex = /#code=([^&]+)/;
                const match = childWindowURL.match(regex);
                let codeParam = '';
                if (match && match[1]) {
                  const codeValue = match[1];
                  codeParam = codeValue;
                } else {
                  codeParam = '';
                }
                this.childWindow.close();
                clearInterval(this.intervalId);
                resolve(codeParam);
              } else if (childWindowURL.includes('#error=')) {
                console.error('Error:', 'something went wrong.');
                this.childWindow.close();

                clearInterval(this.intervalId);
                resolve(null);
              }
            } else if (this.childWindow && this.childWindow.closed === true) {
              clearInterval(this.intervalId);
              resolve(null);
            }
          } catch (e) {}
        }, 500);
      }),
    );
  }
  getVerifyCode(body: any): Observable<any> {
    return this.http.post(
      `${environment.base_url}/eru/eru/getverifycode${
        !environment.production ? '?silent=true' : ''
      }`,
      body,
    );
  }
  getConfirmCode(body: any): Observable<any> {
    return this.http.post(
      `${environment.base_url}/eru/eru/checkverifycode`,
      body,
    ).pipe(tap((res:any)=>{this.setTokens(res);
       this
      .fetchUser(res.access_token, res.id_token)
      .subscribe((res: any) => {
        localStorage.setItem('user', JSON.stringify(res));
        this.setUser(res);
      });}));
  }

}
