<nav class="navbar navbar-expand-lg navbar-component" *ngIf="!hideElement">
	<!-- eruG -->
	<!-- <a class="navbar-brand cursor-pointer" *ngIf="!fromEruWrapper" (click)="onHomeClick()">eru</a> -->
	<a class="navbar-brand cursor-pointer" >
		<!-- <img class="img-eru-logo" src="../../../../assets/images/logo/eruGAuth.svg" alt="">
		eruG -->
		<!-- <img class="img-eru-logo" src="../../../../assets/images/logo/eru_white.svg" /> -->
		<img style="height:40px" class="img-eru-logo" src="../../../../assets/images/logo/EruIconWhite.svg" />

	</a>
	<div
		type="button"
		class="hamburger"
		[ngClass]="{ active: istoggleActive }"
		(click)="togglesideBar()"
	>
		<span class="line"></span>
		<span class="line"></span>
		<span class="line"></span>
	</div>
	<div class="sidebar-nav" [ngClass]="{ open: isSideBarActive}">
		<div class="col-lg-3 mLeft" *ngIf="!fromEruWrapper && !fromEruFiles && !fromEruAuth && !fromEruRoutes && !fromEruGateway && !fromEruQl">
			<mat-list role="list" class="text-center  wrapper-menus">
				<ng-container *ngFor="let item of menus;index as i">
					<ng-container *ngIf="!item.childs; else childViewtemplate">
						<mat-list-item role="listitem" (selectionChange)="selectionChanged(i)" [class.active]="selectedItem === i">
							<div
								class="d-flex flex-row align-items-end"
								(click)="gotoLink(item, false, i, null)"
							>
								<img [src]="item.icon" alt="" />
								<span class="nav-label selected">{{ item.label }}</span>
							</div>
						</mat-list-item>
					</ng-container>
					<ng-template #childViewtemplate>
						<mat-list-item role="listitem">
							<div
								class="d-flex flex-row align-items-end"
								style="font-size: 14px"
								(click)="openSubMenu(item)"
							>
								<img [src]="item.icon" alt="" />
								<span id="dev" class="nav-label color" (click)="textBold()">
									<span *ngIf="showBold" style="font-weight: bold;"> {{ item.label }} </span>
									<span *ngIf="!showBold"> {{ item.label }} </span>
								</span>
								<mat-icon
									class="expand_more red-icon"
									*ngIf="!item.expanded"
									>expand_more</mat-icon
								>
								<mat-icon class="expand_more red-icon" *ngIf="item.expanded"
									>expand_less</mat-icon
								>
							</div>
						</mat-list-item>
						<div *ngIf="item.expanded">
							<mat-list-item *ngFor="let submenu of item.childs; index as j"
							(selectionChange)="selectionChanged(j)" [class.active]="selectedChild === j">
								<div
									id="base"
									class="accordinlist"
									(click)="gotoLink(submenu, true, j, item)"
								>
									<span class="selected">{{ submenu.label }}</span>
								</div>
							</mat-list-item>
						</div>
					</ng-template>
				</ng-container>
			</mat-list>	
		</div> 
		<div class="col-lg-3 mLeft" *ngIf="fromEruWrapper && !fromEruFiles">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='dashboard'}"
								(click)="gotoDashboard()"
							>
								<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
								<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
								<span class="nav-label selected">Dashboard</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='projects'}"
								(click)="gotoProjects()"
							>
								<img *ngIf="link!=='projects'" src="../../../../assets/images/icons/wrapper-projects.svg" alt="">
								<img *ngIf="link==='projects'" src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
								<span class="nav-label selected">Projects</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='users'}"
								(click)="gotoUser()"
							>
								<img *ngIf="link!=='users'" src="../../../../assets/images/icons/wrapper-users.svg" alt="">
								<img *ngIf="link==='users'" src="../../../../assets/images/icons/wrapper-users-active.svg" alt="">
								<span class="nav-label selected">Users</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-3 mLeft" *ngIf="!fromEruWrapper && fromEruFiles">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='dashboard'}"
								(click)="gotoDashboardFiles()"
							>
								<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
								<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
								<span class="nav-label selected">Dashboard</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='files'}"
								(click)="gotoFiles()"
							>
								<img *ngIf="link!=='files'" src="../../../../assets/images/icons/file.svg" alt="">
								<img *ngIf="link==='files'" src="../../../../assets/images/icons/file-hover.svg" alt="">
								<span class="nav-label selected">Storage</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='key'}"
								(click)="gotoKeys()"
							>
								<img *ngIf="link!=='key'" src="../../../../assets/images/icons/key.svg" alt="">
								<img *ngIf="link==='key'" src="../../../../assets/images/icons/key-hover.svg" alt="">
								<span class="nav-label selected">Keys</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='variables'}"
								(click)="gotoVariables()"
							>
								<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
								<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
								<span class="nav-label selected">Variables</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>

				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-3 mLeft" *ngIf="!fromEruWrapper && fromEruRoutes">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='dashboard'}"
					(click)="gotoRoutesDashboard()"
				>
					<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
					<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
					<span class="nav-label selected">Dashboard</span>
				</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='routes-list'}"
					(click)="gotoRoutes()"
				>
					<img *ngIf="link!=='routes-list'" src="../../../../assets/images/icons/erug-routes-icon-black.svg" alt="">
					<img *ngIf="link==='routes-list'" src="../../../../assets/images/icons/erug-routes-icon-red.svg" alt="">
					<span class="nav-label selected">Routes</span>
				</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='functions'}"
					(click)="gotoFunctions()"
				>
					<img *ngIf="link!=='functions'" src="../../../../assets/images/icons/erug-functions-square-black.svg" alt="">
					<img *ngIf="link==='functions'" src="../../../../assets/images/icons/erug-functions-square-red.svg" alt="">
					<span class="nav-label selected">Functions</span>
				</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
					class="d-flex flex-row align-items-center cursor-pointer"
					[ngClass]="{'active-link':link==='variables'}"
					(click)="gotoRouteVariables()"
				>
					<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates.png" alt="" />
					<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
					<span class="nav-label selected">Variables</span>
				</div>
						</mat-list-item>
					</ng-container>
					<hr>

				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-3 mLeft" *ngIf="!fromEruWrapper && fromEruGateway">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='dashboard'}"
								(click)="gotoGatewayDashboard()"
							>
								<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
								<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
								<span class="nav-label selected">Dashboard</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='authorizer-list'}"
								(click)="gotoAuthorizers()"
							>
								<img *ngIf="link!=='authorizer-list'" src="../../../../assets/images/icons/wrapper-projects.svg" alt="">
								<img *ngIf="link==='authorizer-list'" src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
								<span class="nav-label selected">Authorizers</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='rule-list'}"
								(click)="gotoRules()"
							>
								<img *ngIf="link!=='rule-list'" src="../../../../assets/images/icons/wrapper-users.svg" alt="">
								<img *ngIf="link==='rule-list'" src="../../../../assets/images/icons/wrapper-users-active.svg" alt="">
								<span class="nav-label selected">Listener Rules</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-3 mLeft" *ngIf="!fromEruWrapper && fromEruQl">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='overview'}"
								(click)="eruqlOverview()"
							>
								<img *ngIf="link==='overview'" src="../../../../assets/images/side-nav/home.svg" alt="" />
								<img *ngIf="link!=='overview'" src="../../../../assets/images/side-nav/home.svg" alt="" />
								<span class="nav-label selected">Overview</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='database'}"
								(click)="qlDashboard()"
							>
								<img *ngIf="link!=='database'" src="../../../../assets/images/wrapper/ql.svg" alt="">
								<img *ngIf="link==='database'" src="../../../../assets/images/wrapper/ql.svg" alt="">
								<span class="nav-label selected">Database</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='my-queries'}"
								(click)="myQueries()"
							>
								<img *ngIf="link!=='my-queries'" src="../../../../assets/images/wrapper/my-query.svg" alt="">
								<img *ngIf="link==='my-queries'" src="../../../../assets/images/wrapper/my-query.svg" alt="">
								<span class="nav-label selected">My Queries</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='setting'}"
								(click)="qlSettings()"
							>
								<img *ngIf="link!=='setting'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
								<img *ngIf="link==='setting'" src="../../../../assets/images/side-nav/setting2_visit.png" alt="">
								<span class="nav-label selected">Settings</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>		
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='variables'}"
								(click)="qlVariables()"
							>
								<img *ngIf="link!='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="" />
								<img *ngIf="link=='variables'" src="../../../../assets/images/icons/variables-templates-active.png" alt="">
								<span class="nav-label selected">Variables</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-3 mLeft" *ngIf="fromEruAuth">
			<mat-list role="list" class="text-center wrapper-menus">
				<ng-container>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='dashboard'}"
								(click)="gotoAuthDashboard()"
							>
								<img *ngIf="link==='dashboard'" src="../../../../assets/images/icons/wrapper-dashsboard-active.svg" alt="" />
								<img *ngIf="link!=='dashboard'" src="../../../../assets/images/icons/wrapper-dashboard.svg" alt="" />
								<span class="nav-label selected">Dashboard</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='gateway'}"
								(click)="gotoGateway()"
							>
							<img *ngIf="link!='gateway'" src="../../../../assets/images/icons/gateway.svg" alt="" />
								<img *ngIf="link=='gateway'" src="../../../../assets/images/icons/gateway-active.svg" alt="">
								<span class="nav-label selected">Gateway</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem">
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='authorizer'}"
								(click)="gotoAuthorizer()"
							>
							<img *ngIf="link!=='authorizer'" src="../../../../assets/images/icons/authorizer.svg" alt="" />
							<img *ngIf="link=='authorizer'" src="../../../../assets/images/icons/authorizer-active.svg" alt="">
								<span class="nav-label selected">Authorizer</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
					<ng-container>
						<mat-list-item role="listitem" class="listitem" >
							<div
								class="d-flex flex-row align-items-center cursor-pointer"
								[ngClass]="{'active-link':link==='message-template'}"
								(click)="gotoMessageTemplates()"
							>
								<img *ngIf="link!='message-template'" src="../../../../assets/images/icons/message-templates.svg" alt="" />
								<img *ngIf="link=='message-template'" src="../../../../assets/images/icons/message-template-active.svg" alt="">
								<span class="nav-label selected">Message Templates</span>
							</div>
						</mat-list-item>
					</ng-container>
					<hr>
				</ng-container>
			</mat-list>
		</div>
		<div class="col-lg-9 mRight" (click)="closeSide()"></div>	
	</div>

	<!-- navigation links -->
	<div class="collapse navbar-collapse flex-row" *ngIf="!showMe && !fromEruWrapper">
		<!-- <ul class="navbar-nav mr-20">
			<li class="nav-item">
				<div class="pname">Project: 
					<span class="home-link ml-5">Pegasis</span>
					<div class="icons">							
						<div class="photo-container">
						  <a>
							<img src="../../../../assets/images/icons/uil_refresh.svg" />
							<span>Refresh Project</span>
						  </a>
						</div>
					</div>
				</div>
			</li>
			<mat-divider vertical="true"></mat-divider>
		</ul> -->
		<!-- <ng-container *ngIf="project">
			<mat-form-field
				class="nav-item"
				appearance="fill"
				class="project-dropdown"
			>
				<mat-select
					placeholder="Select Database"
					[(value)]="selectedProject"
					(selectionChange)="projectChangehandler($event)"
				>
					<mat-option
						*ngFor="let option of projects"
						[value]="option"
					>
						{{ option }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="dbOptions && dbOptions.length > 0">
			<mat-form-field
				class="nav-item"
				appearance="fill"
				class="project-dropdown"
			>
				<mat-select
					placeholder="Select Database"
					(selectionChange)="dbChangehandler($event)"
				>
					<mat-option
						*ngFor="let option of dbOptions"
						[value]="option"
					>
						{{ option.DbAlias }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</ng-container> -->
	</div>

	<!-- profile -->
	<div class="collapse navbar-collapse flex-row-reverse">
		
		<ul class="navbar-nav">			
			<ng-container *ngIf="!fromEruWrapper">
				<!-- <li class="nav-item pname" style="padding-top: 6px;">
					<div class="ename-style">
						<mat-form-field appearance="outline" >
							<mat-select placeholder="Select Project Name" [(ngModel)]="selectedProject" (selectionChange)="onProjectChange($event)">
							  <mat-option *ngFor="let item of projectList" [value]="item.value">
								{{item.name}}
							  </mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</li> -->
				<li class="nav-item ">
					<div class="fontColorWhite fw-400 f-14"><img src="../../../../assets/images/icons/newproject.svg" style="margin-right: 5px;" /> Project:  <span class="fw-700">{{projectName}}</span></div>
				</li>
			</ng-container>
			
			<ng-container *ngIf="!fromEruWrapper">
				<!-- <li class="nav-item pname" style="padding-top: 6px;">
					<div class="ename-style">
						<mat-form-field appearance="outline" >
							<mat-select placeholder="Select Environment Name" (selectionChange)="onEnvChange($event)">
							  <mat-option *ngFor="let item of envList" [value]="item.value">
								{{item.name}}
							  </mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</li> -->
				<li class="nav-item addborder" >
					<div class="fontColorWhite fw-400 f-14"><img src="../../../../assets/images/icons/programming.svg" style="margin-right: 5px;" /> Environment: <span class="fw-700">{{environmentName}}</span></div>
				</li>
			</ng-container>
			<ng-container *ngIf="fromEruWrapper || fromEruFiles || fromEruAuth">
				<li class="nav-item">
					<div class="tools-btn">
						<img src="../../../../assets/images/icons/tools.svg" alt="">
						Tools
					</div>
				</li>
			</ng-container>
			<ng-container *ngIf="!showMe && !fromEruWrapper && !fromEruFiles && !fromEruAuth">
				<!-- <li class="nav-item">
					<button mat-stroked-button>Create project</button>
				</li> -->
				<!-- <li class="nav-item">
					<span class="home-link" (click)="onHomeClick()">Home</span>
				</li>
				<mat-divider vertical="true"></mat-divider> -->
				<li class="nav-item">
					<a class="nav-link" href="#">Docs</a>
				</li>
				<!-- <li class="nav-item">
					<a class="nav-link" href="#">Learn</a>
				</li> -->
				<!-- <li class="nav-item">
					<img
						src="../../../../assets/images/logo/discord 1.svg"
						alt=""
					/>
				</li> -->
				<li class="nav-item">
					<img
						src="../../../../assets/images/logo/github 2.svg"
						alt=""
					/>
				</li>
			</ng-container>

			<mat-divider vertical="true"></mat-divider>

			<li class="nav-item notification">
				<mat-icon
					matBadge="23"
					matBadgeSize="small"
					matBadgePosition="after"
					class="material-icons-outlined mat-icon-notfication"
				>
					notifications_none</mat-icon
				>
			</li>
			<li class="nav-item">
				
				<button mat-flat-button  [matMenuTriggerFor]="menu">
					{{loginUser}} <mat-icon>expand_more</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="onLogout()">Log Out</button>
				</mat-menu>
				
			</li>
		</ul>
	</div>
</nav>
