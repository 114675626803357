<div class="erug-services">

    <!-- <div *ngFor="let env of projectEnvironments; let e = index">
        <h2 class="f-14 fw-400">{{project?.project_name}} {{env.env_name}}</h2>
        <div *ngFor="let dev of developmentNumber;let i =index">
            <div *ngIf="i>0&&i<developmentNumber.length" class="dashed-line pb-10"></div>
            <div class="row d-flex align-items-center">
                <div class="col-md-8">
                    <div class="row pb-20">
                        <div class="col-md-4">
                            <p>eru services</p>
                            
                            <mat-form-field appearance="outline" class="w100">
                                <mat-select placeholder="Select"
                                (change)="onInputChange($event.target,i,'development_service')">
                                    
                                    <mat-option *ngFor="let item of services" [value]="item">
                                        <span [innerHTML]="item.image"></span>
                                        <span class="px-2"> {{ item.label }}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-8">
                            <p>Path</p>
                            <mat-form-field appearance="outline" class="">
                                <input
                                    matInput
                                    placeholder="URL"
                                    (change)="onInputChange($event.target,i,'development_path')"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 del-btn" *ngIf="developmentNumber.length>1"  align="end">
                    <img src="../../../../assets/images/icons/delete.png" (click)="delete('dev',i)">
                </div>
            </div>
        </div>
        <div class="pt-10 pb-20 col-md-8" style="padding-left: 0;">
            <button class="add-btn" (click)="addMore('dev')">Add more</button>
        </div>
    </div> -->




    <!-- <div class="pt-20" *ngFor="let env of projectEnvironments; let e = index;">
        <h2 class="fw-400 f-14">{{currentProject.project_name}} {{env.env_name}}</h2>
        <form [formGroup]="phaseForm">
            <div formGroupName="phaseExecutions">
                <div formArrayName="PRE">
                    <div  *ngFor="let phase of phaseArray.controls; let i = index;" 
                    [formGroupName]="i">
                        <div class="pb-10 dashed-line" *ngIf="i>0&&i<productionNumber.length"></div>
                        <div class="row d-flex align-items-center">
                            <div class="col-md-8 pb-10">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>eruG Service</p>
                                        
                                        <mat-form-field appearance="outline" class="w100">
                                            <mat-select placeholder="Select" formControlName="phaseType" 
                                            (change)="onInputChange($event.target,i,'product_service')">
                                                <mat-option *ngFor="let item of services" [value]="item">
                                                    <span [innerHTML]="item.image"></span>
                                                    <span class="px-2"> {{ item.label }}</span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-8">
                                        <p>Path</p>
                                        <mat-form-field appearance="outline" class="">
                                            <input formControlName="phaseValue"
                                                matInput
                                                placeholder="URL"
                                                (change)="onInputChange($event.target,i,'production_path')"
                                            />
                                        </mat-form-field>
                    
                                    </div>
                                </div>
                            </div>
                          
                             <div class="col-md-4 del-btn" *ngIf="productionNumber.length>1" (click)="removeOrClear(i)" align="end">
                                <img src="../../../../assets/images/icons/delete.png" alt="">
                            </div>
                        </div>
                    </div>               
                </div>
                <div class="pt-10 pb-20 col-md-8" style="padding-left: 0;">
                    <button class="add-btn"  (click)="addMorePhase()" type="button">Add more</button>
                </div>
            </div>
        </form>
    </div> -->



     <div class="pt-20">
        <form [formGroup]="serviceFormGroup" >
            <div formArrayName="environments">
                <div *ngFor="let environment of environments().controls; let i=index">
                    <h2 class="fw-400 f-14">{{project ? project.project_name : ''}} {{getEnvironmentName(environment)}}</h2>
                    <div [formGroupName]="i" style="padding-left: 35px;">
                        <div>
                            <!-- <div class="pb-10 dashed-line" *ngIf="i>0&&i<productionNumber.length"></div> -->
                            <div class="row d-flex align-items-center" formArrayName="service">
                                <div *ngFor="let servicegroup of servicegroup(i)?.controls; let j=index">
                                    <div class="row" [formGroupName]="j">
                                        <div class="col-md-4 project-style darkgrey-outline form-label w300">
                                            <p>eruG Service</p>
                                            
                                            <mat-form-field appearance="outline" class="w100">
                                                <mat-select placeholder="Select" formControlName="selectservice"
                                                >
                                                    <mat-option *ngFor="let item of services" [value]="item.value">
                                                        <span [innerHTML]="item.image"></span>
                                                        <span class="px-2"> {{ item.label }}</span>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-8 project-style darkgrey-outline form-label w500">
                                            <p>Path</p>
                                            <mat-form-field appearance="outline" class="">
                                                <input formControlName="path"
                                                    matInput
                                                    placeholder="URL"
                                                    autocomplete="off"
                                                    
                                                />
                                            </mat-form-field>
                        
                                        </div>
                                        <div class="col-md-3 del-btn deleteicon"   align="end">
                                            <img (click)="deleteService(environment, servicegroup, i, j)" src="../../../../assets/images/icons/delete.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>  
                    </div>
                    <div class="pt-10 pb-20 col-md-8" style="padding-left: 25px;">
                            <button class="add-btn" (click)="addService(i)">Add more</button>
                    </div>    
                    
                </div>
                <!-- <div class="pt-10 pb-20 col-md-8" style="padding-left: 0;">
                    <button class="add-btn" (click)="addService(i)">Add more</button>
                </div> -->
            </div>
        </form>
    </div>
    
    <div class="btnArea">
        <mat-dialog-actions align="end" style="position: relative;">     
        <div>
            <!-- <button class="btn f-14 fw-500 btn-back" 
            (click)='onBack()'>
                Back
            </button> -->
            <button
            class="btn f-14 fw-500 ml-10 btn-save"
            type="submit"
            (click)='onSubmit()'
            >
                Save Service
            </button>
        </div>
      </mat-dialog-actions>
    </div>
</div>