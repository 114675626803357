import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EruWrapperComponent } from './eru-wrapper.component';
import { DashboardHomeComponent } from 'src/app/dashboard/components/home/home.component';
import { UsersComponent } from './components/users/users.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { NewProjectComponent } from 'src/app/modules/eru-wrapper/components/new-project/new-project.component';
import { WrapperDashboardComponent } from './components/wrapper-dashboard/wrapper-dashboard.component';
import { ImportComponent } from './components/import/import.component';
import { RepositoryComponent } from './components/repository/repository.component';
import { EruRepoComponent } from 'src/app/modules/eru-wrapper/components/eru-repo/eru-repo.component';

const routes: Routes = [
  { 
    path: '', 
    component: EruWrapperComponent, 
    children :[
      // {
      //   path:'dashboard',
      //   component: DashboardHomeComponent
      // },
      {
        path:'dashboard',
        component: WrapperDashboardComponent
      },
      {
        path:'users',
        component: UsersComponent
      },
      {
        path:'projects',
        component: ProjectsComponent
      },
      {
        path:'repository',
        component: RepositoryComponent
      },
      {
        path:'create-project',
        component: NewProjectComponent
      },
      {
        path:'edit-project/:project_name',
        component: NewProjectComponent
      },
      // {
      //   path:'',
      //   redirectTo:'dashboard'
      // }
      {
        path:'projects/import',
        component:ImportComponent,
      },
      {
        path:'repo',
        component:EruRepoComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EruWrapperRoutingModule { }
