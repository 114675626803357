import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '../modules/shared/services/crypto.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  decryptedToken:any;
  token!:string;
  decryptedUrl:any;
  projectName:any;
  environmentName:any;
  serviceName : any = '';
  constructor(
    private authService : AuthService,
    private router : Router,
    private cryptoService: CryptoService,
    private _route: ActivatedRoute)
    {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.checkingRoutes()
    const isCompareServiceRequest = this.checkServiceRequestURL(request.url);
  if (request.url.includes('#authorization')) {
    request = request.clone({
    url: request.url.replace('#authorization', '')
    });
}else{
  if (!this.requestToExclude(request.url) && this.decryptedUrl.indexOf('eru-ql') == -1
    && this.decryptedUrl.indexOf('eru-gateway') == -1 && this.decryptedUrl.indexOf('eru-files') == -1
    && this.decryptedUrl.indexOf('eru-auth') == -1 && this.decryptedUrl.indexOf('eru-functions') == -1 &&
    !isCompareServiceRequest) {
      if (!this.authService.token) {
        const refreshToken: any = localStorage.getItem('refreshToken');
        const id: any = localStorage.getItem('id');
        if (refreshToken && id) {
          this.authService.getTokens(refreshToken,id).subscribe((response :any) => {
            this.authService.setTokens(response);
          });
        }
        else{
          this.router.navigateByUrl('/auth');
        }
      }

      const token = this.authService.token;

      if (token) {
        // If we have a token, we set it to the header
        request = request.clone({
          setHeaders: { Authorization: `${token}` },
        });
      }

      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              //this.showAlertPopup();
            }
          }
        )
      );
    }
    else if(!this.requestToExclude(request.url) && (this.decryptedUrl.indexOf('eru-ql') > 0
    || this.decryptedUrl.indexOf('eru-gateway') > 0 || this.decryptedUrl.indexOf('eru-files') > 0
    || this.decryptedUrl.indexOf('eru-auth') > 0 || this.decryptedUrl.indexOf('eru-functions') > 0 || isCompareServiceRequest)){
    this.serviceName = this.decryptedUrl.indexOf('eru-ql') > 0 ? 'eru-ql' :
                    this.decryptedUrl.indexOf('eru-gateway') > 0 ? 'eru-gateway' :
                    this.decryptedUrl.indexOf('eru-files') > 0 ? 'eru-files' :
                    this.decryptedUrl.indexOf('eru-auth') > 0 ? 'eru-auth' :
                    this.decryptedUrl.indexOf('eru-functions') > 0 ? 'eru-functions' : this.serviceName;

    const projectName = this.projectName;
    const envName = this.environmentName;
      const eruQlProject: any = localStorage.getItem(`${projectName}_${envName}_${this.serviceName}`);

      if (eruQlProject) {
        if(isCompareServiceRequest){
          const url = request.url.split('?');
          request = request.clone({
            url : url[0]
          })
        }
        const eruQlProjectParsed = JSON.parse(eruQlProject);
        if(eruQlProjectParsed.securityType == 'none') {
          if(eruQlProjectParsed){
            request = this.handleBasicAuth(
              request,
              next,
              eruQlProjectParsed.service_path)
            }
        }
        else if(eruQlProjectParsed.securityType == 'apikey'){
          if(eruQlProjectParsed){
            const headerKey = eruQlProjectParsed.api_key_name;
            const headerValue = eruQlProjectParsed.api_key_value;
            request = this.handleBasicAuth(
              request,
              next,
              eruQlProjectParsed.service_path,
              headerKey,
              headerValue)
          }
        }
        else{
          if(eruQlProjectParsed){
            const headerKey = eruQlProjectParsed.auth_header_name;
            const headerValue = eruQlProjectParsed.auth_header_value;
            const token = headerValue ? eruQlProjectParsed.eruQlAuth[headerValue] : ''
            if(token)
            {
              request = request.clone({
                url:request.url.indexOf('https') == -1 ? `${eruQlProjectParsed.service_path}${request.url}` : request.url,
                setHeaders: { [headerKey] : `${token}` },
              });
            }
          }
        }
      }
      else{
        this.router.navigateByUrl('/auth');
      }

      return next.handle(request).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              //this.showAlertPopup();
            }
          }
        )
      );
    }
}


    return next.handle(request);
  }

  handleBasicAuth(
    request: HttpRequest<any>,
    next: HttpHandler,
    service_path : string = '',
    headerKey : string = '',
    headerValue : string = '' ){
    if (!this.authService.token) {
      const refreshToken: any = localStorage.getItem('refreshToken');
      const id: any = localStorage.getItem('id');
      if (refreshToken) {
        this.authService.getTokens(refreshToken,id).subscribe((response :any) => {
          this.authService.setTokens(response);
        });
      }
    }

    const token = this.authService.token;

    if (token) {
      if(service_path){
        if(headerKey && headerValue){
          request = request.clone({
            url:request.url.indexOf('https') == -1 ? `${service_path}${request.url}` : request.url,
            setHeaders: { Authorization : `${token}`,  [headerKey] : `${headerValue}`},
          });
        }
        else{
          request = request.clone({
            url:request.url.indexOf('https') == -1 ? `${service_path}${request.url}` : request.url,
            setHeaders: { Authorization : `${token}` },
          });
        }
      }else{
        // If we have a token, we set it to the header
        request = request.clone({
          setHeaders: { Authorization: `${token}` },
        });
      }
    }

    return request;
    // return next.handle(request).pipe(
    //   tap(
    //     () => {},
    //     (err: any) => {
    //       if (err instanceof HttpErrorResponse) {
    //         if (err.status !== 401) {
    //           return;
    //         }
    //         //this.showAlertPopup();
    //       }
    //     }
    //   )
    // );
  }

  requestToExclude(resquestedUrl: any) {
    if (
      resquestedUrl.indexOf('fetchtokens') > 0 ||
      resquestedUrl.indexOf('login') > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  // showAlertPopup() {
  //   if (this.dialogRef === undefined) {
  //     this.dialogRef = this.dialogService.showInfoPopup(Messages.ERROR_TITLE,Messages.SESSION_TIMEOUT);

  //     this.dialogRef.afterClosed().subscribe(result => {
  //       if (result && result.clickedOkay) {
  //         this.localStorageService.clearAll();
  //         this.router.navigateByUrl('login');
  //         this.dialogRef = undefined;

  //       }
  //     });
  //   }
  //}
  checkingRoutes(){
    if(this._route.snapshot.queryParams.q){
      const decryptedParams = this.cryptoService.decrypt(this._route.snapshot.queryParams.q);
      const splitUrl= this.router.url.split('?')[0];
      this.decryptedUrl = splitUrl+decryptedParams;
      if (decryptedParams) {
        const keyValuePairs = decryptedParams?.split('&');
        const paramsArray = keyValuePairs?.map((pair: string) => {
          const [key, value] = pair?.split('=');
          return { key, value };
        });
        if (paramsArray) {
          for (const param of paramsArray) {
            if (param.key === 'project') {
              this.projectName = param.value;
            } else if (param.key === 'env') {
              this.environmentName = param.value;
            }
          }}}
      return this.decryptedUrl
    }else{
      this.decryptedUrl= this.router.url;
      return this.decryptedUrl
    }
  }

  checkServiceRequestURL(url : any){
    if(url.indexOf('?s=') != -1)
    {
      const splitUrl = url.split('?')[1];
      const params = splitUrl.split('&');
      this.serviceName = params[0].split('=')[1];
      this.projectName = params[1].split('=')[1];
      this.environmentName = params[2].split('=')[1];
      return true;
    }
    return false;
  }
}
