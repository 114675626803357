<div class="projects-component">
    <div class="table-container mt-4" style="height: 360px; overflow: auto">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table-border">

        <ng-container matColumnDef="expandTable">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg"></th>
            <td mat-cell *matCellDef="let element" style="width:73px">
            <div>
                <img class="cursor-pointer"
                (click)="getSecondryTableData(element); element.showSecondImage = !element.showSecondImage;toggleRowExpansion(element)"
                [src]="
                    !element.showSecondImage
                    ? '../../../../../assets/images/icons/right-arrow.png'
                    : '../../../../../assets/images/icons/down-arrow-expand.png'
                " />
            </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Projects">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg project">Project</th>
            <td mat-cell *matCellDef="let element">{{ element.project_name }}</td>
        </ng-container>


        <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg user">Users</th>
            <td mat-cell *matCellDef="let element">
                {{getUserCount(element)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="environment">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg environment">Environment</th>
            <td mat-cell *matCellDef="let element" >
              {{element.env_name}}
            </td>
        </ng-container>


        <ng-container matColumnDef="Services">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg service">Services</th>
            <td mat-cell *matCellDef="let element">
                <!-- {{element.invite_status === 'PENDING' ? '' : getServiceCount(element.environment)}} -->
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="lightpink-bg status">Invitation Status</th>
          <td mat-cell *matCellDef="let element">

          </td>
      </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="lightpink-bg action">Action</th>
            <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.is_owner">
                <div class="img-content">
                <div class="icons">
                    <div class="photo-container icon-style">
                    <a class="edit-img cursor-pointer">
                        <img src="../../../../assets/images/icons/edit1.svg" >
                        <span>Edit</span>
                    </a>
                    </div>
                </div>
                </div>

            </ng-container>
            <ng-container *ngIf="element.invite_status === 'PENDING'">
                <a class="cursor-pointer" style="margin-right: 30px">
                <img src="../../../../../assets/images/icons/email_accept.png" alt="" srcset="" />
                </a>
                <a class="cursor-pointer" style="margin-right: 30px">
                <img src="../../../../../assets/images/icons/mail_reject.svg" alt="" />
                </a>
            </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandTables">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div [hidden]="!element.showSecondImage">
                <table mat-table [dataSource]="getSecondryTableData(element)" class="table-border" style="border: none;">
                <ng-container matColumnDef="expandTable">
                    <td mat-cell *matCellDef="let element1" class="expandTableWidth"></td>
                </ng-container>

                <ng-container matColumnDef="Projects">
                    <td mat-cell *matCellDef="let element1" class="expandTableProjectsWidth"></td>
                </ng-container>


                <ng-container matColumnDef="users">
                    <td mat-cell *matCellDef="let element1" class="expandTableUsersWidth">
                    {{element1.user}}</td>
                </ng-container>

                <ng-container matColumnDef="environment">
                    <td mat-cell *matCellDef="let element1" class="expandTableEnvironmentWidth">

                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <td mat-cell *matCellDef="let element1" class="expandTableEnvironmentWidth">
                    {{ element1.invite_status }}
                  </td>
              </ng-container>


                <ng-container matColumnDef="Services">
                    <td class="text-center text-orange cursor-pointer " mat-cell *matCellDef="let element1"
                    class="expandTableServicesWidth">
                    <div class="d-flex">
                       {{element1.services}}
                    </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <td mat-cell *matCellDef="let element1" style="display: flex;">
                     <ng-container  *ngIf="element1.invite_status =='ACCEPTED'">

                        <div class="img-content">
                            <div class="icons">
                                <div class="photo-container icon-style">
                                <a class="edit-img cursor-pointer">
                                    Revoke
                                    <span>Revoke</span>
                                </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    </td>

                </ng-container>
                <tr mat-row *matRowDef="let row; columns: secondTableDisplayColumns"></tr>
                </table>
            </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"  class="lightpink-bg"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: ['expandTables']" [class.hide]="!element.showSecondImage"
            style="padding: 0 !important"></tr>
        </table>
    </div>
</div>
