import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import{environment} from '../../../../../environments/environment'
import { BehaviorSubject, from, take } from 'rxjs';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { ActivatedRoute } from '@angular/router';
import { CryptoService } from '../../services/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private http : HttpClient,private projectsService:ProjectsService) { }
  childWindow:any=null;
  intervalId:any=null
 projectName=''
saveConfigBody  =
  {
    "claims_key": {
        "Key": ""
    },
    "TokenSecret": {
        "HeaderKey": "",
        "SecretAlgo": "",
        "SecretKey": "",
        "JwkUrl": "",
        "Audience": [],
        "Issuer": []
    },
    "ProjectGitRepo": {
        "RepoName": "",
        "BranchName": "",
        "AuthMode": "",
        "AuthKey": ""
    }
}

  getAesKey (project:any) {
    console.log(this.projectName)
    return this.http.get(environment.url+`/store/project/generateaeskey/${project}`,{})
  }
  setAesKey(value:any){
    this.saveConfigBody.claims_key.Key=value;
    return this.saveConfigBody
  }
  setGitRepo(value :any){
   let gitRepo = this.saveConfigBody.ProjectGitRepo;
   gitRepo.RepoName = value.repoName;
   gitRepo.BranchName = value.BranchName;
   gitRepo.AuthMode = value.authMode;
   gitRepo.AuthKey = value.authKey;

  return this.saveConfigBody
  }
  setTokenSecret(value:any){

let tokenSecret = this.saveConfigBody.TokenSecret;
    tokenSecret.HeaderKey= value.headerKey;
    tokenSecret.SecretAlgo= value.secretAlgo;
    tokenSecret.SecretKey = value.secretKey
    tokenSecret.JwkUrl= value.jwkUrl;
    tokenSecret.Audience = value.audience;
    tokenSecret.Issuer = value.issuer;
  return this.saveConfigBody

  }

  saveConfig(body:any){
    console.log(body)
  return this.http.post(environment.url+`/store/project/config/save/${this.projectName}`,body)

  }
  newSaveConfig(body:any,projectName:any,isGateway:boolean){
    if(isGateway){
      return this.http.post(environment.url+`/store/settings/save`,body)
    }else{
      return this.http.post(environment.url+`/store/${projectName}/settings/save`,body)

    }
  }

  saveRepo(body:any){

  return this.http.post(environment.routes_url+`/eru/func/save_repo`,body)

  }

  getSecretManager (project:any,fromGateWay:boolean) {
    console.log(this.projectName)
    if(fromGateWay){
      return this.http.get(environment.url + `/store/gsm/list`);

    }else{
      // /store/devapps/sm/list
      return this.http.get(environment.url + `/store/${project}/sm/list`);
    }
  }

  getSecretManagerQL (project:any) {
    console.log(this.projectName)
    return this.http.get(environment.url + `/store/${project}/gsm/list`);
  }

  getProjectSettingsQL (project:any) {
    return this.http.get(environment.url + `/store/settings`);
  }
  getProjectConfigGateway () {
    return this.http.get(environment.url + `/store/config`);
  }
  getFetchRepo (project:any ,from:any) {
    if (from === 'eru-gateway') {
      return this.http.get(environment.url + `/store/grepo/list`);

    }else{
      return this.http.get(environment.url + `/store/${project}/repo/list`);

    }
  }

  getFetchKms (project:any ,from:any) {
    if (from === 'eru-gateway') {
      return this.http.get(environment.url + `/store/gkms/list`);

    }else{
      return this.http.get(environment.url + `/store/${project}/kms/list`);

    }
  }
  removeKms (project:any ,from:any,id:any) {
    if (from === 'eru-gateway') {
      return this.http.delete(environment.url + `/store/gkms/remove/${id}`);

    }else{
      return this.http.delete(environment.url + `/store/${project}/kms/remove/${id}`);

    }
  }
  getFetchEvents (project:any ,from:any) {
    if (from === 'eru-gateway') {
      return this.http.get(environment.url + `/store/gevent/list`);

    }else{
      return this.http.get(environment.url + `/store/${project}/event/list`);

    }
  }
  removeEvents (project:any ,from:any,id:any) {
    if (from === 'eru-gateway') {
      return this.http.delete(environment.url + `/store/gevent/remove/${id}`);

    }else{
      return this.http.delete(environment.url + `/store/${project}/event/remove/${id}`);

    }
  }
  saveKms (project:any ,from:any,body:any) {
    if (from === 'eru-gateway') {
      return this.http.post(environment.url + `/store/gkms/save/${body.kms_store_type}`,body);

    }else{
      return this.http.post(environment.url + `/store/${project}/kms/save/${body.kms_store_type}`,body);

    }
  }
  saveEvents (project:any ,from:any,body:any) {
    if (from === 'eru-gateway') {
      return this.http.post(environment.url + `/store/gevent/save/${body.event_type}`,body);

    }else{
      return this.http.post(environment.url + `/store/${project}/gevent/save/${body.event_type}`,body);

    }
  }

  commitRepo (project:any,from:any) {
    if (from === 'eru-gateway') {
         return this.http.post(environment.url + `/store/grepo/commit`,{});


    }else{
      return this.http.post(environment.url + `/store/${project}/repo/commit`,{});


    }
  }

  saveSecretManager(body:any) {
    console.log(body)
    // if (body.from === 'eru-ql') {
    //   return this.http.post(
    //     environment.base_url + `/store/${body.projectName}/sm/save/${body.payload.sm_store_type}`,
    //     body.payload
    //   );
    // } else if (body.from === 'eru-auth') {
    //   return this.http.post(
    //     environment.auth_url + `/store/${body.projectName}/sm/save/${body.payload.sm_store_type}`,
    //     body.payload
    //   );
    // } else
    if (body.from === 'eru-gateway') {
      return this.http.post(
        environment.url + `/store/gsm/save/${body.payload.sm_store_type}`,
        body.payload
      );
    } else {
      return this.http.post(
        environment.url + `/store/${body.projectName}/sm/save/${body.payload.sm_store_type}`,
        body.payload
      );
    }
  }


ApiCalled=false;
installationId:any= new BehaviorSubject(null)
  initiategithubApp(
    url: any,
    envID:any,
    callback:any
  ) {

    return from(
      new Promise<string | null>((resolve) => {
        // const requestId = response.requestId;
        const windowWidth = 500;
        const windowHeight = 500;
        const left = (window.screen.width - windowWidth) / 2;
        const top = (window.screen.height - windowHeight) / 2;
        const childWindowFeatures = `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;

        this.childWindow = window.open(
         url,
          '_blank',
          childWindowFeatures,
        );
        this.intervalId = setInterval(() => {
          try {
            if (this.childWindow && this.childWindow.closed === false) {
              const childWindowURL = this.childWindow.location.href;

              if (childWindowURL.includes('repo?code=') && childWindowURL.includes('&installation_id=')) {
                const regex = /&installation_id=([^&]+)/;
                const match = childWindowURL.match(regex);
                let installationId = '';

                if (match && match[1]) {
                  installationId = match[1];
                  const data = {
                    "env_id": envID,
                    "installation_id":installationId
                  }

                  if(!this.ApiCalled){

                    this.ApiCalled=true


                    this.projectsService.saveInstallationId(data).pipe(take(1)).subscribe((res:any) => {
                      console.log('saveInstallationId',res[0].update_project_envs.err=='-')
                      if(res[0].update_project_envs.err=='-'){
                       this.installationId.next(data.installation_id)

                            this.childWindow.close();
                          clearInterval(this.intervalId);
                          resolve(null)
                      }
                    })
                  }

                }

              }
            } else if (this.childWindow && this.childWindow.closed === true) {

              clearInterval(this.intervalId);
              resolve(null);
            }
          } catch (e) { }
        }, 500);
      }),
    );
  }


}




