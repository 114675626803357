<ng-container *ngIf="!showHtml">
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchDefault>
      <div class="selectServiceContainer">
        <div class="heading align-items-center d-flex justify-content-between headingPadding">
          <div class="titleStyle">Import Route</div>
          <img class="pointer" mat-dialog-close src="../../../../../assets/images/icons/close_icon.png" alt="" />
        </div>
        <div class="container-padding pr-32">
          <ng-container *ngTemplateOutlet="selectService"></ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'serviceLoading'">
      <div class="serviceLoadingContainer">
        <div class="heading align-items-center d-flex justify-content-between headingPadding">
          <div class="titleStyle">{{ popupHeading }}</div>
          <img class="pointer" mat-dialog-close src="../../../../../assets/images/icons/close_icon.png" alt="" />
        </div>
        <div class="container-padding">
          <ng-container *ngTemplateOutlet="serviceLoading"></ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'import'">
      <div class="importContainer">
        <div class="heading align-items-center d-flex justify-content-between headingPadding">
          <div class="titleStyle">{{ popupHeading }}</div>
          <img class="pointer" mat-dialog-close src="../../../../../assets/images/icons/close_icon.png" alt="" />
        </div>
        <div class="container-padding">
          <ng-container *ngTemplateOutlet="import"></ng-container>
        </div>
      </div>
      <div style="padding-right: 1084px"></div>
    </ng-container>
  </ng-container>
  <ng-template #selectService>
    <div style="padding-right: 14px !important">
      <div class="selectSection darkgrey-outline">
        <p class="mb-4 selectTextStyle">Select the environment</p>
        <mat-form-field appearance="outline" class="w100">
          <mat-select [(ngModel)]="selectedEnvironment" (ngModelChange)="environmentSelectionChange()">
            <mat-option *ngFor="let item of evnironment" [value]="item"
              [hidden]="item.environment === removeEnvironmentOption">
              <span class="px-2"> {{ item.environment }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="selectSection darkgrey-outline mt-10">
        <p class="mb-4 selectTextStyle">Select the service</p>
        <mat-form-field appearance="outline" class="w100">
          <mat-select [(ngModel)]="selectedService" (ngModelChange)="serviceSelectionChange()">
            <mat-option *ngFor="let item of environmentName" [value]="item">
              <span class="px-2"> {{ item.service_name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="text-end mt-20">
      <button mat-dialog-close class="btn f-14 fw-500 btn-cancel">Cancel</button>
      <button mat-dialog-close class="btn f-14 fw-500 ml-10 btn-import" (click)="openImport()"
        [disabled]="showImportButton">
        Import
      </button>
    </div>
  </ng-template>
  <ng-template #serviceLoading>
    <div class="serviceLoadingSubContainer">
      <div class="d-flex justify-center">
        <img src="../../../../../assets/images/icons/loading_icon.png" alt="" />
      </div>
      <div class="loadingtextStyle mt-20 text-center">
        Importing {{ popupHeading }}. Please wait...
      </div>
    </div>
  </ng-template>
  <ng-template #import>
    <div class="importSubContainer">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <p>Missing in import <span *ngIf="totalMissingImport > 0">
                  ({{totalMissingImport}})
                </span></p>
          </ng-template>
          <div class="tabContainerBorder tab-subContainersPadding MismatchImport">
            {{totalMissingHeadingThirdImport}}
            <div *ngIf="totalMissingHeadingOneImport">
              <div class="d-flex justify-content-between align-items-center">
                <div class="selectTextStyle">{{commonSubHeadingOne}} <span
                    *ngIf="totalMissingHeadingOneImport?.length>0">({{(totalMissingHeadingOneImport)?.length}})</span>
                </div>
                <!-- <div class="loadingtextStyle colorRed d-flex align-items-center">
                                  <img src="../../../../../assets/images/icons/delete1.svg" class="mr-8" />
                                  Delete All
                              </div> -->
              </div>
              <div class="d-flex justify-content-between align-items-center containerStyle mt-10"
                *ngFor="let item of totalMissingHeadingOneImport">
                <div class="selectTextStyle darkColor">{{item}}</div>
                <div class="loadingtextStyle colorRed d-flex align-items-center pr-110">
                  <div class="photo-container">
                    <img src="../../../../../assets/images/icons/delete1.svg" class="mr-8" />
                    <span>Delete</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <p>New in Import <span *ngIf="totalNewImport >0">
                  ({{totalNewImport}})
                </span></p>
          </ng-template>
          <div class="tabContainerBorder tab-subContainersPadding MismatchImport">
            <div *ngIf="totalNewHeadingOneImport">
              <div class="d-flex justify-content-between align-items-center">
                <div class="selectTextStyle">{{commonSubHeadingOne}} <span
                    *ngIf="totalNewHeadingOneImport?.length>0">({{(totalNewHeadingOneImport)?.length}})</span></div>
                <!-- <div class="loadingtextStyle colorRed d-flex align-items-center">
                                  <img src="../../../../../assets/images/icons/import_icon.png" class="mr-8" />
                                  Import All
                              </div> -->
              </div>
              <div class="d-flex justify-content-between align-items-center containerStyle mt-10"
                *ngFor="let items of totalNewHeadingOneImport">
                <div class="selectTextStyle darkColor">{{items}}</div>
                <div class="loadingtextStyle colorRed d-flex align-items-center pr-110">
                  <div class="photo-container">
                    <img src="../../../../../assets/images/icons/import_icon.png" class="mr-8" />
                    <span>Import</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-35" *ngIf="totalMissingHeadingTwoImport">
              <div class="d-flex justify-content-between align-items-center">
                <div class="selectTextStyle">{{commonSubHeadingTwo}} <span
                    *ngIf="totalMissingHeadingTwoImport?.length>0">({{(totalNewHeadingTwoImport)?.length}})</span></div>
                <!-- <div class="loadingtextStyle colorRed d-flex align-items-center">
                                  <img src="../../../../../assets/images/icons/import_icon.png" class="mr-8" />
                                  Import All
                              </div> -->
              </div>
              <div class="d-flex justify-content-between align-items-center containerStyle mt-10"
                *ngFor="let Items of totalNewHeadingTwoImport">
                <div class="selectTextStyle darkColor">{{Items}}</div>
                <div class="loadingtextStyle colorRed d-flex align-items-center pr-110">
                  <div class="photo-container" >
                    <img src="../../../../../assets/images/icons/import_icon.png" class="mr-8" />
                    <span>Import</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <p> Mismatch in Import <span *ngIf="totalMismatch">
                  ({{totalMismatch}})
                </span></p>
          </ng-template>
          <div class="tabContainerBorder tab-subContainersPadding MismatchImport" style="padding-bottom: 0 !important">
            <div class="mb-30">
              <div class="selectTextStyle mb-12">{{commonSubHeadingOne}} <span
                  *ngIf="(totalMismatchHeadingOneImport) && (Object.keys(totalMismatchHeadingOneImport).length>0)">({{Object.keys(totalMismatchHeadingOneImport)?.length}})</span>
              </div>
              <ng-container *ngIf="totalMismatchHeadingOneImport" style="margin-top: 20px;">
                <mat-accordion class="example-headers-align "
                  *ngFor="let key of Object.keys(this.totalMismatchHeadingOneImport)">
                  <mat-expansion-panel class="mt-10" expanded="false">
                    <mat-expansion-panel-header style="border-bottom: 1px solid #c4c4c4;">
                      <mat-panel-title>
                        <div class="selectTextStyle darkColor">{{key}}</div>
                      </mat-panel-title>
                      <mat-panel-description class="align-items-center justify-end">
                        <img (click)="mismatchSyncSection(key,'')" src="../../../../../assets/images/icons/reload.svg"
                          class="mr-8" />
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let item of Object.keys(this.totalMismatchHeadingOneImport[key])">
                      <mat-expansion-panel hideToggle (opened)="switchImageHeaderOne?.[key][item] = true"
                        (closed)="switchImageHeaderOne?.[key][item] = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="selectTextStyle darkColor">{{item}}</div>
                          </mat-panel-title>
                          <mat-panel-description class="align-items-center justify-end mr-0">
                            <div class="loadingtextStyle colorRed d-flex align-items-center pr-60">
                              <div class="photo-container">
                                <!-- <img src="../../../../../assets/images/icons/reload.svg" class="mr-8" /> -->
                                <!-- <span>Sync</span> -->
                              </div>
                            </div>
                            <img class="pr-20" [src]="
                            switchImageHeaderOne?.[key]?.[item]
                            ? '../../../../../assets/images/icons/unexpand_sub.png'
                            : '../../../../../assets/images/icons/expand_add.png'
                        " />
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div style="padding: 13px 40px; border-top: 1px dashed #c4c4c4">
                          <div class="selectTextStyle colorBlack">Path :{{
                            this.totalMismatchHeadingOneImport?.[key][item]?.path }}</div>
                          <div class="selectTextStyle colorBlack">Add : {{
                            this.totalMismatchHeadingOneImport?.[key][item]?.add_str }}</div>
                          <div class="selectTextStyle colorBlack">Delete : {{
                            this.totalMismatchHeadingOneImport?.[key][item]?.del_str }}</div>
                        </div>
                      </mat-expansion-panel>
                    </ng-container>


                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </div>
            <div class="mb-30">
              <div class="selectTextStyle mb-12">{{commonSubHeadingTwo}} <span
                  *ngIf="(totalMismatchHeadingTwoImport) && (Object.keys(totalMismatchHeadingTwoImport).length>0)">
                  ({{Object.keys(totalMismatchHeadingTwoImport)?.length}})</span></div>
              <ng-container *ngIf="totalMismatchHeadingTwoImport">
                <mat-accordion class="example-headers-align mt-10"
                  *ngFor="let key of Object.keys(this.totalMismatchHeadingTwoImport)">
                  <mat-expansion-panel expanded="false" class="mt-10">
                    <mat-expansion-panel-header style="border-bottom: 1px solid #c4c4c4">
                      <mat-panel-title>
                        <div class="selectTextStyle darkColor">{{key}}</div>
                      </mat-panel-title>
                      <mat-panel-description class="align-items-center justify-end">
                        <!-- <div class="loadingtextStyle colorRed d-flex align-items-center pr-80">
                                                <img src="../../../../../assets/images/icons/reload.svg" class="mr-8" />
                                                Sync All
                                            </div> -->
                        <img  src="../../../../../assets/images/icons/reload.svg"
                          class="mr-8" />
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let item of Object.keys(this.totalMismatchHeadingTwoImport[key])">
                      <mat-expansion-panel hideToggle (opened)="switchImageHeaderTwo?.[key][item] = true"
                        (closed)="switchImageHeaderTwo?.[key][item] = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="selectTextStyle darkColor">{{item}}</div>
                          </mat-panel-title>
                          <mat-panel-description class="align-items-center justify-end mr-0">
                            <div class="loadingtextStyle colorRed d-flex align-items-center pr-60">
                              <div class="photo-container">
                                <!-- <img src="../../../../../assets/images/icons/reload.svg" class="mr-8" /> -->
                                <!-- <span>Sync</span> -->
                              </div>
                            </div>
                            <img class="pr-20" [src]="
                            switchImageHeaderTwo?.[key]?.[item]
                            ? '../../../../../assets/images/icons/unexpand_sub.png'
                            : '../../../../../assets/images/icons/expand_add.png'
                        " />
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div style="padding: 13px 40px; border-top: 1px dashed #c4c4c4">
                          <div class="selectTextStyle colorBlack">Path :{{
                            this.totalMismatchHeadingTwoImport?.[key][item]?.path }}</div>
                          <div class="selectTextStyle colorBlack">Add : {{
                            this.totalMismatchHeadingTwoImport?.[key][item]?.add_str }}</div>
                          <div class="selectTextStyle colorBlack">Delete : {{
                            this.totalMismatchHeadingTwoImport?.[key][item]?.del_str }}</div>
                        </div>
                      </mat-expansion-panel>
                    </ng-container>


                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="showHtml">
  <h2 class="f-22 fw-600 mb-0">Imports</h2>
  <div class="mt-20 d-flex justify-content-end align-items-center">
    <button class="text-white"  mat-flat-button color="primary" mat-button (click)="goBack()">
      Back
    </button>
  </div>
  <div class="importSubContainer">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <p>Missing in import <span >
                ({{getMissingImportTotal()||0}})
              </span></p>
        </ng-template>
        <div class="tabContainerBorder tab-subContainersPadding MismatchImport">


          <div *ngFor="let keys of Object.keys(missingInImports);let i = index" >
            <div class="d-flex justify-content-between align-items-center">
              <div class="selectTextStyle"  *ngIf="missingInImports[keys]?.length>0">{{keys}} <span
                  *ngIf="missingInImports[keys]?.length>0">({{(missingInImports[keys])?.length}})</span>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center containerStyle mt-10"
              *ngFor="let item of missingInImports[keys]||[]">
              <div class="selectTextStyle darkColor">{{item}}</div>
              <div class="loadingtextStyle colorRed d-flex align-items-center pr-110">
                <div class="photo-container" (click)="onDeletingMissingImport(item,keys)" style="cursor: pointer;">
                  <img src="../../../../../assets/images/icons/delete1.svg" class="mr-8" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
          <p>New in Import <span>
                ({{getNewImportTotal() || 0}})
              </span></p>
        </ng-template>
        <div class="tabContainerBorder tab-subContainersPadding MismatchImport">
          <div
          *ngFor="let keys of Object.keys(newInImports);let i = index" >
            <div class="d-flex justify-content-between align-items-center"
            *ngIf="newInImports[keys]?.length>0"
            >
              <div class="selectTextStyle"

              >{{keys}} <span
                  *ngIf="newInImports[keys]?.length>0">({{(newInImports[keys])?.length}})</span></div>
            </div>
            <div class="d-flex justify-content-between align-items-center containerStyle mt-10"
              *ngFor="let items of newInImports[keys]">
              <div class="selectTextStyle darkColor">{{items}}</div>
              <div class="loadingtextStyle colorRed d-flex align-items-center pr-110">
                <div class="photo-container" (click)="importNewInImportSection(items,keys)" style="cursor: pointer;">
                  <img src="../../../../../assets/images/icons/import_icon.png" class="mr-8" />
                  <span>Import</span>
                </div>
              </div>
            </div>
          </div></div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <p> Mismatch in Import <span *ngIf="totalMismatch">
                ({{getMismatchImportTotal() || 0}})
              </span></p>
        </ng-template>

        <div class="tabContainerBorder tab-subContainersPadding MismatchImport" style="padding-bottom: 0 !important">
          <div class=""
            *ngFor="let keys of Object.keys(mismatchInImports);let i = index"
            >
            <div class="mb-30"
            *ngIf="(mismatchInImports[keys]) &&(Object.keys(mismatchInImports[keys]).length>0)">
            <div class="selectTextStyle mb-12"

            >{{keys}} <span
                *ngIf="(mismatchInImports[keys]) && (Object.keys(mismatchInImports[keys]).length>0)">
                ({{Object.keys(mismatchInImports[keys])?.length}})</span>
            </div>
            <ng-container *ngIf="mismatchInImports[keys]" style="margin-top: 20px;">
              <mat-accordion class="example-headers-align "
                *ngFor="let key of Object.keys(mismatchInImports[keys])">
                <mat-expansion-panel class="mt-10" expanded="false">
                  <mat-expansion-panel-header style="border-bottom: 1px solid #c4c4c4;">
                    <mat-panel-title>
                      <div class="selectTextStyle darkColor">{{key}}</div>
                    </mat-panel-title>
                    <mat-panel-description class="align-items-center justify-end" style="cursor: pointer;">
                      <img (click)="mismatchSyncSection(key,keys)" src="../../../../../assets/images/icons/reload.svg"
                        class="mr-8" />
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let item of Object.keys(mismatchInImports[keys][key])">
                    <mat-expansion-panel hideToggle (opened)="switchImageHeaderOne?.[key][item] = true"
                      (closed)="switchImageHeaderOne?.[key][item] = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="selectTextStyle darkColor">{{item}}</div>
                        </mat-panel-title>
                        <mat-panel-description class="align-items-center justify-end mr-0">
                          <div class="loadingtextStyle colorRed d-flex align-items-center pr-60">
                            <div class="photo-container">
                            </div>
                          </div>
                          <img class="pr-20" [src]="
                          switchImageHeaderOne?.[key]?.[item]
                          ? '../../../../../assets/images/icons/unexpand_sub.png'
                          : '../../../../../assets/images/icons/expand_add.png'
                      " />
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div style="padding: 13px 40px; border-top: 1px dashed #c4c4c4">
                        <div class="selectTextStyle colorBlack">Path :{{
                          mismatchInImports[keys]?.[key][item]?.path }}</div>
                        <div class="selectTextStyle colorBlack">Add : {{
                          mismatchInImports[keys]?.[key][item]?.add_str }}</div>
                        <div class="selectTextStyle colorBlack">Delete : {{
                          mismatchInImports[keys]?.[key][item]?.del_str }}</div>
                      </div>
                      </mat-expansion-panel>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
            </div>

          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
