import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'Eru';
  hideElement: boolean = true;
  isLogin: boolean | undefined;
  hideElementNav: boolean = true;
  showMeNav: boolean = true;

  constructor(private router: Router) {}

  ngOnInit() {
    console.log('Version 1.0.1');

    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('refreshToken');
        if(token == null || token == undefined){
         window.location.href='/'
        }

  }


    });
  }
}
