<div class="settings">
    <div>
        <h2 class="f-14 fw-500 mb-0 text-darkgrey">Danger Zone</h2>
        <div class="row mt-20 alignement">
            <div class="col-9 col-md-9 delete-style">
                <div class="row">
                    <span class="fw-500">Delete this project</span>
                    <span>Once you delete a project, there is no going back. Please be certain</span>
                </div>
            </div>
            <div class="col-3 col-md-3" style="text-align: end;">
                <button mat-dialog-close class="btn f-14 fw-500 ml-10 btn-invite"
                (click)="deleteProject()">
                    Delete this project.
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="btnArea">
        <mat-dialog-actions align="end" style="position: relative;">     
        <div>
            <button
            class="btn f-14 fw-500 ml-10 btn-save"
            type="submit">
                Save
            </button>
        </div>
      </mat-dialog-actions>
    </div> -->
</div>