// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  auth_url: 'https://eruauth.dev.erutech.io',
  base_url: 'https://eruql.dev.erutech.io',
  routes_url: 'https://erufunc.dev.erutech.io',
  gateway_url: '',
  wrapperUrl:'https://erufunc.dev.erutech.io',
  key : "f2555ea9b05bd409d",
};

// gateway_url: 'https://eruql.devapp.erutech.io',
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
