<div class="new-project-component">
	<div class="container-fluid pb-20">
		<h2 id="head" class="f-22 fw-600 mb-20">{{labelName}}</h2>
		<mat-tab-group [selectedIndex]="selectedIndex">
			<mat-tab>
				<ng-template mat-tab-label>
					<svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M31.0684 3.75024C31.0664 3.75024 31.0645 3.75 31.0625 3.75H22.5625V2.8125C22.5625 1.26172 21.3008 0 19.75 0H12.25C10.6992 0 9.4375 1.26172 9.4375 2.8125V3.75H0.9375C0.415527 3.75 0 4.17773 0 4.6875V25.3125C0 26.8633 1.26172 28.125 2.8125 28.125H29.1875C30.7383 28.125 32 26.8633 32 25.3125V4.70728C32 4.70605 32 4.70483 32 4.70361C31.9641 4.08789 31.5854 3.75366 31.0684 3.75024ZM11.3125 2.8125C11.3125 2.29565 11.7332 1.875 12.25 1.875H19.75C20.2668 1.875 20.6875 2.29565 20.6875 2.8125V3.75H11.3125V2.8125ZM29.7617 5.625L26.8503 14.3589C26.7227 14.7424 26.3652 15 25.9612 15H20.6875V14.0625C20.6875 13.5447 20.2678 13.125 19.75 13.125H12.25C11.7322 13.125 11.3125 13.5447 11.3125 14.0625V15H6.03882C5.63477 15 5.27734 14.7424 5.14966 14.3589L2.23828 5.625H29.7617ZM18.8125 15V16.875H13.1875V15H18.8125ZM30.125 25.3125C30.125 25.8293 29.7043 26.25 29.1875 26.25H2.8125C2.29565 26.25 1.875 25.8293 1.875 25.3125V10.4646L3.37085 14.9519C3.75415 16.1023 4.82642 16.875 6.03882 16.875H11.3125V17.8125C11.3125 18.3303 11.7322 18.75 12.25 18.75H19.75C20.2678 18.75 20.6875 18.3303 20.6875 17.8125V16.875H25.9612C27.1736 16.875 28.2458 16.1023 28.6292 14.9519L30.125 10.4646V25.3125Z"
							fill="#FF341D" />
					</svg>
					<p>Define Project</p>
				</ng-template>
				<app-define-project (createProjectHandler)="createProjectHandler($event)"
					(updateProjectHandler)="updateProjectHandler($event)"></app-define-project>
			</mat-tab>

			<mat-tab *ngIf="fromEruWrapper" [disabled]="isDisabled">
				<ng-template mat-tab-label>
					<img src="../../../../assets/images/icons/select-eruG-services.svg"
						style="width: 32px;height:32px;">
					<p>Select Services</p>
				</ng-template>
				<app-erug-services [project]="project" (createProjectHandler)="createProjectHandler($event)"
					(navigationHandler)="backClickHandler($event)"
					(updateProjectHandler)="updateProjectHandler($event)"></app-erug-services>
			</mat-tab>

			<mat-tab *ngIf="!fromEruWrapper">
				<ng-template mat-tab-label>
					<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0)">
							<path
								d="M16 8C14.3627 8 0 7.888 0 4C0 0.112 14.3627 0 16 0C17.6373 0 32 0.112 32 4C32 7.888 17.6373 8 16 8ZM2.11733 4C3.09333 4.75733 7.77733 6 16 6C24.2227 6 28.9067 4.75733 29.8827 4C28.9067 3.24267 24.2227 2 16 2C7.77733 2 3.09333 3.24267 2.11733 4ZM30.036 4.15467H30.0493H30.036Z"
								fill="#FF341D" />
							<path
								d="M16 16C14.3627 16 0 15.888 0 12C0 11.448 0.448 11 1 11C1.51333 11 1.936 11.3867 1.99333 11.8853C2.60933 12.6227 7.34267 14 16 14C24.6573 14 29.3907 12.6227 30.0067 11.8853C30.064 11.3867 30.4867 11 31 11C31.552 11 32 11.448 32 12C32 15.888 17.6373 16 16 16ZM30 11.9987C30 12 30 12 30 11.9987V11.9987ZM2 11.9987C2 12 2 12 2 11.9987V11.9987Z"
								fill="#FF341D" />
							<path
								d="M16 24C14.3627 24 0 23.888 0 20C0 19.448 0.448 19 1 19C1.51333 19 1.936 19.3867 1.99333 19.8853C2.60933 20.6227 7.34267 22 16 22C24.6573 22 29.3907 20.6227 30.0067 19.8853C30.064 19.3867 30.4867 19 31 19C31.552 19 32 19.448 32 20C32 23.888 17.6373 24 16 24ZM30 19.9987C30 20 30 20 30 19.9987V19.9987ZM2 19.9987C2 20 2 20 2 19.9987V19.9987Z"
								fill="#FF341D" />
							<path
								d="M16 32C14.3627 32 0 31.888 0 28V4C0 3.448 0.448 3 1 3C1.552 3 2 3.448 2 4V27.892C2.64133 28.6333 7.37333 30 16 30C24.6267 30 29.3587 28.6333 30 27.892V4C30 3.448 30.448 3 31 3C31.552 3 32 3.448 32 4V28C32 31.888 17.6373 32 16 32Z"
								fill="#FF341D" />
							<path
								d="M6.66683 20.0001C7.4032 20.0001 8.00016 19.4031 8.00016 18.6667C8.00016 17.9303 7.4032 17.3334 6.66683 17.3334C5.93045 17.3334 5.3335 17.9303 5.3335 18.6667C5.3335 19.4031 5.93045 20.0001 6.66683 20.0001Z"
								fill="#FF341D" />
							<path
								d="M6.66683 11.9999C7.4032 11.9999 8.00016 11.403 8.00016 10.6666C8.00016 9.9302 7.4032 9.33325 6.66683 9.33325C5.93045 9.33325 5.3335 9.9302 5.3335 10.6666C5.3335 11.403 5.93045 11.9999 6.66683 11.9999Z"
								fill="#FF341D" />
							<path
								d="M6.66683 28.0001C7.4032 28.0001 8.00016 27.4031 8.00016 26.6667C8.00016 25.9303 7.4032 25.3334 6.66683 25.3334C5.93045 25.3334 5.3335 25.9303 5.3335 26.6667C5.3335 27.4031 5.93045 28.0001 6.66683 28.0001Z"
								fill="#FF341D" />
						</g>
						<defs>
							<clipPath id="clip0">
								<rect width="32" height="32" fill="white" />
							</clipPath>
						</defs>
					</svg>

					<P>Define Database</P>
				</ng-template>

				<!-- <app-define-database [project]="project"></app-define-database> -->
			</mat-tab>
			<mat-tab *ngIf="fromEruWrapper" [disabled]="isDisabled">
				<ng-template mat-tab-label>
					<img src="../../../../assets/images/side-nav/setting2_visit.png" style="width: 32px; height: 29px;">
					<p>Settings</p>
				</ng-template>
				<app-settings [project]="project"></app-settings>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>