import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

@Component({
  selector: 'nested-option',
  template: `
    <ng-container>
      <div (click)="$event.stopPropagation()">
        <mat-form-field appearance="outline" #select>
          <mat-select
            #panel
            [(ngModel)]="accessType"
            (selectionChange)="accessHandler.emit(accessType)"
          >
            <mat-option [value]="'READ'"> Read </mat-option>
            <mat-option [value]="'Write'">Write </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class NestedOptionComponent {
  accessType = 'READ';
  @ViewChild('panel') panel!: MatSelect;
  @Output() accessHandler = new EventEmitter<any>();
  constructor() {}
}
