import { Injectable } from '@angular/core';
import { Observable, of as observableOf} from 'rxjs';
import { catchError, exhaustMap, map, startWith, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProjectActions from './project.action';
import { Router } from '@angular/router';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PopupComponent } from 'src/app/modules/eru-wrapper/components/popup/popup.component';

@Injectable()
export class ProjectEffect {
    constructor(
        private actions$ : Actions,
        private projectService : ProjectsService,
        private router: Router,
        private dialog: MatDialog
        ){ }

    LoadProjects$: Observable<any> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActions.LoadProjects),
      exhaustMap((payload : any) => {
        return this.projectService
          .getProjects()
          .pipe(
            map((response: any) => {
              if (response) {
                return ProjectActions.LoadProjectsSuccess({response : response[0].projects});
              } else {
                return ProjectActions.LoadProjectsFailure({
                  error: 'Error fetching projects',
                });
              }
            }),
            catchError((error) => {
              console.log('Error fetching projects', error);
              return observableOf(ProjectActions.LoadProjectsFailure({ error: error }));
            })
          );
      })
    ));
    
    GetProject$: Observable<any> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActions.GetProject),
      exhaustMap((payload : any) => {
        return this.projectService
          .getProjects(payload.data)
          .pipe(
            map((response: any) => {
              if (response) {
                return ProjectActions.GetProjectSuccess({response : response[0].projects[0]});
              } else {
                return ProjectActions.GetProjectFailure({
                  error: 'Error fetching projects',
                });
              }
            }),
            catchError((error) => {
              console.log('Error fetching projects', error);
              return observableOf(ProjectActions.GetProjectFailure({ error: error }));
            })
          );
      })
    ));

    CreateProject$: Observable<any> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActions.CreateProject),
      switchMap((payload : any) => {
        return this.projectService
          .createProject(payload.project)
          .pipe(
            map((response: any) => {
              if (response) {
                console.log('createProject', response)
                return ProjectActions.CreateProjectSuccess({response : response});
              } else {
                return ProjectActions.CreateProjectFailure({
                  error: 'Error creating project',
                });
              }
            }),
            catchError((error) => {
              console.log('Error creating project', error);
              return observableOf(ProjectActions.CreateProjectFailure({ error: error }));
            })
          );
      })
    ));
    
    
    CreateEnvironment$: Observable<any> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActions.CreateEnvironment),
      switchMap((payload : any) => {
        return this.projectService
          .createEnvironment(payload.doc)
          .pipe(
            map((response: any) => {
              if (response) {
                console.log('createEnvironment', response)
                return ProjectActions.CreateEnvironmentSuccess({response : response});
              } else {
                return ProjectActions.CreateEnvironmentFailure({
                  error: 'Error creating Environment',
                });
              }
            }),
            catchError((error) => {
              console.log('Error creating Environment', error);
              return observableOf(ProjectActions.CreateEnvironmentFailure({ error: error }));
            })
          );
      })
    ));

    CreateService$: Observable<any> = createEffect(() => this.actions$.pipe(
      ofType(ProjectActions.CreateService),
      switchMap((payload : any) => {
        return this.projectService
          .createService(payload.service)
          .pipe(
            map((response: any) => {
              if (response) {
                console.log('createService', response)
                this.dialog.open(PopupComponent);      
                this.router.navigate(['eru-wrapper/projects']);
                return ProjectActions.CreateServiceSuccess({response : response});
              } else {
                return ProjectActions.CreateServiceFailure({
                  error: 'Error creating service',
                });
              }
            }),
            catchError((error) => {
              console.log('Error creating service', error);
              return observableOf(ProjectActions.CreateServiceFailure({ error: error }));
            })
          );
      })
    ));
}