import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Route, Router } from '@angular/router';
import { LoggerService } from 'src/app/core/services/logger.service';

@Component({
  selector: 'app-eru-wrapper',
  templateUrl: './eru-wrapper.component.html',
  styleUrls: ['./eru-wrapper.component.scss']
})
export class EruWrapperComponent implements OnInit {
  activeLink:any;
  hideSidebar:boolean = false;
  hideElement: boolean = false;
  showSidemenu: boolean = true;
  constructor(
    private router: Router,
    
  ) {  
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showSidemenu =(event.url === '/eru-ql' || event.url === '/eru-ql/dashboard' || event.url === '/eru-ql/dashboard/home')? false : true;
        LoggerService.log('Navigation End  this.showSidemenu ', this.showSidemenu);
      }});
  }

  ngOnInit(): void {   
    // if(this.router.url.includes('create-project')){
    //   this.hideSidebar = true;
    // }
    // else{
    //   this.hideSidebar = false;
    // }

  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       this.hideElement =
  //         event.url === '/eru-wrapper/create-project'  ? true : false;
  //     }
  //  });

  }

}
