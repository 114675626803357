import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectRoutesSelectors from './project-routes.selector';
import * as ProjectRoutesActions from './project-files.action';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EruRoutesService } from 'src/app/modules/eru-routes/services/eru-routes.service';

@Injectable()
export class ProjectRoutesFacadeService {
    getProjectConfig$ = this.store.select(ProjectRoutesSelectors.getProjectConfig);


    constructor(private store : Store, private router : Router, private eruRoutesService : EruRoutesService) {

    }

    loadProjectConfig(projectName : string){
        this.store.dispatch(
            ProjectRoutesActions.LoadProjectConfig({projectName : projectName})
        )
    }


    saveRoutes(projectName : string, data : any){
        return this.eruRoutesService.saveRoutes(projectName, data);
      }

    removeRoutes(projectName : string, routeName:string, data : any){
        return this.eruRoutesService.removeRoutes(projectName, routeName, data);
      }



    clearProjectServiceStore(){
        this.store.dispatch(
            ProjectRoutesActions.ClearProjectRoutes()
        )
    }

    removeFunGroups(projectName:string,funcName:string,data:any) {
        return this.eruRoutesService.removeFunc(projectName,funcName,data);
    }

    saveFunGroups(projectName:string,data:any) {
        return this.eruRoutesService.saveFunc(projectName,data);
    }

}
