import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-repository',
  templateUrl: './repository.component.html',
  styleUrls: ['./repository.component.scss']
})
export class RepositoryComponent implements OnInit {
  envID:any='';
  repo_details:any={}
  constructor(private router:Router) { 
 
    this.envID=this.router.getCurrentNavigation()?.extras?.state?.envId
    this.repo_details=this.router.getCurrentNavigation()?.extras?.state?.repoSettings
    console.log(this.repo_details)
    if(!this.envID){
      this.router.navigateByUrl('eru-wrapper/projects')
    }
  }

  ngOnInit(): void {
  
  }

}
