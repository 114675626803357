import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Observable, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { tap } from "rxjs/operators";

import * as DashboardActions from "../actions/dashboards.actions";

import { ProjectCreationService } from "../../../project-creation/services/project-creation.service";
import { LoggerService } from "src/app/core/services/logger.service";
 

@Injectable()
export class DashboardEffects{

    /**
     *
     */
    constructor(
    private actions: Actions,
    private _projectCreationService: ProjectCreationService,
    private router: Router
    ) {
    
    }

    ProjectsLoad$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsLoad),
       switchMap((payload) => {
         LoggerService.log('payload ',payload);
         return this._projectCreationService
           .getProjects()
           .pipe(
             map((response) => {
               if(response != null){
               console.log("Load", response);
               return DashboardActions.ProjectsLoadSuccess({response : response});
               }
               else{
                 return DashboardActions.ProjectsLoadFailure({ error: "Error." });
               }
             }),
             catchError((error) => {
               console.log("Load", error);
               return observableOf(DashboardActions.ProjectsLoadFailure({ error: error }));
             })
           );
       })
    ));
    LoadProject$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.LoadProject),
       switchMap((payload : any) => {
         return this._projectCreationService
           .getProjectConfig(payload.projectName)
           .pipe(
             map((response) => {
              console.log("Load", response);
               if(response ){              
                return DashboardActions.LoadProjectSuccess({response : response});
               }
               else{
                 return DashboardActions.LoadProjectFailure({ error: "Error." });
               }
             }),
             catchError((error) => {
               console.log("Load", error);
               return observableOf(DashboardActions.LoadProjectFailure({ error: error }));
             })
           );
       })
    ));
    
    AddTablesToSchema$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.AddTablesToSchema),
       switchMap((payload) => {
         
         return this._projectCreationService
           .addTableToSchema(payload.project,payload.dbAlias,payload.tableName)
           .pipe(
             map((response) => {
               if(response != null){
               console.log("LoadDataStores", response);
               return DashboardActions.AddTablesToSchemaSuccess({response : response});
               }
               else{
                 return DashboardActions.AddTablesToSchemaFailure({ error: "Error." });
               }
             }),
             catchError((error) => {
               console.log("Load", error);
               return observableOf(DashboardActions.AddTablesToSchemaFailure({ error: error }));
             })
           );
       })
    ));

    RemoveTableFromSchema$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.RemoveTablesToSchema),
       switchMap((payload) => {
         
         return this._projectCreationService
           .removeTableFromSchema(payload.project,payload.dbAlias,payload.tableName)
           .pipe(
             map((response) => {
               if(response != null){
               console.log("LoadDataStores", response);
               return DashboardActions.RemoveTablesToSchemaSuccess({response : response});
               }
               else{
                 return DashboardActions.RemoveTablesToSchemaFailure({ error: "Error." });
               }
             }),
             catchError((error) => {
               console.log("Load", error);
               return observableOf(DashboardActions.RemoveTablesToSchemaFailure({ error: error }));
             })
           );
       })
    ));

    ProjectsAddEdit$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsAddEdit),
      switchMap((payload:any) => {
      
        return this._projectCreationService
          .addProject(payload)
          .pipe(
            map((response) => {
              if(response != null){
              console.log("Project Add Succesffully", response);
              return DashboardActions.ProjectsAddEditSuccess({response : response});
              }
              else{
                return DashboardActions.ProjectsAddEditFailure({ error: "Incorrect email and/or password." });
              }
            }),
            catchError((error) => {
              console.log("LogIn", error);
              return observableOf(DashboardActions.ProjectsAddEditFailure({ error: error }));
            })
          );
      })
    ));  

    ProjectsAddEditSuccess$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsAddEditSuccess),
      tap((response:any) => {
        const data: any = response.payload;
      })
    ), { dispatch: false });

    ProjectsAddEditFailure$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsAddEditSuccess),
      tap((error) => {
        console.log("Error", error);
      })
    ), { dispatch: false });

    AddprojectDatastore$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsDatastoreAddEdit),
      switchMap((payload:any) => {
      
        return this._projectCreationService
          .saveProjectDataStoreConfig(payload.body,payload.project)
          .pipe(
            map((response) => {
              if(response != null){
              console.log("Project Add Succesffully", response);
              return DashboardActions.ProjectsDatastoreAddEditSuccess({response : response});
              }
              else{
                return DashboardActions.ProjectsDatastoreAddEditFailure({ error: response });
              }
            }),
            catchError((error) => {
              return observableOf(DashboardActions.ProjectsDatastoreAddEditFailure({ error: error }));
            })
          );
      })
    ));

    ProjectsDatastoreAddEditSuccess$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsDatastoreAddEditSuccess),
      tap((response:any) => {
        const data: any = response.payload;
      })
    ), { dispatch: false });
    ProjectsDatastoreAddEditFailure$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.ProjectsDatastoreAddEditFailure),
      tap((error) => {
        console.log("Error", error);
      })
    ), { dispatch: false });

    GetDefaultDataStoreConfig$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.GetDefaultDriverConfig),
      switchMap((payload:any) => {
      
        return this._projectCreationService
          .getDefaultDriverConfig(payload)
          .pipe(
            map((response) => {
              if(response != null){
              console.log("Get DefaultDriver config", response);
              return DashboardActions.GetDefaultDriverConfigSuccess({response : response});
              }
              else{
                return DashboardActions.GetDefaultDriverConfigFailure({ error: response });
              }
            }),
            catchError((error) => {
              return observableOf(DashboardActions.GetDefaultDriverConfigFailure({ error: error }));
            })
          );
      })
    ));

    GetDefaultConfigSuccess$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.GetDefaultDriverConfigSuccess),
      tap((response:any) => {
        const data: any = response.payload;
      })
    ), { dispatch: false });
    GetDefaultConfigFailure$ = createEffect(() => this.actions.pipe(
      ofType(DashboardActions.GetDefaultDriverConfigFailure),
      tap((error) => {
        console.log("Error", error);
      })
    ), { dispatch: false });      


  }