import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-loader',
  templateUrl: './import-loader.component.html',
  styleUrls: ['./import-loader.component.scss']
})
export class ImportLoaderComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public popupHeading: any,
    private dialogRef:MatDialogRef<ImportLoaderComponent>,
    private router:Router
  ) { 
  }

  ngOnInit(): void {
  }
  close(){
    this.router.navigateByUrl('/eru-wrapper/projects');
    this.dialogRef.close()
  }
}
