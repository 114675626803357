import { DefineDatabaseComponent } from '../../../../project-creation/components/define-database/define-database.component';
import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators, FormControl,FormArray } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { NavigationEnd,ActivatedRoute} from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DialogComponent } from '../../../../project-creation/components/dialog/dialog.component';
import { ProjectCreationService } from '../../../../project-creation/services/project-creation.service';
import { cloneDeep } from 'lodash';
 
/* import {
  ProjectsState,
 } from "../../store/project-creations.state"; */
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { CommonPopupComponent } from 'src/app/modules/eru-wrapper/components/common-popup/common-popup.component';


@Component({
  selector: 'app-define-project',
  templateUrl: './define-project.component.html',
  styleUrls: ['./define-project.component.scss'],
})
export class DefineProjectComponent implements OnInit {
  form!: FormGroup;
  currentIndex:any;
  showAPIOptions: boolean = false;
  showAuthOptions: boolean = false;
  openDefine = false;
  projectNumber : any= [
    {
      "env_name":'',
      "is_default":'',                
      "api_key_name":'',
      "api_key_value":'',
      "auth_user_name":'',
      "auth_user_password":'',
      "auth_header_name":'',
      "auth_header_value":'',
      "login_url_path":''
    }
  ];
  fromEruWrapper: boolean = false;
  projectName: string | any;
  docs:any = {};
  isDefault : boolean = false;
  securityType : string[] = ['none'];
  editableProject : any;
  modifiedEnvs : any = [];
  deletedEnvs : any = [];
  newEnvs : any = [];
  @Output() createProjectHandler:EventEmitter<any> = new EventEmitter<any>();
  @Output() updateProjectHandler:EventEmitter<any> = new EventEmitter<any>();
  constructor( private fb:FormBuilder,
    private formBuilder: FormBuilder,
    private dialog: MatDialog, 
    private route: Router,
    private activateRoute: ActivatedRoute,
    private projectFacadeService:ProjectFacadeService

    ) {}

  ngOnInit(): void {
    this.projectName = this.activateRoute.snapshot.paramMap.get('project_name');
    this.form = this.fb.group({  
      project: new FormControl(null, {validators: [Validators.required ]}), 
      environments: new FormArray([]),  
    });  
    this.environments().clear();
    this.environments().push(this.newEnvironment()); 
    if(this.route.url.includes('eru-wrapper')){
      this.fromEruWrapper = true;
    }
    else{
      this.fromEruWrapper = false;
    }

    this.projectFacadeService.loadProjects();
    
    this.projectFacadeService.getProjects$.subscribe((projects : any) => {
      if(projects.projects) {
        const getProject = cloneDeep(projects.projects);
        this.editableProject = getProject.find(
          (p : any) => {return p.project_name == this.projectName}
        )
        if(this.editableProject){
          this.updateProjectHandler.emit({project:this.editableProject})
        }    
      }
    })
    
    this.projectFacadeService.createProjectResponse$.subscribe((response : any) => {
      if(response && response.createProjectResponse){
       this.projectFacadeService.getProject(this.form.controls.project.value);        
        this.createProjectHandler.emit({project:this.docs})
      }
    })
    this.addEnvsForEditableProject();
  }

  addEnvsForEditableProject(){
    if(this.editableProject){
      this.environments().clear();
      for(let env of  this.editableProject.public___project_envs)
      {
        
        let editFormGroup : any =  new FormGroup({
            env_id: new FormControl(env.env_id),
            environment: new FormControl(env.env_name, {validators: [Validators.required ]}),
            default_environment: new FormControl(env.is_default == 'Y' ? true : false),
           
            hname: new FormControl(env.api_key_name, {validators: [Validators.required ]}),
            key: new FormControl(env.api_key_value, {validators: [Validators.required ]}),

            user_id: new FormControl(env.auth_user_name, {validators: [Validators.required ]}),
            password: new FormControl(env.auth_user_password, {validators: [Validators.required ]}),
            header_name: new FormControl(env.auth_header_name, {validators: [Validators.required ]}),
            key_value: new FormControl(env.auth_header_value, {validators: [Validators.required ]}),
            login_url: new FormControl(env.login_url_path, {validators: [Validators.required ]}),

            type: new FormControl(env.api_key_name ? 'apikey' : (env.auth_user_name ? 'authentication' : 'none')),
          })
          if(editFormGroup.controls.type.value == 'none')
          {
            editFormGroup.removeControl('hname');
            editFormGroup.removeControl('key');
            editFormGroup.removeControl('user_id');
            editFormGroup.removeControl('password');
            editFormGroup.removeControl('header_name');
            editFormGroup.removeControl('key_value');
            editFormGroup.removeControl('login_url');
          }
          else if(editFormGroup.controls.type.value == 'apikey')
          {
            editFormGroup.removeControl('user_id');
            editFormGroup.removeControl('password');
            editFormGroup.removeControl('header_name');
            editFormGroup.removeControl('key_value');
            editFormGroup.removeControl('login_url');
          }          
          else if(editFormGroup.controls.type.value == 'authentication')
          {            
            editFormGroup.removeControl('hname');
            editFormGroup.removeControl('key');
          }
          this.environments().push(editFormGroup)
      }
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.form.controls[control].hasError(error);
  }

  environments() : FormArray {  
    return this.form.get("environments") as FormArray  
  }  
     
  newEnvironment(): FormGroup {  
    return new FormGroup({  
      environment: new FormControl('', {validators: [Validators.required ]}),
      default_environment: new FormControl(false),
      type: new FormControl('none'),
      // hname: '',
      // key: '',
      // user_id: '',
      // password: '',
      // header_name: '',
      // key_value: '',
      // login_url: ''
    })  
  }  
     
  addEnvironment() {  
    this.environments().push(this.newEnvironment()); 
  }  
     
  removeEnvironment(index:number) {  
    let envForm : any = this.environments().at(index) as FormGroup
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      data: {
        title: 'Delete Environment',
        message : 'Are you sure, you want to delete the environment.'}
    });

    dialogRef.afterClosed().subscribe((result : any) => {
      if(result.event == 'Ok'){
        if(envForm.controls.env_id && envForm.controls.env_id.value){              
            this.projectFacadeService.deleteEnvironment({doc : {
              "env_id": envForm.controls.env_id.value
            }}).subscribe((res : any) => {
              if(res){            
                this.environments().removeAt(index);                
              }
            })
        }
        else{
          this.environments().removeAt(index);          
        }
      }
    });
      
  }
  
  getSecurityType(fromGroup : any){
    return fromGroup.get(`type`).value
  }

  onRadioChange(e:any, index : any){
    console.log(e.value, 'value');
    if(e.value=='none'){
      this.securityType = ['none'];
      
      let envForm : any = this.environments().at(index) as FormGroup
      
      envForm.removeControl('hname');
      envForm.removeControl('key');
      envForm.removeControl('user_id');
      envForm.removeControl('password');
      envForm.removeControl('header_name');
      envForm.removeControl('key_value');
      envForm.removeControl('login_url');
      // index.a.hidden = true;
      // index.b.hidden = true;
    }
    else if(e.value=='apikey'){
      this.securityType = ['apikey'];
      let envForm : any = this.environments().at(index) as FormGroup
      envForm.addControl('hname', new FormControl('', {validators: [Validators.required ]}));
      envForm.addControl('key', new FormControl('', {validators: [Validators.required ]}));
      // Remove controls      
      envForm.removeControl('user_id');
      envForm.removeControl('password');
      envForm.removeControl('header_name');
      envForm.removeControl('key_value');
      envForm.removeControl('login_url');
      // index.a.hidden = false;
      // index.b.hidden = true;
    }else if(e.value=='authentication'){
      this.securityType = ['authentication'];
      let envForm : any = this.environments().at(index) as FormGroup
      envForm.addControl('user_id', new FormControl('', {validators: [Validators.required ]}));
      envForm.addControl('password', new FormControl('', {validators: [Validators.required ]}));
      envForm.addControl('header_name', new FormControl('', {validators: [Validators.required ]}));
      envForm.addControl('key_value', new FormControl('', {validators: [Validators.required ]}));
      envForm.addControl('login_url', new FormControl('', {validators: [Validators.required ]}));
      // Remove controls
      envForm.removeControl('hname');
      envForm.removeControl('key');
      // index.a.hidden = true;
      // index.b.hidden = false;
    }
  }

  addMore(text:any){
    if(text==='project'){
      this.projectNumber.push(
        {
          "env_name":'',
          "is_default":'',                
          "api_key_name":'',
          "api_key_value":'',
          "auth_user_name":'',
          "auth_user_password":'',
          "auth_header_name":'',
          "auth_header_value":'',
          "login_url_path":''
        }
      );
    }
  }

  delete(index:any){
    this.projectNumber.splice(index,1);
  }

  createProj(e:any){
    if(this.form.valid){
      if(!this.editableProject){        
          const envs = this.generateEnvStruct();
          let user : any = localStorage.getItem('user');
          user = JSON.parse(user)
          this.docs ={
            "project_name":this.form.controls.project.value,
            "public.project_users": {
              "user_id": user.id,
              "is_owner":"Y",
              "invite_status":"ACCEPTED"
          },
            "public.project_envs":envs
          } 
          this.projectFacadeService.createProject({project:{"docs" : this.docs}});
      }
      else{
        this.checkEnvDirty();
        if(this.newEnvs.length > 0){
          const newEnvDoc = this.getNewEnvs(this.newEnvs);
          this.projectFacadeService.createEnvironment({doc : newEnvDoc}).subscribe((res : any) => {
            console.log('createEnvironment', res)
          })
        }
        if(this.modifiedEnvs.length > 0){
          const modifyEnvs = this.getModifiedEnvs(this.modifiedEnvs);
          for(let mEnvs of modifyEnvs){
            this.projectFacadeService.editEnvironment({doc : mEnvs}).subscribe((res : any) => {
            console.log('editEnvironment', res)
          })
          }
        }
        // if(this.deletedEnvs.length > 0){
        //   for(let dEnvs of this.deletedEnvs){
        //     this.projectFacadeService.deleteEnvironment({doc : {"env_id" : dEnvs.env_id}})
        //   }
        // }
        this.projectFacadeService.getProject(this.editableProject.project_name);
        this.createProjectHandler.emit({project:this.editableProject})
      }
    }
  }

  generateEnvStruct(){
    let envs : any = [];
    this.environments().controls.forEach((control: any, index : any) => {
      envs.push(
        {
          "env_name":control.controls['environment'].value,
          "is_default":control.controls['default_environment'].value ? 'Y' : 'N',                
          "api_key_name":control.controls.hname ? control.controls.hname.value : '',
          "api_key_value":control.controls.key ? control.controls.key.value : '',
          "auth_user_name":control.controls.user_id ? control.controls.user_id.value : '',
          "auth_user_password":control.controls.password ? control.controls.password.value : '',
          "auth_header_name":control.controls.header_name ? control.controls.header_name.value : '',
          "auth_header_value":control.controls.key_value ? control.controls.key_value.value : '',
          "login_url_path": control.controls.login_url ? control.controls.login_url.value : '',
        }        
      )
      if(control.controls.env_id) 
      { 
        envs[index].env_id = control.controls.env_id.value
      } 
    });
    return envs;
  }

  isDefaultClick(event : any){
    this.isDefault = false;
  }

  checkEnvDirty(){
    if(this.editableProject && this.editableProject.public___project_envs){
      const envs = this.generateEnvStruct();
      for(let env of  this.editableProject.public___project_envs)
      {
        //const env1 = envs.find((e:any) => {return e.env_id == env.env_id})
        for(let e of  envs)
        {          
          if(e.env_id == env.env_id){
            const keys = Object.keys(e);
            for(let k of keys){
              if(e[k] !== env[k])
              {
                this.modifiedEnvs.push(e)
                break;
              }
            }     
          }
        }
        // const env2 = envs.find((e:any) => {return e.env_id != env.env_id})
        // if(env2){
        //   this.deletedEnvs.push(env2)
        // }
      }

      for(let newEnv of  envs)
      {
        if(!newEnv.env_id)
        {
          this.newEnvs.push(newEnv)
        }
      }

    }
    
  }

  getModifiedEnvs(modifiedEnvs : any){
    const modifiedEnvsStruct : any = [];
    for(let mEnv of modifiedEnvs)
    {
      modifiedEnvsStruct.push(
          {
            "doc": {
                        "project_id":this.editableProject.project_id,
                        "env_name": mEnv.env_name,
                        "is_default": mEnv.is_default,
                        "api_key_name": mEnv.api_key_name,
                        "api_key_value": mEnv.api_key_value,
                        "auth_user_name": mEnv.auth_user_name,
                        "auth_user_password": mEnv.auth_user_password,
                        "auth_header_name": mEnv.auth_header_name,
                        "auth_header_value": mEnv.auth_header_value,
                        "login_url_path": mEnv.login_url_path
                    },
                    "env_id":mEnv.env_id
                  
        }
      )
    }
    return modifiedEnvsStruct; 
  }

  getNewEnvs(newEnvs : any){
    const newEnvsStruct : any = [];
    for(let mEnv of newEnvs)
    {
      newEnvsStruct.push(
          {
            "project_id":this.editableProject.project_id,
            "env_name":mEnv.env_name,
            "is_default":mEnv.is_default,
            "api_key_name":mEnv.api_key_name,
            "api_key_value":mEnv.api_key_value,
            "auth_user_name":mEnv.auth_user_name,
            "auth_user_password":mEnv.auth_user_password,
            "auth_header_name":mEnv.auth_header_name,
            "auth_header_value":mEnv.auth_header_value,
            "login_url_path":mEnv.login_url_path
        }
      )
    }
    return { "docs" :  newEnvsStruct}
  }

}
