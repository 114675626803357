export const MENUS:any[] = [
    {
      label:'Overview',
      link:'eru-ql/dashboard/overview',
      icon:'../../../../assets/images/side-nav/home.svg',
      isDevelop:false
    },
    {
      label:'Database',
      link:'eru-ql/dashboard/database/list',
      icon:'../../../../assets/images/wrapper/ql.svg',
      isDevelop:false
    },
    {
      label:'My Queries',
      link:'eru-ql/dashboard/database/my-queries',
      icon:'../../../../assets/images/wrapper/my-query.svg',
      isDevelop:false
    },
    // {
    //   label:'Develop',
    //   isDevelop:true,
    //   icon:'../../../../assets/images/side-nav/dns.svg',
    //   expanded:false,
    //   childs:[
    //     {
    //       label:'Database',
    //       link:'eru-ql/dashboard/database/list',
    //     },
    //     {
    //       label:'My Queries',
    //       link:'eru-ql/dashboard/database/my-queries',
    //     },
    //     // {
    //     //   label:'Cache',
    //     //   link:'dashboard/database/cache',
    //     // },
    //   ]
    // },
    // {
    //   label:'API Explorer',
    //   link:'dashboard/api_explorer',
    //   icon:'../../../../assets/images/side-nav/explore.svg',
    //   isDevelop:true
    // },
    {
      label:'Settings',
      link:'eru-ql/dashboard/setting',
      icon:'../../../../assets/images/side-nav/setting2_visit.png',
      isDevelop:false
    },
  ]
  