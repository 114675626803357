export class APIConstants {
  
   
    public static readonly GET_PROJECTS: string = '/store/project/list';
    public static readonly ADD_PROJECT: string = '/store/project/save/';
    public static readonly DELETE_PROJECT: string = '/store/project/remove/';
    public static readonly GET_PROJECT_CONFIG: string = '/store/project/config/';
    public static readonly GET_PROJECT_DATASTORE_CONFIG: string = '/store/project/test/datastore/config/postgresscdem';
   


    public static readonly GET_DEFAULT_DRIVER_CONFIG: string = '/datasource/store/defaultdriverconfig/mysql';
    public static readonly GET_PROJECT_DATASTORE_TABLE: string = '/store/project/test/datastore/postgresscdemo1/schema';


   
}