<div class="secrets-wrapper ">
    <div class="secrets-inner minH493">        
        <!-- <div class="radio-item">
            <input type="radio" id="secret" name="secretOpt" [value]="1">
            <label for="secret">Secret</label>
        </div>
        
        <div class="radio-item">
            <input type="radio" id="jwt" name="secretOpt" [value]="0">
            <label for="jwt">JWT</label>
        </div> -->
        <div class="secrets d-flex">
            <label class="f-14 text-grey d-flex mr-20">
                <input 
                  [value]="1" 
                  [(ngModel)]="isVisible" 
                  name="isVisible" 
                  type="radio" 
                  (change)='toggleChange(1)'
                  [checked]="isSelected"/> 
                  
                  <span class="ml-10">Secret</span>
            </label>
            
            <label class="f-14 text-grey d-flex mr-20">
                <input 
                  [value]="0" 
                  [(ngModel)]="isVisible" 
                  name="isVisible" 
                  type="radio" 
                  (change)='toggleChange(0)'
                  [checked]="!isSelected"/> 
                  <span class="ml-10">JWT</span>
            </label>
        </div>
        <div *ngIf="isVisible == 1">
            <div class="row">
                <div class="mt-20 col-sm-12 col-md-5 col-lg-4">
                    <h4 class="mt-0 text-grey3 f-12">Header Key</h4>
                    <div>
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]='headerKey' name="headerKey"matInput autocomplete="off" (input)="inputChange($event,'text')" [disabled]="read"/>
                        </mat-form-field>
                    </div>
                    <h4 class="mt-0 text-grey3 f-12">Algorithm</h4>
                    
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-select  (selectionChange)="inputChange($event,'option')" [disabled]="read">
                                <mat-option
                                    *ngFor="let item of algorithm"
                                    [value]="item.value"
                                   
                                >
                                    {{ item.viewValue }}
                                </mat-option>
                                </mat-select>
                        </mat-form-field>
                    </div>
                    <h4 class="mt-0 text-grey3 f-12">Secret Key</h4>
                    <div>
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]='secretKey' name="secretKey"matInput autocomplete="off" (input)="inputChange($event,'text')" [disabled]="read" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isVisible == 0">
            <div class="row">
                <div class="mt-20 col-sm-12 col-md-5 col-lg-4">
                    <h4 class="mt-0 text-grey3 f-12">JKT Url</h4>
                    <div>
                        <mat-form-field appearance="outline">
                            <input matInput autocomplete="off" (input)="inputChange($event,'text')" [disabled]="read"/>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-md-6 mt-20">
                <h4 class="mt-0 bcolor f-14">Audience Verification</h4>
                <div>
                    <mat-slide-toggle (change)="toggle($event,'audience')"[(ngModel)]="isChecked1"><p class="text-grey3" style="margin: inherit;">Allow to audience verficiation</p></mat-slide-toggle>
                    <div *ngIf="isChecked1" class="mt-20">
                        <mat-label>Audience</mat-label>
                       
                       
                        <div class="d-flex" *ngFor="let input of audience ; let i = index">
                
                            <mat-form-field appearance="outline">
                                <input (change)="verificationInputChange($event.target,i,'audience')"  [value]="audience[i]"matInput autocomplete="off" [disabled]="read"/>
                               
                            </mat-form-field>
                            <img  style='cursor: pointer;' (click)="deleteInput('audience',i)" *ngIf="!read" class='ml-1'src="../../../../assets//images/icons/delete1.svg" alt="">
                        </div>
                
                        

                        <p (click)="addMore('audience')"class="text-orange cursor-pointer add_more" *ngIf="!read">++ Add more</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-20">
                <h4 class="mt-0 bcolor f-14">Issuer Verification</h4>
                <div>
                    <mat-slide-toggle (change)="toggle($event,'issuer')"[(ngModel)]="isChecked2"><p class="text-grey3" style="margin: inherit;">Allow to issuer verficiation</p></mat-slide-toggle>
                    <div *ngIf="isChecked2" class="mt-20">
                        <mat-label>Issuer</mat-label>
                        <div class="d-flex"  *ngFor="let input of issuer ; let i = index">
                            <mat-form-field appearance="outline">
                                <input (change)="verificationInputChange($event.target,i,'issuer')" [value]="issuer[i]"  matInput autocomplete="off" [disabled]="read"/>
                            </mat-form-field>
                            <img  style='cursor: pointer;' *ngIf="!read"  (click)="deleteInput('issuer',i)" class='ml-1'src="../../../../assets//images/icons/delete1.svg" alt="">
                        </div>
                      
                        <p (click)="addMore('issuer')"class="text-orange cursor-pointer add_more" *ngIf="!read">++ Add more</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row button-style">
            <div class="text-right">
                <button style="height: 35px;"
                (click)='save()'
                        *ngIf="!read"
                        mat-flat-button
                        color="primary"
                        type="submit"
                        cdkFocusInitial>
                        Save
                    </button>
            </div>
        </div>
    </div>
</div>
