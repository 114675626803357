<div class="secret-manager-wrapper">
    <div class="inner-wrapper minH493">
        <div class="d-flex" style="justify-content: space-between;">
          <h4>Repository Settings</h4>
          <div>
            <button (click)="commitRepo()" mat-flat-button style="background: #ff341d; color: white;">
             Commit
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Repo Name</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]='repoName' name='repoName' matInput autocomplete="off" placeholder="" disabled />
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 select-drop">
                <h4 class="mt-0 text-grey3 f-12">Repo Type</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectedValue" disabled>
                          <mat-option *ngFor="let item of type" [value]="item.name">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Branch Name</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <input  [(ngModel)]='branchName' name='branchName'matInput autocomplete="off" placeholder="" disabled/>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4">
                <h4 class="mt-0 text-grey3 f-12">Auth Type</h4>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectedAuthValue" disabled>
                          <mat-option *ngFor="let item of authType" [value]="item.value">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <h5 *ngIf="this.selectedAuthValue == 2"  class="mt-0 f-12"> Installation ID:{{installation_id }}</h5>


            </div>
        </div>

        <div class="row mt-20">
            <div class="col-md-4">
                <span *ngIf='secretOpt===1'>
                    <p class="f-14 text-grey3">Key</p>

                    <mat-form-field appearance="outline" class="w-75 instance-input default-msg-text-area">
                        <textarea  [(ngModel)]='key' name ="key" matInput rows="5" cols="9" placeholder="Write your script here" disabled>
                      </textarea>
                    </mat-form-field>
                </span>
                <span *ngIf='selectedAuthValue===1'>
                    <p class="f-14 text-grey3">Token</p>
                    <mat-form-field appearance="outline">
                        <input [(ngModel)]='token' name="token" matInput autocomplete="off" placeholder="" disabled />
                    </mat-form-field>
                </span>
            </div>

        </div>
        <div class="row mt-10">
            <div class="col-md-4">

                    <p class="f-14 text-grey3">Auto Commit</p>

                    <mat-checkbox [checked]="autoCommit" name="autoCommit" disabled></mat-checkbox>


            </div>

        </div>


    </div>
</div>
