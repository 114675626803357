import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/core/services/logger.service';
// import { selectDashboardState } from 'src/app/dashboard/store/dashboard.state';
// import { DashboardState } from 'src/app/dashboard/store/reducers/dashboard.reducers';
//import { ProjectsState } from '../../store/project-creations.state';
//import {selectprojectsState} from '../../store/project-creations.state';
@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent implements OnInit {
  project:any;
  selectedIndex:number =0;
  // getState: Observable<any>;
  fromEruWrapper :boolean = true;
  labelName : string = '';
  isDisabled : boolean = false;
  constructor(
    // private store: Store<DashboardState>,
    private router: Router,
    ) {
    // this.getState = this.store.select(selectDashboardState);
  }

  ngOnInit(): void {
    if(this.router.url.includes('eru-wrapper')){
      this.fromEruWrapper = true;
    }
    else{
      this.fromEruWrapper = false;
    }
    if(this.router.url.includes('edit-project')){
      this.labelName = 'Edit project';
    }
    else{
      this.labelName = 'Create new project'
    }

    this.isDisabled = this.project && this.project.project_id ? false : true;

  }
  createProjectHandler(event:any){
    this.project = event.project;
    this.selectedIndex = 1
  }

  updateProjectHandler(event:any){
    this.project = event.project;
    
    this.isDisabled = this.project && this.project.project_id ? false : true;
  }

  backClickHandler(event:any){
    this.selectedIndex = 0
  }
}
