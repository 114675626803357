<div class="edituser-component">
    <div class="heading">
        <h2 class="f-16 fw-500">Edit User</h2>
        <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    </div>
    <hr>
    <div class="formArea">
        <form>
            <div class="row">
                <div class="col-md-12 d-flex">
                    <p class="f-12 fw-400 text-grey">Email Address:</p>
                    <span class="f-14 fw-400 text-darkgrey" style="margin-left: 5px;"> vinayparab@eru.com</span>
                </div>
            </div>
            <div class="row selectArea d-flex align-items-center " *ngFor="let section of sectionNumber;let i = index;">
                <div class="col-md-3 project-style darkgrey-outline">
                    <p *ngIf="i==0">Project</p>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-select >
                            <mat-option *ngFor="let item of project" [value]="item">
                                <span class="px-2"> {{ item.name }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 project-style darkgrey-outline">
                    <p *ngIf="i==0">Environment</p>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-select >
                            <mat-option *ngFor="let item of environment" [value]="item">
                                <span class="px-2"> {{ item.name }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 project-style darkgrey-outline">
                    <p *ngIf="i==0">Product</p>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-select>
                            <mat-option *ngFor="let item of product" [value]="item">
                                <span class="px-2"> {{ item.name }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-1" *ngIf="sectionNumber.length>1" align="center">
                    <div class="del-btn" (click)="delete(i)">
                        <img src="../../../../../assets/images/icons/delete.png" alt="">
                    </div>
                </div>
            </div>
            <div>
                <button class="add-btn" (click)="addMore()">Add more</button>
            </div>
            
            </form>
    </div>
    <div class="btnArea">
        <mat-dialog-actions align="end" style="position: relative;">     
        <div>
          <button mat-dialog-close class="btn f-14 fw-500 btn-cancel">
              Cancel
          </button>
          <button
            class="btn f-14 fw-500 ml-10 btn-invite"
            type="submit"
          >
            Save
          </button>
        </div>
      </mat-dialog-actions>
    </div>
</div>