<div class="encrpt-wrapper">
    <div class="encrypt-inner minH493">
        <h4>Settings</h4>
        <div class="row mt-20 d-flex align-item-center" style="">
            <div class="col-md-4">
                <span class="f-12 fw-500 text-grey3">Claims Key</span>
                <mat-form-field appearance="outline" class="w100">
                    <input #aesKey [(ngModel)]="claimKeyValue" matInput required type="text" [disabled]="read">

                </mat-form-field>
            </div>
            <div class="col-12">
                <div class="text-right">
                    <button style="height: 35px;"
                            *ngIf="!read"
                            (click)='onSave()'
                            mat-flat-button
                            color="primary"
                            type="submit"
                            class="text-white"
                            cdkFocusInitial>
                            Save
                    </button>
                </div>
            </div>
        </div>
        <div class="row button-style">

        </div>
    </div>
</div>
