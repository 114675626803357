








import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../service/settings.service';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-git-settings',
  templateUrl: './git-settings.component.html',
  styleUrls: ['./git-settings.component.scss']
})
export class GitSettingsComponent implements OnInit {
  @Input()envID:any
  @Input()repo_details:any
  autoCommit:any=false
  repoName:string=''
  branchName:string=''
  secretOpt:any=null
  token:any
  key:any
  selectedValue: any;
  selectedAuthValue: any;
  @Input()read:any;
  constructor(private settingsService:SettingsService) {
    this.settingsService.installationId.subscribe((res:any)=>{
      this.installation_id=res
    })
   }

  type = [
   {
    name: 'GITHUB'
   }
  ];

  authType = [
    {
      name: 'TOKEN',
      value: 1
    },
    {
      name: 'GITHUBAPP',
      value: 2
    }
  ]

  ngOnInit(): void {
   
    if(this.repo_details&&Object.keys(this.repo_details).length>0){
     this.installation_id = this.repo_details.installation_id||null;
     this.selectedValue = this.repo_details.repo_type||'';
     this.repoName = this.repo_details.repo_name||'';
     this.branchName = this.repo_details.branch_name||'';
     this.selectedAuthValue = this.repo_details.auth_mode=='GITHUBAPP'?2:this.repo_details.auth_mode=='TOKEN'?1:'';
     this.autoCommit = this.repo_details.auto_commit||'';
     this.token = this.repo_details.auth_key||'';
    }
  }

  csvInputChange(fileInputEvent: any) {
    console.log(fileInputEvent.target.files[0]);
  }
  inputChange(){
    this.token=''
    this.key=''
  }
onSave(){
let body ={
  "env_id": this.envID,
  "doc": {
      "installation_id": this.installation_id?this.installation_id:'',
      "repo_type": this.selectedValue,
      "repo_name": this.repoName,
      "branch_name": this.branchName,
      "auth_mode": this.selectedAuthValue == 2?'GITHUBAPP':this.selectedAuthValue == 1?'TOKEN':'',
      "auto_commit": this.autoCommit,
      "auth_key": this.selectedAuthValue == 1?this.token:''
  }
}


this.settingsService.saveRepo(body).subscribe((res:any)=>{
console.log(res)
})
  
}
installation_id:any
callback (v:any){
if(v){
  this.installation_id=v
}
 
  // this.installation_id=v
}

onChange(newValue: any) {
  console.log(newValue);
  this.selectedAuthValue = newValue;
  console.log(this.selectedAuthValue,'selectedAuthValue');
  if(this.selectedAuthValue == 2) {
    this.settingsService.installationId.next(null)
   this.settingsService.initiategithubApp('https://github.com/apps/eru-githubapp/installations/new',this.envID,GitSettingsComponent)
  }
}
}



