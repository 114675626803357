import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectSelectors from './project.selector';
import * as ProjectActions from './project.action';
import { Router } from '@angular/router';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectFacadeService {
    getProjects$ = this.store.select(ProjectSelectors.getProjects);
    createProjectResponse$ = this.store.select(ProjectSelectors.createProjectResponse);
    createServiceResponse$ = this.store.select(ProjectSelectors.createServiceResponse);

    
    constructor(private store : Store, private router : Router, private projectService : ProjectsService) {
        
    }

    loadProjects(){
        this.store.dispatch(
            ProjectActions.LoadProjects({})
        )
    }

    getProject(projectName : string){
        this.store.dispatch(
            ProjectActions.GetProject({data : {'project_name' : projectName}})
        )
    }
    
    createProject(data : any){
        this.store.dispatch(
            ProjectActions.CreateProject(data)
        )
    }

    
    deleteProject(data : any) : Observable<any>{
        return this.projectService.deleteProject(data.doc)
    }

    createEnvironment(data : any) : Observable<any>{
        return this.projectService.createEnvironment(data.doc)
    }

    
    editEnvironment(data : any) : Observable<any>{        
        return this.projectService.editEnvironment(data.doc)
    }

    
    deleteEnvironment(data : any): Observable<any>{
        return this.projectService.deleteEnvironment(data.doc)
    }
    
    createService(data : any): Observable<any>{
        // this.store.dispatch(
        //     ProjectActions.CreateService(data)
        // )
        return this.projectService.createService(data.service)
    }
    
    editService(data : any) : Observable<any>{        
        return this.projectService.editService(data.doc)
    }

    
    deleteService(data : any) : Observable<any>{
        return this.projectService.deleteService(data.doc)
    }

    clearProjectServiceStore(){
        this.store.dispatch(
            ProjectActions.ClearProjectAndService()
        )
    }

    authenticateService(loginUrl : any, data:any) : Observable<any>{        
        return this.projectService.authenticateForService(loginUrl, data)
    }

}