import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from 'src/app/modules/shared/services/crypto.service';
import { SettingsService } from './service/settings.service';
import { NotificationService } from '../../eru-ql/components/database/services/notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  envID:any='';
  repo_details:any={}
  projectName: string = '';
  read: boolean = false;
  encryptedParams: string = '';
  decryptedParams: string = '';
  activeLink: string[] = [];
  from: string = '';
  fromQl: boolean = false;
  secretDataFromApi: any = {};
  repoDataFromApi: any = {};
  tabIndex:number = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
    private settingsService: SettingsService,
    private notifyService: NotificationService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.encryptedParams = params['q'];
    });
  }

  ngOnInit(): void {
    this.mapParamsValue();
    this.activeLink = this.router.url.split('/');
    this.fetchSettings();
  }
  isFromQl(){
    this.from = this.activeLink[1];
    this.fromQl = this.from === 'eru-ql';
    return   this.fromQl
  }
  isFromGateway(){
    this.from = this.activeLink[1];
    this.fromQl = this.from === 'eru-gateway';
    return   this.fromQl
  }
  mapParamsValue(): void {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const paramsArray = this.decryptedParams.split('&').map((pair: string) => {
        const [key, value] = pair.split('=');
        return { key, value };
      });
      paramsArray.forEach(param => {
        if (param.key === 'read') {
          this.read = param.value === 'true';
        } else if(param.key === 'project') {
          this.projectName = param.value;
        }
      });
    }
  }

  saveSecret(e: any): void {
    const body = {
      payload: e,
      projectName: this.projectName,
      from: this.from,
    };

    this.settingsService.saveSecretManager(body).subscribe({
      next:(res: any) => {
        this.notifyService.showSuccess(res.msg, 'Sucess');
  },
  error:(error)=>{
    this.notifyService.showError(error.error.error||'Something went wrong', 'Error');
  }

});
  }

  fetchSettings(): void {
    this.settingsService.getSecretManager(this.projectName,this.isFromGateway()).subscribe({
      next: (res: any) => {
        console.log(res);
        this.secretDataFromApi = res;
      }
    });
  }

}
