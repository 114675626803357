<div class="variables-component">
    <div class="container-fluid pb-20">
        <h2 id="head" class="f-22 fw-600" style="margin-bottom: 0 !important;">Variables</h2>
        <mat-tab-group [(selectedIndex)]="tabIndex">
            <mat-tab>
            <ng-template mat-tab-label>
                <p>Variables</p>
            </ng-template>

            <app-variables-tab [projectName]="projectName" *ngIf="tabIndex+1 == 1" [read]="read"></app-variables-tab>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <p>Environment Variables </p>
            </ng-template>

            <app-environment-variable-tab [projectName]="projectName" *ngIf="tabIndex+1 == 2"[read]="read"></app-environment-variable-tab>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <p>Secret</p>
            </ng-template>

            <app-secret-tab  [projectName]="projectName" *ngIf="tabIndex+1 == 3" [read]="read"></app-secret-tab>

        </mat-tab>
        </mat-tab-group>
    </div>
</div>
