import * as ProjectFilesReducer from "./project-files.reducer";
import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

const selectProjectFilesState = createFeatureSelector<ProjectFilesReducer.ProjectFilesState>('PROJECTFILES');

export const getProjectConfig = createSelector(selectProjectFilesState, (state) => {
    return {
        projectConfig : state.projectConfig
    }
})

