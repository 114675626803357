import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProjectFilesSelectors from './project-files.selector';
import * as ProjectFilesActions from './project-files.action';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectFilesService } from 'src/app/modules/eru-files/services/project-files.service';

@Injectable()
export class ProjectFilesFacadeService {
    getProjectConfig$ = this.store.select(ProjectFilesSelectors.getProjectConfig);

    
    constructor(private store : Store, private router : Router, private projectFilesService : ProjectFilesService) {
        
    }

    loadProjectConfig(projectName : string){
        this.store.dispatch(
            ProjectFilesActions.LoadProjectConfig({projectName : projectName})
        )
    }

    saveProjectStorage(projectName : string, storageName : string, storageType : string, data:any) : Observable<any>{
        return this.projectFilesService.saveProjectStorage(projectName, storageName, storageType, data)
    }

    
    generateAESKey(projectName : string, key : string, data:any){        
        return this.projectFilesService.generateAESKey(projectName , key , data).toPromise()
    }

    
    removeProjectStorage(projectName : string, storageName : any, data:any): Observable<any>{
        return this.projectFilesService.removeProjectStorage(projectName, storageName, data)
    }
    

    clearProjectServiceStore(){
        this.store.dispatch(
            ProjectFilesActions.ClearProjectFiles()
        )
    }


}