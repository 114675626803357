<div class="secret-manager-wrapper">
    <div class="inner-wrapper minH493">
        <div class="d-flex align-items-center">
            <img src="../../../../../assets/images/icons/idea.png" alt="">
            <div class="ms-2" *ngIf="from">
                Configure your secret manager to store secret variables and encryption key to encrypt/decrypt data.
            </div>
            <div class="ms-2" *ngIf="!from">
                Configure your secret manager to store secret variables
            </div>
        </div>

        <section class="mt-20">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="">Secret Manager Store</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <mat-select formControlName="managerStore">
                                <mat-option value="AWS">AWS</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <div class="">Region</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <input matInput formControlName="region">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <div class="">Authentication</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <mat-select formControlName="authentication" (selectionChange)="authSelectChange($event)">
                                <mat-option value="Secret">Secret</mat-option>
                                <mat-option value="IAM">IAM</mat-option>
                                <!-- <mat-option value="ECS">ECS</mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="showFields">
                    <div class="col-lg-3">
                        <div class="">Key</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <input matInput formControlName="key">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <div class="">Secret</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <input matInput formControlName="secret">
                        </mat-form-field>
                    </div>
                </div>

                <div class="mt-10 row">
                    <div class="col-lg-3">
                        <div class="">Secret Manager Name</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <input matInput formControlName="managerName">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3" *ngIf="from">
                        <div class="">Key Store Name</div>
                        <mat-form-field appearance="outline" class="mt-10">
                            <input matInput formControlName="keyStoreName">
                        </mat-form-field>
                    </div>
                </div>

                <div class="text-right" style="margin-top: auto;">
                    <button style="height: 35px;" *ngIf="!read" (click)='onSave()' mat-flat-button color="primary"
                        [disabled]="!form.valid" type="submit" class="text-white" cdkFocusInitial>
                        Save
                    </button>
                </div>

            </form>
        </section>
    </div>
</div>