import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { CryptoService } from '../../services/crypto.service';
import {
  DashboardState,
  selectDashboardState,
} from 'src/app/dashboard/store/dashboard.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProjectAuthFacadeService } from 'src/app/modules/eru-auth/state/project-files/project-auth-facade.service';
import { NotificationService } from 'src/app/database/services/notification.service';
import { ProjectFilesFacadeService } from 'src/app/modules/eru-files/state/project-files/project-files-facade.service';
import { ProjectQlFacadeService } from 'src/app/modules/eru-ql/state/project-ql/project-ql-facade.service';
import { ProjectRoutesFacadeService } from 'src/app/modules/eru-routes/state/project-routes/project-routes-facade.service';

@Component({
  selector: 'app-encrypt-decrypt',
  templateUrl: './encrypt-decrypt.component.html',
  styleUrls: ['./encrypt-decrypt.component.scss'],
})
export class EncryptDecryptComponent implements OnInit {
  @ViewChild('aesKey', { static: true }) aesKey!: ElementRef;
  @Input() read: any;
  encryptedParams: any;
  decryptedParams: any;
  claimKeyValue:string=''
  ismyTextFieldType: boolean | undefined;
  projectName: string= ''
  inputTextField!: ElementRef;
  projectFacadeService:any;
  activeLink: string[] = [];
    from =''
  constructor(
    private settingsService: SettingsService,
    private router: ActivatedRoute,
    private route: Router,
    private cryptoService: CryptoService,
    // private projectAuthFacadeService: ProjectAuthFacadeService,
    private notifyService: NotificationService,
    private injector: Injector
  ) {
    this.activeLink = this.route.url.split('/');
    this.from = this.activeLink[1];
    switch(this.from){
      case 'eru-auth':
        this.projectFacadeService = this.injector.get(ProjectAuthFacadeService);
        break;
        case 'eru-files':
          this.projectFacadeService = this.injector.get(ProjectFilesFacadeService);
          break;
          case 'eru-ql':
            this.projectFacadeService = this.injector.get(ProjectQlFacadeService);
            break;
              case 'eru-functions':
            this.projectFacadeService = this.injector.get(ProjectRoutesFacadeService);
            break;
    }




    this.router.queryParams.subscribe((params: any) => {
      this.settingsService.projectName = params.project;
      this.encryptedParams = params['q'];
      this.mapParamsValue();
    });
  }

  ngOnInit(): void {
    if(this.from != 'eru-gateway'){
      this.projectFacadeService.getProjectConfig$.subscribe((res: any) => {
        if (res.projectConfig && res.projectConfig.project) {
          console.log(res.projectConfig);
           let  {claims_key} =res.projectConfig['project']['project_settings']
           this.claimKeyValue = claims_key||''
        }
      });
    }else{
     this.settingsService .getProjectConfigGateway().subscribe({
      next:(res:any)=>{
        console.log(res)
        let {claims_key}= res['project_settings']
        this.claimKeyValue = claims_key||''
      }
     })
    }

  }

  mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const paramsArray = this.decryptedParams?.split('&').map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      this.projectName = paramsArray?.find((param:any) => param.key === 'project')?.value || '';
    }
  }

  togglePasswordFieldVisibility() {
    this.ismyTextFieldType = !this.ismyTextFieldType;
  }

  generateAESKey() {
    this.settingsService
      .getAesKey(this.projectName)
      .subscribe((response: any) => {
        this.inputTextField.nativeElement.value = response.key;
      });
  }

  onSave() {
    const body = {
      claims_key: this.claimKeyValue,
    };
    this.settingsService
      .newSaveConfig(body, this.projectName ,this.from == 'eru-gateway')
      .subscribe(
        {
          next:(res: any) => {
            this.notifyService.showSuccess(res.msg, 'Sucess');
      },
      error:(error)=>{
        this.notifyService.showError(error.error.error||'Something went wrong', 'Error');
      }

    });
  }
}
