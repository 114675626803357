<div class="serviceLoadingContainer">
    <div class="heading align-items-center d-flex justify-content-between headingPadding">
      <div class="titleStyle">{{ popupHeading }}</div>
      <img class="pointer"  (click)="close()" src="../../../../../../assets/images/icons/close_icon.png" alt="" />
    </div>
    <div class="container-padding">
        <div class="serviceLoadingSubContainer">
            <div class="d-flex justify-center">
              <img src="../../../../../../assets/images/icons/loading_icon.png" alt="" />
            </div>
            <div class="loadingtextStyle mt-20 text-center">
              Importing {{ popupHeading }}. Please wait...
            </div>
          </div>
    </div>
  </div>
