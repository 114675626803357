import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable, Observer } from "rxjs";
import { from } from "rxjs";
import {HttpService} from '../../core/services/http.service';
import { environment } from "src/environments/environment.prod";
import { APIConstants } from "src/app/core/constants/api-constants";
import { LoggerService } from "src/app/core/services/logger.service";
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ProjectCreationService {
  eru_ql_url : string = '';
  constructor(private httpService:HttpService,  
    private _route: ActivatedRoute) {
      const projectName = this._route.snapshot.queryParams.project; 
      const eruQlProject: any = localStorage.getItem(`${projectName}_eru-ql`);
      if (eruQlProject) { 
        const eruQlProjectParsed = JSON.parse(eruQlProject);
        this.eru_ql_url =  eruQlProjectParsed.service_path
      }
  }

  getProjects(): Observable<any> {
      const url:string=`${this.eru_ql_url}${APIConstants.GET_PROJECTS}`;
      return from(this.httpService.get(url));
  };

  addProject(projectName:any): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${projectName}/save`;
    return from(this.httpService.post(url,projectName));
  };

  deleteProject(data:any): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${data}/remove`;
    return from(this.httpService.delete(url));
  };

  getProjectConfig(project:any): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${project}/config`;
    return from(this.httpService.get(url));
  };  

  getProjectDataStoreConfig(): Observable<any> {
    const url:string=`${this.eru_ql_url}${APIConstants.GET_PROJECT_DATASTORE_CONFIG}`;
    return from(this.httpService.get(url));
  };  

  saveProjectDataStoreConfig(data:any,projectname:string): Observable<any> {
    console.log('data of store',data)
     const url:string=`${this.eru_ql_url}/store/${projectname}/datasource/save/${data.db_alias}`;
    return from(this.httpService.post(url,data));
  }; 
  
  removeProjectDataStoreConfig(projectname:string, dbAlias:any,): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${projectname}/datasource/remove/${dbAlias}`;
   return from(this.httpService.delete(url));
 };  

  getDefaultDriverConfig(db:string): Observable<any> {
    const url:string=`${this.eru_ql_url}${APIConstants.GET_DEFAULT_DRIVER_CONFIG}`;
    return from(this.httpService.get(url));
  };  

  getProjectDataStoreTables(project:any,dbAlias:any): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${project}/datasource/schema/${dbAlias}`;
    return from(this.httpService.get(url));
  }; 

  addTableToSchema(project:any,dbAlias:any,tableName:any): Observable<any> {
   // store/datasource/MyProjectNew/schema/postgrestestNew/addtable/test_inner_detail
 
    const url:string=`${this.eru_ql_url}/store/${project}/datasource/schema/${dbAlias}/addtable/${tableName}`;
    LoggerService.log('url  ',url);
    return from(this.httpService.post(url,{}));
  }; 
  removeTableFromSchema(project:any,dbAlias:any,tableName:any): Observable<any> {
    const url:string=`${this.eru_ql_url}/store/${project}/datasource/schema/${dbAlias}/removetable/${tableName}`;
    return from(this.httpService.delete(url));
  }; 

  getDataStores(project:string){
    const url:string=`${this.eru_ql_url}/store/${project}/datasource/list`;
    return from(this.httpService.get(url));
    
  }

}
