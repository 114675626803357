import { createAction, props } from '@ngrx/store';

const PROJECTFILES : string = 'PROJECTFILES';

export const LoadProjectConfig = createAction(`${PROJECTFILES} Load Project Config`,(payload: any = {}) => payload);
export const LoadProjectConfigSuccess = createAction(`${PROJECTFILES} Load Project Config Success`, 
                                                        props<{response : any}>());
export const LoadProjectConfigFailure = createAction(`${PROJECTFILES} Load Project Config Failure`, 
                                                        props<{error : any}>());

                                                        
export const ClearProjectFiles = createAction(`${PROJECTFILES} Clear Project Files`);