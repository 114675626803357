<div class="inviteuser-component">
    <form  [formGroup]="form" >
    <div class="heading">
        <h2 class="f-16 fw-500">Invite Users</h2>
        <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    </div>
    <hr>
    <div class="formArea">
        <form>
            <div class="row emailui">
                <div class="col-md-12 project-style darkgrey-outline">
                    <p class="f-12 fw-400 ">Email Address</p>
                    <mat-form-field class="mat-chip" appearance="outline" >
                        <mat-chip-list #chipList>
                          <mat-chip
                            *ngFor="let email of emails"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="remove(email)"
                          >
                            {{ email }}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>
                          <input
                          class="inviteInputStyle"
                            placeholder=""
                            #emailInput
                            [formControl]="emailCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)"
                          />
                        </mat-chip-list>
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          (optionSelected)="selected($event);emailInput.value=''"
                        >
                          <mat-option
                            *ngFor="let name of filteredEmails | async"
                            [value]="name.userEmail"
                          >


                       <ng-container>
                     <!-- <div class="option_text">
                      <div class="f-14 username" >  {{ name.userName }} (<span style="font-size: 9px;">{{name.userEmail}}</span>)</div>

                     </div> -->
                     <div class="option_text">
                      <div class="f-14 username" >   {{ name.userName }}</div> 
                      <div class="f-12 email">  {{name.userEmail}}</div>
                     </div>
                       </ng-container>


                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <mat-error *ngIf="invalidEmail" class="errText">Invalid Email </mat-error>
                    <span class="f-10 fw-400">Use comma (,) to seperate multiple email addresses</span>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-3 mt-20">
                    <mat-checkbox
                        (change)="onAllowCompleteAccess($event)"
                        [checked]="checkedBox"
                    ><span class="f-14 fw-400">Allow complete access</span></mat-checkbox>
                </div>
            </div> -->
            <div *ngFor="let ele of rows;let i =index">
                <div class="row selectArea">
                    <div class="col-md-4 project-style darkgrey-outline ">
                        <mat-form-field appearance="outline" class="w100">
                            <mat-select  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedData.projectID" placeholder="Select Project" (selectionChange)="selectProject($event)">
                                <mat-option *ngFor="let item of this.projectLists" [value]="item.project_id"  >
                                    <span class="px-2"> {{ item.project_name }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 project-style darkgrey-outline">
                        <mat-form-field appearance="outline" class="w100">
                            <mat-select  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedData.environment" placeholder="Select Environment" (selectionChange)="selectEnvironment($event)">
                                <mat-option *ngFor="let env of getEnvs(this.projectLists,selectedData.projectID)" [value]="env.env_id">
                                    <span class="px-2"> {{ env.env_name }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 project-style darkgrey-outline">
                        <mat-form-field appearance="outline" class="w100 service">
                            <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedData.services" placeholder="Select Services" multiple>
                                <mat-option *ngFor="let item of getServices(this.projectLists,selectedData.environment)" [value]="item">
                                  <div class="d-flex nestedoption_container px-2" style="display: flex !important;">
                                    {{ item.service_name }}
                                    <span (click)="$event.stopPropagation()">
                                      <div class="nestedoption1">
                                        <nested-option
                                          #nestedOption
                                          [id]="item.service_name"
                                          (accessHandler)="accessHandler($event, item)"
                                        ></nested-option>
                                      </div>
                                    </span>
                                  </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </form>
    </div>
    <div class="btnArea">
        <mat-dialog-actions align="end" style="position: relative;">
        <div>
          <button mat-dialog-close class="btn f-14 fw-500 btn-cancel">
              Cancel
          </button>
          <button
            class="btn f-14 fw-500 ml-10 btn-invite"
            type="submit"
            (click)="onSubmit()"
          >
            Invite
          </button>
        </div>
      </mat-dialog-actions>
    </div>
</form>
</div>
