<div class="popup-section" [ngClass]="(data.status =='Success')?'sucess-popup':'fail-popup'">
    <div class="popup-header">
        <span mat-dialog-title>{{data.title}}</span>
        <mat-icon mat-dialog-close class="pclose">close</mat-icon>
    </div>
    <div class="popup-body">
        <div mat-dialog-content class="message">
            <p class="dialog-message">{{data.message}}</p>
        </div>
    </div>
    <div class="popup-footer">
        <mat-dialog-actions class="d-flex justify-content-end">
            <button  (click)='cancel()'  mat-raised-button mat-dialog-close class="cancel">Cancel</button>
            <button (click)='confirm()'  mat-raised-button style="background-color: #ff341d; color: #fff; width: 88px; border: 1px solid #ff341d">{{data.confirmText || 'Yes' }}</button>
        </mat-dialog-actions>
    </div>
</div>