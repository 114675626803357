<div class="wDashboard-container">
    <div class="container-fluid">
        <div class=" wDashboard-inner">
            <div class="row">
                <div class="col-sm-12">
                    <h2 class="f-22 fw-600 mb-0">
                        Welcome to eru
                    </h2>
                </div>

                <div class="col-md-12 mt-20">
                    <div class="row mx-0">
                        <div class="col-md-3 card">
                            <div class="card-top d-flex"
                            (click)="openProjects()"
                            >
                                <div class="card-logo">
                                    <img src="../../../../assets/images/icons/wrapper-projects-active.svg" alt="">
                                    <p class="text-grey">Projects</p>
                                </div>
                                <div class="data-count">
                                    <h3>{{projectLength}}</h3>
                                </div>
                            </div>
                            <div class="card-actions cursor-pointer">
                                <a (click)="createProject()">Create New</a>
                            </div>
                        </div>
                        <div class="col-md-3 card">
                            <div class="card-top d-flex"
                            (click)="openUsers()">
                                <div class="card-logo">
                                    <img src="../../../../assets/images/icons/wrapper-users-active.svg" alt="">
                                    <p class="text-grey">Users</p>
                                </div>
                                <div class="data-count">
                                    <h3>00</h3>
                                </div>
                            </div>
                            <div class="card-actions cursor-pointer">
                                <a (click)="inviteUsers()">Invite User</a>
                            </div>
                        </div>
                        <!-- <div class="col-md-3" style="cursor: pointer;color: var(--orange1);">
                            <span (click)="notification()">Notification</span>
                        </div> -->
                        <div class="col-md-6 notification" *ngIf="!userService.invitations.clicked && userService.invitations.totalInvites>0">
                            <div style="box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15); padding: 10px;">
                                You have been added to {{userService.invitations.totalInvites}} new projects.<span style="color: var(--orange1); cursor: pointer;" (click)="notification()"> View Now</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-sm-12">
                    <div class="row detailsArea mt-50 sm-mt-20">
                        <div class="col-md-2 col-sm-6 details form-label">
                            <p class="f-14 fw-600 text-grey">Last Logged in </p>
                            <div class="f-14 fw-400">19 Aug 2022, 3:53 PM</div>
                        </div>
                        <div class="col-md-2 col-sm-6 details form-label">
                            <p class="f-14 fw-600 text-grey">Last Project Accessed</p>
                            <div class="f-14 fw-400">NA</div>
                        </div>
                        <div class="col-md-2 col-sm-6 details form-label">
                            <p class="f-14 fw-600 text-grey">Last Service Accessed</p>
                            <div class="f-14 fw-400">NA</div>
                        </div>
                        <div class="col-md-5 col-sm-6 details form-label">
                            <p class="f-14 fw-600 text-grey">eru services Enabled</p>
                            <div class="f-14 fw-400">eru-files, eru-routes, eru-auth, eru-ql</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="container-wrapper">
            <ul class="dashboard-icons-ul">
                <li class="cursor-pointer">
                    <img src="../../../../assets/images/icons/file 1document.svg" alt="" />
                    <div class="bLink">View documentation</div>
                </li>
                <li class="cursor-pointer">
                    <img src="../../../../assets/images/icons/online-video.svg" alt="" />
                    <div class="bLink">Learn through videos</div>
                </li>
                <li class="cursor-pointer">
                    <img src="../../../../assets/images/icons/discord.svg" alt="" />
                    <div class="bLink">Join our Discord community</div>
                </li>
                <li class="cursor-pointer">
                    <img src="../../../../assets/images/icons/github.svg" alt="" />
                    <div class="bLink">Visit us on Github</div>
                </li>
            </ul>
        </div>
    </div>
</div>
