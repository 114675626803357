import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupComponent } from 'src/app/modules/eru-wrapper/components/popup/popup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonPopupComponent } from 'src/app/modules/shared/common-popup/common-popup.component';
import { CryptoService } from '../../services/crypto.service';
import { NotificationService } from 'src/app/database/services/notification.service';
@Component({
  selector: 'app-variables-tab',
  templateUrl: './variables-tab.component.html',
  styleUrls: ['./variables-tab.component.scss']
})
export class VariablesTabComponent implements OnInit {
  form!: FormGroup;
  @Input()projectName:any=''
vars : any;
  @Input()read:any;

  readValue:any;
  encryptedParams: any;
  decryptedParams: any;
  isEditClick: boolean = false;
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private sharedService : SharedService,
    private notificationService:NotificationService,
    private router: Router,
    private cryptoService: CryptoService,
    private notifyService:NotificationService,
    private route: ActivatedRoute) {
    this.route.queryParams
    .subscribe((params : any) => {
      this.encryptedParams = params['q'];
    });



     this.form = this.fb.group({
      variables:  this.fb.array([this.newVariables()]),
    });
   }
  ngOnInit(): void {
    this.mapParamsValue();
    let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

    this.sharedService.fetchVars(this.projectName,isEruGateway).subscribe((res:any)=>{
      let  {env_vars,secrets,vars}= res
      this.vars=vars

      if(this.vars){
        this.variables().clear();
        const varKeys = Object.keys(this.vars)
        varKeys.forEach((e :any , index :any) => {
          const obj = this.vars[e]
          const getVars = new FormGroup({
            keyName: new FormControl({value:obj.key, disabled: true}),
            valueName: new FormControl(obj.value)
          })
          this.variables().push(getVars);
        })
      }

     },()=>{

     })


  }

  ngOnChanges(changes: SimpleChanges){
    if(this.vars){
      this.variables().clear();
      const varKeys = Object.keys(this.vars)
      varKeys.forEach((e :any , index :any) => {
        const obj = this.vars[e]
        const getVars = new FormGroup({
          keyName: new FormControl({value:obj.key, disabled: true}),
          valueName: new FormControl(obj.value)
        })
        this.variables().push(getVars);
      })
      // this.variables().push(this.newVariables())

    }
  }
  mapParamsValue() {
    this.decryptedParams = this.cryptoService.decrypt(this.encryptedParams);
    if (this.decryptedParams) {
      const keyValuePairs = this.decryptedParams?.split('&');
      const paramsArray = keyValuePairs?.map((pair: string) => {
        const [key, value] = pair?.split('=');
        return { key, value };
      });
      if (paramsArray) {
        for (const param of paramsArray) {
          if (param.key === 'project') {
            this.projectName = param.value;
          } else if (param.key === 'read') {
            this.readValue = param.value;
            if (this.readValue === 'true') {
              this.read = true;
            } else {
              this.read = false;
            }
          }
        }
      }
    }
  }
variables(): FormArray {
    return this.form.get('variables') as FormArray;
  }
  newVariables(): FormGroup {
    return this.fb.group({
      keyName: [''],
      valueName: ['']
    });
  }
  addVariables() {
    this.variables().push(this.newVariables());
  }
  // removeVariables(index:number) {
  //   this.variables().removeAt(index);
  // }

  removeVariables(index:number){
  console.log(this.variables().controls, 'check');
  const dialogRef = this.dialog.open(CommonPopupComponent, {
    data: {
      title: 'Delete Variable',
      message : 'Are you sure, you want to delete the variable.'
    }
  });

  dialogRef.afterClosed().subscribe((result : any) => {
    if(result.event == 'Ok'){
      let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

      const variables : any = this.variables().controls;
      this.sharedService
        .deleteVariables(
          this.projectName,
          variables[index].controls.keyName.value,
          isEruGateway
        )
        .subscribe(
          (res: any) => {
            this.variables().removeAt(index);
            this.notifyService.showSuccess(res.msg, 'Sucess');
          },
          (error: any) => {
            this.notifyService.showError(
              error.error.error || 'Something went wrong',
              'Error'
            );
          }
        );
    }
  });
}
edit(index: any) {
console.log(index);
this.isEditClick = true;
}

cancel(index: any) {
  console.log(index);
  this.isEditClick = false;
}

  saveVars(index : any){
    let isEruGateway = new URL(`${window.location.href}`).pathname.split('/')[1] == 'eru-gateway'

    const variables : any = this.variables().controls;
    let key = variables[index].controls.keyName.value;
    let value = variables[index].controls.valueName.value;
    if(key && key.trim() !== "" && value && value.trim() !== "" )
    {
      const data = {
        "key":key,
        "value":value
      }
      this.sharedService.saveVariables(this.projectName, data,isEruGateway).subscribe((res : any) => {

        this.variables().controls[index].get('keyName')?.disable();
        this.notifyService.showSuccess(res.msg, 'Sucess');
      },(error:any)=>{
        this.notifyService.showError(error.error.error||'Something went wrong', 'Error');

      })
    }

  }
}
