import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import{CommonsUiModule}from'./commons-ui/commons-ui.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./store/reducers";
import { EffectsModule } from "@ngrx/effects";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import * as project from "./project-creation/store/reducers/project-creations.reducers";
import * as dashboard from "./dashboard/store/reducers/dashboard.reducers";
//import * as database from "./database/store/reducers/database.reducers";
//import{ProjectCreationsEffects} from './project-creation/store/effects/project-creations.effects';
import {DashboardEffects} from './dashboard/store/effects/dashboard.effects';
//import {DatabaseEffects} from './database/store/effects/database.effects'
import {ProjectCreationService} from './project-creation/services/project-creation.service';
import {HttpService} from './core/services/http.service';
import {LoggerService} from './core/services/logger.service';
import {ApplicationService} from './core/services/application.service';
import { ToastrModule } from 'ngx-toastr';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { AuthInterceptor } from 'src/app/core/auth.interceptor';
import { appInitializer } from 'src/app/core/app.initializer';
import { AllMaterialModule } from 'src/app/material-module';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { EruWrapperModule } from 'src/app/modules/eru-wrapper/eru-wrapper.module';
import { SharedModule } from './modules/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgOtpInputModule } from 'ng-otp-input';
@NgModule({
  declarations: [AppComponent, CommonDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AllMaterialModule,
    HttpClientModule,
    EruWrapperModule,
    MatDialogModule,
    CommonsUiModule,
    NgOtpInputModule,
    ToastrModule.forRoot(),
    SharedModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([
     // ProjectCreationsEffects,
      DashboardEffects,
    ]),
  ],

  providers: [
    HttpService,
    ProjectCreationService,
    LoggerService,
    ApplicationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
