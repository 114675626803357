import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CoreModule } from '../../core/core.module';
import { CommonsUiModule } from 'src/app/commons-ui/commons-ui.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

import { EruWrapperRoutingModule } from './eru-wrapper-routing.module';
import { EruWrapperComponent } from './eru-wrapper.component';
import { DashboardHomeComponent } from 'src/app/dashboard/components/home/home.component';
import { UsersComponent } from './components/users/users.component';
import { InviteUsersComponent } from './components/invite-users/invite-users.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ErugServicesComponent } from './components/erug-services/erug-services.component';
import { DefineProjectComponent } from './components/define-project/define-project.component';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProjectsService } from 'src/app/modules/eru-wrapper/services/projects.service';
import { UsersService } from 'src/app/modules/eru-wrapper/services/users.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { projectReducer } from 'src/app/modules/eru-wrapper/state/project/project.reducer';
import { ProjectCreationsEffects } from 'src/app/project-creation/store/effects/project-creations.effects';
import { ProjectFacadeService } from 'src/app/modules/eru-wrapper/state/project/project-facade.service';
import { ProjectEffect } from 'src/app/modules/eru-wrapper/state/project/project.effect';
import { WrapperDashboardComponent } from './components/wrapper-dashboard/wrapper-dashboard.component';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { SettingsComponent } from './components/settings/settings.component';
import { InvitePopupComponent } from './components/invite-popup/invite-popup.component';
import { NestedOptionComponent } from './components/invite-popup/nestedOption.component';
import { AllMaterialModule } from 'src/app/material-module';
import { ImportComponent } from './components/import/import.component';
import { ImportLoaderComponent } from './components/import/import-loader/import-loader.component';
import { RepositoryComponent } from './components/repository/repository.component';
import { SharedModule } from '../shared/shared.module';
import { EruRepoComponent } from './components/eru-repo/eru-repo.component';
import { MyInvitiesTableComponent } from './components/my-invities-table/my-invities-table.component';

@NgModule({
  declarations: [
    EruWrapperComponent,
    UsersComponent,
    InviteUsersComponent,
    EditUserComponent,
    ProjectsComponent,
    ErugServicesComponent,
    DefineProjectComponent,
    NewProjectComponent,
    PopupComponent,
    WrapperDashboardComponent,
    CommonPopupComponent,
    SettingsComponent,
    InvitePopupComponent,
    NestedOptionComponent,
    ImportComponent,
    ImportLoaderComponent,
    RepositoryComponent,
    EruRepoComponent,
    MyInvitiesTableComponent,
    
  ],
  imports: [
    // RouterModule.forChild(routes),
    CommonModule,
    EruWrapperRoutingModule,
    AllMaterialModule,
    CoreModule,
    CommonsUiModule,
    MatTableModule,
    SharedModule,
    StoreModule.forFeature('PROJECT', projectReducer),
    EffectsModule.forFeature([ProjectEffect]),
  ],
  providers: [ProjectFacadeService, ProjectsService, UsersService],
})
export class EruWrapperModule {}
